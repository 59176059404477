<template>
	<div class="page-container">
		<Head />
		<Foot />
	</div>
</template>

<script>
	// @ is an alias to /src
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			Foot
		},
		data() {
			return {};
		},
		methods: {

		}
	}
</script>
