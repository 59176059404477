<template>
	<div>
		<div class="header">
			<div class="container-fluid flex flex-ac">
				<div class="logo">
					<router-link to="/"><img src="../assets/photo/logo.png" alt=""></router-link>
				</div>
				<!-- pC -->
				<div class="flex flex_bd head-body hidden-xs">
					<div class="switch-box"><div class="switch-item">{{$t('exchange')}}</div> <div class="switch-item">DAPP</div></div>
					<el-menu :default-active="$route.path" @select="handleSelect" class="el-menu-nav" mode="horizontal"
						:router="true">
						<!-- <el-menu-item index="/">{{$t('meun1')}}</el-menu-item> -->
						<!-- 一键买币 -->
						<!-- <el-menu-item index="/trade/USDT">{{$t('meun2')}}</el-menu-item> -->
						<!-- 现货交易 -->
						<el-menu-item index="/exchange/btc_usdt?1">{{$t('meun3_1')}}
						</el-menu-item>
						<!-- 行情中心 -->
						<el-menu-item index="/exchange/btc_usdt?2">{{$t('markets')}}
						</el-menu-item>
						<!-- 云算力 -->
						<el-menu-item index="/cloudMining"><img class="hot" src="../assets/photo/hot.png"
									alt="">{{$t('CloudMining')}}
						</el-menu-item>
						<!-- <el-menu-item index="#"><img class="hot" src="../assets/photo/hot.png"
									alt="">{{$t('CloudMining')}}
						</el-menu-item> -->
						<!-- <el-submenu index="1" popper-class="legal-submenu">
							<template slot="title">{{$t('meun3')}}</template>
							<el-menu-item index="/exchange/btc_usdt"><img src="../assets/photo/in1.png"
									alt="">{{$t('meun3_1')}}
							</el-menu-item>
							<el-menu-item index="/convert"><img src="../assets/photo/in2.png" alt="">{{$t('meun3_2')}}
							</el-menu-item>
						</el-submenu> -->
						<!-- 合约交易 -->
						<!-- <el-submenu index="2" popper-class="legal-submenu">
							<template slot="title">{{$t('meun4')}}</template>
							<el-menu-item index="/swap/btc_usdt"><img src="../assets/photo/in3.png"
									alt="">{{$t('meun4_1')}}
							</el-menu-item>
							<el-menu-item index="/coinswap/btc_usdt"><img src="../assets/photo/in4.png"
									alt="">{{$t('meun4_2')}}
							</el-menu-item>
							<el-menu-item index="/option"><img src="../assets/photo/in5.png" alt="">{{$t('meun4_3')}}
							</el-menu-item>
							<el-menu-item index="/second/btc_usdt"><img src="../assets/photo/in6.png"
									alt="">{{$t('meun4_4')}}
							</el-menu-item> -->
							<!-- <li class="el-menu-item" @click="toSwap"><img src="../assets/photo/in3.png"
									alt="">{{$t('meun4_1')}}</li>
							<li class="el-menu-item" @click="toCoinswap"><img src="../assets/photo/in4.png" alt="">{{$t('meun4_2')}}</li>
							<el-menu-item index="/option"><img src="../assets/photo/in5.png" alt="">{{$t('meun4_3')}}
							</el-menu-item>
							<li class="el-menu-item" @click="toSecond"><img src="../assets/photo/in6.png"
									alt="">{{$t('meun4_4')}}</li> -->
						<!-- </el-submenu> -->
						<!-- <el-submenu index="3" popper-class="legal-submenu">
							<template slot="title">{{$t('meun5')}}</template>
							<el-menu-item index="/lab"><img src="../assets/photo/in7.png" alt="">{{$t('meun5_1')}}
							</el-menu-item>
							<el-menu-item index="/invite"><img src="../assets/photo/in8.png" alt="">{{$t('meun5_2')}}
							</el-menu-item>
						</el-submenu> -->
						<!-- 理财 -->
						<el-menu-item index="/earn">{{$t('meun6')}}</el-menu-item>
						<!-- 公告 -->
						<!-- <el-menu-item index="/announcement/0">{{$t('meun7')}}</el-menu-item> -->
						<el-menu-item index="/announcement/0"><img class="hot" src="../assets/photo/hot.png"
									alt="">{{$t('Activities')}}</el-menu-item>
					</el-menu>
				</div>
				<div class="head-right flex flex-ac hidden-sm">
					<div v-if="isLogin">
					<!-- <div v-if="isLogin == false" class=" menu-other"> -->
						<el-menu class="el-menu-nav" mode="horizontal" :router="true">
							<el-submenu index="1" popper-class="legal-submenu">
								<template slot="title">{{$t('meun10')}}</template>
								<el-menu-item index="/money"><img src="../assets/photo/in1.png"
										alt="">{{$t('meun10_1')}}
								</el-menu-item>
								<!-- <el-menu-item index="/swapAssets"><img src="../assets/photo/in3.png"
										alt="">{{$t('meun10_2')}}
								</el-menu-item>
								<el-menu-item index="/coinSwapAssets"><img src="../assets/photo/in4.png"
										alt="">{{$t('meun10_3')}}
								</el-menu-item>
								<el-menu-item index="/secondAssets"><img src="../assets/photo/in6.png"
										alt="">{{$t('meun10_4')}}
								</el-menu-item>
								<el-menu-item index="/financeAssets"><img src="../assets/photo/in9.png"
										alt="">{{$t('meun10_5')}}
								</el-menu-item> -->
								<el-menu-item index="/walletHistory"><img src="../assets/photo/in10.png"
										alt="">{{$t('meun10_6')}}
								</el-menu-item>
							</el-submenu>
							<el-submenu index="2" popper-class="legal-submenu">
								<template slot="title">{{$t('meun11')}}</template>
								<el-menu-item index="/spotOrder"><img src="../assets/photo/in11.png"
										alt="">{{$t('meun11_1')}}
								</el-menu-item>
								<!-- <el-menu-item index="/ad"><img src="../assets/photo/in12.png" alt="">{{$t('meun11_2')}}
								</el-menu-item>
								<el-menu-item index="/earnOrder"><img src="../assets/photo/in13.png"
										alt="">{{$t('meun11_3')}}
								</el-menu-item>
								<el-menu-item index="/convertHistory"><img src="../assets/photo/in14.png"
										alt="">{{$t('meun11_4')}}
								</el-menu-item>
								<el-menu-item index="/innovation/myorders"><img src="../assets/photo/in15.png"
										alt="">{{$t('meun11_5')}}
								</el-menu-item> -->
							</el-submenu>
							<el-submenu index="3" popper-class="legal-submenu">
								<template slot="title">
									<div class="user-avatar">
										<img :src="avatar" alt="">
									</div>
								</template>
								<el-menu-item index="/admin"><img src="../assets/photo/in11.png"
										alt="">{{$t('meun13_1')}}
								</el-menu-item>
								<!-- <el-menu-item index="/collection"><img src="../assets/photo/in12.png"
										alt="">{{$t('meun13_2')}}
								</el-menu-item>
								<el-menu-item index="/promotion"><img src="../assets/photo/in13.png"
										alt="">{{$t('meun13_3')}}
								</el-menu-item> -->
								<el-menu-item index="/api"><img src="../assets/photo/in14.png" alt="">{{$t('meun13_4')}}
								</el-menu-item>
								<li class="el-menu-item" @click="logOutClick"><img src="../assets/photo/in20.png"
										alt="">{{$t('meun13_5')}}</li>
							</el-submenu>
						</el-menu>
					</div>
					<div v-else class="head-nav">
						<el-menu class="el-menu-nav" mode="horizontal" :router="true">
							<el-menu-item index="/login"><el-button size="small" type="primary">{{$t('meun8')}}</el-button></el-menu-item>
							<el-menu-item index="/register"><el-button size="small">{{$t('meun9')}}</el-button></el-menu-item>
						</el-menu>
					</div>
					
					<img src="../assets/photo/notice.png" class="notice-btn" alt="">
					<div class="head-download">
						<!-- <el-popover placement="bottom" width="120" trigger="hover">
							<img slot="reference" src="../assets/photo/download.png" class="download-btn" alt="">
							<div class="ios">
								<img src="../assets/photo/web/appdownload.png" alt="" />
								<div class="tips">
									<span>{{ $t("scandownload") }}</span>
								</div>
							</div>
						</el-popover> -->
						<!-- 翻译 -->
						<el-dropdown>
							<span class="el-dropdown-link">
								<img slot="reference" src="../assets/photo/language.png"  class="notice-btn" alt="">
							</span>
							<el-dropdown-menu slot="dropdown"  class="language-dropdown">
								<el-dropdown-item v-for="(item,index) in diaList" :key="index">
									<el-radio v-model="current" :label="index"  @input="toggle(item,index)">{{item.name}}</el-radio>
								</el-dropdown-item>
								<!-- <div class="dia_col" :class="{active:index == current}" >
										{{item.name}}
									</div> -->
							</el-dropdown-menu>
						</el-dropdown>
						<!-- <el-menu class="el-menu-nav" mode="horizontal" :router="true">
						<el-menu-item @click="langClick">
							<div class="menu-local">
								{{diaList[current].name}}
							</div>
						</el-menu-item>
					</el-menu> -->
					</div>
				</div>
				<!-- USDT -->
				<!-- {{currentList[current2].fullName}} -->
				<!-- <div class="menu-nav flex flex-ac hidden-sm">
					<div class="unit-span" @click="currentClick">{{currentInfo.fullName}}</div> -->
					<!-- <div class="menu-lang">
						<el-button v-if="theme" @click="themeClick('black')"><span class="iconfont icon-sun"></span>
						</el-button>
						<el-button v-else @click="themeClick('white')"><span class="iconfont icon-moon"></span>
						</el-button>
					</div> -->
				<!-- </div> -->
				<!-- H5 -->
				<div class="menu-icon pc-menu" @click="drawer = true">
					<span class="iconfont icon-menu"></span>
				</div>
			</div>
		</div>
		<el-drawer :modal="false"  :show-close="false" :visible.sync="drawer" :direction="direction" size='30%' custom-class="custom-drawer">
			<div class="head-body head-drawer-body">
				<el-menu :default-active="activeIndex" class="el-menu-nav" :router="true">
					<div class="menu-icon" @click="drawer = false">
						<span class="iconfont icon-menu icon-menu-dark"></span>
					</div>
					<div class="drawer-switch-line"></div>
					<div class="switch-box drawer-switch"><div class="switch-item">{{$t('exchange')}}</div> <div class="switch-item">DAPP</div></div>
					<!-- <el-menu-item index="/">{{$t('meun1')}}</el-menu-item> -->
					<!-- <el-menu-item index="/trade/USDT">{{$t('meun2')}}
					</el-menu-item> -->
					<!-- 现货交易 -->
					<el-menu-item index="/exchange/btc_usdt?1">{{$t('meun3_1')}}
						</el-menu-item>
					<!-- <el-submenu index="1">
						<template slot="title">{{$t('meun3')}}</template>
						<el-menu-item index="/exchange/btc_usdt"><img src="../assets/photo/in1.png"
								alt="">{{$t('meun3_1')}}
						</el-menu-item> -->
						<!-- <el-menu-item index="/exchange/btc_usdt"><img src="../assets/photo/in1.png"
								alt="">{{$t('meun3_1')}}</el-menu-item> -->
						<!-- <el-menu-item index="/convert"><img src="../assets/photo/in2.png" alt="">{{$t('meun3_2')}}
						</el-menu-item>
					</el-submenu> -->
					<!-- 行情中心 -->
					<el-menu-item index="/exchange/btc_usdt?2">{{$t('markets')}}
					</el-menu-item>
					<!-- 云算力 -->
					<el-menu-item index="/cloudMining"><img class="hot" src="../assets/photo/hot.png"
								alt="">{{$t('CloudMining')}}
					</el-menu-item>
					<!-- <el-menu-item index="#"><img class="hot" src="../assets/photo/hot.png"
								alt="">{{$t('CloudMining')}}
					</el-menu-item> -->
					<!-- <el-submenu index="2">
						<template slot="title">{{$t('meun4')}}</template>
						<el-menu-item index="/swap/btc_usdt"><img src="../assets/photo/in3.png" alt="">{{$t('meun4_1')}}
						</el-menu-item>
						<el-menu-item index="/coinswap/btc_usdt"><img src="../assets/photo/in4.png"
								alt="">{{$t('meun4_2')}}
						</el-menu-item>
						<el-menu-item index="/option"><img src="../assets/photo/in5.png" alt="">{{$t('meun4_3')}}
						</el-menu-item>
						<el-menu-item index="/second/btc_usdt"><img src="../assets/photo/in6.png"
								alt="">{{$t('meun4_4')}}
						</el-menu-item> -->
						<!-- <li class="el-menu-item" @click="toSwap"><img src="../assets/photo/in3.png"
								alt="">{{$t('meun4_1')}}</li>
						<li class="el-menu-item" @click="toCoinswap"><img src="../assets/photo/in4.png"
								alt="">{{$t('meun4_2')}}</li>
						<el-menu-item index="/option"><img src="../assets/photo/in5.png" alt="">{{$t('meun4_3')}}
						</el-menu-item>
						<li class="el-menu-item" @click="toSecond"><img src="../assets/photo/in6.png"
								alt="">{{$t('meun4_4')}}</li> -->
					<!-- </el-submenu> -->
					<!-- <el-submenu index="3">
						<template slot="title">{{$t('meun5')}}</template>
						<el-menu-item index="/lab"><img src="../assets/photo/in7.png" alt="">{{$t('meun5_1')}}
						</el-menu-item>
						<el-menu-item index="/invite"><img src="../assets/photo/in8.png" alt="">{{$t('meun5_2')}}
						</el-menu-item>
					</el-submenu> -->
					<el-menu-item index="/earn">{{$t('meun6')}}</el-menu-item>
					<el-menu-item index="/announcement/0"><img class="hot" src="../assets/photo/hot.png"
									alt="">{{$t('Activities')}}</el-menu-item>
					<!-- <el-menu-item index="/announcement/0">{{$t('meun7')}}</el-menu-item> -->
				</el-menu>
				<div class="el-line"></div>
				<el-menu class="el-menu-nav" :router="true">
					<el-submenu index="1" v-if="isLogin">
						<template slot="title">{{$t('meun10')}}</template>
						<el-menu-item index="/money"><img src="../assets/photo/in1.png" alt="">{{$t('meun10_1')}}
						</el-menu-item>
						<!-- <el-menu-item index="/swapAssets"><img src="../assets/photo/in17.png" alt="">{{$t('meun10_2')}}
						</el-menu-item>
						<el-menu-item index="/coinSwapAssets"><img src="../assets/photo/in18.png"
								alt="">{{$t('meun10_3')}}
						</el-menu-item>
						<el-menu-item index="/secondAssets"><img src="../assets/photo/in19.png"
								alt="">{{$t('meun10_4')}}</el-menu-item>
						<el-menu-item index="/financeAssets"><img src="../assets/photo/in9.png"
								alt="">{{$t('meun10_5')}}
						</el-menu-item> -->
						<el-menu-item index="/walletHistory"><img src="../assets/photo/in10.png"
								alt="">{{$t('meun10_6')}}
						</el-menu-item>
					</el-submenu>
					<el-submenu index="2" v-if="isLogin">
						<template slot="title">{{$t('meun11')}}</template>
						<el-menu-item index="/spotOrder"><img src="../assets/photo/in11.png" alt="">{{$t('meun11_1')}}
						</el-menu-item>
						<!-- <el-menu-item index="/ad"><img src="../assets/photo/in12.png" alt="">{{$t('meun11_2')}}
						</el-menu-item>
						<el-menu-item index="/earnOrder"><img src="../assets/photo/in13.png" alt="">{{$t('meun11_3')}}
						</el-menu-item>
						<el-menu-item index="/convertHistory"><img src="../assets/photo/in14.png"
								alt="">{{$t('meun11_4')}}
						</el-menu-item>
						<el-menu-item index="/innovation/myorders"><img src="../assets/photo/in15.png"
								alt="">{{$t('meun11_5')}}
						</el-menu-item> -->
					</el-submenu>
					<!-- <el-menu-item index="">{{$t('meun12')}}</el-menu-item>
					<el-menu-item @click="langClick">
						{{diaList[current].name}}
					</el-menu-item> -->
				</el-menu>
				<div class="menu-nav flex flex-ac el-menu-user">
					<el-menu v-if="isLogin == true" class="el-menu-nav " mode="horizontal" :router="true"
						menu-trigger="click">
						<el-submenu index="1" class="el-menu-user-sub">
							<template slot="title">
								<div class="user-avatar">
									<img src="../assets/photo/user.png" alt="">
								</div>
							</template>
							<el-menu-item index="/admin"><img src="../assets/photo/in11.png" alt="">{{$t('meun13_1')}}
							</el-menu-item>
							<el-menu-item index="/collection"><img src="../assets/photo/in12.png"
									alt="">{{$t('meun13_2')}}
							</el-menu-item>
							<!-- <el-menu-item index="/promotion"><img src="../assets/photo/in13.png"
									alt="">{{$t('meun13_3')}}
							</el-menu-item>
							<el-menu-item index="/api"><img src="../assets/photo/in14.png" alt="">{{$t('meun13_4')}}
							</el-menu-item> -->
							<li class="el-menu-item" @click="logOutClick"><img src="../assets/photo/in20.png"
									alt="">{{$t('meun13_5')}}</li>
						</el-submenu>
					</el-menu>
					<el-menu v-else class="el-menu-nav el-menu-btns" :router="true">
						<!-- <el-menu-item index="/login">{{$t('meun8')}}</el-menu-item>
						<el-menu-item index="/register">{{$t('meun9')}}</el-menu-item> -->
						<el-menu-item index="/login"><el-button size="small" type="primary">{{$t('meun8')}}</el-button></el-menu-item>
							<el-menu-item index="/register"><el-button class="register" size="small">{{$t('meun9')}}</el-button></el-menu-item>
					</el-menu>
					<!-- <div class="unit-span" @click="currentClick">{{currentInfo.fullName}}</div> -->
					<!-- <div class="menu-lang">
						<el-button v-if="theme" @click="themeClick('black')"><span class="iconfont icon-sun"></span>
						</el-button>
						<el-button v-else @click="themeClick('white')"><span class="iconfont icon-moon"></span>
						</el-button>
					</div> -->
				</div>
			</div>
		</el-drawer>
		<div class="language">
			<el-dialog :visible.sync="dialogVisible" width="840px" top="18vh" :close-on-click-modal="false">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane :label="$t('meun14_1')" name="first">
						<div class="dialog_name">{{$t('meun14_3')}}</div>
						<div class="dialog_list">
							<el-row>
								<el-col :lg="6" :xs="8" v-for="(item,index) in diaList" :key="index">
									<div class="dia_col" :class="{active:index == current}" @click="toggle(item,index)">
										{{item.name}}
									</div>
								</el-col>
							</el-row>
						</div>
					</el-tab-pane>
					<el-tab-pane :label="$t('meun14_2')" name="second">
						<div class="dialog_name">{{$t('meun14_4')}}</div>
						<div class="dialog_list">
							<el-row>
								<el-col :lg="6" :xs="8" v-for="(item,index) in currentList" :key="index">
									<div class="dia_col" :class="{active:index == current2}"
										@click="toggleCurrent(item,index)">
										{{item.fullName}}-{{item.symbol}}
									</div>
								</el-col>
							</el-row>
						</div>
					</el-tab-pane>
				</el-tabs>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		loginOut,
		currencyFindAll
	} from '@/api/api/user'
	export default {
		name: 'Header',
		inject: ['reload'],
		data() {
			return {
				activeIndex: '1',
				navinput: '',
				drawer: false,
				direction: 'rtl',
				theme: false,
				dialogVisible: false,
				activeName: 'first',
				diaList: [{
						name: "English",
						value: 'en_US'
					},
					{
						name: "简体中文",
						value: 'zh_CN'
					},
					// {
					// 	name: "繁體中文",
					// 	value: 'zh_HK'
					// },
					// {
					// 	name: "日本語",
					// 	value: 'ja_JP'
					// },
					// {
					// 	name: "한국인",
					// 	value: 'ko_KR'
					// },
					// {
					// 	name: "Deutsch",
					// 	value: 'de_DE'
					// },
					// {
					// 	name: "Français",
					// 	value: 'fr_FR'
					// },
					// {
					// 	name: "Italiano",
					// 	value: 'it_IT'
					// },
					// {
					// 	name: "español",
					// 	value: 'es_ES'
					// },
				],
				currentList: [],
				current: 0,
				current2: 0,
				avatar: require('../assets/photo/user.png'),
				symbol: ''
			};
		},
		created() {
			this.symbol = localStorage.getItem('setSymbol')
		},
		computed: {
			isLogin: function() {
				console.log(this.$store.getters.isLogin)
				return this.$store.getters.isLogin;
			},
			currentInfo: function() {
				return this.$store.getters.current
			}
		},
		mounted() {
			// let user = this.$store.state.user
			// console.log(this.$store.state.user)
			// if(user.avatar){
			// 	this.avatar = user.avatar
			// }

			let theme = localStorage.getItem('theme')
			if (theme == 'black') {
				this.theme = false
			} else {
				this.theme = true
			}
			let isZh = localStorage.getItem('lang')
			if (isZh == 'en_US') {
				this.current = 0
			}
			if (isZh == 'zh_CN') {
				this.current = 1
			}
      if (isZh == 'zh_HK') {
        this.current = 2
      }
      if (isZh == 'ja_JP') {
        this.current = 3
      }
      if (isZh == 'ko_KR') {
        this.current = 4
      }
      if (isZh == 'de_DE') {
        this.current = 5
      }
      if (isZh == 'fr_FR') {
        this.current = 6
      }
      if (isZh == 'it_IT') {
        this.current = 7
      }
      if (isZh == 'es_ES') {
        this.current = 8
      }
			this.getCurrent()
		},
		methods: {

			getCurrent() {
				// this.$axios({
				// 	url: '/api2/currency/findAll',
				// 	method: 'get',
				// }).then(res => {
				// 	if (res.data.code == 0) {
				// 		this.currentList = res.data.data
				// 	}
				// })
				currencyFindAll().then(res => {
					if (res.code == 0) {
						this.currentList = res.data
					}
				})
			},
			handleSelect(key, keyPath) {
				console.log(key, keyPath);
			},
			logOutClick() {
				loginOut().then(res => {
					if (res.code == 0) {
						localStorage.removeItem('token')
						this.$store.commit('SET_ISLOGIN', false)
						this.$store.commit("SET_MEMBER", null);
						this.isLogin = false
						this.$message({
							message: '退出成功',
							type: 'success'
						});
						this.$router.push({
							path: '/'
						})
						// location.reload()
					}
				})
			},
			themeClick(theme) {
				this.theme = !this.theme
				this.$store.dispatch('setTheme', theme)
				window.document.documentElement.setAttribute('data-theme', theme);
			},
			handleClick(tab, event) {
				console.log(tab, event);
			},
			toggle(item, index) {
				this.current = index
				this.dialogVisible = false
				this.$i18n.locale = item.value
				this.$emit('newLocal', item.value)
				localStorage.setItem('lang', item.value)
				this.reload()
			},
			toggleCurrent(item, index) {
				this.dialogVisible = false
				this.current2 = index
				// this.currentList.splice(index, 1)
				// this.currentList.unshift(item)
				// this.$emit('setCurrent', item)
				this.$store.commit('SET_CURRENT', item)
			},
			langClick() {
				this.activeName = 'first'
				this.dialogVisible = true
			},
			currentClick() {
				this.dialogVisible = true
				this.activeName = 'second'
			}
		},
		watch: {

		}
	}
</script>
<style>
	.el-menu-user .el-menu .el-icon-arrow-down {
		color: var(--theme-color) !important;
	}
	.el-menu-user-sub .el-menu {
		background-color: var(--head-theme-color) !important;
	}
	.el-menu--horizontal .el-menu .el-menu-item {
		background-color: var(--head-theme-color) !important;
	}
	.el-menu {
		background-color: var(--head-theme-color) !important;
	}
	/* @media (max-width:1450px) {
		.hidden-sm {
			display: none !important;
		}
		
	} */

	/* @media screen and (min-width: 1201px) {
		.custom-drawer {
			display: none !important;
			overflow: hidden;
		}
	} */
	.custom-drawer {
		display: none !important;
		overflow: hidden;
		height: 0;
		width: 0;
	}
	.pc-menu {
		display: none !important;
	}
	@media (max-width:1200px) {
		.pc-menu {
			display: block !important;
		}
		.custom-drawer {
			display: block !important;
			overflow: auto;
		}
		.hidden-sm {
			display: none !important;
		}
		.hidden-xs {
			display: none !important;
		}
		
		.el-menu-item,
		.el-submenu__title {
			height: 42px !important;
			line-height: 42px !important;

		}

		.el-drawer__header {
			margin-bottom: 0 !important;
		}

		.menu-nav {
			width: 100% !important;
			padding: 0 20px !important;
		}

		.el-menu-user .el-submenu__title {
			padding: 0 5px;

		}

		.head-body .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
			margin-left: 8px;
			margin-top: 0;
			align-items: center;
		}

		.el-drawer__body {
			overflow-y: auto;
		}

		.el-menu-btns.el-menu-nav>.el-menu-item {
			padding: 0 15px !important;
		}

		.el-menu-btns.el-menu-nav>.el-menu-item:first-child {
			padding-left: 0 !important;
		}
	}

	@media(max-width:768px) {
		.el-menu-btns.el-menu-nav>.el-menu-item {
			padding: 0 !important;
			max-width: 50px;
			margin-right: 7px !important;
		}


		.menu-nav .unit-span {
			margin: 0;
			padding: 0 10px;
		}

		.menu-nav {
			padding: 0 0 0 20px !important;
		}
	}

	.custom-drawer .el-drawer__close-btn {
		padding: 0;
	}
</style>
