export default {
  testText1: "C'est une station de test ! ",
  testText2: '@Alcexcom',
  testText3: '@bzengineer (la seule chaîne, attention à ne pas être trompé)',
  meun1: 'page de garde',
  meun2: 'Achetez des pièces en un clic',
  meun3: 'commerce',
  meun3_1: 'Trading au comptant',
  meun3_2: 'Échange rapide',
  meun4: 'négociation de contrats',
  meun4_1: 'Contrat type U',
  meun4_2: 'Contrat avec marge en pièces',
  meun4_3: "contrat d'options",
  meun4_4: 'deuxième contrat',
  meun5: 'Services financiers',
  meun5_1: "Laboratoire d'innovation",
  meun5_2: 'Partenaire promotionnel',
  meun6: 'direction financière',
  meun7: 'annonce',
  meun8: 'Se connecter',
  meun9: 'registre',
  meun10: 'portefeuille',
  meun10_1: 'compte ponctuel',
  meun10_2: 'Compte de contrat basé sur U',
  meun10_3: 'Compte de contrat avec marge en pièces',
  meun10_4: 'deuxième contrat de base',
  meun10_5: 'compte financier',
  meun10_6: 'Historique du portefeuille',
  meun11: 'Commande',
  meun11_1: 'commande ponctuelle',
  meun11_2: 'Commande C2C',
  meun11_3: 'Ordonnances de gestion financière',
  meun11_4: 'Historique des échanges Flash',
  meun11_5: "Gestion de l'innovation",
  meun12: 'télécharger',
  meun13_1: 'Gestion de compte',
  meun13_2: 'mode de paiement',
  meun13_3: 'ma promotion',
  meun13_4: 'Gestion des API',
  meun13_5: 'se déconnecter',
  meun14_1: 'langue',
  meun14_2: 'taux de change',
  meun14_3: 'Choisissez une langue',
  meun14_4: 'Sélectionnez le taux de change',
  openTrade: 'Commencez à trader à tout moment et en tout lieu.',
  scan: 'IOS et Android Scannez le code QR pour télécharger',
  footmeun1: 'à propos',
  footmeun1_1: 'à propos de nous',
  footmeun1_2: 'Rejoignez-nous',
  footmeun1_3: 'Annonce de la plateforme',
  footmeun1_4: 'papier blanc',
  footmeun2: "Centre d'aide",
  footmeun2_1: 'Guide du débutant',
  footmeun2_2: 'Problème commun',
  footmeun2_3: 'Guide de négociation',
  footmeun2_4: 'Informations sur les devises',
  footmeun3: "conditions d'accord",
  footmeun3_1: 'Clause de non-responsabilité',
  footmeun3_2: 'politique de confidentialité',
  footmeun3_3: "Accord de l'utilisateur",
  footmeun3_4: 'Descriptif tarifaire',
  footmeun4: 'Contactez-nous',
  footmeun4_1: 'E-mail du service client',
  footmeun4_2: 'La coopération commerciale',
  footmeun4_3: 'Application de cotation de pièces',
  footmeun4_4: 'Plaintes',
  foottext: "Échange mondial d'actifs numériques de haute qualité",
  indexTxt1: 'Top 50 des échanges de devises numériques dans le monde',
  indexTxt2: 'Les agences nationales garantissent la sécurité des actifs',
  indexTxt3: 'Guide du débutant',
  indexTxt4: 'Achetez des pièces rapidement',
  indexTxt5: "Canal auxiliaire officiel d'entrée des novices Alcex",
  indexLabel1: 'Trading de crypto-monnaie',
  indexVal1: 'Utiliser un actif numérique pour échanger directement',
  indexLabel2: "négociation d'options",
  indexVal2: 'Dérivés sur actifs numériques',
  indexLabel3: 'négociation de contrats',
  indexVal3: 'Norme de pièce de monnaie, contrat standard U',
  indexLabel4: 'Échange rapide',
  indexVal4:
    'Échange de devises instantané, effectuez des transactions en un seul clic',
  indexth: 'tendance des prix',
  indexbtn: 'Échangeons maintenant',
  indexh1: 'honnête',
  indexh2: 'équitable',
  indexh3: 'enthousiasme',
  indexh4: 'ouvrir',
  indexh5: 'Sécurité de qualité financière',
  indexh6: 'Dépôts et retraits extrêmement rapides',
  indexh7: 'service mondial',
  indexh8: 'Des projets soigneusement sélectionnés',
  indexp1:
    "Alcex a été fondée par un groupe de premiers participants et connaisseurs de Bitcoin. Les principaux membres de l'équipe viennent d'entreprises bien connues telles que Google, Microsoft, Alibaba et Tencent. Ils possèdent de profondes capacités de R&D et une riche expérience dans l'exploitation de produits Internet.",
  indexp2:
    "Alcex se positionne comme un fournisseur de services de blockchain de base et s'engage à fournir des plateformes de trading d'actifs cryptographiques de haute qualité aux utilisateurs mondiaux. Il adhère au principe de base de « ne pas faire le mal », insiste pour servir les clients de manière honnête, équitable et avec enthousiasme. et accueille toutes les parties intéressées avec une attitude ouverte. Partenaires/projets qui profitent aux intérêts fondamentaux des utilisateurs.",
  indexp3:
    'Un système complet de contrôle des risques financiers et un système antivol, des portefeuilles chauds et froids et un système multi-signature garantissent la sécurité des fonds.',
  indexp4:
    "Les dépôts et les retraits peuvent être effectués en seulement 3 minutes, et un examen manuel en ligne 24 heures sur 24 empêche les clients de rater les meilleures opportunités d'investissement.",
  indexp5:
    'Couverture mondiale du réseau de services aux entreprises pour vous aider à investir dans des actifs cryptographiques mondiaux et à échanger avec des utilisateurs mondiaux',
  indexp6:
    'Sélectionnez strictement les projets de chiffrement de haute qualité et filtrez pour vous 80 % des projets à risque extrêmement élevé.',
  indexp7: "Scannez le code QR et téléchargez l'application",
  scandownload: "Scannez pour télécharger l'application",
  ormenu1: 'Commande C2C',
  ormenu2: 'Ma commande',
  ormenu3: 'Gagner des pièces',
  please: "s'il te plaît d'abord",
  account: 'Connexion au compte Alcex',
  phone: 'numéro de téléphone',
  email: 'Mail',
  sign: 'Se connecter',
  password: 'mot de passe',
  password2: 'mot de passe',
  password3: 'oublier le mot de passe',
  account2: 'Créer un compte Alcex',
  logErr: "Veuillez saisir votre nom d'utilisateur",
  logErr2: 'Veuillez entrer le mot de passe',
  logErr3:
    'La longueur du mot de passe ne peut pas être inférieure à 6 caractères',
  logSuccess: 'connexion réussie',
  account3: 'Créer un compte personnel',
  phone1: 'Enregistrez votre téléphone',
  email1: 'inscription par courrier électronique',
  email2: 'adresse e-mail',
  code: 'Code de vérification par SMS',
  code2: 'Envoyer le code de vérification',
  code3: 'Le code de vérification',
  password4: 'Confirmez le mot de passe',
  invite: "Code d'invitation (facultatif)",
  agree: "J'ai lu et accepté",
  agreement: "Accord de l'utilisateur",
  register: 'registre',
  regErr: 'Veuillez saisir le code de vérification SMS',
  confirmpwdtip: 'Veuillez saisir le mot de passe de confirmation',
  confirmpwderr: 'Les deux saisies de mot de passe sont incohérentes !',
  wjtab: 'Réinitialiser le mot de passe du téléphone mobile',
  wjtab2: 'Réinitialiser le mot de passe de messagerie',
  wjlabel: 'nouveau mot de passe',
  wjerr: 'Veuillez entrer un nouveau mot de passe',
  labTxt: "Laboratoire Alcex ● Partager les résultats de l'innovation",
  labTxt2:
    'Un laboratoire ouvert gagnant-gagnant pour les investisseurs, les porteurs de projets, les bourses et autres acteurs',
  labTab1: 'tous',
  labTab2: 'Sur le point de commencer',
  labTab3: 'en cours',
  labTab4: 'Distribuer',
  labTab5: 'complété',
  tokendistribute: 'Le jeton est en cours de distribution',
  activitytype: "Type d'abonnement",
  labStart: 'Heure de départ',
  labEnd: 'Heure de fin',
  labStartPlaceholder: 'Veuillez sélectionner une heure de début',
  labDetail: 'vérifier les détails',
  labSpeen: 'calendrier',
  labNumber: 'Activité totale',
  labType1: 'Premier en ligne (achat urgent)',
  labType2: 'Première fois en ligne (même partagée)',
  labType3: 'Partage de position',
  labType4: 'Abonnement gratuit',
  labType5: 'Abonnement à une machine de cloud mining',
  labType6: 'déverrouillage',
  baseinfo: 'Informations de base',
  ruledetail: 'Description du type',
  releaseType: 'Type de version',
  releaseType0: 'Libération en quantités égales',
  releaseType1: 'Libération à rapport égal',
  lock: 'période de libération',
  releaseDetail: 'Détails de la version',
  lockday: 'jour',
  lockweek: 'semaine',
  lockmonth: 'lune',
  lockyear: 'Année',
  releasePercent: 'Taux de libération',
  lockFee: 'Seuil de blocage',
  releaseTimes: 'Libérer plusieurs',
  times: 'fois',
  publishprice: "Prix ​​de l'abonnement",
  holdcoin: 'Devise requise pour détenir des positions',
  limittimes: "Limite d'achat personnelle",
  limitamount: "Limite d'achat personnelle",
  unlimited: 'Sans limites',
  leveloneCount: "Nombre d'amis de premier niveau requis",
  alreadyamount: 'Quantité achetée',
  leftamount: 'Le montant restant',
  myalreadyholdamount: 'Mes postes participants',
  alreadyholdamount: "Total des avoirs des participants à l'événement",
  currentdivided: 'Mon poste actuel peut être divisé en environ',
  holdtips:
    "Pour participer à l'activité de type [Partage de position], vous devez verrouiller votre position jusqu'à la fin de l'activité",
  inputholdamount: 'Entrez le montant du blocage',
  pleaseinputamount: 'Veuillez saisir la quantité à échanger',
  pleaseinputholdamount: 'Veuillez saisir le montant du blocage',
  inputamount: 'Entrez le montant du remboursement',
  inputminingamount: 'Entrez le nombre de machines minières à acheter',
  attend: 'Participez maintenant',
  attention: 'Avis',
  attentiontxt1:
    "1. Après avoir soumis votre participation aux activités de type [Partage de position], [Abonnement gratuit], [Abonnement Cloud Mining Machine] et [Verrouillage], les actifs seront gelés et seront dégelés ou déverrouillés une fois l'activité terminée. .",
  attentiontxt2:
    '2. Après avoir participé à des activités de type [Partage de position], [Abonnement gratuit], [Abonnement Cloud Mining Machine], [Verrouillage], les utilisateurs ne sont pas autorisés à annuler leur demande de participation.',
  attentiontxt3:
    "3. Si l'événement est annulé en raison de la partie au projet ou d'autres facteurs de force majeure, les avoirs gelés seront restitués à l'itinéraire d'origine.",
  attentiontxt4:
    "* Le droit d'interprétation finale de cette activité appartient au Alcex officiel (www.al-cex.com)",
  transfer: 'Transfert de fonds',
  placeholder: 'Devise de recherche',
  bith1: 'négociation de contrats',
  bith2: 'Droits de compte',
  bith3: 'profit ou perte non réalisé',
  bith4: 'Marge disponible',
  bith5: 'Marge utilisée',
  bith6: 'Marge de gel',
  bith7: 'Compte de contrat',
  btn1: 'recharger',
  btn2: 'retirer',
  btn3: 'transfert',
  btn4: 'Déposer des pièces',
  btn5: 'Retirer des pièces',
  totalAssets: 'Total des actifs convertis',
  th1: 'Nom de la devise',
  th2: 'Actifs disponibles',
  th3: 'Geler les avoirs',
  th4: 'Actifs à libérer',
  th5: 'fonctionner',
  czTxt: 'Recharge de monnaie numérique',
  czTxt2: 'Devise',
  czTxt3: 'réseau de transfert',
  czTxt4: 'Solde disponible',
  czTxt5: 'Adresse de retrait',
  czTxt6: 'Quantité de pièces retirées',
  czTxt7: "Quantité d'arrivée",
  czTxt8: 'frais de gestion',
  czTxt9: 'Conseils aimables',
  czTxt10: 'Montant minimum du dépôt :',
  czTxt10_1:
    'Les recharges inférieures au montant minimum ne seront pas créditées.',
  czTxt11:
    "Veuillez ne déposer aucun actif autre qu'en devises à l'adresse ci-dessus, sinon les actifs ne seront pas récupérés.",
  czTxt12:
    "Après avoir rechargé à l'adresse ci-dessus, une confirmation de l'ensemble du nœud du réseau blockchain est requise. Généralement, le compte sera crédité après 3 confirmations réseau du réseau blockchain principal.",
  czTxt13:
    'Votre adresse de recharge ne changera pas fréquemment et pourra être rechargée à plusieurs reprises ; en cas de changement, nous ferons de notre mieux pour vous en informer via des annonces sur le site Web ou par e-mail.',
  czTxt14:
    'Veuillez vous assurer que votre ordinateur et votre navigateur sont sécurisés pour éviter que les informations ne soient falsifiées ou divulguées.',
  czTxt15: "Recharger l'enregistrement",
  czTxt16:
    'Afin de garantir la sécurité des fonds, lorsque vous modifiez la politique de sécurité de votre compte, modifiez votre mot de passe ou utilisez une nouvelle adresse pour retirer des pièces, nous procéderons à un examen manuel du retrait. Veuillez être patient et attendre que le personnel vous contacte. vous par téléphone ou par email.',
  czTxt17: 'Le montant minimum de retrait est de :',
  czth1: 'Délai de retrait',
  czth2: 'depuis',
  czth3: 'à',
  czth4: 'Quantité de rachat instantané',
  czth5: "Rapport d'échange",
  czth6: "Quantité d'échange",
  placeholder2: "Entrez s'il vous plait",
  placeholder3: "Choisissez s'il vous plaît",
  czEmpty: 'aucun enregistrement',
  logintip: "s'il vous plait Connectez-vous d'abord",
  cztip:
    "Vous n'avez pas demandé d'adresse de recharge. Veuillez cliquer sur le bouton pour obtenir l'adresse de recharge.",
  czget: "Obtenir l'adresse de recharge",
  czplaceholder: 'Veuillez sélectionner un protocole',
  mAccount: 'deuxième compte de contrat',
  btn6: 'recherche',
  wtTxt: 'Heure de début et de fin',
  wtTxt2: "Type d'opération",
  wtth1: 'heure de transaction',
  wtth2: 'taper',
  wtth3: 'Devise',
  wtth4: 'quantité',
  wtth5: 'Frais de dossier à payer',
  wtth6: 'Déduire les frais de dossier',
  wtth7: 'Frais de traitement réels',
  wtth8: 'État',
  nav1: 'Sécurité du compte',
  nav2: 'mon invitation',
  nav3: 'Ma carte promotionnelle',
  acTxt: 'Niveau de sécurité',
  acbtn1: 'non certifié',
  acbtn2: 'obligatoire',
  acbtn3: 'Réviser',
  acbtn4: 'vérifié',
  acbtn5: 'Lié',
  acbtn6: 'installation',
  acbtn7: "à l'étude",
  acbtn8: 'Recommencez',
  acTxt2: 'Vérifié',
  acTxt3: 'Mail',
  acTxt4: 'téléphone portable',
  acTxt5: 'mot de passe',
  acTxt6: 'Mot de passe du fonds',
  acTxt7: 'Authentificateur Google',
  acTxt8: 'Définir le mot de passe du fonds',
  acTxt9: 'Changer le mot de passe du fonds',
  acTxt10: 'Récupérer le mot de passe du fonds',
  acDesc2:
    'Pour garantir la sécurité de votre compte, veuillez vous authentifier avec votre nom réel avant de trader !',
  acDesc3: 'Lier un e-mail',
  acDesc4: 'Lier un téléphone portable',
  acDesc5: 'Utilisé lors de la connexion à la plateforme',
  acDesc6:
    'Lorsque les fonds du compte changent, le mot de passe du fonds doit être vérifié',
  acDesc7: "Veuillez lier l'authentificateur Google",
  acDesc8: 'Google Authenticator est lié',
  acDesc9: 'Délier',
  acTit: 'Reliure de téléphone portable',
  acTit2: 'Liaison par e-mail',
  acTit3: 'Liaison Google',
  acTit4: 'Google dissocier',
  mailtip: 'veuillez saisir votre email',
  emailerr2:
    "Le format de l'e-mail est incorrect, veuillez le saisir à nouveau.",
  agreementtip: "Veuillez lire et accepter les conditions d'utilisation",
  acverify: "Échec de l'examen",
  again: 'Veuillez réessayer',
  verifiedmsg: "Veuillez d'abord effectuer une authentification par nom réel",
  apiTxt: 'Lier un compte avec son nom réel',
  apiTxt2: 'indice',
  apiTxt3:
    'Nous mettons à votre disposition une API puissante grâce à laquelle vous pouvez utiliser des services tels que les enquêtes de marché et le trading automatique. ',
  apiTxt4: "Chaque utilisateur peut créer jusqu'à 5 ensembles de clés API",
  apiTxt5:
    "Veuillez ne pas divulguer votre clé API pour éviter la perte d'actifs. ",
  apiTxt6: "Remarques sur l'API",
  apiTxt7:
    "IP autorisée (la clé de l'adresse IP autorisée est valable 90 jours)",
  apibtn: 'créer',
  apith1: "Remarques sur l'API",
  apith2: 'temps de creation',
  apith3: "clef d'accès",
  apith4: "Lier l'adresse IP",
  apith5: 'Période de validité restante (jours)',
  chTxt: 'Changer le mot de passe de connexion',
  chTxt2: 'Scannez le code pour lier',
  chTxt3: 'veuillez entrer le code de vérification',
  chTxt4: 'Mot de passe original du fonds',
  chTxt5: 'Nouveau mot de passe du fonds',
  chTxt6: 'Confirmez le nouveau mot de passe',
  chTxt7: 'oublier le mot de passe',
  chTxt8: 'Modifier la vérification du téléphone mobile',
  chTxt9: "Mot de passe de connexion d'origine",
  chTxt10: 'Nouveau mot de passe de connexion',
  chTxt11: "Code de vérification de l'e-mail",
  chTxt12: 'Code de vérification du téléphone portable',
  telerr: 'Le numéro de téléphone portable est incorrect',
  emailerr: "L'e-mail est incorrect",
  codeerr: 'code de vérification incorrect',
  reset: 'réinitialiser',
  save: 'sauvegarder',
  chtip: "Veuillez saisir le mot de passe d'origine",
  chtip2:
    "Veuillez saisir un nouveau mot de passe de connexion d'au moins 6 chiffres",
  chtip3: 'Le nouveau mot de passe de connexion est incohérent',
  chtip4: 'Veuillez saisir le mot de passe correct du fonds',
  chtip5: 'Le mot de passe ne doit pas contenir moins de 6 caractères',
  chtip6: "Veuillez saisir un mot de passe d'au moins 6 chiffres",
  chtip7: 'Les mots de passe sont incohérents',
  chtip8: 'Veuillez saisir le mot de passe du fonds',
  chtip9: 'Veuillez entrer le numéro de téléphone',
  chtip10: "s'il vous plait entrez votre mot de passe",
  realnametip: 'veuillez entrer votre vrai nom',
  idcardtip: "Veuillez entrer votre numéro d'identification",
  clTxt:
    "Veuillez définir votre mode de paiement et assurez-vous d'utiliser votre compte réel.",
  clTxt2: 'Ajouter un mode de paiement',
  edit: 'modifier',
  delete: 'supprimer',
  name: 'Nom',
  clTxt3: "Numéro d'identification",
  clTxt4: 'Numéro IBAN',
  clTxt5: 'Tous les moyens de paiement',
  clLink1: 'nom du compte',
  clLink2: 'Modifier le mode de paiement',
  clLink3: 'Détails du paiement (facultatif)',
  clplaceholder1: "S'il vous plait entrez votre nom entier",
  clplaceholder2: 'Veuillez saisir le mot de passe de votre compte bancaire',
  clplaceholder3: "S'il vous plaît entrez vos informations de paiement",
  cltip: 'Note spéciale',
  cltip2:
    "Assurez-vous d'ajouter votre numéro de carte bancaire pour un paiement instantané. ",
  cltip3:
    "Rappel chaleureux : lorsque vous vendez de la monnaie numérique, le mode de paiement que vous choisissez sera affiché à l'acheteur. ",
  clTxt6: 'Facultatif',
  upload: 'télécharger',
  uploadTip: '(Prend en charge le format JPG/JPEG/PNG/BMP, moins de 1 Mo)',
  tgbtn: 'Utiliser le code',
  tgth1: 'Nom de la carte',
  tgth2: 'Utiliser le code',
  tgth3: 'Devise de la carte',
  tgth4: 'Montant nominal de la carte',
  tgth5: 'total',
  tgth6: 'Déjà racheté',
  tgth7: 'Heure de collecte',
  tgbtn2: 'Matériel promotionnel',
  tgbtn3: 'Échangez maintenant',
  tgplaceholder: 'Veuillez saisir le code de remboursement',
  exchangesuccess: 'Rédemption réussie ! ',
  inth1: 'Inviter des utilisateurs',
  inth2: "Heure d'inscription",
  inth3: 'Statut du vrai nom',
  adTxt: 'mon annonce',
  adTxt2:
    'Lorsque le montant minimum de transaction de la publicité majoré des frais de traitement est supérieur à la quantité restante de la publicité, la publicité sera automatiquement retirée des étagères.',
  adbtn: 'Conditions claires',
  adbtn2: 'Sur les étagères',
  adbtn3: 'Retiré des étagères',
  adth1: "Numéro d'annonce",
  adth2: 'type de publicité',
  adth3: 'Limites de commande',
  adth4: 'Le montant restant',
  adtip: 'confirmer pour supprimer',
  num_text1: 'Veuillez entrer ce que vous voulez',
  num_text2: 'quantité',
  wTxt: 'Marchandises ponctuelles',
  wTxt2: 'Commande en cours',
  wTxt3: 'paire de trading',
  wTxt4: 'Orientation commerciale',
  wth1: 'temps',
  wth2: 'direction',
  wth3: 'prix',
  wth4: 'Affaire conclue',
  wth5: "Chiffre d'affaires",
  wth6: 'Type de délégué',
  wth7: 'prix de déclenchement',
  wth8: 'prix final',
  wth9: 'Quantité commandée',
  wth10: 'Profits et pertes de règlement',
  wth11: 'Statut de délégation',
  wth12: 'Ouvrir/Fermer une position',
  wth13: 'marge',
  wbtn: 'Annuler la commande',
  wtip: "Rappel d'annulation",
  wtiptxt: "Confirmer l'annulation de la commande ?",
  zbplaceholder: 'Entrez le numéro de commande pour lancer la recherche',
  zbtab1: "Plan d'investissement fixe",
  zbtab2: "Dossier d'investissement fixe",
  zbth1: "cycle d'investissement",
  zbth2: 'changer',
  zbth3: "Montant de l'investissement cumulé",
  zbth4: 'Quantité détenue',
  zbth5: 'coût moyen',
  zbth6: 'profit ou perte non réalisé',
  zbth7: "Prochaine date d'investissement prévue",
  zbth8: 'Date de création du forfait',
  etTxt: "Centre d'annonces",
  etTxt2: 'Numériser pour partager',
  etmenu1: 'Crypto-monnaie',
  etmenu2: 'Annonce de mise à jour du serveur Alcex',
  et2menu1: 'Introduction à RX.Tron',
  et2menu2: 'Bitcoin est-il une chaîne de Ponzi ?',
  hisTxt: 'commande historique',
  orTab1: 'Non payé',
  orTab2: 'Déjà payé',
  orTab3: 'complété',
  orTab4: 'Annulé',
  orTab5: 'Attirant',
  orth1: 'numéro de commande',
  orth2: 'Devise de la transaction',
  orth3: 'Type de transaction',
  orth4: 'Partenaires commerciaux',
  orth5: 'Montant',
  qcTab1: 'Résultats précédents',
  qcTab2: 'Cotations en temps réel',
  qcTxt: 'Non.',
  qcTxt2: 'Attendre',
  qcTxt3: "horaire d'ouverture",
  qcTxt4: 'heure de fermeture',
  qcTxt5: "prix d'ouverture",
  qcTxt6: 'le dernier prix',
  qcTxt7: "Prix ​​d'achat total",
  qcTxt8: 'Montant total des achats et des ventes',
  qcTxt9: 'Mon poste ouvert',
  qcTxt10: 'Progression des prévisions',
  qcTxt11: "prix d'ouverture",
  qcTxt12: 'Le dernier prix',
  qcTxt13: 'Cette période de prévision',
  qcbtn1: 'haussier',
  qcbtn2: 'Baissier',
  qcth1: 'Nombre de périodes de contrat',
  qcth2: "Montant d'ouverture",
  qcth3: 'direction de prédiction',
  qcth4: 'résultat prévu',
  qcth5: 'Montant du bonus',
  qcth6: "Frais d'ouverture",
  qcth7: 'pompe à eau',
  mplaceholder: 'Entrez le nom de la devise à rechercher',
  mTab: 'Facultatif',
  mth: 'collecter',
  mth1: 'Dernier prix',
  mth2: '24h de montée et de descente',
  mTxt: 'Prix ​​le plus élevé sur 24h',
  mTxt2: 'Prix ​​le plus bas 24h',
  mTxt3: 'Nombre de transactions',
  mTxt4: 'taux de financement',
  mTxt5: 'ouvrir',
  mTxt6: 'haut',
  mTxt7: 'Faible',
  mTxt8: 'recevoir',
  echat1: 'Graphique en ligne K',
  echat2: 'Carte de profondeur',
  mTab2: 'Position actuelle',
  mTab3: 'Historique des commissions',
  mth3: 'Rémunération garantie',
  mth4: "Prix ​​d'ouverture",
  mth5: 'faire du vélo',
  mth6: 'Le dernier prix',
  mth7: 'profit',
  mTxt9: 'modèle commercial',
  mTxt10: "Horaire d'ouverture",
  mTxt11: 'Rentabilité',
  mTxt12: 'Équilibre',
  mTxt13: "Volume d'achat",
  mTxt14: 'milieu',
  cadTxt: 'Créer un accord publicitaire',
  cadTxt2:
    'Si vous échangez fréquemment, vous pouvez créer vos propres annonces commerciales.',
  cadTxt2_1:
    "Si vous n'échangez qu'occasionnellement, nous vous recommandons d'effectuer une recherche directement",
  cadTxt2_2: "La création d'une annonce commerciale est gratuite.",
  cadLink2: 'publicité transactionnelle',
  cadTxt3:
    'Si vous souhaitez modifier directement une annonce que vous avez créée, veuillez consulter',
  cadLink3: 'mon annonce',
  cadTxt4: 'prix de participation au marché',
  cadTxt5:
    'La prime fait référence au pourcentage de vente au-dessus du prix actuel du marché.',
  cadTxt6: 'Formule de tarification',
  cadTxt7:
    '[Astuce] Vous pouvez accéder au centre personnel pour lier/ajouter des modes de paiement',
  cadTxt8:
    'Le montant maximum de la transaction ne peut pas dépasser le montant total de votre vente 0CNYI',
  cadTxt9:
    "Après l'avoir activé, lorsqu'un utilisateur initie une transaction avec vous via cette annonce, le système enverra automatiquement le texte de réponse automatique que vous avez sélectionné à l'autre partie.",
  cadTxt10:
    'La prime fait référence au pourcentage au-dessus du prix actuel du marché à acheter',
  cadLabel1: 'Je veux:',
  cadLabel2: 'Transaction en devises :',
  cadLabel3: 'nation:',
  cadLabel4: 'devise:',
  cadLabel5: 'Activer le prix fixe',
  cadLabel6: 'Prime:',
  cadLabel7: 'Prix ​​de négociation',
  cadLabel8: 'Quantité vendue:',
  cadLabel9: 'mode de paiement:',
  cadLabel10: 'Montant minimum de la transaction :',
  cadLabel11: 'Montant maximum de la transaction :',
  cadLabel12: 'Activer les réponses automatiques',
  cadLabel13: 'Mot de passe du fonds :',
  cadLabel14: 'Remarques:',
  cadLabel15: 'Prix ​​fixe:',
  cadLabel16: 'réponse automatique:',
  cadLabel17: "Quantité d'achat:",
  radio: 'à vendre en ligne',
  radio2: 'acheter en ligne',
  cadplaceholder: 'Veuillez définir votre prime',
  cadplaceholder2: 'Veuillez entrer la quantité que vous souhaitez vendre',
  cadplaceholder3: 'Veuillez saisir le montant minimum de transaction souhaité',
  cadplaceholder4:
    'Veuillez saisir le montant maximum de la transaction souhaité',
  cadplaceholder5:
    'Vous pouvez remplir vos exigences particulières dans les informations de remarque, telles que : les exigences pour les acheteurs, le temps en ligne, etc.',
  cadplaceholder6: 'Veuillez saisir le prix de votre transaction',
  cadplaceholder7:
    "Après réception de la commande, répondez automatiquement aux informations de l'acheteur, telles que le mode de paiement, le numéro de compte de paiement, etc.",
  cadplaceholder8: 'Veuillez entrer la quantité que vous souhaitez acheter',
  caderr1: 'Veuillez saisir le montant minimum de votre transaction',
  caderr2:
    'Le montant minimum de transaction doit être supérieur ou égal à 100 !',
  caderr3:
    'Le montant minimum de la transaction doit être inférieur au montant maximum de la transaction',
  caderr4: 'Veuillez saisir le montant maximum de votre transaction !',
  caderr5: 'Veuillez saisir un nombre entier',
  caderr6:
    'Le montant maximum de la transaction doit être supérieur au montant minimum de la transaction !',
  caderr7:
    'Le montant maximum de la transaction ne peut excéder le montant total de vos ventes',
  caderr8: 'Veuillez sélectionner une méthode de transaction',
  submit: 'soumettre',
  cadnew:
    "Après l'activation, le prix de votre devise ne fluctuera pas avec le marché et le prix restera inchangé.",
  dhTxt: 'Échangez en un clic et profitez des avantages',
  dhTxt2: 'limite de prix',
  dhTxt3: 'vendre',
  dhTxt4: 'Acheter',
  dhTxt5: 'maximum',
  dhTxt6: 'Entrer le montant',
  dhplaceholder: 'Solde disponible du portefeuille ponctuel',
  dhSuccess: 'toutes nos félicitations! ',
  crTxt: 'Trading de devises fiduciaires',
  crTxt2: 'Achat et vente pratiques, sûrs et rapides de devises numériques',
  crTxt3: 'Ajustement dynamique',
  crTxt4: 'Pas de frais de dossier',
  crTxt5: 'Transaction instantanée',
  crTxt6: 'Garantie plateforme',
  crTxt7: 'Fluctuer en temps réel en fonction des prix du marché',
  crTxt8:
    "Ce que les utilisateurs voient est ce qu'ils obtiennent, et il n'y a pas de frais de plateforme autres que le prix d'achat et de vente.",
  crTxt9:
    "Présentation d'une plate-forme pour servir les commerçants, la correspondance intelligente et les ordres de transaction sans attendre la correspondance",
  crTxt10:
    'Marchands certifiés par la plateforme, service client sûr et sécurisé 24 heures sur 24 pour protéger les transactions',
  crth1: 'Marchand',
  crth2: 'Nombre de transactions',
  crth3: 'mode de paiement',
  crth4: 'limite',
  crth5: 'prix unitaire',
  crbtn: 'Acheter',
  crbtn2: 'Devenez commerçant',
  cr1Txt: 'Toutes nos félicitations! ',
  cr1Txt2: 'préparer le matériel',
  cr1Txt3: 'Poster le commentaire',
  cr1Txt4: 'vérifié',
  cr1Txt5: 'Vous disposez des autorisations suivantes',
  cr1Txt6: 'Stand exclusif',
  cr1Txt7: 'Service individuel',
  cr1Txt8:
    'Les commerçants bénéficient de stands publicitaires exclusifs pour augmenter les taux de réussite des transactions',
  cr1Txt9: 'Frais réduits',
  cr1btn: 'Placer une annonce',
  cr1btn2: 'Faire une demande de rachat',
  reTxt: "Alcex Financial Management ● Solution d'investissement unique",
  reTxt2: "Gagnez de l'argent instantanément, facilement et en toute sécurité",
  reTab: 'Gestion financière régulière',
  reTab2: 'Gestion financière des investissements fixes',
  reTxt3: 'régulier',
  reTxt4: 'Personnalisation flexible, bénéfices potentiels plus élevés',
  reTxt6: 'investissement fixe',
  reTxt7:
    'Commencez à utiliser l’investissement fixe et faites croître vos actifs',
  reTxt8: 'Développez les 18 produits réguliers',
  reth1: 'taux de rendement annualisé',
  reth2: 'Délai (jours)',
  reth3: 'Limite de transaction',
  reth4: 'produit',
  reth5: 'retour sur investissement historique',
  rebtn: 'Créer un plan',
  redTxt: 'Créer',
  redTxt2: "Plan d'investissement fixe",
  redTxt3: "Entrez le montant de l'investissement",
  redTxt4: 'Mon solde disponible est',
  redTxt5: 'Disponible',
  redTxt6: 'période de cycle',
  redTxt7: 'tous les jours',
  redTxt8: 'hebdomadaire',
  redTxt9: 'par mois',
  redTxt10: 'heure locale',
  redTxt11: "Votre premier cycle d'investissement automatique sera",
  redTxt12: 'commencer',
  confirm: 'confirmer',
  confirm2: 'Bien sûr',
  cancel: 'Annuler',
  redongoing: 'en cours',
  redcompleted: 'complété',
  minNumErr: 'Inférieur au montant minimum de départ',
  minNumErr2: 'Le montant de départ ne peut pas être 0',
  buyamounttip: "Veuillez saisir le montant de l'achat",
  pricetipwarning: 'Le montant de la transaction ne peut pas être supérieur à',
  buyamounttipwarning: 'La quantité achetée ne peut pas être supérieure à',
  sellamounttip: 'Veuillez saisir le montant de la vente',
  sellamounttipwarning: 'La quantité vendue ne peut pas être supérieure à',
  sellpricetip: 'Veuillez entrer le prix de vente',
  sellpricetipwarning: 'Le prix de vente ne peut être supérieur à',
  sellmore: 'Le maximum qui peut être vendu',
  sellunit: 'individuel',
  loginFirst: "s'il vous plait Connectez-vous d'abord",
  betsuccess: 'toutes nos félicitations! ',
  selectAmount: 'Veuillez sélectionner le montant de votre pari',
  balancenotenough: 'Solde disponible insuffisant !',
  balancenotenough2: 'Solde insuffisant!',
  minlimitamountfailed:
    'Le montant de rachat ne peut être inférieur au montant minimum de rachat !',
  maxlimitamountfailed:
    'La quantité échangée ne peut pas être supérieure à la quantité maximale d’achat !',
  limittimesfailed:
    'Le nombre de fois où vous avez participé dépasse le nombre maximum d’achats personnels !',
  commitfailed: 'La soumission pour participer a échoué !',
  trplaceholder: 'Renseigner',
  trplaceholder2: 'dernier prix',
  trplaceholder3: 'Achetez au meilleur prix du marché',
  trplaceholder4: 'Vendre au meilleur prix du marché',
  triggleplaceholder: "Le prix de l'ordre par défaut est le prix du marché",
  triggerpriceshouldbigger: 'Le prix de déclenchement doit être supérieur à 0',
  marginModeSuccessTip: 'Mode de compte de contrat modifié avec succès',
  noenoughbalance: 'Marge de compte insuffisante',
  pleaseinputopenvolume: "La quantité d'ouverture doit être supérieure à 0",
  pleaseinputcorrectopenvolume:
    "Veuillez entrer la quantité d'ouverture correcte",
  pleaseinputrightentrustprice: 'Veuillez saisir le prix de commande correct',
  noenoughposition: 'Positions liquidables insuffisantes',
  pleaseinputclosevolume: 'La quantité de clôture doit être supérieure à 0',
  pleaseinputcorrectclosevolume:
    'Veuillez entrer la quantité de clôture correcte',
  option1: 'antenne',
  option2: 'périmètre',
  option3: 'ligne mensuelle',
  trTxt: 'Volume des transactions',
  trTxt2: 'prix du marché',
  trTxt3: 'Volume de vente',
  trSuccess: 'Soumis avec succès',
  udTxt: 'Mode marge',
  udTxt2: 'Ajuster les multiples de contrat',
  udTxt3: 'levier',
  udTxt4: 'Durable',
  udTxt4_1: 'Durable',
  udTxt5: 'Montant transférable',
  udTxt6: 'Quantité transférée',
  udTxt7: 'Transférer tout',
  udTxt8: 'compte perpétuel',
  ubtn: 'Entrepôt complet',
  ubtn2: 'Position isolée',
  uplaceholder: 'Compte à contrat perpétuel',
  uradio1: 'partage de temps',
  uradio2: '15 minutes',
  uradio3: '1 heure',
  uradio4: '4 heures',
  uradio5: 'Un jour',
  uth1: 'contrat multiple',
  uth2: 'revenu',
  uth3: 'taux de retour',
  uth4: 'Intérêt ouvert',
  uth5: 'Peut être mesuré uniformément',
  uth6: "cours d'ouverture moyen",
  uth7: 'Forte parité',
  uth8: 'Marge actuelle',
  uth9: 'taux de marge',
  zhang: 'ouvrir',
  or: 'ou',
  ureg: "S'inscrire maintenant",
  uTxt8: 'Commerce',
  uTab: 'Ouvrir un poste',
  uTab2: 'Position fermée',
  uTxt9: "Peut s'ouvrir longtemps",
  uTxt10: 'Peut être ouvert court',
  uTxt11: 'Prix ​​de la commande',
  uplaceholder2: 'Transaction au meilleur prix du marché',
  uTxt12: 'Acheter pour ouvrir longtemps',
  uTxt13: 'vendre à découvert',
  closeup: 'acheter à découvert',
  closedown: 'vendre longtemps',
  uTab3: 'Prenez des bénéfices et stoppez les pertes',
  uTxt14: 'Mon compte contrat',
  ulabel1: 'Mode compte',
  ulabel2: 'Revenu total du compte',
  ulabel3: 'Marge de position',
  ulabel4: "Taux d'utilisation des fonds",
  ulabel5: 'beaucoup',
  ulabel6: 'nul',
  uTxt15: 'long',
  uTxt16: 'court',
  total: 'total',
  limited_price: 'ordre limité',
  market_price: 'ordre du marché',
  spot_price: 'Prenez des bénéfices et stoppez les pertes',
  ent_status1: 'À la commission',
  ent_status2: 'Révoqué',
  ent_status3: 'La délégation a échoué',
  ent_status4: 'Délégation réussie',
  ent_statusblast: 'Liquidation',
  uTxt17: 'Les positions longues peuvent être fermées',
  uTxt18: 'La position courte peut être fermée',
  prev: 'Précédent',
  next: 'Suivant',
  prtxt: "Mon lien d'invitation",
  copy: 'copie',
  prtxt2: 'Mon ami de premier niveau',
  prtxt3: 'Mes amis secondaires',
  prtxt4: "Revenus de commissions (équivalents à l'USDT)",
  prtxt5: 'Récompenses supplémentaires',
  prtxt6: 'Niveau de partenariat',
  prtit: 'Détails de la règle',
  prdesc:
    "[Partenaire de promotion] de Alcex est une activité de promotion avec le taux de remise de commission le plus élevé et la durée de remise la plus longue (rabais maximum à vie) sur l'ensemble du réseau, permettant aux promoteurs qui déploient les efforts correspondants de devenir un véritable « partenaire » de la plateforme et de profiter du les dividendes de la croissance avec la plateforme de trading Alcex ! ",
  prli1:
    "1. Afin de promouvoir le concept d'actifs numériques et d'élargir l'échelle des utilisateurs, Alcex a lancé le programme [Promotion Partner], efficace depuis longtemps.",
  prli2:
    "2. L'invitation d'amis est divisée en deux niveaux. Si A invite B, alors B est l'ami de premier niveau de A. Si B invite C, alors C est l'ami de premier niveau de B et également l'ami de deuxième niveau de A.",
  prli3:
    "3. Lors de votre inscription en tant qu'ami promotionnel, vous devez utiliser le lien fourni par l'invitant ou saisir manuellement le code d'invitation de l'invitant pour devenir l'ami de premier niveau de l'invitant.",
  prli4:
    "4. La remise ne peut prendre effet qu'une fois que l'invité s'est enregistré avec le code d'invitation de l'invitant et a terminé l'authentification par son nom réel.",
  prli5:
    "5. La période de remise est de N mois à compter du moment où l'invité effectue l'authentification de son nom réel. Selon le niveau, le taux de remise est différent.",
  prli6:
    "6. L'actif de remise promotionnelle est les frais de transaction de la devise de l'ami subordonné, et la devise de remise est constituée des trois principales devises de base du marché, à savoir : USDT, BTC et ETH.",
  prli7:
    "7. Le taux de remise de la promotion et la durée de la remise sont positivement corrélés au nombre d'amis de premier niveau promus. Plus vous promouvez de personnes, plus le taux de remise dont vous pouvez bénéficier est élevé. ",
  prli8:
    '8. Les 10 ou 100 premiers du classement des commissions de promotion recevront de temps à autre des récompenses supplémentaires officiellement émises par Alcex.',
  prli9:
    "9. Si d'autres activités entrent en conflit avec les activités de [Partenaire de promotion], des changements temporaires seront décidés après consultation. Nous espérons votre compréhension de la part des partenaires.",
  prli10:
    '10. Les partenaires de niveau L4 (Gouverneur), L5 (Préfet) et L6 (Jiedushi) bénéficient respectivement de dividendes de 5 %, 10 % et 15 % de la remise annuelle totale des commissions.',
  prli11:
    "11. Le droit d'interprétation finale de cette activité appartient à Alcex.",
  prth1: 'niveau',
  prth2: "Nombre d'amis de premier niveau",
  prth3: 'Ratio de remise ami de premier niveau/durée de remise',
  prth4: 'Ratio de remise ami de deuxième niveau/durée de remise',
  prth5: 'Dividendes des partenaires',
  prlev1: 'Li Zheng',
  prlev2: 'Magistrat du comté',
  prlev3: 'préfet',
  prlev4: 'gouverneur',
  prlev5: 'Préfet',
  prlev6: 'Jiedushi',
  prtd1: 'mois',
  prtd2: 'Rabais à vie',
  prtit2: 'Par exemple',
  prtxt7:
    "Si l'utilisateur Xiao Yan invite 100 amis de premier niveau et que chacun de ses amis de premier niveau invite 5 amis de deuxième niveau, Xiao Yan aura 100 amis de premier niveau et 500 amis de deuxième niveau. ",
  prtxt8:
    "Si l'utilisateur Xiao Yan invite 1 000 amis de premier niveau et que chacun de ses amis de premier niveau invite 5 amis de deuxième niveau, Xiao Yan aura 1 000 amis de premier niveau et 5 000 amis de deuxième niveau. ",
  prtit3: 'Boîte à outils promotionnelle',
  prtit4: 'Top 20 des remises promotionnelles totales (liste totale)',
  prtit5: 'Top 20 des promoteurs (liste totale)',
  prtxt9: 'Date limite des statistiques :',
  prth6: 'Classement',
  prth7: 'membre',
  prth8: 'Numéro de promotion',
  prth9: 'Rabais (équivalent à USDT)',
  prth10: 'Récompenses supplémentaires',
  prtip1:
    'Les données de commission ci-dessus ne sont pas mises à jour en temps réel, et le système les compte et les met à jour toutes les 24 heures.',
  prtip2:
    "Le nombre d'invités est le nombre d'amis de premier niveau, et cette liste est le classement du montant total de la remise.",
  prtip3:
    "Le nombre d'invités est le nombre d'amis de premier niveau, et cette liste est le classement du nombre d'invités de premier niveau.",
  recth1: "Heure d'arrivée",
  recth2: 'Protocole réseau',
  recth3: 'Adresse de dépôt',
  recth4: 'Quantité de recharge',
  recbtn: 'Recharge du code de retrait de pièces',
  recplaceholder: 'Veuillez saisir le code de retrait',
  recstatus_0: 'échouer',
  recstatus_1: 'Non reçu',
  recstatus_2: 'Déjà arrivé',
  wittit: 'Retrait de monnaie numérique',
  wittext: 'Registre des retraits',
  wittext2: 'Code de retrait',
  wittext3: 'Liste des codes de retrait de pièces',
  witbtn: 'Retirer le code de la pièce Retirer la pièce',
  witbtn2: 'Générer un code de retrait de devise',
  witdia: 'vérification de la sécurité',
  witdia2: 'Code de retrait généré avec succès',
  witplaceholder: 'Veuillez saisir le montant du retrait',
  witerr: 'Veuillez sélectionner la devise',
  addresstip: "Veuillez remplir l'adresse",
  witstate: 'À racheter',
  witstate2: 'échouer',
  witstate3: 'Rachat terminé',
  witstatus_1: "à l'étude",
  witstatus_2: 'Transfert',
  witstatus_3: 'échouer',
  witstatus_4: 'succès',
  type1: 'transfert',
  type2: 'Trading de crypto-monnaie',
  type3: 'Acheter de la monnaie Fiat',
  type4: 'Vendre de la monnaie fiduciaire',
  type5: "Prix ​​d'activité",
  type6: 'Récompenses promotionnelles',
  type7: 'dividende',
  type8: 'vote',
  type9: 'Recharge manuelle',
  type10: 'paire',
  type11: "Rédemption d'événement",
  type12: 'Acheter C2C',
  type13: 'Vente C2C',
  type14: 'enveloppes rouges',
  type15: 'recevoir une enveloppe rouge',
  type16: 'Retirer le code de la pièce Retirer la pièce',
  type17: 'Retirer le code de la pièce et déposer la pièce',
  type18: 'Frais de traitement du contrat perpétuel',
  type19: 'Bénéfice du contrat perpétuel',
  type20: 'Perte perpétuelle du contrat',
  type21: "Échec du contrat d'options",
  type22: 'Frais de traitement des contrats d’options',
  type23: "Bonus sur contrat d'options",
  type24: 'Rabais contractuel',
  type25: 'Récompenses de niveau',
  type26: 'Revenus des frais de plateforme',
  type27: 'Le deuxième contrat a échoué',
  type28: 'Prime de deuxième contrat',
  type29: 'intérêt financier',
  type30: 'Dépenser des fonds',
  type31: 'Obtention des frais de financement',
  type32: "Achat d'investissement fixe",
  type33: 'Vente de placements fixes',
  type34: 'acheter régulièrement',
  type35: 'Rachat régulier',
  type36: 'Transfert de contrat basé sur des pièces',
  type37: 'Transfert de contrat basé sur des pièces',
  type38: 'Transfert de contrat basé sur U',
  type39: 'Transfert de contrat basé sur U',
  type40: 'Transfert du deuxième contrat',
  type41: 'Deuxième transfert de contrat',
  type42: 'Transfert de pièces de monnaie',
  type43: 'Transfert de pièces',
  lctit: 'compte financier',
  lcbtn: 'Transfert de gestion financière',
  lcth1: 'Intérêts accumulés',
  day: 'ciel',
  lctxt: "Devise d'activité",
  lctxt2: 'Devises acceptées',
  lctxt3: 'Quantité de départ',
  lctxt4: 'période de sélection',
  year: 'Année',
  month: 'lune',
  ldtab1: 'Enregistrement de poste',
  ldtab2: 'Clôture des dossiers',
  ldth1: 'Date de début de gains',
  ldth2: 'Date de remboursement',
  ldth3: 'estimation des revenus',
  ldth4: 'revenu',
  attit: "Modifier le plan d'investissement fixe",
  attxt: "Quantité d'investissement",
  attxt2: "cycle d'investissement",
  atth1: "Date d'investissement fixe",
  atth2: "Montant de l'investissement fixe",
  atth3: 'Taux de transaction',
  success: 'succès',
  cxth1: 'nom',
  cxth2: 'taper',
  cxth3: "Quantité d'abonnement",
  cxth4: 'Devises participantes',
  cxth5: "Unité d'abonnement",
  cxth6: 'Statut actuel',
  cxth7: "Chiffre d'affaires",
  cxth8: 'temps de creation',
  cxnav1: "j'ai participé",
  cxnav2: 'Ma machine minière',
  cxnav3: 'Ma position de verrouillage',
  cxtit2: 'Ma liste de machines minières',
  cxtit3: 'Ma liste de verrouillage',
  cxkth1: 'Devise de sortie',
  cxkth2: 'cycle de production',
  cxkth3: "Temps d'exploitation minière",
  cxkth4: 'Temps extrait',
  cxkth5: 'capacité de base',
  cxkth6: 'Capacité réelle',
  cxstate1: 'Non déployé',
  cxstate2: 'Déployé',
  cxstate3: 'Révoqué',
  cxstate4: 'transaction en attente',
  cxstate5: 'sur',
  cxstate6: 'Être libéré',
  cxstate7: 'libération',
  cxcth1: 'Devise de libération',
  cxcth2: 'Verrouillage total',
  cxcth3: 'période totale de libération',
  cxcth4: 'Cycle libéré',
  cxcth5: 'version de base',
  cxcth6: 'sortie réelle',
  more: 'Plus',
  abtit: 'Fonctionnalités de la plateforme',
  abtit2: 'à propos de nous',
  abtit3: 'Contactez-nous',
  abtit4: 'Communauté Alcex',
  abdesc:
    "Alcex est la première plateforme technologique de négociation d'actifs numériques au monde. Elle est enregistrée aux îles Caïmans et son équipe opérationnelle principale est située à Hong Kong. ",
  abdesc2:
    "La plate-forme Alcex dispose d'une structure de transaction professionnelle au niveau financier et d'un moteur de transaction de correspondance de mémoire à haute concurrence auto-développé.La plate-forme adopte un système de portefeuille de recharge entièrement à froid + multi-signature + système d'attaque DDOS à haute protection et d'autres structures de transaction pour assurer la sécurité des actifs des clients.",
  abdesc3:
    "Afin de permettre aux clients de mieux saisir les opportunités d'investissement, Alcex adopte une gestion plate au sein du service client et met en place un mécanisme de réponse de service extrêmement rapide. Le responsable du service client actifs est en ligne 7*24H toute l'année pour fournir aux clients des services tels que des actifs. recharge et retrait, garantissant que les clients peuvent recevoir des services dans les 5 minutes.Dépôt et retrait complets des actifs.",
  abdesc4:
    "Alcex sélectionne strictement des projets de haute qualité et fournit des services de conservation d'actifs sûrs et stables. ",
  abdesc5:
    "Bien que les actifs numériques ne représentent désormais qu'un petit cercle, nous pensons que ce type d'actifs que tout le monde peut contrôler pleinement deviendra certainement populaire à l'avenir. Travaillons ensemble et attendons avec impatience !",
  abdesc6: 'service client',
  abdesc7: 'Soutien technique',
  abdesc8: 'Application de cotation de pièces',
  abdesc9: 'Suggestions',
  abdesc10: 'La coopération commerciale',
  abdesc11: 'WeChat',
  abdesc12: 'Weibo',
  abdesc13: 'Twitter',
  abdesc14: 'Utilisation des pièces',
  abdesc15: 'télégraphe',
  abdesc16:
    'Ajoutez "bmate601" comme ami WeChat et entrez dans la communauté sociale WeChat',
  realtxt: 'nom réel',
  realtxt2: "Numéro d'identification",
  realtxt3: "Photo recto de la carte d'identité",
  realtxt4: "Photo du verso de la carte d'identité",
  realtxt5:
    "Photo de titulaire d'une carte d'identité et d'une signature personnelle",
  realtxt6:
    "1. Les formats JPG et PNG sont pris en charge et la taille du fichier est inférieure à 8 Mo ; les informations sur le certificat sont clairement visibles et aucune modification ou blocage n'est autorisé. Le numéro et le nom du certificat doivent être clairement visibles.",
  realtxt7:
    '2. La photo doit être prise sans chapeau ni maquillage. Les traits du visage de la personne qui tient le document doivent être clairement visibles et les bras doivent être entièrement exposés.',
  realtxt8:
    '3. Vous devez détenir en même temps le certificat et la copie manuscrite (nom + date de candidature). Toutes mes actions sur ce site sont menées par moi-même, je suis conscient des risques encourus et je suis prêt à supporter toutes les conséquences juridiques. de ce compte. ',
  realbtn: 'Cliquez pour télécharger',
  realstate: "Ce n'est pas son vrai nom",
  realstate2: 'Déjà un vrai nom',
  uploadimg: "Veuillez télécharger une photo recto de votre carte d'identité",
  uploadimg2:
    "Veuillez télécharger une photo du verso de votre carte d'identité",
  uploadimg3: "Veuillez télécharger une photo de votre carte d'identité",
  uploaderr: "La taille de l'image téléchargée ne peut pas dépasser",
  save_success: 'Enregistré avec succès !',
  save_failure: 'Échec de la sauvegarde!',
  close: 'fermeture',
  hztxt: 'Confirmer le transfert',
  submittip1:
    "Pour la sécurité de vos fonds, veuillez d'abord procéder à une [authentification par nom réel] !",
  submittip2:
    "Pour la sécurité de vos fonds, veuillez d'abord effectuer [liaison de téléphone mobile] !",
  submittip3:
    "Pour la sécurité de vos fonds, veuillez d'abord définir un [mot de passe du fonds] !",
  submittip4: 'Veuillez lier au moins un mode de paiement',
  aderr1: 'Veuillez entrer le bon numéro',
  aderr2: 'La valeur de la prime est de 0 à 20',
  aderr3:
    'La valeur de la prime est comprise entre 0 et 20 et ne peut pas être égale à 0',
  aderr4: 'Veuillez saisir le prix fixe correct',
  aderr5:
    'Veuillez entrer le numéro correct et le montant maximum de la transaction ne dépasse pas 100 pièces',
  orderTypeNo: 'Non',
  orderTypeYes: 'Oui',
  win: 'succès',
  lose: 'échouer',
  tied: 'dessiner',
  cancelsuccess: 'Annulation réussie !',
  shelves:
    "Les annonces ne peuvent être supprimées qu'après leur suppression !",
  shelves2:
    "La modification ne peut être effectuée qu'après la suppression de l'annonce !",
  business: "Veuillez d'abord demander une certification de commerçant !",
  swappleaseselectwallet:
    "Veuillez d'abord sélectionner le compte de transfert/transfert",
  swappleaseinputamount: 'Veuillez saisir le montant du transfert',
  clickchange: 'Cliquez pour changer la direction du balayage',
  daterange: 'Veuillez sélectionner une plage de dates de recherche',
  apierr: 'Veuillez saisir les informations relatives aux remarques',
  apierr2: "Veuillez entrer l'adresse IP",
  apierr3: 'Veuillez entrer la bonne adresse IP',
  apierr4: 'veuillez entrer le code de vérification',
  clickget: 'Cliquez pour obtenir',
  apisecond: 'Deuxième',
  apitips:
    "La clé ne sera affichée que lorsqu'elle sera ajoutée, veuillez la sauvegarder à temps",
  apiaccess: 'clé',
  do_favorite: 'collecté',
  cancel_favorite: 'Annuler les favoris',
  fail_favorite: 'échouer',
  deptotal: 'total',
  chart: 'graphique',
  book: 'carnet de commande',
  trade: 'dernière transaction',
  pt_title: 'Carte-cadeau en monnaie numérique',
  pt_desc:
    "Après l'échange, vous deviendrez automatiquement un ami de niveau inférieur et vous pourrez envoyer des amis, des parents, des clients et des partenaires en cadeau. ",
  pt_more: "Plus d'outils, restez à l'écoute",
  pt_tips:
    'Si vous avez une bonne idée, envoyez un email à promotion@al-cex.com et vous serez récompensé si elle est adoptée !',
  pt_card_amount: 'Montant nominal de la carte',
  pt_card_deadline: 'Période de validité',
  pt_card_noend: 'indéfiniment',
  pt_card_btn:
    'Les partenaires promotionnels reçoivent 30 billets gratuits (environ 2 000 yuans)',
  pt_card_btn_login: 'Connectez-vous et obtenez 30 billets gratuits',
  pt_card_rule: 'Détails de la règle',
  pt_card_summary:
    "Les cartes-cadeaux en monnaie numérique sont l'un des outils de promotion développés pour permettre aux partenaires de promotion de mieux inviter des amis hors ligne. Lorsque les utilisateurs cibles de la promotion utilisent des cartes-cadeaux, ils deviennent automatiquement les amis hors ligne du promoteur.",
  pt_card_rule1:
    '1. Chaque utilisateur est limité à 30 cartes-cadeaux gratuites. ',
  pt_card_rule2: '2. Chaque utilisateur est limité à 1 carte-cadeau gratuite. ',
  pt_card_rule3:
    "3. Avant que l'utilisateur n'utilise la carte cadeau, s'il n'y a pas d'« invité » (c'est-à-dire qu'aucun code d'invitation n'a été saisi lors de l'enregistrement du compte), l'utilisateur sera automatiquement associé en tant qu'ami de premier niveau de l'émetteur de la carte. ",
  pt_card_rule4:
    "4. Afin d'éviter les transactions frauduleuses, le montant du compte de la carte-cadeau n'est pas utilisé en temps réel et l'authentification du nom réel doit être effectuée après réception avant l'opération.",
  pt_card_rule5:
    "5. Vous devez effectuer une authentification par nom réel lorsque vous recevez des cartes-cadeaux gratuites. Vous n'avez pas besoin de procéder à une authentification par nom réel lors de l'échange. Ceci permet aux promoteurs d'obtenir plus rapidement des amis de niveau inférieur.",
  pt_card_rule6:
    "6. Le droit d'interprétation finale de la carte cadeau gratuite appartient à Alcex.",
  pt_card_day: 'ciel',
  pt_card_title_tips: 'Recevez une subvention de promotion de 2 000 yuans',
  pt_invite_desc:
    "Code QR exclusif, vous pouvez automatiquement devenir un ami subordonné grâce à l'enregistrement du code QR, adapté au transfert dans divers groupes ou forums.",
  saveimage: "enregistrer l'image",
  imagetips:
    "Si vous ne parvenez pas à télécharger l'image normalement, veuillez utiliser un outil de capture d'écran pour prendre une capture d'écran et l'enregistrer !",
  pt_card_receivew_success: 'Toutes nos félicitations! ',
  usepromotion: 'Utiliser du matériel promotionnel',
  context_title: 'Code de réduction exclusif du partenaire promotionnel',
  context_title1: 'Scannez pour en savoir plus',
  context_title2: "mon code d'invitation",
  context_title3: "Photo d'invitation",
  pt_card_title: 'Carte de promotion partenaire',
  copysuccess: 'Copiez avec succès !',
  copyerr: 'La copie a échoué ! ',
  noopening: "Il n'y a actuellement aucun contrat d'options en attente",
  createSuccess: 'Créé avec succès',
  tradewarning1: "Entrez jusqu'à 2 décimales",
  tradewarning2: 'Le montant de la commande est',
  tradewarning3: "Entrez jusqu'à 8 décimales",
  tradewarning4: 'La quantité commandée est',
  tradeconfirmbuyin: "Confirmer l'achat",
  tradeconfirmsellout: 'Confirmer la vente',
  tradeinfo: {
    emaildone: 'E-mail certifié',
    emailundo: 'Email non authentifié',
    teldone: 'Le numéro de téléphone portable a été vérifié',
    telundo: 'Numéro de téléphone portable non vérifié',
    idcarddone: "La carte d'identité a été vérifiée",
    idcardundo: "Carte d'identité non authentifiée",
    exchangetimes: 'Nombre de transactions',
    price: 'prix',
    num: 'quantité',
    paymethod: 'mode de paiement',
    exchangelimitamount: 'Limite de transaction',
    location: 'emplacement',
    location_text: 'Chine',
    exchangeperiod: 'Période de transaction',
    minute: 'minute',
    amounttip: 'Veuillez saisir le montant',
    numtip: 'Veuillez entrer la quantité',
    remarktip: 'dites-lui votre demande',
    remarktitle: 'Remarques',
    exchangetitle: 'Instructions de négociation',
    exchange_tip1:
      'Après avoir lancé une demande de transaction, la monnaie numérique est bloquée et protégée par la plateforme. ',
    exchange_tip2:
      "Avant de négocier, veuillez lire les « Conditions de service du réseau de plateforme » et les documents d'aide tels que les FAQ et les guides de transaction.",
    exchange_tip3: 'Attention aux escrocs ! ',
    exchange_tip4:
      'Veuillez noter que les arrondis et les fluctuations de prix peuvent affecter le montant final de la monnaie numérique échangée. ',
    exchange_tip5:
      'Les services Escrow protègent les acheteurs et les vendeurs de transactions en ligne. ',
    warning1: "Entrez jusqu'à 2 décimales",
    warning2: 'Le montant de la commande est',
    warning3: "Entrez jusqu'à 8 décimales",
    warning4: 'La quantité commandée est',
    confirmbuyin: "Confirmer l'achat",
    confirmsellout: 'Confirmer la vente',
    buyin: 'Acheter',
    sellout: 'vendre',
    warning5: 'Veuillez remplir la commande comme requis',
  },
  convertResult:'Aperçu du résultat de la conversion',
  willGet:'Vous obtiendrez',
  pleaseCommit:'Veuillez cliquer pour confirmer dans ce délai. ',
  returnText:'retour',
  convert:'convertir'
};
