<template>
	<div class="footer">
		<div class="container flex" style="justify-content: space-between;margin: 0px auto; max-width: 1400px;width: auto">
			<div class="foot-left">
				<div class="foot-logo">
					<img src="../assets/photo/logo-black.png" alt="">
				</div>
				<div class="copyright">
					{{$t('foottext')}}<br>
					©2022 ALPHA - CEX{{$t('copyright')}}
				</div>
				<ul class="foot-share clearfix">
					<li>
						<el-tooltip class="item" effect="dark" content="https://t.me/Alcexhevin" placement="top">
							<div class="share-link">
								<span class="iconfont icon-fasongx"></span>
							</div>
						</el-tooltip>
					</li>
					<li>
						<a href="" class="share-link">
							<el-tooltip class="item" effect="dark" content="https://twitter.com/Alcexhevin"
								placement="top">
								<div class="share-link">
									<span class="iconfont icon-tuite"></span>
								</div>
							</el-tooltip>
						</a>
					</li>
				</ul>
			</div>
			<!-- <div class="foot-nav flex clearfix" style="margin: 0;padding-right: 100px">
				<ul class="foot-list">
					<li><span class="foot-hd">{{$t('footmeun1')}}</span></li>
					<li>
						<router-link to="/about">{{$t('footmeun1_1')}}</router-link>
					</li>
					<li>
						<router-link target="_blank" :to="{path:'/helplist',query:{cate:6,cateTitle:$t('footmeun1_2')}}">
							{{$t('footmeun1_2')}}
						</router-link>
					</li>
					<li>
						<router-link to="/announcement/0">{{$t('footmeun1_3')}}</router-link>
					</li>
					<li>
						<router-link to="/whitepaper">{{$t('footmeun1_4')}}</router-link>
					</li>
				</ul>
				<ul class="foot-list">
					<li><span class="foot-hd">{{$t('footmeun2')}}</span></li>
					<li>
						<router-link target="_blank" :to="{path:'/helplist',query:{cate:0,cateTitle:$t('footmeun2_1')}}">
							{{$t('footmeun2_1')}}
						</router-link>
					</li>
					<li>
						<router-link target="_blank" :to="{path:'/helplist',query:{cate:1,cateTitle:$t('footmeun2_2')}}">
							{{$t('footmeun2_2')}}
						</router-link>
					</li>
					<li>
						<router-link target="_blank" :to="{path:'/helplist',query:{cate:2,cateTitle:$t('footmeun2_3')}}">
							{{$t('footmeun2_3')}}
						</router-link>
					</li>
					<li>
						<router-link target="_blank" :to="{path:'/helplist',query:{cate:3,cateTitle:$t('footmeun2_4')}}">
							{{$t('footmeun2_4')}}
						</router-link>
					</li>
				</ul>
				<ul class="foot-list">
					<li><span class="foot-hd">{{$t('footmeun3')}}</span></li>
					<li>
						<router-link target="_blank" :to="{path:'/helplist',query:{cate:5,cateTitle:$t('footmeun3_1')}}">
							{{$t('footmeun3_1')}}
						</router-link>
					</li>
					<li>
						<router-link target="_blank" :to="{path:'/helplist',query:{cate:5,cateTitle:$t('footmeun3_2')}}">
							{{$t('footmeun3_2')}}
						</router-link>
					</li>
					<li>
						<router-link target="_blank" :to="{path:'/helplist',query:{cate:5,cateTitle:$t('footmeun3_3')}}">
							{{$t('footmeun3_3')}}
						</router-link>
					</li>
					<li>
						<router-link target="_blank" :to="{path:'/helplist',query:{cate:5,cateTitle:$t('footmeun3_4')}}">
							{{$t('footmeun3_4')}}
						</router-link>
					</li>
				</ul>
				<ul class="foot-list">
					<li><span class="foot-hd">{{$t('footmeun4')}}</span></li>
					<li>
						<el-tooltip class="item" effect="dark" content="service@al-cex.com" placement="right">
							<div class="foot-link">{{$t('footmeun4_1')}}</div>
						</el-tooltip>
					</li>
					<li>
						<el-tooltip class="item" effect="dark" content="support@al-cex.com" placement="right">
							<div class="foot-link">{{$t('footmeun4_2')}}</div>
						</el-tooltip>
					</li>
					<li>
						<el-tooltip class="item" effect="dark" content="list@al-cex.com" placement="right">
							<div class="foot-link">{{$t('footmeun4_3')}}</div>
						</el-tooltip>
					</li>
					<li>
						<el-tooltip class="item" effect="dark" content="ceo@al-cex.com" placement="right">
							<div class="foot-link">{{$t('footmeun4_4')}}</div>
						</el-tooltip>
					</li>
				</ul>
				<ul class="foot-list">
					<li><span class="foot-hd">{{$t('footmeun4')}}</span></li>
					<li>
						<el-tooltip class="item" effect="dark" content="service@al-cex.com" placement="right">
							<div class="foot-link">{{$t('footmeun4_1')}}</div>
						</el-tooltip>
					</li>
					<li>
						<el-tooltip class="item" effect="dark" content="support@al-cex.com" placement="right">
							<div class="foot-link">{{$t('footmeun4_2')}}</div>
						</el-tooltip>
					</li>
					<li>
						<el-tooltip class="item" effect="dark" content="list@al-cex.com" placement="right">
							<div class="foot-link">{{$t('footmeun4_3')}}</div>
						</el-tooltip>
					</li>
					<li>
						<el-tooltip class="item" effect="dark" content="ceo@al-cex.com" placement="right">
							<div class="foot-link">{{$t('footmeun4_4')}}</div>
						</el-tooltip>
					</li>
				</ul>
			</div > -->
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Foot',
	}
</script>
