import axios from 'axios'
import {
	Message
} from 'element-ui'
import {
	devIp
} from '@/api/ipConfig'

import store from '../store/index'
import router from '@/router'
// create an axios instance

const service = axios.create({
	baseURL: devIp + '', // 测试IP
	timeout: 100000, // request timeout
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',
		'lang': 'en_US'
	}
})

// request interceptor
service.interceptors.request.use(config => {
	// Do something before request is sent
	let token = localStorage.getItem('token')
	let lang = localStorage.getItem('lang')
	if (token) {
		config.headers['x-auth-token'] = token // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
	}
	if (lang) {
		config.headers['lang'] = lang // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
	}
	return config
}, error => {
	// Do something with request error
	// console.log(error); // for debug
	Promise.reject(error)
})

// respone interceptor
service.interceptors.response.use(
	// response => response,
	response => {
		const res = response.data

		if (response.config.url.includes('/check/login')) {
			// 这里处理特殊请求的逻辑
			if (res.code === 4000) {
				return res;
			} else {
				return res
			}
		}

		if (res.code === 4000) {
			Message({
				message: res.message,
				type: 'error',
				duration: 3000
			})
			localStorage.removeItem('token')
			store.commit('SET_ISLOGIN', false)
			store.commit("SET_MEMBER", null);
			router.push('/login')
		} else {
			return res
		}
	},
	error => {
		Message({
			message: error.message,
			type: 'error',
			duration: 3000
		})
		return Promise.reject(error)
	})

export default service
