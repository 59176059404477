export default {
  testText1: 'ここは試験場です！',
  testText2: '@Alcexcom',
  testText3:
    '@bzengineer (唯一のチャンネルです、だまされないように注意してください)',
  meun1: '表紙',
  meun2: 'ワンクリックでコインを購入',
  meun3: '貿易',
  meun3_1: '現物取引',
  meun3_2: '素早い交換',
  meun4: '契約取引',
  meun4_1: 'U標準契約',
  meun4_2: 'コインマージン契約',
  meun4_3: 'オプション契約',
  meun4_4: '2回目の契約',
  meun5: '金融業務',
  meun5_1: 'イノベーションラボ',
  meun5_2: 'プロモーションパートナー',
  meun6: '財務管理',
  meun7: '発表',
  meun8: 'ログイン',
  meun9: '登録する',
  meun10: '財布',
  meun10_1: 'スポットアカウント',
  meun10_2: 'Uベースの契約アカウント',
  meun10_3: 'コインマージン契約口座',
  meun10_4: 'セカンドベースの契約',
  meun10_5: '金融口座',
  meun10_6: 'ウォレット履歴',
  meun11: '注文',
  meun11_1: 'スポット注文',
  meun11_2: 'C2C注文',
  meun11_3: '財務管理命令',
  meun11_4: 'フラッシュ交換履歴',
  meun11_5: 'イノベーションマネジメント',
  meun12: 'ダウンロード',
  meun13_1: 'アカウント管理',
  meun13_2: '支払方法',
  meun13_3: '私の昇進',
  meun13_4: 'API管理',
  meun13_5: 'サインアウト',
  meun14_1: '言語',
  meun14_2: '為替レート',
  meun14_3: '言語を選択してください',
  meun14_4: '為替レートを選択してください',
  openTrade: 'いつでもどこでも取引を始めましょう。',
  scan: 'IOS および Android QR コードをスキャンしてダウンロード',
  footmeun1: 'について',
  footmeun1_1: '私たちについて',
  footmeun1_2: '参加しませんか',
  footmeun1_3: 'プラットフォームの発表',
  footmeun1_4: '白書',
  footmeun2: 'ヘルプセンター',
  footmeun2_1: '初心者向けガイド',
  footmeun2_2: 'よくある問題',
  footmeun2_3: '取引ガイド',
  footmeun2_4: '通貨情報',
  footmeun3: '規約同意',
  footmeun3_1: '免責事項',
  footmeun3_2: 'プライバシーポリシー',
  footmeun3_3: 'ユーザー規約',
  footmeun3_4: '料金の説明',
  footmeun4: 'お問い合わせ',
  footmeun4_1: 'カスタマーサービスメール',
  footmeun4_2: '事業協力',
  footmeun4_3: 'コイン上場申請',
  footmeun4_4: '苦情',
  foottext: 'グローバルな高品質デジタル資産交換',
  indexTxt1: '世界のトップ50のデジタル通貨取引所',
  indexTxt2: '国家機関が資産の安全性を保証',
  indexTxt3: '初心者向けガイド',
  indexTxt4: 'コインをすぐに購入する',
  indexTxt5: 'Alcex公式初心者エントリー補助チャンネル',
  indexLabel1: '暗号通貨取引',
  indexVal1: 'デジタル資産を使用して直接交換する',
  indexLabel2: 'オプション取引',
  indexVal2: 'デジタル資産デリバティブ',
  indexLabel3: '契約取引',
  indexVal3: 'コイン標準、U標準契約',
  indexLabel4: '素早い交換',
  indexVal4: '即時通貨交換、ワンクリックで取引を完了',
  indexth: '価格動向',
  indexbtn: '今すぐ取引',
  indexh1: '正直',
  indexh2: '公平',
  indexh3: '熱意',
  indexh4: '開ける',
  indexh5: '金融グレードのセキュリティ',
  indexh6: '非常に速い入出金',
  indexh7: 'グローバルサービス',
  indexh8: '厳選されたプロジェクト',
  indexp1:
    'Alcex は初期のビットコイン参加者とオタクのグループによって設立され、チームの中心メンバーは Google、Microsoft、Alibaba、Tencent などの有名企業出身で、深い研究開発能力とインターネット製品の運用における豊富な経験を持っています。',
  indexp2:
    'Alcexは、基本的なブロックチェーンサービスプロバイダーとして位置付けられ、「悪を行わない」という基本原則を遵守し、誠実、公正、熱意を持って顧客にサービスを提供することを主張し、世界中のユーザーに高品質の暗号資産取引プラットフォームを提供することに尽力しています。ユーザーの基本的利益に利益をもたらすパートナー/プロジェクト。',
  indexp3:
    '包括的な金融リスク管理システムと盗難防止システム、ホットウォレットとコールドウォレット、マルチ署名システムにより資金の安全性が保証されます。',
  indexp4:
    '入金と出金はわずか 3 分で完了し、24 時間の手動オンラインレビューにより、顧客は最良の投資機会を逃すことがなくなります。',
  indexp5:
    'グローバル ビジネス サービス ネットワークをカバーし、グローバル暗号資産への投資やグローバル ユーザーとの取引を支援します',
  indexp6:
    '高品質の暗号化プロジェクトを厳密に選択し、非常にリスクの高いプロジェクトの 80% をフィルタリングします。',
  indexp7: 'QRコードをスキャンしてアプリをダウンロード',
  scandownload: 'スキャンしてAPPをダウンロード',
  ormenu1: 'C2C注文',
  ormenu2: '私の注文',
  ormenu3: 'コインを稼ぐ',
  please: 'まずはお願いします',
  account: 'Alcexアカウントログイン',
  phone: '電話番号',
  email: '郵便',
  sign: 'ログイン',
  password: 'パスワード',
  password2: 'パスワード',
  password3: 'パスワードを忘れる',
  account2: 'Alcexアカウントを作成する',
  logErr: 'ユーザー名を入力してください',
  logErr2: 'パスワードを入力してください',
  logErr3: 'パスワードの長さは 6 文字未満にすることはできません',
  logSuccess: 'ログイン成功',
  account3: '個人アカウントを作成する',
  phone1: '電話を登録する',
  email1: 'メール登録',
  email2: '電子メールアドレス',
  code: 'SMS認証コード',
  code2: '確認コードを送信する',
  code3: '検証コード',
  password4: 'パスワードを認証する',
  invite: '招待コード（オプション）',
  agree: '読んで同意します',
  agreement: 'ユーザー規約',
  register: '登録する',
  regErr: 'SMS認証コードを入力してください',
  confirmpwdtip: '確認用のパスワードを入力してください',
  confirmpwderr: '2 つのパスワード入力が矛盾しています。',
  wjtab: '携帯電話のパスワードをリセットする',
  wjtab2: 'メールパスワードをリセットする',
  wjlabel: '新しいパスワード',
  wjerr: '新しいパスワードを入力してください',
  labTxt: 'Alcex Laboratory ● イノベーション成果の共有',
  labTxt2:
    '投資家、プロジェクト当事者、取引所、その他の関係者にとってWin-Winのオープンラボラトリー',
  labTab1: '全て',
  labTab2: 'まもなく開始します',
  labTab3: '進行中',
  labTab4: '配布中',
  labTab5: '完了しました',
  tokendistribute: 'トークン配布中',
  activitytype: 'サブスクリプションタイプ',
  labStart: '開始時間',
  labEnd: '終了時間',
  labStartPlaceholder: '開始時間を選択してください',
  labDetail: '詳細を確認する',
  labSpeen: 'スケジュール',
  labNumber: '総活動量',
  labType1: '初めてのオンライン（急ぎ購入）',
  labType2: '初めてのオンライン（分割でも）',
  labType3: 'ポジション共有',
  labType4: '無料購読',
  labType5: 'クラウドマイニングマシンのサブスクリプション',
  labType6: 'ロック解除',
  baseinfo: '基本情報',
  ruledetail: 'タイプの説明',
  releaseType: 'リリースタイプ',
  releaseType0: '同量放出',
  releaseType1: '等比率リリース',
  lock: '期間リリース',
  releaseDetail: 'リリース詳細',
  lockday: '日',
  lockweek: '週',
  lockmonth: '月',
  lockyear: '年',
  releasePercent: '放出率',
  lockFee: 'ロックアップ閾値',
  releaseTimes: '複数のリリース',
  times: '回',
  publishprice: '定期購入価格',
  holdcoin: 'ポジション保有に必要な通貨',
  limittimes: '個人購入制限',
  limitamount: '個人購入制限',
  unlimited: '制限なし',
  leveloneCount: '必要な第 1 レベルの友達の数',
  alreadyamount: '購入数量',
  leftamount: '残りの金額',
  myalreadyholdamount: '私の参加ポジション',
  alreadyholdamount: 'イベント参加者の合計保有額',
  currentdivided: '私の現在の立場は大きく分けて次のようになります。',
  holdtips:
    '【ポジションシェア】タイプのアクティビティに参加するには、アクティビティ終了までポジションをロックする必要があります',
  inputholdamount: 'ロック量を入力してください',
  pleaseinputamount: '引き換え数量を入力してください',
  pleaseinputholdamount: 'ロック量を入力してください',
  inputamount: '償還金額を入力してください',
  inputminingamount: '購入するマイニングマシンの数を入力してください',
  attend: '今すぐ参加してください',
  attention: '知らせ',
  attentiontxt1:
    '1. [ポジション共有]、[無料サブスクリプション]、[クラウドマイニングマシンサブスクリプション]、[ロックアップ]タイプのアクティビティへの参加申請後、アセットは凍結され、アクティビティ終 了後に凍結が解除またはロック解除されます。 。',
  attentiontxt2:
    '2. ユーザーは、[ポジションシェア]、[無料サブスクリプション]、[クラウドマイニングマシンサブスクリプション]、[ロックアップ]タイプのアクティビティに参加した後、参加申請をキャンセルする ことはできません。',
  attentiontxt3:
    '3. 企画当事者その他の不可抗力によりイベントが中止となった場合、凍結された資産は元のルートに返却されます。',
  attentiontxt4:
    '※本アクティビティの最終解釈権はAlcex公式（www.al-cex.com）に帰属します。',
  transfer: '口座振替え',
  placeholder: '通貨の検索',
  bith1: '契約取引',
  bith2: 'アカウントの権利',
  bith3: '未実現損益',
  bith4: '利用可能な証拠金',
  bith5: '使用済み証拠金',
  bith6: 'フリーズマージン',
  bith7: '契約口座',
  btn1: '補充する',
  btn2: '撤回する',
  btn3: '移行',
  btn4: 'コインを入金する',
  btn5: 'コインを引き出す',
  totalAssets: '換算された総資産',
  th1: '通貨名',
  th2: '利用可能なアセット',
  th3: '資産を凍結する',
  th4: 'リリースされるアセット',
  th5: '操作する',
  czTxt: 'デジタル通貨のリチャージ',
  czTxt2: '通貨',
  czTxt3: '転送ネットワーク',
  czTxt4: '利用可能残高',
  czTxt5: '出金アドレス',
  czTxt6: '引き出したコインの量',
  czTxt7: '入荷数',
  czTxt8: '手数料',
  czTxt9: '親切なヒント',
  czTxt10: '最低入金額:',
  czTxt10_1: '最低金額未満のリチャージはクレジットされません。',
  czTxt11:
    '上記のアドレスには非通貨資産を預けないでください。預けないと資産が回収されません。',
  czTxt12:
    '上記のアドレスにリチャージした後、ブロックチェーンネットワークノード全体からの確認が必要となり、通常、メインブロックチェーンネットワークからの3回のネットワーク確認後にアカウントに入金され ます。',
  czTxt13:
    'リチャージアドレスは頻繁に変更されることはなく、繰り返しリチャージすることができます。変更があった場合には、ウェブサイトのお知らせまたは電子メールを通じてお知らせできるよう最善を尽くします。',
  czTxt14:
    '情報の改ざんや漏洩を防ぐため、お使いのパソコンやブラウザが安全であることをご確認ください。',
  czTxt15: 'リチャージ記録',
  czTxt16:
    '資金の安全性を確保するため、アカウントのセキュリティ ポリシーを変更したり、パスワードを変更したり、新しいアドレスを使用してコインを引き出す場合は、出金の手動審査が行われます。スタッフから の連絡をお待ちください。電話かメールで。',
  czTxt17: '最低出金額は次のとおりです。',
  czth1: '出金時間',
  czth2: 'から',
  czth3: 'に',
  czth4: '即時引き換えの数量',
  czth5: '交換比率',
  czth6: '交換数量',
  placeholder2: '入ってください',
  placeholder3: '選んでください',
  czEmpty: '記録がない',
  logintip: 'まずログインしてください',
  cztip:
    'リチャージアドレスを申請していません。ボタンをクリックしてリチャージアドレスを取得してください。',
  czget: 'リチャージアドレスを取得する',
  czplaceholder: 'プロトコルを選択してください',
  mAccount: '2番目の契約アカウント',
  btn6: '検索',
  wtTxt: '開始時間と終了時間',
  wtTxt2: '操作の種類',
  wtth1: '取引時間',
  wtth2: 'タイプ',
  wtth3: '通貨',
  wtth4: '量',
  wtth5: '手数料の支払いが必要',
  wtth6: '手数料を差し引く',
  wtth7: '実費手数料',
  wtth8: '州',
  nav1: 'アカウントのセキュリティ',
  nav2: '私の招待状',
  nav3: '私のプロモーションカード',
  acTxt: 'セキュリティレベル',
  acbtn1: '認定されていない',
  acbtn2: 'バインディング',
  acbtn3: '改訂',
  acbtn4: '検証済み',
  acbtn5: 'バウンド',
  acbtn6: '設定',
  acbtn7: '検討中',
  acbtn8: 'リトライ',
  acTxt2: '確認済み',
  acTxt3: '郵便',
  acTxt4: '携帯電話',
  acTxt5: 'ログインパスワード',
  acTxt6: '基金のパスワード',
  acTxt7: 'Google認証システム',
  acTxt8: 'ファンドのパスワードを設定する',
  acTxt9: 'ファンドのパスワードを変更する',
  acTxt10: 'ファンドのパスワードを取得する',
  acDesc2:
    'アカウントの安全性を確保するため、取引前に実名認証を完了してください！',
  acDesc3: '電子メールをバインドする',
  acDesc4: '携帯電話をバインドする',
  acDesc5: 'プラットフォームにログインするときに使用されます',
  acDesc6:
    'アカウントの資金が変更されると、資金のパスワードを確認する必要があります',
  acDesc7: 'Google 認証システムをバインドしてください',
  acDesc8: 'Google 認証システムがバインドされている',
  acDesc9: '解放する',
  acTit: '携帯電話のバインド',
  acTit2: '電子メールバインディング',
  acTit3: 'Googleバインディング',
  acTit4: 'Googleのバインド解除',
  mailtip: 'メールアドレスを入力してください',
  emailerr2: 'メールの形式が間違っています。再入力してください。',
  agreementtip: 'ユーザー規約を読んで同意してください',
  acverify: 'レビューに失敗しました',
  again: 'もう一度試してください',
  verifiedmsg: '最初に実名認証を実行してください',
  apiTxt: '実名アカウントをバインドする',
  apiTxt2: 'ヒント',
  apiTxt3:
    '市場調査や自動売買などのサービスを利用できる強力なAPIを提供します。 ',
  apiTxt4: '各ユーザーは最大 5 セットの API キーを作成できます',
  apiTxt5: '資産の損失を避けるために、API キーを開示しないでください。',
  apiTxt6: 'API ノート',
  apiTxt7: '認可された IP (認可された IP アドレスのキーは 90 日間有効です)',
  apibtn: '作成する',
  apith1: 'API ノート',
  apith2: '作成時間',
  apith3: 'アクセスキー',
  apith4: 'IPアドレスをバインドする',
  apith5: '残り有効期限（日）',
  chTxt: 'ログインパスワードを変更する',
  chTxt2: 'コードをスキャンしてバインドする',
  chTxt3: '確認コードを入力してください',
  chTxt4: '元のファンドのパスワード',
  chTxt5: '新しいファンドのパスワード',
  chTxt6: '新しいパスワードを確認します',
  chTxt7: 'パスワードを忘れる',
  chTxt8: '携帯電話認証を変更する',
  chTxt9: '元のログインパスワード',
  chTxt10: '新しいログインパスワード',
  chTxt11: '電子メール認証コード',
  chTxt12: '携帯電話認証コード',
  telerr: '携帯電話番号が間違っています',
  emailerr: 'メールが間違っています',
  codeerr: '間違った確認コード',
  reset: 'リセット',
  save: '保存',
  chtip: '元のパスワードを入力してください',
  chtip2: '新しいログインパスワードを6桁以上入力してください',
  chtip3: '新しいログインパスワードが一致しません',
  chtip4: '正しいファンドのパスワードを入力してください',
  chtip5: 'パスワードは6文字以上である必要があります',
  chtip6: '6桁以上のパスワードを入力してください',
  chtip7: 'パスワードが一致しません',
  chtip8: 'ファンドのパスワードを入力してください',
  chtip9: '電話番号を入力してください',
  chtip10: 'パスワードを入力してください',
  realnametip: '本名を入力してください',
  idcardtip: 'ID番号を入力してください',
  clTxt: '支払い方法を設定し、必ず実名のアカウントを使用してください',
  clTxt2: '支払い方法を追加する',
  edit: '編集',
  delete: '消去',
  name: '名前',
  clTxt3: 'ID番号',
  clTxt4: 'IBAN番号',
  clTxt5: 'すべての支払い方法',
  clLink1: 'アカウント名',
  clLink2: '支払い方法を編集する',
  clLink3: '支払いの詳細 (オプション)',
  clplaceholder1: 'フルネームを入力してください',
  clplaceholder2: '銀行口座のパスワードを入力してください',
  clplaceholder3: 'お支払いの詳細を入力してください',
  cltip: '特記事項',
  cltip2: '即時支払いのため、必ず銀行カード番号を追加してください。',
  cltip3:
    '注意: デジタル通貨を販売する場合、選択した支払い方法が購入者に表示されます。',
  clTxt6: 'オプション',
  upload: 'アップロード',
  uploadTip: '(JPG/JPEG/PNG/BMP形式に対応、1MB以下)',
  tgbtn: 'コードを引き換える',
  tgth1: 'カード名',
  tgth2: 'コードを引き換える',
  tgth3: 'カード通貨',
  tgth4: 'カード額面金額',
  tgth5: '合計',
  tgth6: 'すでに引き換えられています',
  tgth7: '収集時間',
  tgbtn2: '宣伝用の資料',
  tgbtn3: '今すぐ交換',
  tgplaceholder: '引き換えコードを入力してください',
  exchangesuccess: '引き換え成功！',
  inth1: 'ユーザーを招待する',
  inth2: '登録時間',
  inth3: '実名ステータス',
  adTxt: '私の広告',
  adTxt2:
    '広告の最低取引金額に手数料を加えた金額が広告の残量を超える場合、広告は自動的に棚から削除されます。',
  adbtn: 'クリア条件',
  adbtn2: '棚の上',
  adbtn3: '棚から削除されました',
  adth1: '広告番号',
  adth2: '広告の種類',
  adth3: '注文制限',
  adth4: '残りの金額',
  adtip: '削除を確認する',
  num_text1: 'ご希望の内容をご入力ください',
  num_text2: '量',
  wTxt: 'スポットグッズ',
  wTxt2: '現在の注文',
  wTxt3: '取引ペア',
  wTxt4: '取引の方向性',
  wth1: '時間',
  wth2: '方向',
  wth3: '価格',
  wth4: '交渉成立',
  wth5: 'ひっくり返す',
  wth6: 'デリゲートタイプ',
  wth7: 'トリガー価格',
  wth8: '最終価格',
  wth9: '注文数量',
  wth10: '決済損益',
  wth11: '代表団のステータス',
  wth12: 'ポジションをオープン/クローズする',
  wth13: 'マージン',
  wbtn: '注文をキャンセルする',
  wtip: 'キャンセルリマインダー',
  wtiptxt: '注文のキャンセルを確認しますか?',
  zbplaceholder: '注文番号を入力して検索を開始します',
  zbtab1: '設備投資計画',
  zbtab2: '固定投資実績',
  zbth1: '投資サイクル',
  zbth2: 'スイッチ',
  zbth3: '累計投資額',
  zbth4: '保有数量',
  zbth5: '平均コスト',
  zbth6: '未実現損益',
  zbth7: '次回の投資予定日',
  zbth8: '計画作成日',
  etTxt: 'アナウンスセンター',
  etTxt2: 'スキャンして共有する',
  etmenu1: '暗号通貨',
  etmenu2: 'Alcexサーバーアップデートのお知らせ',
  et2menu1: 'RX.Tron の紹介',
  et2menu2: 'ビットコインはポンジスキームですか?',
  hisTxt: '歴史委員会',
  orTab1: '未払い',
  orTab2: '既に支払いました',
  orTab3: '完了しました',
  orTab4: 'キャンセル',
  orTab5: '魅力的',
  orth1: '注文番号',
  orth2: '取引通貨',
  orth3: '取引タイプ',
  orth4: '取引パートナー',
  orth5: '額',
  qcTab1: '過去の結果',
  qcTab2: 'リアルタイムの相場',
  qcTxt: 'いいえ。',
  qcTxt2: '期待する',
  qcTxt3: '開始時間',
  qcTxt4: '閉店時間',
  qcTxt5: '始値',
  qcTxt6: '終値',
  qcTxt7: '購入総額',
  qcTxt8: '売買総額',
  qcTxt9: '私のオープンポジション',
  qcTxt10: '進捗状況の予測',
  qcTxt11: '始値',
  qcTxt12: '終値',
  qcTxt13: 'この予測期間',
  qcbtn1: '強気な',
  qcbtn2: '弱気',
  qcth1: '契約期間数',
  qcth2: '開口量',
  qcth3: '予測方向',
  qcth4: '予測結果',
  qcth5: 'ボーナス額',
  qcth6: '出店料',
  qcth7: 'ポンプ水',
  mplaceholder: '通貨名を入力して検索します',
  mTab: 'オプション',
  mth: '集める',
  mth1: '最新の価格',
  mth2: '24時間上昇と下降',
  mTxt: '24時間最高値',
  mTxt2: '24時間最低価格',
  mTxt3: 'トランザクション数',
  mTxt4: '資金調達率',
  mTxt5: '開ける',
  mTxt6: '高い',
  mTxt7: '低い',
  mTxt8: '受け取る',
  echat1: 'K 折れ線グラフ',
  echat2: '深度マップ',
  mTab2: '現在位置',
  mTab3: '委員会の歴史',
  mth3: '保証された補償',
  mth4: 'オープン価格',
  mth5: 'サイクル',
  mth6: '終値',
  mth7: '利益',
  mTxt9: '取引モデル',
  mTxt10: '開始時間',
  mTxt11: '収益性',
  mTxt12: 'バランス',
  mTxt13: '購入量',
  mTxt14: '真ん中',
  cadTxt: '広告取引を作成する',
  cadTxt2: '頻繁に取引する場合は、独自の取引広告を作成できます。',
  cadTxt2_1: 'たまにしか取引しない場合は、直接検索することをお勧めします',
  cadTxt2_2: '貿易広告の作成は無料です。',
  cadLink2: 'トランザクション広告',
  cadTxt3: '作成した広告を直接編集したい場合は、こちらをご覧ください。',
  cadLink3: '私の広告',
  cadTxt4: '市場参加価格',
  cadTxt5: 'プレミアムとは、現在の市場価格を上回る販売割合を指します。',
  cadTxt6: '価格計算式',
  cadTxt7:
    '[ヒント] パーソナル センターにアクセスして、支払い方法をバインド/追加できます。',
  cadTxt8: '最大取引額は合計販売額 0CNYI を超えることはできません',
  cadTxt9:
    '有効にした後、ユーザーがこの広告を通じてあなたとの取引を開始すると、システムはあなたが選択した自動返信文言を自動的に相手に送信します。',
  cadTxt10: 'プレミアムとは、現在の市場価格を超えて購入できる割合を指します',
  cadLabel1: '欲しい：',
  cadLabel2: '通貨取引:',
  cadLabel3: '国家：',
  cadLabel4: '通貨：',
  cadLabel5: '固定価格をオンにする',
  cadLabel6: 'プレミアム:',
  cadLabel7: '取引価格',
  cadLabel8: '販売数量:',
  cadLabel9: '支払方法：',
  cadLabel10: '最低取引金額:',
  cadLabel11: '最大取引金額:',
  cadLabel12: '自動応答をオンにする',
  cadLabel13: '基金のパスワード:',
  cadLabel14: '備考：',
  cadLabel15: '固定価格：',
  cadLabel16: '自動応答:',
  cadLabel17: '購入数量:',
  radio: 'オンライン販売用',
  radio2: 'インターネットで買う',
  cadplaceholder: 'プレミアムを設定してください',
  cadplaceholder2: '売りたい数量を入力してください',
  cadplaceholder3: '希望する最低取引金額を入力してください',
  cadplaceholder4: '希望する最大取引額を入力してください',
  cadplaceholder5:
    '備考情報に、購入者の要件、オンライン時間などの特別な要件を入力できます。',
  cadplaceholder6: '取引価格を入力してください',
  cadplaceholder7:
    '注文を受け取った後、支払い方法、支払い口座番号などの購入者の情報を自動的に返信します。',
  cadplaceholder8: '購入したい数量を入力してください',
  caderr1: '最低取引金額を入力してください',
  caderr2: '最低取引額は 100 以上である必要があります。',
  caderr3: '最低取引額は最大取引額未満である必要があります',
  caderr4: '最大取引金額を入力してください。',
  caderr5: '整数を入力してください',
  caderr6: '最大取引額は最低取引額より大きくなければなりません。',
  caderr7: '最大取引額は売上総額を超えることはできません',
  caderr8: '取引方法を選択してください',
  submit: '提出する',
  cadnew: '有効化後、通貨の価格は市場に応じて変動せず、価格は変わりません。',
  dhTxt: 'ワンクリックで交換して特典をお楽しみください',
  dhTxt2: '価格制限',
  dhTxt3: '売る',
  dhTxt4: '買う',
  dhTxt5: '最大',
  dhTxt6: '金額を入力します',
  dhplaceholder: 'スポットウォレットの利用可能残高',
  dhSuccess: 'おめでとう！',
  crTxt: '法定通貨取引',
  crTxt2: '便利、安全、迅速なデジタル通貨の売買',
  crTxt3: '動的調整',
  crTxt4: '手数料無料',
  crTxt5: 'インスタントトランザクション',
  crTxt6: 'プラットフォーム保証',
  crTxt7: '市場価格に基づいてリアルタイムに変動します',
  crTxt8:
    'ユーザーが目にするものはそのまま得られるものであり、売買価格以外にプラットフォーム手数料はかかりません。',
  crTxt9:
    '販売者、インテリジェントなマッチング、マッチングを待たずにトランザクション注文を提供するプラットフォームの導入',
  crTxt10:
    'プラットフォーム認定加盟店、安全で安心な、取引を保護する 24 時間のカスタマー サービス',
  crth1: '商人',
  crth2: 'トランザクション数',
  crth3: '支払方法',
  crth4: '限界',
  crth5: '単価',
  crbtn: '買う',
  crbtn2: '商人になる',
  cr1Txt: 'おめでとう！',
  cr1Txt2: '材料を準備する',
  cr1Txt3: 'レビュー送信',
  cr1Txt4: '検証済み',
  cr1Txt5: '次の権限が付与されています',
  cr1Txt6: '専用ブース',
  cr1Txt7: '1対1のサービス',
  cr1Txt8: '販売者は取引の成功率を高めるために専用の広告ブースを利用します',
  cr1Txt9: '手数料の削減',
  cr1btn: '広告を掲載する',
  cr1btn2: '降伏を申請する',
  reTxt: 'Bizan Financial Management●ワンストップ投資ソリューション',
  reTxt2: '簡単かつ安全にすぐにお金を稼ぐことができます',
  reTab: '定期的な財務管理',
  reTab2: '固定投資財務管理',
  reTxt3: '通常',
  reTxt4: '柔軟なカスタマイズ、より高い潜在利益',
  reTxt6: '固定投資',
  reTxt7: '固定投資を始めて資産を増やしましょう',
  reTxt8: 'レギュラー商品全18品を展開',
  reth1: '年換算収益率',
  reth2: '締切日（日）',
  reth3: '取引限度額',
  reth4: '製品',
  reth5: '過去の投資収益率',
  rebtn: '計画の作成',
  redTxt: '作成する',
  redTxt2: '設備投資計画',
  redTxt3: '投資額を入力してください',
  redTxt4: '私の利用可能残高は',
  redTxt5: '利用可能',
  redTxt6: '周期',
  redTxt7: '毎日',
  redTxt8: '毎週',
  redTxt9: '月あたり',
  redTxt10: '現地時間',
  redTxt11: '最初の自動投資サイクルは次のようになります。',
  redTxt12: '始める',
  confirm: '確認する',
  confirm2: 'もちろん',
  cancel: 'キャンセル',
  redongoing: '進行中',
  redcompleted: '完了しました',
  minNumErr: '最低開始金額未満',
  minNumErr2: '開始金額を0にすることはできません',
  buyamounttip: '購入金額を入力してください',
  pricetipwarning: '取引金額は以下を超えることはできません',
  buyamounttipwarning: '購入数量は以下を超えることはできません',
  sellamounttip: '販売金額を入力してください',
  sellamounttipwarning: '販売数量を超えることはできません',
  sellpricetip: '販売価格を入力してください',
  sellpricetipwarning: '販売価格はこれより高くすることはできません',
  sellmore: '一番売れるのは',
  sellunit: '個人の',
  loginFirst: 'まずログインしてください',
  betsuccess: 'おめでとう！',
  selectAmount: 'ベット額を選択してください',
  balancenotenough: '利用可能な残高が不足しています!',
  balancenotenough2: '残高不足です！',
  minlimitamountfailed: '引き換え額は最低引き換え額を下回ることはできません。',
  maxlimitamountfailed: '引換数量は最大購入数量を超えることはできません。',
  limittimesfailed: '参加回数が個人購入上限を超えています！',
  commitfailed: '参加申請が失敗しました!',
  trplaceholder: 'お問い合わせ',
  trplaceholder2: '最新の価格',
  trplaceholder3: '市場で最高の価格で購入する',
  trplaceholder4: '市場で最高の価格で販売する',
  triggleplaceholder: 'デフォルトの注文価格は市場価格です',
  triggerpriceshouldbigger: 'トリガー価格は0より大きくなければなりません',
  marginModeSuccessTip: '契約アカウントモードが正常に変更されました',
  noenoughbalance: '口座証拠金が不十分です',
  pleaseinputopenvolume: '開始数量は 0 より大きくなければなりません',
  pleaseinputcorrectopenvolume: '正しい開封数量を入力してください',
  pleaseinputrightentrustprice: '正しい注文価格を入力してください',
  noenoughposition: '流動性ポジションが不十分',
  pleaseinputclosevolume: '終了数量は 0 より大きくなければなりません',
  pleaseinputcorrectclosevolume: '正しい終了数量を入力してください',
  option1: 'アンテナ',
  option2: '周囲',
  option3: '月次ライン',
  trTxt: '取引量',
  trTxt2: '市場価格',
  trTxt3: '販売量',
  trSuccess: '正常に送信されました',
  udTxt: 'マージンモード',
  udTxt2: '契約倍率を調整する',
  udTxt3: 'レバー',
  udTxt4: '持続可能な',
  udTxt4_1: '持続可能な',
  udTxt5: '譲渡可能額',
  udTxt6: '転送量',
  udTxt7: '全部転送',
  udTxt8: '永久アカウント',
  ubtn: '倉庫がいっぱい',
  ubtn2: '孤立した位置',
  uplaceholder: '永久契約アカウント',
  uradio1: '時間を共有する',
  uradio2: '15分',
  uradio3: '1時間',
  uradio4: '4時間',
  uradio5: '1日',
  uth1: '複数の契約',
  uth2: '所得',
  uth3: '利益率',
  uth4: '建玉',
  uth5: '均一に測定できる',
  uth6: '平均始値',
  uth7: '強力なパリティ',
  uth8: '現在のマージン',
  uth9: 'マージン率',
  zhang: '開ける',
  or: 'または',
  ureg: '今すぐサインアップ',
  uTxt8: '取引',
  uTab: 'ポジションをオープンする',
  uTab2: 'クローズポジション',
  uTxt9: '長く開けられる',
  uTxt10: '短く開けることも可能',
  uTxt11: '注文価格',
  uplaceholder2: '市場最安値での取引',
  uTxt12: '長く開くために買う',
  uTxt13: '空売りする',
  closeup: '空売りを買う',
  closedown: '長く売る',
  uTab3: 'テイクプロフィットとストップロス',
  uTxt14: '私の契約アカウント',
  ulabel1: 'アカウントモード',
  ulabel2: '口座収入合計',
  ulabel3: 'ポジションマージン',
  ulabel4: '資金利用率',
  ulabel5: '多くの',
  ulabel6: 'ヌル',
  uTxt15: '長さ',
  uTxt16: '短い',
  total: '合計',
  limited_price: '指値注文',
  market_price: '成行注文',
  spot_price: 'テイクプロフィットとストップロス',
  ent_status1: '委託中',
  ent_status2: '取り消されました',
  ent_status3: '委任が失敗しました',
  ent_status4: '委任が成功しました',
  ent_statusblast: '清算',
  uTxt17: 'ロングポジションを決済できる',
  uTxt18: 'ショートポジションはクローズ可能',
  prev: '前の',
  next: '次',
  prtxt: '私の招待リンク',
  copy: 'コピー',
  prtxt2: '私の第一レベルの友達',
  prtxt3: '私の二次的な友達',
  prtxt4: '手数料収入（USDT相当）',
  prtxt5: '追加の報酬',
  prtxt6: 'パートナーシップレベル',
  prtit: 'ルールの詳細',
  prdesc:
    'Alcexの【プロモーションパートナー】は、ネットワーク全体で最高の手数料リベート率と最長のリベート期間（最大生涯リベート）を誇るプロモーション活動であり、相応の努力をしたプロモーターがプラッ トフォームの真の「パートナー」となり、配当金を享受することができます。 Alcex取引プラットフォームとともに成長していきます！',
  prli1:
    '1.Alcexは、デジタルアセットの概念を推進し、ユーザー規模を拡大するために、長期にわたって効果を発揮する【プロモーションパートナー】プログラムを開始しました。',
  prli2:
    '2. 友達の招待は 2 つのレベルに分かれており、A が B を招待した場合、B は A の第 1 レベルの友達となり、B が C を招待した場合、C は B の第 1 レベルの友達であり、A の第 2 レベルの友達になります 。',
  prli3:
    '3. プロモーション友達として登録する場合、招待者が提供するリンクを使用するか、招待者の招待コードを手動で入力して、招待者の第 1 レベルの友達になる必要があります。',
  prli4:
    '4. リベートインセンティブは、招待者が招待者の招待コードを使用して登録し、実名認証を完了した後にのみ有効になります。',
  prli5:
    '5. リベート期間は招待者の実名認証完了時からNヶ月間で、レベルに応じてリベート率が異なります。',
  prli6:
    '6. プロモーション リベート資産は、従属友人の通貨の取引手数料であり、リベート通貨は 3 つの主要な市場基本通貨、つまり USDT、BTC、ETH です。',
  prli7:
    '7. プロモーション リベート率とリベート期間は、昇格する第 1 レベルの友達の数と正の相関関係があり、昇格する人数が増えるほど、より高いリベート率を享受できます。',
  prli8:
    '8. プロモーション手数料ランキングの上位10名または上位100名には、Alcexが公式に発行する追加報酬が随時提供されます。',
  prli9:
    '9. 他の活動が【プロモーションパートナー】の活動と抵触する場合は、一時的な変更については協議の上決定させていただきますので、パートナー様におかれましては、ご理解を賜りますようお願いいたします 。',
  prli10:
    '10. L4 (Governor)、L5 (Prefect)、および L6 (Jiedushi) レベルのパートナーは、年間手数料リベート総額のそれぞれ 5%、10%、および 15% の配当報酬を享受します。',
  prli11: '11. 本アクティビティの最終解釈権はAlcexに帰属します。',
  prth1: 'レベル',
  prth2: '第一レベルの友達の数',
  prth3: '第 1 レベルの友達リベート率/リベート時間',
  prth4: '第 2 レベルの友達リベート率/リベート時間',
  prth5: 'パートナー配当',
  prlev1: '李鄭',
  prlev2: '郡判事',
  prlev3: '知事',
  prlev4: '知事',
  prlev5: '知事',
  prlev6: 'ジエドゥシ',
  prtd1: '月',
  prtd2: '生涯リベート',
  prtit2: '例えば',
  prtxt7:
    'ユーザー Xiao Yan が 100 人の第 1 レベルの友達を招待し、その第 1 レベルの友達がそれぞれ 5 人の第 2 レベルの友達を招待すると、Xiao Yan には第 1 レベルの友達が 100 人、第 2 レベルの友達が 500 人になります。',
  prtxt8:
    'ユーザー Xiao Yan が 1,000 人の第 1 レベルの友達を招待し、その第 1 レベルの友達がそれぞれ 5 人の第 2 レベルの友達を招待した場合、Xiao Yan には第 1 レベルの友達が 1,000 人、第 2 レベルの友達が 5,000 人いることになります。',
  prtit3: 'プロモーションツールボックス',
  prtit4: 'プロモーション リベート合計上位 20 (合計リスト)',
  prtit5: '上位20プロモーター（合計リスト）',
  prtxt9: '統計期限:',
  prth6: 'ランキング',
  prth7: 'メンバー',
  prth8: 'プロモーション番号',
  prth9: 'リベート（USDT相当）',
  prth10: '追加の報酬',
  prtip1:
    '上記の手数料データはリアルタイムでは更新されず、システムが 24 時間ごとにカウントして更新します。',
  prtip2:
    '招待者数は第1レベルの友達の数であり、このリストはリベート総額のランキングです',
  prtip3:
    '招待者数は第 1 レベルの友達の数であり、このリストは第 1 レベルの招待者数のランキングです。',
  recth1: '到着時刻',
  recth2: 'ネットワークプロトコル',
  recth3: '入金アドレス',
  recth4: 'リチャージ量',
  recbtn: 'コイン出金コードのリチャージ',
  recplaceholder: '出金コードを入力してください',
  recstatus_0: '失敗',
  recstatus_1: '受信していない',
  recstatus_2: 'すでに到着しました',
  wittit: 'デジタル通貨の出金',
  wittext: '出金記録',
  wittext2: '出金コード',
  wittext3: 'コイン出金コード一覧',
  witbtn: 'コインの引き出しコード コインの引き出し',
  witbtn2: '通貨引き出しコードを生成する',
  witdia: '安全性の検証',
  witdia2: '出金コードが正常に生成されました',
  witplaceholder: '出金額を入力してください',
  witerr: '通貨を選択してください',
  addresstip: '住所をご記入ください',
  witstate: '償還される',
  witstate2: '失敗',
  witstate3: '償還が完了しました',
  witstatus_1: '検討中',
  witstatus_2: '転送中',
  witstatus_3: '失敗',
  witstatus_4: '成功',
  type1: '移行',
  type2: '暗号通貨取引',
  type3: '法定通貨の購入',
  type4: '法定通貨を売る',
  type5: '活動賞',
  type6: 'プロモーション特典',
  type7: '配当',
  type8: '投票する',
  type9: '手動充電',
  type10: 'ペア',
  type11: 'イベント引き換え',
  type12: 'C2C購入',
  type13: 'C2C販売',
  type14: '赤い封筒',
  type15: '赤い封筒を受け取る',
  type16: 'コインの引き出しコード コインの引き出し',
  type17: 'コインコードの出金とコインの入金',
  type18: '永久契約事務手数料',
  type19: '無期契約利益',
  type20: '無期限契約の喪失',
  type21: 'オプション契約が失敗した',
  type22: 'オプション契約事務手数料',
  type23: 'オプション契約ボーナス',
  type24: '契約リベート',
  type25: 'レベル報酬',
  type26: 'プラットフォーム手数料収入',
  type27: '2回目の契約は失敗しました',
  type28: '2回目の契約ボーナス',
  type29: '金銭的利益',
  type30: '資金の支出',
  type31: '資金調達手数料の取得',
  type32: '固定投資の購入',
  type33: '固定投資の売り',
  type34: '定期的に購入する',
  type35: '通常の償還',
  type36: 'コインベースの契約移転',
  type37: 'コインベースの契約送金',
  type38: 'Uベースの契約移転',
  type39: 'Uベースの契約移管',
  type40: '二次契約譲渡譲渡',
  type41: '2回目の契約譲渡',
  type42: 'コイン転送転送',
  type43: 'コイン送金アウト',
  lctit: '金融口座',
  lcbtn: '財務管理の譲渡',
  lcth1: '累積利息',
  day: '空',
  lctxt: 'アクティビティ通貨',
  lctxt2: '受け入れられる通貨',
  lctxt3: '開始数量',
  lctxt4: '選考期間',
  year: '年',
  month: '月',
  ldtab1: '位置記録',
  ldtab2: '記録を閉じる',
  ldth1: '獲得開始日',
  ldth2: '償還日',
  ldth3: '収益見積もり',
  ldth4: '所得',
  attit: '固定投資計画の変更',
  attxt: '投資額',
  attxt2: '投資サイクル',
  atth1: '固定投資日',
  atth2: '固定投資額',
  atth3: 'トランザクションレート',
  success: '成功',
  cxth1: '名前',
  cxth2: 'タイプ',
  cxth3: '購読数量',
  cxth4: '参加通貨',
  cxth5: 'サブスクリプション単位',
  cxth6: '現在のステータス',
  cxth7: 'ひっくり返す',
  cxth8: '作成時間',
  cxnav1: '私は参加しました',
  cxnav2: '私のマイニングマシン',
  cxnav3: '私のロック位置',
  cxtit2: '私のマイニングマシンリスト',
  cxtit3: '私のロックリスト',
  cxkth1: '出力通貨',
  cxkth2: '生産サイクル',
  cxkth3: '採掘時間',
  cxkth4: '採掘時間',
  cxkth5: '基本能力',
  cxkth6: '実際の容量',
  cxstate1: '導入されていません',
  cxstate2: '導入済み',
  cxstate3: '取り消されました',
  cxstate4: '保留中のトランザクション',
  cxstate5: '以上',
  cxstate6: 'リリース予定',
  cxstate7: '解放する',
  cxcth1: 'リリース通貨',
  cxcth2: 'トータルロックアップ',
  cxcth3: '総リリース期間',
  cxcth4: 'リリースされたサイクル',
  cxcth5: 'ベースリリース',
  cxcth6: '実際のリリース',
  more: 'もっと',
  abtit: 'プラットフォームの機能',
  abtit2: '私たちについて',
  abtit3: 'お問い合わせ',
  abtit4: 'ビザンコミュニティ',
  abdesc:
    'Alcex は、テクノロジーを活用した世界有数のデジタル資産取引プラットフォームで、ケイマン諸島に登録されており、中核的な運営チームは香港にあります。 ',
  abdesc2:
    'Alcexプラットフォームは、専門的な金融レベルのトランザクション構造と自社開発の高同時実行メモリマッチングトランザクションエンジンを備え、完全コールドリチャージウォレットシステム+マルチシグ ネチャ+高保護DDOS攻撃システムなどのトランザクション構造を採用し、顧客資産の安全。',
  abdesc3:
    'Alcexでは、お客様がより投資機会を掴めるよう、カスタマーサービス部門内でのフラットマネジメントを採用し、極めて迅速なサービス対応体制を確立しており、アセットカスタマーサービスマネージャーが年中無休で7時間365日オンラインでお客様に資産運用などのサービスを提供しています。顧客が 5 分以内に応答できることを保証し、資産の入出金を完了します。',
  abdesc4:
    'Alcexは質の高い案件を厳選し、安全・安定した資産お預かりサービスをご提供いたします。 ',
  abdesc5:
    'デジタルアセットは今はまだ小さな輪にすぎませんが、将来的にはこのような誰もが完全にコントロールできるアセットが普及すると信じており、一緒に楽しみながら取り組んでいきましょう！',
  abdesc6: '顧客サービス',
  abdesc7: 'テクニカルサポート',
  abdesc8: 'コイン上場申請',
  abdesc9: '提案',
  abdesc10: '事業協力',
  abdesc11: '微信',
  abdesc12: '微博',
  abdesc13: 'ツイッター',
  abdesc14: 'コインの使用',
  abdesc15: '電信',
  abdesc16:
    '「bmate601」をWeChat友達として追加し、WeChat福祉コミュニティに参加してください',
  realtxt: '本名',
  realtxt2: 'ID番号',
  realtxt3: 'IDカードの表面写真',
  realtxt4: 'IDカードの裏面の写真',
  realtxt5: 'ID カードと個人の署名を持った写真',
  realtxt6:
    '1. JPG および PNG 形式がサポートされており、ファイル サイズは 8M 未満です。証明書の情報は明確に表示され、変更やブロックは許可されません。証明書の番号と名前は明確に表示される必要があります。',
  realtxt7:
    '2. 写真は帽子や化粧をせず、書類を持つ人の顔の特徴がはっきりと見え、腕が完全に露出している必要があります。',
  realtxt8:
    '3. 証明書と手書きのコピー (名前 + 申請日) を同時に保持する必要があります。このサイトでの私のすべての行為は私自身によって行われ、関連するリスクを承知しており、すべての法的結果を負担する用 意があります。このアカウントの。 ',
  realbtn: 'クリックしてアップロード',
  realstate: '本名ではありません',
  realstate2: 'すでに実名',
  uploadimg: '身分証明書の正面の写真をアップロードしてください',
  uploadimg2: '身分証明書の裏面の写真をアップロードしてください',
  uploadimg3: '身分証明書の写真をアップロードしてください',
  uploaderr: 'アップロードする画像のサイズは以下を超えることはできません',
  save_success: '正常に保存！',
  save_failure: '保存に失敗しました!',
  close: '閉鎖',
  hztxt: '転送の確認',
  submittip1: '資金の安全のため、まずは【実名認証】を行ってください！',
  submittip2:
    '資金の安全のため、最初に[携帯電話のバインド]を実行してください。',
  submittip3:
    '資金の安全性を確保するために、まず [ファンドのパスワード] を設定してください。',
  submittip4: '少なくとも 1 つの支払い方法をバインドしてください',
  aderr1: '正しい番号を入力してください',
  aderr2: 'プレミアム値は0～20です',
  aderr3: 'プレミアム値は 0 ～ 20 であり、0 にすることはできません',
  aderr4: '正しい固定価格を入力してください',
  aderr5:
    '正しい数字を入力してください。最大取引額は 100 コインを超えないようにしてください。',
  orderTypeNo: 'いいえ',
  orderTypeYes: 'はい',
  win: '成功',
  lose: '失敗',
  tied: '描く',
  cancelsuccess: 'キャンセルが成功しました！',
  shelves: '広告は削除された後にのみ削除できます。',
  shelves2: '編集は広告を削除した後にのみ行うことができます。',
  business: 'まずは加盟店認定を申請してください！',
  swappleaseselectwallet: '最初に振込/振込口座を選択してください',
  swappleaseinputamount: '送金額を入力してください',
  clickchange: 'クリックしてスワイプ方向を切り替えます',
  daterange: '検索期間を選択してください',
  apierr: '備考情報を入力してください',
  apierr2: 'IPアドレスを入力してください',
  apierr3: '正しいIPアドレスを入力してください',
  apierr4: '確認コードを入力してください',
  clickget: 'クリックして入手',
  apisecond: '2番',
  apitips: 'キーは追加された場合にのみ表示されます。時間内に保存してください。',
  apiaccess: '鍵',
  do_favorite: '集めました',
  cancel_favorite: 'お気に入りをキャンセルする',
  fail_favorite: '失敗',
  deptotal: '総計',
  chart: 'チャート',
  book: '注文書',
  trade: '最新の取引',
  pt_title: 'デジタル通貨ギフトカード',
  pt_desc:
    '引き換え後は自動的に下位レベルのフレンドになり、友人、親戚、顧客、パートナーにギフトを送ることができます。',
  pt_more: 'その他のツールについては、続報をお待ちください',
  pt_tips:
    '良いアイデアをお持ちの場合は、promotion@al-cex.com までメールを送信してください。採用されれば報酬が与えられます。',
  pt_card_amount: 'カード額面金額',
  pt_card_deadline: '有効期間',
  pt_card_noend: '無限に',
  pt_card_btn:
    'プロモーションパートナーはチケット30枚を無料で受け取る（約2,000元）',
  pt_card_btn_login: 'ログインしてチケット30枚を無料でゲット',
  pt_card_rule: 'ルールの詳細',
  pt_card_summary:
    'デジタル通貨ギフトカードは、プロモーションパートナーがオフラインの友人をより効果的に招待できるように開発されたプロモーションツールの1つであり、プロモーション対象ユーザーがギフトカ ードを引き換えると、自動的にプロモーターのオフライン友人になります。',
  pt_card_rule1:
    '1. 各ユーザーの無料ギフトカードは 30 枚までに制限されています。',
  pt_card_rule2:
    '2. 各ユーザーは、無料ギフトカードを 1 枚に制限されています。 ',
  pt_card_rule3:
    '3. ユーザーがギフト カードを引き換える前に、「招待者」がいない場合 (つまり、アカウントの登録時に招待コードが入力されていない場合)、ユーザーは自動的にカード発行者の第 1 レベルの友人として関連付けられます。 ',
  pt_card_rule4:
    '4. 不正取引を防止するため、ギフトカードの口座金額はリアルタイムで引き換えられず、受け取り後運用前に実名認証を完了する必要があります。',
  pt_card_rule5:
    '5. 無料ギフトカードを受け取る際には実名認証が必要ですが、引き換えの際には実名認証は必要ありませんが、これはプロモーターが低レベルの友達をより早く獲得しやすくするためです。',
  pt_card_rule6: '6. 無料ギフトカードの最終解釈権はAlcexに帰属します。',
  pt_card_day: '空',
  pt_card_title_tips: '2,000元のプロモーション補助金を受け取る',
  pt_invite_desc:
    '専用のQRコード、QRコード登録で自動的に部下の友達になることができ、各種グループやフォーラムへの転送に適しています。',
  saveimage: '画像を保存する',
  imagetips:
    '画像が正常にダウンロードできない場合は、スクリーンショットツールを使ってスクリーンショットを撮って保存してください！',
  pt_card_receivew_success: 'おめでとう！',
  usepromotion: '販促資料を使用する',
  context_title: 'プロモーションパートナー限定の引き換えコード',
  context_title1: 'スキャンして詳細を確認する',
  context_title2: '私の招待コード',
  context_title3: '招待状の写真',
  pt_card_title: 'パートナープロモーションカード',
  copysuccess: 'コピー成功！',
  copyerr: 'コピーに失敗しました!',
  noopening: '現在保留中のオプション契約はありません',
  createSuccess: '正常に作成されました',
  tradewarning1: '小数点以下2桁まで入力してください',
  tradewarning2: '注文金額は、',
  tradewarning3: '小数点以下8桁まで入力してください',
  tradewarning4: '注文数量は、',
  tradeconfirmbuyin: '購入を確認する',
  tradeconfirmsellout: '販売を確認する',
  tradeinfo: {
    emaildone: '電子メール認証済み',
    emailundo: 'メールが認証されていません',
    teldone: '携帯電話番号が認証されました',
    telundo: '携帯電話番号が認証されていません',
    idcarddone: 'IDカードが認証されました',
    idcardundo: 'IDカードが認証されていません',
    exchangetimes: 'トランザクション数',
    price: '価格',
    num: '量',
    paymethod: '支払方法',
    exchangelimitamount: '取引限度額',
    location: '位置',
    location_text: '中国',
    exchangeperiod: '取引期間',
    minute: '分',
    amounttip: '金額を入力してください',
    numtip: '数量を入力してください',
    remarktip: '彼にあなたのリクエストを伝えてください',
    remarktitle: '備考',
    exchangetitle: '取引指示',
    exchange_tip1:
      'トランザクション要求を開始すると、デジタル通貨はエスクローにロックされ、プラットフォームによって保護されます。',
    exchange_tip2:
      'お取引の前に「プラットフォームネットワークサービス規約」やFAQ、取引ガイドなどのヘルプドキュメントを必ずお読みください。',
    exchange_tip3: '詐欺師に注意してください！',
    exchange_tip4:
      '四捨五入や価格変動は、最終的に取引されるデジタル通貨の金額に影響を与える可能性があることに注意してください。',
    exchange_tip5:
      'エスクロー サービスは、オンライン取引の買い手と売り手を保護します。',
    warning1: '小数点以下2桁まで入力してください',
    warning2: '注文金額は、',
    warning3: '小数点以下8桁まで入力してください',
    warning4: '注文数量は、',
    confirmbuyin: '購入を確認する',
    confirmsellout: '販売を確認する',
    buyin: '買う',
    sellout: '売る',
    warning5: '必要に応じて注文を入力してください',
  },
  convertResult:'変換結果のプレビュー',
  willGet:'あなたは得ます',
  pleaseCommit: 'この時間内にクリックして確認してください。 ',
  returnText:'return',
  convert:'変換'
};
