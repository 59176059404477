export default {
    testText1: '這是一個測試站！',
    testText2: '@Alcexcom',
    testText3: '@bzengineer（唯一管道，謹防被騙）',
    meun1: '首頁',
    meun2: '一鍵買幣',
    meun3: '交易',
    meun3_1: '現貨交易',
    meun3_2: '快速兌換',
    meun4: '合約交易',
    meun4_1: 'U本位合約',
    meun4_2: '幣本位合約',
    meun4_3: '選擇權合約',
    meun4_4: '秒合約',
    meun5: '金融業務',
    meun5_1: '創新實驗室',
    meun5_2: '推廣合夥人',
    meun6: '理財',
    meun7: '公告',
    meun8: '登入',
    meun9: '註冊',
    meun10: '錢包',
    meun10_1: '現貨帳戶',
    meun10_2: 'U本位合約帳戶',
    meun10_3: '幣本位合約帳戶',
    meun10_4: '秒本位合約',
    meun10_5: '理財帳戶',
    meun10_6: '錢包歷史記錄',
    meun11: '訂單',
    meun11_1: '現貨訂單',
    meun11_2: 'C2C訂單',
    meun11_3: '理財訂單',
    meun11_4: '閃兌歷史',
    meun11_5: '創新管理',
    meun12: '下載',
    meun13_1: '帳戶管理',
    meun13_2: '收款方式',
    meun13_3: '我的推廣',
    meun13_4: 'API管理',
    meun13_5: '登出登入',
    meun14_1: '語言',
    meun14_2: '匯率',
    meun14_3: '選擇語言',
    meun14_4: '選擇匯率',
    openTrade: '隨時隨地，開啟交易。',
    scan: 'IOS & Andraid 掃碼下載',
    footmeun1: '關於',
    footmeun1_1: '關於我們',
    footmeun1_2: '加入我們',
    footmeun1_3: '平台公告',
    footmeun1_4: '白皮書',
    footmeun2: '幫助中心',
    footmeun2_1: '新手指南',
    footmeun2_2: '常見問題',
    footmeun2_3: '交易指南',
    footmeun2_4: '幣種資料',
    footmeun3: '條款協議',
    footmeun3_1: '免責條款',
    footmeun3_2: '隱私權條款',
    footmeun3_3: '使用者協定',
    footmeun3_4: '資費說明',
    footmeun4: '聯絡我們',
    footmeun4_1: '客服信箱',
    footmeun4_2: '商務合作',
    footmeun4_3: '上幣申請',
    footmeun4_4: '投訴舉報',
    foottext: '全球優質數位資產交易所',
    indexTxt1: '全球前50的數位貨幣交易所',
    indexTxt2: '國家機構擔保資產安全',
    indexTxt3: '新手入門',
    indexTxt4: '極速買幣',
    indexTxt5: 'Alcex官方新手入門輔助頻道',
    indexLabel1: '幣幣交易',
    indexVal1: '用一種數位資產直接換取',
    indexLabel2: '選擇權交易',
    indexVal2: '數位資產衍生性商品',
    indexLabel3: '合約交易',
    indexVal3: '幣本位，U本位合約',
    indexLabel4: '快速兌換',
    indexVal4: '幣幣閃兌，一鍵完成交易',
    indexth: '價格趨勢',
    indexbtn: '立即交易',
    indexh1: '誠實',
    indexh2: '公平',
    indexh3: '熱情',
    indexh4: '開放',
    indexh5: '金融級安全',
    indexh6: '極速充提',
    indexh7: '全球服務',
    indexh8: '嚴選項目',
    indexp1: 'Alcex 由一群比特幣早期參與者與極客創立，團隊核心成員來自於Google、微軟、阿里巴巴、騰訊等知名企業，具有深厚的研發實力與豐富的網路產品營運經驗。',
    indexp2: 'Alcex 定位於區塊鏈基礎服務商，致力於為全球用戶提供優質加密資產交易平台，秉承著「不作惡」的基本原則，堅持誠實、公正、熱情的服務於客戶，以開放的態度迎接一切有',
    indexp3: '全方位金融風控系統及防盜系統，冷熱錢包、多簽系統保障資金安全',
    indexp4: '儲值提現最快3分鐘完成，24H人工線上審核，保護客戶不錯過最佳投資機會',
    indexp5: '全球業務服務網路覆蓋，幫助您投資全球加密資產，與全球用戶交易',
    indexp6: '嚴格選擇優質加密項目，為您過濾80%極高風險項目',
    indexp7: '掃描二維碼，下載app',
    scandownload: '掃一掃下載APP',
    ormenu1: 'C2C訂單',
    ormenu2: '我的訂單',
    ormenu3: '賺幣',
    please: '請先',
    account: 'Alcex帳號登入',
    phone: '手機號碼',
    email: '信箱',
    sign: '登入',
    password: '密碼',
    password2: '密碼',
    password3: '忘記密碼',
    account2: '建立一個Alcex帳戶',
    logErr: '請輸入帳號',
    logErr2: '請輸入密碼',
    logErr3: '密碼長度不能少於6位',
    logSuccess: '登入成功',
    account3: '建立個人帳戶',
    phone1: '手機註冊',
    email1: '信箱註冊',
    email2: '郵件地址',
    code: '簡訊驗證碼',
    code2: '發送驗證碼',
    code3: '驗證碼',
    password4: '確認密碼',
    invite: '邀請碼(選填)',
    agree: '我已閱讀並同意',
    agreement: '使用者協定',
    register: '註冊',
    regErr: '請輸入驗證碼',
    confirmpwdtip: '請輸入確認密碼',
    confirmpwderr: '兩次密碼輸入不一致!',
    wjtab: '重設手機密碼',
    wjtab2: '重設郵件信箱密碼',
    wjlabel: '新密碼',
    wjerr: '請輸入新密碼',
    labTxt: 'Alcex實驗室 ● 分享創新成果',
    labTxt2: '投資者、專案方、交易所等多方共贏的開放實驗室',
    labTab1: '全部',
    labTab2: '即將開始',
    labTab3: '進行中',
    labTab4: '派發中',
    labTab5: '已完成',
    tokendistribute: 'Token派發中',
    activitytype: '認購類型',
    labStart: '開始時間',
    labEnd: '結束時間',
    labStartPlaceholder: '請選擇開始時間',
    labDetail: '看詳情',
    labSpeen: '進度',
    labNumber: '活動總量',
    labType1: '首次上線(搶購)',
    labType2: '首次上線(平分)',
    labType3: '持倉瓜分',
    labType4: '自由認購',
    labType5: '雲端礦機認購',
    labType6: '鎖倉釋放',
    baseinfo: '基本訊息',
    ruledetail: '類型說明',
    releaseType: '釋放類型',
    releaseType0: '等額釋放',
    releaseType1: '等比釋放',
    lock: '期釋放',
    releaseDetail: '釋放詳情',
    lockday: '日',
    lockweek: '週',
    lockmonth: '月',
    lockyear: '年',
    releasePercent: '釋放比例',
    lockFee: '鎖倉門檻',
    releaseTimes: '釋放倍數',
    times: '倍',
    publishprice: '認購價格',
    holdcoin: '持倉要求幣種',
    limittimes: '個人限購次數',
    limitamount: '個人限購數量',
    unlimited: '不限',
    leveloneCount: '需要一級好友數',
    alreadyamount: '已購數量',
    leftamount: '剩餘數量',
    myalreadyholdamount: '我的參與持倉',
    alreadyholdamount: '活動參與總持倉',
    currentdivided: '目前我的持倉可瓜分約',
    holdtips: '參與【持倉瓜分】類型活動需鎖倉至活動結束',
    inputholdamount: '輸入鎖倉數量',
    pleaseinputamount: '請輸入兌換數量',
    pleaseinputholdamount: '請輸入鎖倉數量',
    inputamount: '輸入兌換數量',
    inputminingamount: '輸入購買礦機數',
    attend: '立即參與',
    attention: '注意',
    attentiontxt1: '1.提交參與【持倉瓜分】、【自由認購】、【雲礦機認購】、【鎖倉】類型活動後將凍結資產，活動結束後解凍或解鎖釋放',
    attentiontxt2: '2.參與【持倉瓜分】、【自由認購】、【雲端礦機認購】、【鎖倉】類型活動後，用戶不得撤銷參與申請',
    attentiontxt3: '3、如因項目方及其他不可抗力因素取消活動，將原路退回凍結資產',
    attentiontxt4: '* 本活動最終解釋權歸Alcex(www.al-cex.com)官方所有',
    transfer: '資金劃轉',
    placeholder: '搜尋幣種',
    bith1: '合約交易',
    bith2: '帳戶權益',
    bith3: '未實現盈虧',
    bith4: '可用保證金',
    bith5: '已用保證金',
    bith6: '凍結保證金',
    bith7: '合約帳戶',
    btn1: '儲值',
    btn2: '提現',
    btn3: '劃轉',
    btn4: '充幣',
    btn5: '提幣',
    totalAssets: '總資產折合',
    th1: '幣種名稱',
    th2: '可用資產',
    th3: '凍結資產',
    th4: '待釋放資產',
    th5: '操作',
    czTxt: '數位貨幣儲值',
    czTxt2: '幣種',
    czTxt3: '轉帳網絡',
    czTxt4: '可用餘額',
    czTxt5: '提幣地址',
    czTxt6: '提幣數量',
    czTxt7: '到帳數量',
    czTxt8: '手續費',
    czTxt9: '溫馨提示',
    czTxt10: '最小儲值金額：',
    czTxt10_1: '小於最小金額的儲值將不會上帳。',
    czTxt11: '請勿向上述地址充值任何非幣種資產，否則資產將不可找回。',
    czTxt12: '您儲值至上述位址後，需整個區塊鏈網路節點的確認，一般區塊鏈主網3次網路確認後才到帳。',
    czTxt13: '您的儲值地址不會經常改變，可以重複充值；如有更改，我們會盡量透過網站公告或郵件通知您。',
    czTxt14: '請務必確認電腦及瀏覽器安全，防止資訊被竄改或外洩。',
    czTxt15: '儲值記錄',
    czTxt16: '為保障資金安全，當您帳戶安全策略變更、密碼修改、使用新地址提幣，我們會對提幣進行人工審核，請耐心等待工作人員電話或郵件聯絡。',
    czTxt17: '最小提幣數量為：',
    czth1: '提現時間',
    czth2: '從',
    czth3: '至',
    czth4: '閃兌數量',
    czth5: '兌換比例',
    czth6: '兌換數量',
    placeholder2: '請輸入',
    placeholder3: '請選擇',
    czEmpty: '無記錄',
    logintip: '請先登入',
    cztip: '您未申請過儲值地址，請點選按鈕取得儲值地址',
    czget: '取得儲值地址',
    czplaceholder: '請選擇協議',
    mAccount: '秒合約帳戶',
    btn6: '搜尋',
    wtTxt: '起止時間',
    wtTxt2: '操作類型',
    wtth1: '交易時間',
    wtth2: '類型',
    wtth3: '幣種',
    wtth4: '數量',
    wtth5: '應付手續費',
    wtth6: '抵扣手續費',
    wtth7: '實付手續費',
    wtth8: '狀態',
    nav1: '帳戶安全',
    nav2: '我的邀請',
    nav3: '我的推廣卡',
    acTxt: '安全等級',
    acbtn1: '未認證',
    acbtn2: '綁定',
    acbtn3: '修改',
    acbtn4: '已認證',
    acbtn5: '已綁定',
    acbtn6: '設定',
    acbtn7: '審核中',
    acbtn8: '重試',
    acTxt2: '實名認證',
    acTxt3: '信箱',
    acTxt4: '智慧型手機',
    acTxt5: '登入密碼',
    acTxt6: '資金密碼',
    acTxt7: '谷歌驗證器',
    acTxt8: '設定資金密碼',
    acTxt9: '更改資金密碼',
    acTxt10: '找回資金密碼',
    acDesc2: '為保障您的帳戶安全，請完成實名認證後方可交易操作！',
    acDesc3: '綁定信箱',
    acDesc4: '綁定手機',
    acDesc5: '登入平台時使用',
    acDesc6: '帳戶資金變動時，需要驗證資金密碼',
    acDesc7: '請綁定Google驗證器',
    acDesc8: '已綁定Google驗證器',
    acDesc9: '解除綁定',
    acTit: '手機綁定',
    acTit2: '信箱綁定',
    acTit3: 'Google綁定',
    acTit4: '谷歌解除綁定',
    mailtip: '請輸入信​​箱',
    emailerr2: '郵箱格式不正確,請重新輸入',
    agreementtip: '請閱讀並同意用戶協議',
    acverify: '審核未通過',
    again: '請重試',
    verifiedmsg: '請先進行實名認證',
    apiTxt: '綁定實名帳號',
    apiTxt2: '提示',
    apiTxt3: '我們為您提供了強大的API,您可以透過API使用行情查詢、自動交易等服務。',
    apiTxt4: '每個用戶最多創建5組API Key',
    apiTxt5: '請不要洩漏您的API Key，以免造成資產損失。',
    apiTxt6: 'API備註',
    apiTxt7: '授權IP(授權IP位址的金鑰有效期限為90天)',
    apibtn: '創建',
    apith1: 'API備註',
    apith2: '創建時間',
    apith3: '存取密鑰',
    apith4: '綁定IP位址',
    apith5: '剩餘有效期限（天）',
    chTxt: '更改登入密碼',
    chTxt2: '掃碼綁定',
    chTxt3: '請輸入驗證碼',
    chTxt4: '原資金密碼',
    chTxt5: '新資金密碼',
    chTxt6: '確認新密碼',
    chTxt7: '忘記密碼',
    chTxt8: '更改手機驗證',
    chTxt9: '原登入密碼',
    chTxt10: '新登入密碼',
    chTxt11: '信箱驗證碼',
    chTxt12: '手機驗證碼',
    telerr: '手機號碼不正確',
    emailerr: '信箱不正確',
    codeerr: '驗證碼不正確',
    reset: '重置',
    save: '儲存',
    chtip: '請輸入原密碼',
    chtip2: '請輸入不小於6位新登入密碼',
    chtip3: '新登入密碼不一致',
    chtip4: '請輸入正確的資金密碼',
    chtip5: '密碼不得少於6個字符',
    chtip6: '請輸入不小於6位密碼',
    chtip7: '密碼不一致',
    chtip8: '請輸入資金密碼',
    chtip9: '請輸入手機號',
    chtip10: '請輸入登入密碼',
    realnametip: '請輸入真實姓名',
    idcardtip: '請輸入身分證號碼',
    clTxt: '請設定您的收款方式，請務必使用本人的實名帳號',
    clTxt2: '新增收款方式',
    edit: '編輯',
    delete: '刪除',
    name: '姓名',
    clTxt3: 'ID號碼',
    clTxt4: 'IBAN號碼',
    clTxt5: '全部收款方式',
    clLink1: '帳戶名稱',
    clLink2: '編輯收款方式',
    clLink3: '付款詳情(選填)',
    clplaceholder1: '請輸入您的完整姓名',
    clplaceholder2: '請輸入您的銀行帳戶密碼',
    clplaceholder3: '請輸入您的付款詳情',
    cltip: '特別提示',
    cltip2: '請確保添加您的銀行卡號以進行即時付款。',
    cltip3: '小提醒：當您出售數位貨幣時，您選擇的收款方式將向買方展示。',
    clTxt6: '選填',
    upload: '上傳',
    uploadTip: '(支援JPG/JPEG/PNG/BMP格式, 小於1MB)',
    tgbtn: '兌換碼兌換',
    tgth1: '卡名',
    tgth2: '兌換碼',
    tgth3: '卡面幣種',
    tgth4: '卡面金額',
    tgth5: '總數',
    tgth6: '已兌',
    tgth7: '領取時間',
    tgbtn2: '推廣素材',
    tgbtn3: '立即兌換',
    tgplaceholder: '請輸入兌換碼',
    exchangesuccess: '兌換成功！',
    inth1: '邀請用戶',
    inth2: '註冊時間',
    inth3: '實名狀態',
    adTxt: '我的廣告',
    adTxt2: '當廣告最小交易金額所購買數量加上手續費大於廣告剩餘數量，該廣告自動下架',
    adbtn: '清空條件',
    adbtn2: '上架',
    adbtn3: '下架',
    adth1: '廣告編號',
    adth2: '廣告類型',
    adth3: '訂單限制',
    adth4: '剩餘數量',
    adtip: '確定刪除嗎',
    num_text1: '請輸入您要',
    num_text2: '的數量',
    wTxt: '現貨',
    wTxt2: '當前委託',
    wTxt3: '交易對',
    wTxt4: '交易方向',
    wth1: '時間',
    wth2: '方向',
    wth3: '價格',
    wth4: '已成交',
    wth5: '成交金額',
    wth6: '委託類型',
    wth7: '觸發價',
    wth8: '成交價',
    wth9: '委託數量',
    wth10: '結算盈虧',
    wth11: '委託狀態',
    wth12: '開倉/平倉',
    wth13: '保證金',
    wbtn: '撤銷單',
    wtip: '撤銷單提示',
    wtiptxt: '是否確認撤銷單',
    zbplaceholder: '輸入訂單編號開始搜尋',
    zbtab1: '定投計劃',
    zbtab2: '定投記錄',
    zbth1: '投資週期',
    zbth2: '開/關',
    zbth3: '累計投資金額',
    zbth4: '持有數量',
    zbth5: '平均成本',
    zbth6: '未實現盈虧',
    zbth7: '下次定投日期',
    zbth8: '計劃創建日期',
    etTxt: '公告中心',
    etTxt2: '掃一掃分享',
    etmenu1: '加密數位貨幣',
    etmenu2: 'Alcex伺服器更新公告',
    et2menu1: 'RX.波場簡介',
    et2menu2: '比特幣是龐氏騙局嗎?',
    hisTxt: '歷史委託',
    orTab1: '未付款',
    orTab2: '已付款',
    orTab3: '已完成',
    orTab4: '已取消',
    orTab5: '申訴中',
    orth1: '訂單編號',
    orth2: '交易幣種',
    orth3: '交易類型',
    orth4: '交易對象',
    orth5: '金額',
    qcTab1: '往期結果',
    qcTab2: '即時行情',
    qcTxt: '第',
    qcTxt2: '期',
    qcTxt3: '開盤時間',
    qcTxt4: '收盤時間',
    qcTxt5: '開盤價格',
    qcTxt6: '收盤價格',
    qcTxt7: '買漲總額',
    qcTxt8: '買跌總額',
    qcTxt9: '我的開倉',
    qcTxt10: '預測進度',
    qcTxt11: '開盤價',
    qcTxt12: '收盤價',
    qcTxt13: '本期預測週期',
    qcbtn1: '看漲',
    qcbtn2: '看跌',
    qcth1: '合約期數',
    qcth2: '開倉金額',
    qcth3: '預測方向',
    qcth4: '預測結果',
    qcth5: '獎金數量',
    qcth6: '開倉手續費',
    qcth7: '抽水',
    mplaceholder: '輸入幣種名稱搜尋',
    mTab: '自選',
    mth: '收藏',
    mth1: '最新價',
    mth2: '24h漲跌',
    mTxt: '24h最高價',
    mTxt2: '24h最低價',
    mTxt3: '交易數量',
    mTxt4: '資金費率',
    mTxt5: '開',
    mTxt6: '高',
    mTxt7: '低',
    mTxt8: '收',
    echat1: 'K線圖',
    echat2: '深度圖',
    mTab2: '目前持倉',
    mTab3: '委託歷史',
    mth3: '包賠',
    mth4: '開倉價格',
    mth5: '週期',
    mth6: '平倉價格',
    mth7: '獲利',
    mTxt9: '交易模式',
    mTxt10: '開倉時間',
    mTxt11: '獲利率',
    mTxt12: '餘額',
    mTxt13: '買入量',
    mTxt14: '中',
    cadTxt: '建立一個廣告交易',
    cadTxt2: '如果您經常進行交易，您可以建立自己的交易廣告。',
    cadTxt2_1: '如果您只是偶爾交易，我們建議您直接搜索',
    cadTxt2_2: '創建一則交易廣告是免費的。',
    cadLink2: '交易廣告',
    cadTxt3: '若您想直接編輯已建立的廣告，請查看',
    cadLink3: '我的廣告',
    cadTxt4: '市場參與價格',
    cadTxt5: '溢價是指高於當前市場價格多少百分比進行賣出',
    cadTxt6: '計價公式',
    cadTxt7: '【提示】可前往個人中心綁定/增加支付方式',
    cadTxt8: '最大交易額不能超過您的賣出總金額0CNYI',
    cadTxt9: '啟用後，用戶透過此廣告向您發起交易，系統自動將您選擇的自動回覆用語傳送給對方。',
    cadTxt10: '溢價是指高於目前市價多少百分比進行買入',
    cadLabel1: '我想要：',
    cadLabel2: '幣種交易：',
    cadLabel3: '國家：',
    cadLabel4: '貨幣：',
    cadLabel5: '開啟固定價格',
    cadLabel6: '溢價：',
    cadLabel7: '交易價格',
    cadLabel8: '賣出數量：',
    cadLabel9: '付款方式：',
    cadLabel10: '最小交易額：',
    cadLabel11: '最大交易額：',
    cadLabel12: '開啟自動回覆',
    cadLabel13: '資金密碼：',
    cadLabel14: '備註資訊：',
    cadLabel15: '固定價格：',
    cadLabel16: '自動回覆：',
    cadLabel17: '買入數量：',
    radio: '線上販售',
    radio2: '線上購買',
    cadplaceholder: '請設定您的溢價',
    cadplaceholder2: '請輸入您要賣出數量',
    cadplaceholder3: '請輸入您想要最小交易額',
    cadplaceholder4: '請輸入您要最大交易額',
    cadplaceholder5: '可以內備註資訊填寫您的特殊要求，例如：對買方的要求，線上時間等',
    cadplaceholder6: '請輸入您的交易價格',
    cadplaceholder7: '接收訂單後，自動回覆給買家的訊息，例如：收款方式、收款帳號等。',
    cadplaceholder8: '請輸入您要買入數量',
    caderr1: '請輸入您的最小交易額',
    caderr2: '最小交易額必須大於等於100！',
    caderr3: '最小交易額必須小於最大交易額',
    caderr4: '請輸入您的最大交易額！',
    caderr5: '請輸入整數',
    caderr6: '最大交易額必須大於最小交易額！',
    caderr7: '最大交易額不能超過您的賣出總金額',
    caderr8: '請選擇交易方式',
    submit: '提交',
    cadnew: '啟用後，您的幣價不會隨市場波動，價格不變。',
    dhTxt: '一鍵兌換·坐享收益',
    dhTxt2: '限價',
    dhTxt3: '賣出',
    dhTxt4: '買入',
    dhTxt5: '最大',
    dhTxt6: '輸入金額',
    dhplaceholder: '現貨錢包可用餘額',
    dhSuccess: '恭喜你！',
    crTxt: '法幣交易',
    crTxt2: '便利、安全、快速買賣數位貨幣',
    crTxt3: '動態調整',
    crTxt4: '免手續費',
    crTxt5: '即時成交',
    crTxt6: '平台擔保',
    crTxt7: '根據市場價格即時波動',
    crTxt8: '用戶所見即所得，買賣價格外無須任何平台手續費',
    crTxt9: '引進平台服務商家，智能匹配，成交訂單，無須等待撮合',
    crTxt10: '平台認證商家，安全有保障，24小時客服為交易保駕護航',
    crth1: '商家',
    crth2: '交易筆數',
    crth3: '付款方式',
    crth4: '限額',
    crth5: '單價',
    crbtn: '購買',
    crbtn2: '成為商家',
    cr1Txt: '恭喜！',
    cr1Txt2: '準備資料',
    cr1Txt3: '提交審核',
    cr1Txt4: '已認證',
    cr1Txt5: '您獲得以下權限',
    cr1Txt6: '專屬展位',
    cr1Txt7: '一對一服務',
    cr1Txt8: '商家享有專屬廣告展位，增加交易成功率',
    cr1Txt9: '更低手續費',
    cr1btn: '發布廣告',
    cr1btn2: '申請退保',
    reTxt: 'Alcex理財●一站式投資解決方案',
    reTxt2: '立即賺取收益、簡單&安全',
    reTab: '定期理財',
    reTab2: '定投理財',
    reTxt3: '定期',
    reTxt4: '靈活定制，潛在收益更高',
    reTxt6: '訂投',
    reTxt7: '開始使用定投並增加您的資產',
    reTxt8: '展開所有18個定期產品',
    reth1: '年化報酬率',
    reth2: '期限(天)',
    reth3: '交易限額',
    reth4: '產品',
    reth5: '歷史投資報酬率',
    rebtn: '創建計劃',
    redTxt: '創建一個',
    redTxt2: '定投計劃',
    redTxt3: '輸入投資數量',
    redTxt4: '我的可用餘額為',
    redTxt5: '可用',
    redTxt6: '循環週期',
    redTxt7: '每天',
    redTxt8: '每週',
    redTxt9: '每月',
    redTxt10: '本地時間',
    redTxt11: '您首次自動投資週期將於',
    redTxt12: '開始',
    confirm: '確認',
    confirm2: '確定',
    cancel: '取消',
    redongoing: '進行中',
    redcompleted: '已完成',
    minNumErr: '小於最低起投數量',
    minNumErr2: '起投數量不能為0',
    buyamounttip: '請輸入買入量',
    pricetipwarning: '交易額不能高於',
    buyamounttipwarning: '買入數量不能高於',
    sellamounttip: '請輸入賣出量',
    sellamounttipwarning: '賣出數量不能高於',
    sellpricetip: '請輸入賣出價格',
    sellpricetipwarning: '賣出價格不能高於',
    sellmore: '最多能賣',
    sellunit: '個',
    loginFirst: '請先登入',
    betsuccess: '恭喜你！',
    selectAmount: '請選擇投注數量',
    balancenotenough: '可用餘額不足!',
    balancenotenough2: '餘額不足！',
    minlimitamountfailed: '兌換數量不能小於最低起兌量！',
    maxlimitamountfailed: '兌換數量不能大於最高限購！',
    limittimesfailed: '您參與的次數超過個人最高限購次數！',
    commitfailed: '提交參與失敗！',
    trplaceholder: '查詢',
    trplaceholder2: '最新價格',
    trplaceholder3: '以市面上最優價格買入',
    trplaceholder4: '以市面上最優價格賣出',
    triggleplaceholder: '預設委託價為市價',
    triggerpriceshouldbigger: '觸發價必須大於0',
    marginModeSuccessTip: '合約帳戶模式修改成功',
    noenoughbalance: '帳戶保證金不足',
    pleaseinputopenvolume: '開倉數量必須大於0',
    pleaseinputcorrectopenvolume: '請輸入正確的開倉數量',
    pleaseinputrightentrustprice: '請輸入正確的委託價格',
    noenoughposition: '可平倉位不足',
    pleaseinputclosevolume: '平倉數量必須大於0',
    pleaseinputcorrectclosevolume: '請輸入正確的平倉數量',
    option1: '天線',
    option2: '週線',
    option3: '月線',
    trTxt: '交易額',
    trTxt2: '市價',
    trTxt3: '賣出量',
    trSuccess: '提交成功',
    udTxt: '保證金模式',
    udTxt2: '調整合約倍數',
    udTxt3: '槓桿',
    udTxt4: '永續',
    udTxt4_1: '永續',
    udTxt5: '可轉額度',
    udTxt6: '劃轉數量',
    udTxt7: '全部劃轉',
    udTxt8: '永續帳戶',
    ubtn: '全倉',
    ubtn2: '逐倉',
    uplaceholder: '永續合約帳戶',
    uradio1: '分時',
    uradio2: '15分鐘',
    uradio3: '1小時',
    uradio4: '4小時',
    uradio5: '1天',
    uth1: '合約倍數',
    uth2: '效益',
    uth3: '殖利率',
    uth4: '持倉量',
    uth5: '可平量',
    uth6: '開倉均價',
    uth7: '強勢平價',
    uth8: '目前保證金',
    uth9: '保證金費率',
    zhang: '張',
    or: '或',
    ureg: '立即註冊',
    uTxt8: '進行交易',
    uTab: '開倉',
    uTab2: '平倉',
    uTxt9: '可開多',
    uTxt10: '可開空',
    uTxt11: '委託價',
    uplaceholder2: '以市場最優價成交',
    uTxt12: '買進開多',
    uTxt13: '賣出開空',
    closeup: '買入平空',
    closedown: '賣平多',
    uTab3: '停損',
    uTxt14: '我的合約帳戶',
    ulabel1: '帳戶模式',
    ulabel2: '帳戶總收益',
    ulabel3: '部位保證金',
    ulabel4: '資金利用率',
    ulabel5: '多',
    ulabel6: '空',
    uTxt15: '多頭',
    uTxt16: '空頭',
    total: '合計',
    limited_price: '限價委託',
    market_price: '市價委託',
    spot_price: '停損',
    ent_status1: '委託中',
    ent_status2: '已撤銷',
    ent_status3: '委託失敗',
    ent_status4: '委託成功',
    ent_statusblast: '爆倉',
    uTxt17: '多倉可平',
    uTxt18: '空倉可平',
    prev: '上一篇',
    next: '下一篇',
    prtxt: '我的邀請連結',
    copy: '複製',
    prtxt2: '我的一級好友',
    prtxt3: '我的二級好友',
    prtxt4: '佣金收入(折合USDT)',
    prtxt5: '額外獎勵',
    prtxt6: '合夥級別',
    prtit: '規則詳情',
    prdesc: 'Alcex的【推廣合夥人】是全網返傭比例最高、返傭時長最長(最高終身返傭)的推廣活動，讓付出相應努力的推廣者能夠成為真正意義上的平台“合夥人”，享受',
    prli1: '一、為佈道數位資產理念與擴大用戶規模，Alcex推出【推廣合夥人】計劃，該活動長期有效。',
    prli2: '二、邀請好友分為兩級，如A邀請B，則B是A的一級好友，若B又邀請了C，則C屬於B的一級好友，同時也屬於A的二級好友。',
    prli3: '三、推廣好友註冊時，需透過邀請者提供的連結或手動輸入邀請者邀請碼方可成為邀請者的一級好友。',
    prli4: '四、被邀請者透過邀請者邀請碼註冊，並且完成實名認證後，返傭激勵方可生效。',
    prli5: '五、返傭時間為從被邀請者完成實名認證開始的N個月，依等級不同，享有的返傭比例不同。',
    prli6: '六、推廣返傭資產為下級好友幣幣交易手續費，返傭幣為三大市場基礎幣，即：USDT、BTC、ETH。',
    prli7: '七、推廣返傭比例、返傭時長與推廣一級好友人數呈正相關，推廣人數越多，可享返傭比例越高。',
    prli8: '八、推廣返傭排行榜TOP10或TOP100不定期會獲得Alcex官方發放的額外獎勵。',
    prli9: '九、如其他活動與【推廣合夥人】活動衝突，則經協商後決定是否臨時變更，望諸合夥人諒解。',
    prli10: '十、L4(巡撫)、L5(太守)、L6(節度使)級合夥人分別享有每年返傭總額的5%、10%、15%的分紅獎勵。',
    prli11: '十一、本活動最終解釋權歸Alcex所有。',
    prth1: '等級',
    prth2: '一級好友數',
    prth3: '一級好友返傭比例 / 返傭時間',
    prth4: '二級好友返傭比例 / 返傭時間',
    prth5: '合夥人分紅',
    prlev1: '裡正',
    prlev2: '縣令',
    prlev3: '知府',
    prlev4: '巡撫',
    prlev5: '太守',
    prlev6: '節度使',
    prtd1: '個月',
    prtd2: '終身返傭',
    prtit2: '舉例說明',
    prtxt7: '若用戶小嚴邀請了100位一級好友，TA的一級好友分別邀請了5位二級好友，此時小嚴就擁有100位一級好友，500位二級好友。',
    prtxt8: '若用戶小嚴邀請了1000個一級好友，TA的一級好友分別邀請了5位二級好友，此時小嚴就擁有1000名一級好友，5000名二級好友。',
    prtit3: '推廣工具箱',
    prtit4: '推廣返傭總金額TOP20(總榜)',
    prtit5: '推廣人數TOP20(總榜)',
    prtxt9: '統計截止時間:',
    prth6: '排名',
    prth7: '會員',
    prth8: '推廣人數',
    prth9: '返傭(折合USDT)',
    prth10: '額外獎勵',
    prtip1: '以上佣金資料非即時更新，系統每24H統計並更新一次。',
    prtip2: '邀請人數為一級好友數，此榜為返傭總金額排名',
    prtip3: '邀請人數為一級好友數，此榜為一級邀請人數排名',
    recth1: '到帳時間',
    recth2: '網路協定',
    recth3: '充幣地址',
    recth4: '儲值數量',
    recbtn: '提幣碼儲值',
    recplaceholder: '請輸入提幣碼',
    recstatus_0: '失敗',
    recstatus_1: '未到帳',
    recstatus_2: '已到帳',
    wittit: '數位貨幣提現',
    wittext: '提現記錄',
    wittext2: '提幣碼',
    wittext3: '提幣碼列表',
    witbtn: '提幣碼提幣',
    witbtn2: '生成提幣碼',
    witdia: '安全驗證',
    witdia2: '提現碼生成成功',
    witplaceholder: '請輸入提幣數量',
    witerr: '請選擇幣種',
    addresstip: '請填入地址',
    witstate: '待兌換',
    witstate2: '失敗',
    witstate3: '兌換完成',
    witstatus_1: '審核中',
    witstatus_2: '轉帳中',
    witstatus_3: '失敗',
    witstatus_4: '成功',
    type1: '轉帳',
    type2: '幣幣交易',
    type3: '法幣買入',
    type4: '法幣賣出',
    type5: '活動獎勵',
    type6: '推廣獎勵',
    type7: '分紅',
    type8: '投票',
    type9: '人工儲值',
    type10: '配對',
    type11: '活動兌換',
    type12: 'C2C買入',
    type13: 'C2C賣出',
    type14: '發紅包',
    type15: '領紅包',
    type16: '提幣碼提幣',
    type17: '提幣碼充幣',
    type18: '永續合約手續費',
    type19: '永續合約獲利',
    type20: '永續合約虧損',
    type21: '選擇權合約失敗',
    type22: '選擇權合約手續費',
    type23: '選擇權合約獎金',
    type24: '合約返傭',
    type25: '平級獎勵',
    type26: '平台手續費收入',
    type27: '秒合約失敗',
    type28: '秒合約獎金',
    type29: '理財利息',
    type30: '支出資金費用',
    type31: '獲得資金費用',
    type32: '定投買入',
    type33: '定投賣出',
    type34: '購買定期',
    type35: '定期贖回',
    type36: '幣本位合約轉轉入',
    type37: '幣本位合約劃轉轉出',
    type38: 'U本位合約劃轉轉入',
    type39: 'U本位合約劃轉轉出',
    type40: '秒合約劃轉轉入',
    type41: '秒合約劃轉轉出',
    type42: '幣幣劃轉轉入',
    type43: '幣幣劃轉轉出',
    lctit: '理財帳戶',
    lcbtn: '理財轉入',
    lcth1: '累計利息',
    day: '天',
    lctxt: '活動幣種',
    lctxt2: '接受幣種',
    lctxt3: '起投數量',
    lctxt4: '選擇時間',
    year: '年',
    month: '月',
    ldtab1: '持倉記錄',
    ldtab2: '平倉記錄',
    ldth1: '開始收益日',
    ldth2: '贖回日',
    ldth3: '收益估算值',
    ldth4: '效益',
    attit: '修改定投計劃',
    attxt: '投資數量',
    attxt2: '投資週期',
    atth1: '定投日期',
    atth2: '定投數量',
    atth3: '交易費率',
    success: '成功',
    cxth1: '名稱',
    cxth2: '類型',
    cxth3: '認購數量',
    cxth4: '參與幣種',
    cxth5: '認購單位',
    cxth6: '目前狀態',
    cxth7: '成交額',
    cxth8: '創建時間',
    cxnav1: '我參與的',
    cxnav2: '我的礦機',
    cxnav3: '我的鎖倉',
    cxtit2: '我的礦機列表',
    cxtit3: '我的鎖倉列表',
    cxkth1: '產出幣種',
    cxkth2: '產出週期',
    cxkth3: '挖礦時間',
    cxkth4: '已挖時間',
    cxkth5: '基礎產能',
    cxkth6: '實際產能',
    cxstate1: '未部署',
    cxstate2: '已部署',
    cxstate3: '已撤銷',
    cxstate4: '待成交',
    cxstate5: '已結束',
    cxstate6: '待釋放',
    cxstate7: '釋放中',
    cxcth1: '釋放幣種',
    cxcth2: '總鎖倉',
    cxcth3: '總釋放週期',
    cxcth4: '已釋放週期',
    cxcth5: '基礎釋放',
    cxcth6: '實際釋放',
    more: '更多',
    abtit: '平台特色',
    abtit2: '關於我們',
    abtit3: '聯絡我們',
    abtit4: 'Alcex社區',
    abdesc: 'Alcex是科技全球領先的數位資產交易平台，註冊於開曼群島，核心營運團隊位於香港。 ',
    abdesc2: 'Alcex平台擁有專業金融等級的交易架構，擁有自主研發的高並發記憶體撮合交易引擎，平台採用全冷充值皮夾系統+多重簽章+高防護DDOS攻擊系統等交易架構，確保客戶資產安全。',
    abdesc3: 'Alcex為了讓客戶更好的把握投資機會，客服部門內部採用扁平化管理，建立了極速服務響應機制，資產客服經理常年7*24H在線，為客戶提供資產充值提現等服務，保障客戶在5分鐘內',
    abdesc4: 'Alcex嚴格篩選優質專案並提供安全穩定的資產託管服務。 ',
    abdesc5: '儘管數位資產現在只在一個小小的圈子裡，但是我們相信，這種每個人能夠完全掌握的資產在未來一定能夠流行起來，讓我們一起努力並期待著！',
    abdesc6: '客戶服務',
    abdesc7: '技術支援',
    abdesc8: '上幣申請',
    abdesc9: '投訴建議',
    abdesc10: '商務合作',
    abdesc11: '微信',
    abdesc12: '微博',
    abdesc13: '推特',
    abdesc14: '幣用',
    abdesc15: '電報',
    abdesc16: '新增 “bmate601” 為微信好友，進入微信福利社群',
    realtxt: '真實姓名',
    realtxt2: '身分證號',
    realtxt3: '身分證正面照',
    realtxt4: '身分證反面照',
    realtxt5: '手持身分證與個人簽名照',
    realtxt6: '1.支援JPG、PNG格式，文件小於8M；證件上的信息，清晰可見，不允許任何修改和遮擋，必須能看清證件號和姓名。',
    realtxt7: '2、照片需免冠，建議未化妝，手持證件人的五官清晰可鑑，完整露出手臂。',
    realtxt8: "3、需要同時手持證件和手寫文案（姓名+申請日期）'我在本站的所有行為均為本人操作，並已知悉相關風險，願意承擔本帳戶的一切法律後果。 ",
    realbtn: '點擊上傳',
    realstate: '未實名',
    realstate2: '已實名',
    uploadimg: '請上傳身分證正面照',
    uploadimg2: '請上傳身分證反面照',
    uploadimg3: '請上傳手持身分證照',
    uploaderr: '上傳圖片大小不能超過',
    save_success: '保存成功!',
    save_failure: '保存失敗!',
    close: '關閉',
    hztxt: '確認劃轉',
    submittip1: '為了您的資金安全，請先進行【實名認證】！',
    submittip2: '為了您的資金安全，請先進行【手機綁定】！',
    submittip3: '為了您的資金安全，請先設定【資金密碼】！',
    submittip4: '請至少綁定一種支付方式',
    aderr1: '請輸入正確數字',
    aderr2: '溢價值為0-20',
    aderr3: '溢價值為0-20，且不能為0',
    aderr4: '請輸入正確固定價格',
    aderr5: '請輸入正確數字，且最大交易數量不超過100幣',
    orderTypeNo: '否',
    orderTypeYes: '是',
    win: '成功',
    lose: '失敗',
    tied: '平手',
    cancelsuccess: '撤單成功！',
    shelves: '下架廣告後才可以刪除！',
    shelves2: '下架廣告後才可以編輯修改！',
    business: '請先申請商家認證！',
    swappleaseselectwallet: '請先選擇轉入/轉出帳戶',
    swappleaseinputamount: '請輸入劃轉數量',
    clickchange: '點擊切換劃轉方向',
    daterange: '請選擇搜尋日期範圍',
    apierr: '請輸入備註資訊',
    apierr2: '請輸入IP位址',
    apierr3: '請輸入正確IP位址',
    apierr4: '請輸入驗證碼',
    clickget: '點擊獲取',
    apisecond: '秒',
    apitips: '密鑰只在新增時展示，請及時儲存',
    apiaccess: '金鑰',
    do_favorite: '已收藏',
    cancel_favorite: '取消收藏',
    fail_favorite: '失敗',
    deptotal: '累計',
    chart: '圖表',
    book: '訂單簿',
    trade: '最新成交',
    pt_title: '數位貨幣禮品卡',
    pt_desc: '兌換後自動成為下級好友，送朋友、送親人、送客戶、送夥伴。',
    pt_more: '更多工具，敬請期待',
    pt_tips: '如果您有好的創意，請發送郵件至promotion@al-cex.com，採納後有獎勵哦！',
    pt_card_amount: '卡面金額',
    pt_card_deadline: '有效期間',
    pt_card_noend: '無限期',
    pt_card_btn: '推廣合夥人免費領30張(約2000元)',
    pt_card_btn_login: '登入後免費領取30張',
    pt_card_rule: '規則詳情',
    pt_card_summary: '數位貨幣禮品卡是為了讓推廣合夥人能夠更好地邀請下線好友而開發的推廣工具之一，推廣對像用戶兌換禮品卡時，會自動成為推廣者的下線好友。',
    pt_card_rule1: '一、免費禮品卡每個用戶限領30張。',
    pt_card_rule2: '二、免費禮品卡每個用戶限兌1份。 ',
    pt_card_rule3: '三、用戶兌換禮品卡前，如不存在「邀請者」（即註冊帳號時未輸入過邀請碼），則自動關聯為發卡者的一級好友。',
    pt_card_rule4: '四、為防止刷客，禮品卡帳面金額並非即時兌付，領取後需完成實名認證才可操作。',
    pt_card_rule5: '五、領取免費禮品卡時需要完成實名認證，兌換時無需完成實名認證即可領取，此舉是為了方便推廣者更快獲取下級好友。',
    pt_card_rule6: '六、免費禮品卡最終解釋權歸Alcex所有。',
    pt_card_day: '天',
    pt_card_title_tips: '領2000元推廣補助金',
    pt_invite_desc: '專屬二維碼，透過二維碼註冊自動成為下級好友，適合在各種群組或論壇轉發。',
    saveimage: '保存圖片',
    imagetips: '如無法正常下載圖片，請使用截圖工具截圖儲存！',
    pt_card_receivew_success: '恭喜！',
    usepromotion: '使用推廣素材',
    context_title: '推廣合夥人專屬兌換碼',
    context_title1: '掃一掃了解更多',
    context_title2: '我的邀請碼',
    context_title3: '邀請圖片',
    pt_card_title: '合夥人推廣卡',
    copysuccess: '複製成功！',
    copyerr: '複製失敗！',
    noopening: '暫無待選擇權合約',
    createSuccess: '創建成功',
    tradewarning1: '最多輸入2位小數',
    tradewarning2: '下單金額為',
    tradewarning3: '最多輸入8位小數',
    tradewarning4: '下單數量為',
    tradeconfirmbuyin: '確認買入',
    tradeconfirmsellout: '確認賣出',
    tradeinfo: {
        emaildone: '郵件已認證',
        emailundo: '郵件未認證',
        teldone: '手機號碼已認證',
        telundo: '手機號碼未認證',
        idcarddone: '身分證已認證',
        idcardundo: '身分證未認證',
        exchangetimes: '交易次數',
        price: '價格',
        num: '數量',
        paymethod: '付款方式',
        exchangelimitamount: '交易限額',
        location: '所在地',
        location_text: '中國',
        exchangeperiod: '交易期限',
        minute: '分分鐘',
        amounttip: '請輸入金額',
        numtip: '請輸入數量',
        remarktip: '告訴他您的要求',
        remarktitle: '備註資訊',
        exchangetitle: '交易須知',
        exchange_tip1: '在您發起交易請求後，數位貨幣被鎖定在託管中，受到平台保護。',
        exchange_tip2: '交易前請先閱讀《平台網路服務條款》以及常見問題、交易指南等幫助文件。',
        exchange_tip3: '當心騙子！',
        exchange_tip4: '請注意，四捨五入和價格的波動可能會影響最終成交的數位貨幣金額。',
        exchange_tip5: '託管服務保護網上交易的買賣雙方。',
        warning1: '最多輸入2位小數',
        warning2: '下單金額為',
        warning3: '最多輸入8位小數',
        warning4: '下單數量為',
        confirmbuyin: '確認買入',
        confirmsellout: '確認賣出',
        buyin: '買入',
        sellout: '賣出',
        warning5: '請依要求填寫訂單',
    },
    convertResult:'預覽兌換結果',
    willGet:'你將得到',
    pleaseCommit:'請在該時間內點選確認。 ',
    returnText:'返回',
    convert:'兌換'
};
