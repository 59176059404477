import Vue from 'vue'
import VueRouter from 'vue-router'
import LiveView from '../views/LiveView.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const routes = [{
		path: '/LiveView',
		name: 'LiveView',
		component: LiveView
	},
	{
		path: '/',
		name: 'index',
		component: () => import('../views/web/index.vue')
	},	{
		path: '/index2',
		name: 'index2',
		component: () => import('../views/web/index2.vue')
	},
	{
		path: '/earn',
		name: 'earn',
		component: () => import('../views/web/earn.vue')
	},
	{
		path: '/cloudMining',
		name: 'cloudMining',
		component: () => import('../views/web/cloudMining.vue')
	},
	{
		path: '/option',
		name: 'option',
		component: () => import('../views/web/option.vue')
	},
	{
		path: '/second/:id',
		component: () => import('../views/web/second.vue')
	},
	{
		path: '/swap/:id',
		component: () => import('../views/web/swap.vue')
	},
	{
		path: '/exchange/:id',
		component: () => import('../views/web/exchange.vue')
	},
	{
		path: '/trade/:unit',
		name: 'trade',
		component: () => import('../views/web/trade.vue')
	},
	{
		path: '/identbusiness',
		name: 'identbusiness',
		component: () => import('../views/web/identbusiness.vue')
	},
	{
		path: '/admin',
		name: 'admin',
		component: () => import('../views/admin/admin.vue')
	},
	{
		path: '/Register',
		name: 'Register',
		component: () => import('../views/Register.vue')
	},
	{
		path: '/realName',
		name: 'realName',
		component: () => import('../views/admin/realName.vue')
	},
	{
		path: '/changePhone',
		name: 'changePhone',
		component: () => import('../views/admin/changePhone.vue')
	},
	{
		path: '/changeFund',
		name: 'changeFund',
		component: () => import('../views/admin/changeFund.vue')
	},
	{
		path: '/setFund',
		name: 'setFund',
		component: () => import('../views/admin/setFund.vue')
	},
	{
		path: '/retrieveFund',
		name: 'retrieveFund',
		component: () => import('../views/admin/retrieveFund.vue')
	},
	{
		path: '/changeLogin',
		name: 'changeLogin',
		component: () => import('../views/admin/changeLogin.vue')
	},
	{
		path: '/changeEmail',
		name: 'changeEmail',
		component: () => import('../views/admin/changeEmail.vue')
	},
	{
		path: '/changeGoogle',
		name: 'changeGoogle',
		component: () => import('../views/admin/changeGoogle.vue')
	},
	{
		path: '/removeGoogle',
		name: 'removeGoogle',
		component: () => import('../views/admin/removeGoogle.vue')
	},
	{
		path: '/collection',
		name: 'collection',
		component: () => import('../views/admin/collection.vue')
	},
	{
		path: '/collection2',
		name: 'collection2',
		component: () => import('../views/admin/collection2.vue')
	},
	{
		path: '/collection3',
		name: 'collection3',
		component: () => import('../views/admin/collection3.vue')
	},
	{
		path: '/collection4',
		name: 'collection4',
		component: () => import('../views/admin/collection4.vue')
	},
	{
		path: '/myCards',
		name: 'myCards',
		component: () => import('../views/admin/myCards.vue')
	},
	{
		path: '/promotion',
		name: 'promotion',
		component: () => import('../views/admin/promotion.vue')
	},
	{
		path: '/api',
		name: 'api',
		component: () => import('../views/admin/api.vue')
	},
	{
		path: '/Login',
		name: 'Login',
		component: () => import('../views/Login.vue')
	},
	{
		path: '/money',
		name: 'money',
		component: () => import('../views/account/money.vue')
	},
	{
		path: '/swapAssets',
		name: 'swapAssets',
		component: () => import('../views/account/swapAssets.vue')
	},
	{
		path: '/secondAssets',
		name: 'secondAssets',
		component: () => import('../views/account/secondAssets.vue')
	},
	{
		path: '/financeAssets',
		name: 'financeAssets',
		component: () => import('../views/account/financeAssets.vue')
	},
	{
		path: '/walletHistory',
		name: 'walletHistory',
		component: () => import('../views/account/walletHistory.vue')
	},
	{
		path: '/coinSwapAssets',
		name: 'coinSwapAssets',
		component: () => import('../views/account/coinSwapAssets.vue')
	},
	{
		path: '/recharge',
		name: 'recharge',
		component: () => import('../views/account/recharge.vue')
	},
	{
		path: '/withdrawCode',
		name: 'withdrawCode',
		component: () => import('../views/account/withdrawCode.vue')
	},
	{
		path: '/withdraw',
		name: 'withdraw',
		component: () => import('../views/account/withdraw.vue')
	},
	{
		path: '/spotOrder',
		name: 'spotOrder',
		component: () => import('../views/order/spotOrder.vue'),
		meta: { name: "spot" }
	},
	{
		path: '/orderHistory',
		name: 'orderHistory',
		component: () => import('../views/order/orderHistory.vue'),
		meta: { name: "spot" }
	},
	{
		path: '/ad',
		name: 'ad',
		component: () => import('../views/order/ad.vue')
	},
	{
		path: '/orderMy',
		name: 'orderMy',
		component: () => import('../views/order/orderMy.vue')
	},
	{
		path: '/orderThrow',
		name: 'orderThrow',
		component: () => import('../views/order/orderThrow.vue')
	},
	{
		path: '/earnOrder',
		name: 'earnOrder',
		component: () => import('../views/order/earnOrder.vue')
	},
	{
		path: '/convertHistory',
		name: 'convertHistory',
		component: () => import('../views/order/convertHistory.vue')
	},
	{
		path: '/announcement/:id',
		component: () => import('../views/order/announcement.vue')
	},
	{
		path: '/helplist',
		name: 'helplist',
		component: () => import('../views/foot/helplist.vue')
	},
	{
		path: '/helpdetail',
		name: 'helpdetail',
		component: () => import('../views/foot/helpdetail.vue')
	},
	{
		path: '/lab',
		name: 'lab',
		component: () => import('../views/lab.vue')
	},
	{
		path: '/lab/detail/:id',
		name: 'labDetail',
		component: () => import('../views/labDetail.vue')
	},
	{
		path: '/invite',
		name: 'invite',
		component: () => import('../views/invite.vue')
	},
	{
		path: '/convert',
		name: 'convert',
		component: () => import('../views/web/convert.vue')
	},
	{
		path: '/editAd',
		name: 'editAd',
		component: () => import('../views/web/editAd.vue')
	},
	{
		path: '/createAd',
		name: 'createAd',
		component: () => import('../views/web/createAd.vue')
	},
	{
		path: '/innovation/myorders',
		name: 'myorders',
		component: () => import('../views/innovation/myorders.vue')
	},
	{
		path: '/innovation/myminings',
		name: 'myminings',
		component: () => import('../views/innovation/myminings.vue')
	},
	{
		path: '/innovation/mylocked',
		name: 'mylocked',
		component: () => import('../views/innovation/mylocked.vue')
	},
	{
		path: '/coinswap/:id',
		name: 'coinswap',
		component: () => import('../views/web/coinswap.vue')
	},
	{
		path: '/whitepaper',
		name: 'whitepaper',
		component: () => import('../views/foot/whitepaper.vue')
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('../views/foot/about.vue')
	},
	{
		path: '/forget',
		name: 'forget',
		component: () => import('../views/forget.vue')
	},
	{
		path: '/tradeInfo',
		name: 'tradeInfo',
		component: () => import('../views/web/tradeInfo.vue')
	},
	{
		path: '/cloudMining',
		name: 'cloudMining',
		component: () => import('../views/web/cloudMining.vue')
	}
]


const router = new VueRouter({
	routes
})



export default router
