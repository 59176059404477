export default {
  testText1: 'Questa è una stazione di prova! ',
  testText2: '@Alcexcom',
  testText3: "@bzengineer (l'unico canale, attenzione a non essere truffati)",
  meun1: 'prima pagina',
  meun2: 'Acquista monete con un clic',
  meun3: 'commercio',
  meun3_1: 'Commercio a pronti',
  meun3_2: 'Scambio rapido',
  meun4: 'negoziazione a contratto',
  meun4_1: 'Contratto tipo U',
  meun4_2: 'Contratto con margine monetario',
  meun4_3: 'contratto di opzione',
  meun4_4: 'secondo contratto',
  meun5: 'Servizi finanziari',
  meun5_1: 'Laboratorio di innovazione',
  meun5_2: 'Socio di promozione',
  meun6: 'gestione finanziaria',
  meun7: 'annuncio',
  meun8: 'Login',
  meun9: 'Registrati',
  meun10: 'portafoglio',
  meun10_1: 'conto spot',
  meun10_2: 'Conto contrattuale basato su U',
  meun10_3: 'Conto contrattuale con margine in monete',
  meun10_4: 'contratto di seconda base',
  meun10_5: 'conto finanziario',
  meun10_6: 'Cronologia del portafoglio',
  meun11: 'Ordine',
  meun11_1: 'ordine a pronti',
  meun11_2: 'Ordine C2C',
  meun11_3: 'Ordini di gestione finanziaria',
  meun11_4: 'Cronologia degli scambi flash',
  meun11_5: "Gestione dell'innovazione",
  meun12: 'scaricamento',
  meun13_1: 'Gestione contabile',
  meun13_2: 'metodo di pagamento',
  meun13_3: 'la mia promozione',
  meun13_4: "Gestione dell'API",
  meun13_5: 'disconnessione',
  meun14_1: 'lingua',
  meun14_2: 'tasso di cambio',
  meun14_3: 'Scegli una lingua',
  meun14_4: 'Seleziona il tasso di cambio',
  openTrade: 'Inizia a fare trading sempre e ovunque.',
  scan: 'IOS e Andraid Scansiona il codice QR per scaricarlo',
  footmeun1: 'Di',
  footmeun1_1: 'chi siamo',
  footmeun1_2: 'Unisciti a noi',
  footmeun1_3: 'Annuncio della piattaforma',
  footmeun1_4: 'carta bianca',
  footmeun2: 'Centro assistenza',
  footmeun2_1: 'Guida per principianti',
  footmeun2_2: 'problema comune',
  footmeun2_3: 'Guida al commercio',
  footmeun2_4: 'Informazioni sulla valuta',
  footmeun3: 'accordo sui termini',
  footmeun3_1: 'Disclaimer',
  footmeun3_2: 'politica sulla riservatezza',
  footmeun3_3: 'Accordo per gli utenti',
  footmeun3_4: 'Descrizione tariffa',
  footmeun4: 'Contattaci',
  footmeun4_1: 'E-mail del servizio clienti',
  footmeun4_2: 'Cooperazione commerciale',
  footmeun4_3: "Applicazione per l'elenco delle monete",
  footmeun4_4: 'Denunce, contestazioni',
  foottext: 'Scambio globale di risorse digitali di alta qualità',
  indexTxt1: 'I 50 migliori scambi di valuta digitale nel mondo',
  indexTxt2: 'Le agenzie nazionali garantiscono la sicurezza dei beni',
  indexTxt3: 'Guida per principianti',
  indexTxt4: 'Acquista rapidamente monete',
  indexTxt5:
    "Canale ausiliario ufficiale per l'ingresso dei principianti Alcex",
  indexLabel1: 'Commercio di criptovaluta',
  indexVal1: 'Utilizza una risorsa digitale per lo scambio diretto',
  indexLabel2: 'negoziazione di opzioni',
  indexVal2: 'Derivati ​​di asset digitali',
  indexLabel3: 'negoziazione a contratto',
  indexVal3: 'Standard moneta, contratto standard U',
  indexLabel4: 'Scambio rapido',
  indexVal4: 'Cambio valuta istantaneo, completa le transazioni con un clic',
  indexth: 'andamento dei prezzi',
  indexbtn: 'Fai trading adesso',
  indexh1: 'onesto',
  indexh2: 'Giusto',
  indexh3: 'entusiasmo',
  indexh4: 'aprire',
  indexh5: 'Sicurezza di livello finanziario',
  indexh6: 'Depositi e prelievi estremamente veloci',
  indexh7: 'servizio globale',
  indexh8: 'Progetti accuratamente selezionati',
  indexp1:
    'Alcex è stata fondata da un gruppo di primi partecipanti e fanatici di Bitcoin. I membri principali del team provengono da aziende famose come Google, Microsoft, Alibaba e Tencent. Hanno profonde capacità di ricerca e sviluppo e una ricca esperienza nella gestione di prodotti Internet.',
  indexp2:
    'Alcex si posiziona come fornitore di servizi blockchain di base e si impegna a fornire piattaforme di trading di criptovalute di alta qualità per utenti globali. Aderisce al principio di base di "non fare il male", insiste nel servire i clienti in modo onesto, equo ed entusiasta, e accoglie con un atteggiamento aperto tutte le parti interessate, partner/progetti che vanno a beneficio degli interessi fondamentali degli utenti.',
  indexp3:
    'Un sistema completo di controllo del rischio finanziario e un sistema antifurto, portafogli caldi e freddi e un sistema multi-firma garantiscono la sicurezza dei fondi',
  indexp4:
    'Depositi e prelievi possono essere completati in soli 3 minuti e la revisione manuale online 24 ore su 24 protegge i clienti dal perdere le migliori opportunità di investimento.',
  indexp5:
    'Copertura di rete di servizi aziendali globali per aiutarti a investire in risorse crittografiche globali e a commerciare con utenti globali',
  indexp6:
    "Seleziona rigorosamente progetti di crittografia di alta qualità e filtra per te l'80% dei progetti ad alto rischio",
  indexp7: "Scansiona il codice QR e scarica l'app",
  scandownload: "Scansione per scaricare l'APP",
  ormenu1: 'Ordine C2C',
  ormenu2: 'Il mio ordine',
  ormenu3: 'Guadagnare monete',
  please: 'per favore prima',
  account: "Accesso all'account Alcex",
  phone: 'numero di telefono',
  email: 'Posta',
  sign: 'Login',
  password: "parola d'ordine",
  password2: "parola d'ordine",
  password3: 'dimenticare la password',
  account2: 'Crea un account Alcex',
  logErr: 'Per favore inserisci il nome utente',
  logErr2: 'Per favore, inserisci la password',
  logErr3: 'La lunghezza della password non può essere inferiore a 6 caratteri',
  logSuccess: 'accesso riuscito',
  account3: 'Crea un account personale',
  phone1: 'Registra il tuo telefono',
  email1: 'registrazione email',
  email2: 'indirizzo e-mail',
  code: 'Codice di verifica SMS',
  code2: 'Invia il codice di verifica',
  code3: 'Codice di verifica',
  password4: 'Conferma password',
  invite: 'Codice invito (facoltativo)',
  agree: "Ho letto e sono d'accordo",
  agreement: 'Accordo per gli utenti',
  register: 'Registrati',
  regErr: 'Inserisci il codice di verifica SMS',
  confirmpwdtip: 'Inserisci la password di conferma',
  confirmpwderr: 'I due input della password non sono coerenti!',
  wjtab: 'Reimposta la password del cellulare',
  wjtab2: "Reimposta la password dell'e-mail",
  wjlabel: 'nuova password',
  wjerr: 'Inserisci una nuova password',
  labTxt: "Laboratorio Alcex ● Condividere i risultati dell'innovazione",
  labTxt2:
    'Un laboratorio aperto vantaggioso per investitori, parti di progetto, scambi e altre parti',
  labTab1: 'Tutto',
  labTab2: 'Sto per iniziare',
  labTab3: 'in corso',
  labTab4: 'Distribuire',
  labTab5: 'completato',
  tokendistribute: 'Il token è in fase di distribuzione',
  activitytype: 'Tipo di abbonamento',
  labStart: 'Tempo di partenza',
  labEnd: 'Tempo scaduto',
  labStartPlaceholder: "Seleziona un'ora di inizio",
  labDetail: 'controlla i dettagli',
  labSpeen: 'programma',
  labNumber: 'Attività totale',
  labType1: 'Primo online (acquisto urgente)',
  labType2: 'Prima volta online (anche diviso)',
  labType3: 'Condivisione della posizione',
  labType4: 'Abbonamento gratuito',
  labType5: 'Abbonamento alla macchina di mining nel cloud',
  labType6: 'rilascio della serratura',
  baseinfo: 'Informazioni di base',
  ruledetail: 'Descrizione del tipo',
  releaseType: 'Tipo di rilascio',
  releaseType0: 'Rilasciare in quantità uguali',
  releaseType1: 'Rilascio con rapporto uguale',
  lock: 'periodo di rilascio',
  releaseDetail: 'Dettagli del rilascio',
  lockday: 'giorno',
  lockweek: 'settimana',
  lockmonth: 'luna',
  lockyear: 'Anno',
  releasePercent: 'Rapporto di rilascio',
  lockFee: 'Soglia di blocco',
  releaseTimes: 'Rilascia più',
  times: 'volte',
  publishprice: 'Prezzo di abbonamento',
  holdcoin: 'Valuta richiesta per mantenere le posizioni',
  limittimes: 'Limite di acquisto personale',
  limitamount: 'Limite di acquisto personale',
  unlimited: 'Senza limiti',
  leveloneCount: 'Numero di amici di primo livello richiesti',
  alreadyamount: 'Quantità acquistata',
  leftamount: "L'ammontare residuo",
  myalreadyholdamount: 'Le mie posizioni partecipative',
  alreadyholdamount: "Possesso totale dei partecipanti all'evento",
  currentdivided: 'La mia posizione attuale può essere suddivisa in circa',
  holdtips:
    "Per partecipare all'attività di tipo [Condivisione della posizione], è necessario bloccare la propria posizione fino alla fine dell'attività",
  inputholdamount: "Inserisci l'importo del blocco",
  pleaseinputamount: 'Inserisci la quantità di rimborso',
  pleaseinputholdamount: "Inserisci l'importo del blocco",
  inputamount: "Inserisci l'importo del riscatto",
  inputminingamount: 'Inserisci il numero di macchine minerarie da acquistare',
  attend: 'Partecipa ora',
  attention: 'Avviso',
  attentiontxt1:
    "1. Dopo aver inviato la partecipazione alle attività di tipo [Condivisione della posizione], [Abbonamento gratuito], [Abbonamento alla macchina di estrazione cloud] e [Blocco], le risorse verranno congelate e verranno sbloccate o sbloccate al termine dell'attività .",
  attentiontxt2:
    '2. Dopo aver partecipato ad attività di tipo [Condivisione della posizione], [Abbonamento gratuito], [Abbonamento alla macchina di estrazione cloud], [Blocco], agli utenti non è consentito annullare la propria richiesta di partecipazione.',
  attentiontxt3:
    "3. Se l'evento viene annullato a causa della parte del progetto o di altri fattori di forza maggiore, i beni congelati verranno riportati al percorso originale.",
  attentiontxt4:
    '* Il diritto di interpretazione finale di questo evento appartiene al sito ufficiale Alcex (www.al-cex.com)',
  transfer: 'Trasferimento di fondi',
  placeholder: 'Cerca valuta',
  bith1: 'negoziazione a contratto',
  bith2: "Diritti dell'account",
  bith3: 'profitti o perdite non realizzati',
  bith4: 'Margine disponibile',
  bith5: 'Margine utilizzato',
  bith6: 'Congelare il margine',
  bith7: 'Conto contrattuale',
  btn1: 'Riempire',
  btn2: 'ritirare',
  btn3: 'trasferimento',
  btn4: 'Deposita monete',
  btn5: 'Ritirare le monete',
  totalAssets: 'Totale asset convertiti',
  th1: 'Nome della valuta',
  th2: 'Risorse disponibili',
  th3: 'Congelare i beni',
  th4: 'Beni da liberare',
  th5: 'operare',
  czTxt: 'Ricarica valuta digitale',
  czTxt2: 'Valuta',
  czTxt3: 'rete di trasferimento',
  czTxt4: 'saldo disponibile',
  czTxt5: 'Indirizzo di ritiro',
  czTxt6: 'Quantità di monete ritirate',
  czTxt7: 'Quantità in arrivo',
  czTxt8: 'commissione di gestione',
  czTxt9: 'Consigli gentili',
  czTxt10: 'Importo minimo del deposito:',
  czTxt10_1:
    "Le ricariche inferiori all'importo minimo non verranno accreditate.",
  czTxt11:
    "Si prega di non depositare beni non valutari all'indirizzo sopra indicato, altrimenti i beni non verranno recuperati.",
  czTxt12:
    "Dopo aver effettuato la ricarica all'indirizzo sopra indicato è necessaria la conferma da parte dell'intero nodo della rete blockchain, generalmente l'accredito sul conto verrà effettuato dopo 3 conferme di rete dalla rete blockchain principale.",
  czTxt13:
    'Il tuo indirizzo di ricarica non cambierà frequentemente e potrà essere ricaricato ripetutamente; in caso di modifiche, faremo del nostro meglio per avvisarti tramite annunci sul sito web o e-mail.',
  czTxt14:
    'Assicurati che il tuo computer e il tuo browser siano sicuri per evitare che le informazioni vengano manomesse o divulgate.',
  czTxt15: 'Ricaricare il registro',
  czTxt16:
    'Al fine di garantire la sicurezza dei fondi, quando cambi la politica di sicurezza del tuo account, modifichi la password o utilizzi un nuovo indirizzo per prelevare monete, condurremo una revisione manuale del prelievo. Sii paziente e attendi che lo staff ti contatti per telefono o e-mail.',
  czTxt17: "L'importo minimo di prelievo è:",
  czth1: 'Tempo di ritiro',
  czth2: 'da',
  czth3: 'A',
  czth4: 'Quantità di rimborso istantaneo',
  czth5: 'Rapporto di cambio',
  czth6: 'Quantità di scambio',
  placeholder2: 'prego entra',
  placeholder3: 'si prega di scegliere',
  czEmpty: 'nessuna registrazione',
  logintip: 'per favore fai prima il log in',
  cztip:
    "Non hai richiesto un indirizzo di ricarica. Fai clic sul pulsante per ottenere l'indirizzo di ricarica.",
  czget: "Ottieni l'indirizzo di ricarica",
  czplaceholder: 'Seleziona un protocollo',
  mAccount: 'secondo conto contrattuale',
  btn6: 'ricerca',
  wtTxt: 'Ora di inizio e fine',
  wtTxt2: 'Tipo di operazione',
  wtth1: 'ora della transazione',
  wtth2: 'tipo',
  wtth3: 'Valuta',
  wtth4: 'quantità',
  wtth5: 'Commissione di gestione da pagare',
  wtth6: 'Detrarre le spese di gestione',
  wtth7: 'Commissione di gestione effettiva',
  wtth8: 'stato',
  nav1: "Sicurezza dell 'Account",
  nav2: 'il mio invito',
  nav3: 'La mia carta promozionale',
  acTxt: 'Livello di sicurezza',
  acbtn1: 'non certificato',
  acbtn2: 'legame',
  acbtn3: 'Rivedere',
  acbtn4: 'verificato',
  acbtn5: 'Limite',
  acbtn6: 'impostare',
  acbtn7: 'in corso di revisione',
  acbtn8: 'Riprova',
  acTxt2: 'Verificato',
  acTxt3: 'Posta',
  acTxt4: 'cellulare',
  acTxt5: 'password per il login',
  acTxt6: 'Password del fondo',
  acTxt7: 'Autenticatore di Google',
  acTxt8: 'Imposta la password del fondo',
  acTxt9: 'Modifica la password del fondo',
  acTxt10: 'Recupera la password del fondo',
  acDesc2:
    "Per garantire la sicurezza del tuo account, completa l'autenticazione del nome reale prima di fare trading!",
  acDesc3: "Associa l'e-mail",
  acDesc4: 'Legare il cellulare',
  acDesc5: 'Utilizzato quando si accede alla piattaforma',
  acDesc6:
    'Quando i fondi del conto cambiano, è necessario verificare la password del fondo',
  acDesc7: "Associa l'autenticatore Google",
  acDesc8: 'Google Authenticator è associato',
  acDesc9: 'Slegare',
  acTit: 'Legatura del cellulare',
  acTit2: 'Associazione e-mail',
  acTit3: 'Vincolo di Google',
  acTit4: 'Google scioglie',
  mailtip: 'per favore inserisci la tua email',
  emailerr2: "Il formato dell'email non è corretto, inseriscilo nuovamente.",
  agreementtip: "Si prega di leggere e accettare le condizioni d'uso",
  acverify: 'Revisione fallita',
  again: 'Per favore riprova',
  verifiedmsg: "Esegui prima l'autenticazione del nome reale",
  apiTxt: "Associa l'account con nome reale",
  apiTxt2: 'suggerimento',
  apiTxt3:
    'Ti forniamo una potente API attraverso la quale puoi utilizzare servizi come indagini di mercato e trading automatico. ',
  apiTxt4: 'Ogni utente può creare fino a 5 set di chiavi API',
  apiTxt5:
    'Ti preghiamo di non rivelare la tua chiave API per evitare la perdita di risorse. ',
  apiTxt6: 'Note API',
  apiTxt7:
    "IP autorizzato (la chiave per l'indirizzo IP autorizzato è valida per 90 giorni)",
  apibtn: 'creare',
  apith1: 'Note API',
  apith2: 'tempo di creazione',
  apith3: 'chiave di accesso',
  apith4: "Associa l'indirizzo IP",
  apith5: 'Periodo di validità rimanente (giorni)',
  chTxt: 'Cambia password di accesso',
  chTxt2: 'Scansiona il codice per associarlo',
  chTxt3: 'inserisci il codice di verifica',
  chTxt4: 'Password del fondo originale',
  chTxt5: 'Nuova password del fondo',
  chTxt6: 'Conferma la nuova password',
  chTxt7: 'dimenticare la password',
  chTxt8: 'Modifica la verifica del telefono cellulare',
  chTxt9: 'Password di accesso originale',
  chTxt10: 'Nuova password di accesso',
  chTxt11: 'Codice di verifica e-mail',
  chTxt12: 'Codice di verifica del telefono cellulare',
  telerr: 'Il numero di cellulare non è corretto',
  emailerr: "L'e-mail non è corretta",
  codeerr: 'Codice di verifica errato',
  reset: 'Ripristina',
  save: 'salva',
  chtip: 'Inserisci la password originale',
  chtip2:
    'Inserisci una nuova password di accesso composta da non meno di 6 cifre',
  chtip3: 'La nuova password di accesso non è coerente',
  chtip4: 'Inserisci la password del fondo corretta',
  chtip5: 'La password non deve contenere meno di 6 caratteri',
  chtip6: 'Inserisci una password di non meno di 6 cifre',
  chtip7: 'Le password non sono coerenti',
  chtip8: 'Inserisci la password del fondo',
  chtip9: 'Inserisci il numero di telefono',
  chtip10: 'per favore inserisci LA TUA password',
  realnametip: 'per favore inserisci il tuo vero nome',
  idcardtip: 'Inserisci il tuo numero identificativo',
  clTxt:
    'Imposta il metodo di pagamento e assicurati di utilizzare il tuo account con nome reale',
  clTxt2: 'Aggiungi metodo di pagamento',
  edit: 'modificare',
  delete: 'eliminare',
  name: 'Nome',
  clTxt3: 'Numero di identificazione',
  clTxt4: 'Numero IBAN',
  clTxt5: 'Tutti i metodi di pagamento',
  clLink1: 'nome utente',
  clLink2: 'Modifica metodo di pagamento',
  clLink3: 'Dettagli di pagamento (facoltativo)',
  clplaceholder1: 'Inserisci il tuo nome e cognome',
  clplaceholder2: 'Inserisci la password del tuo conto bancario',
  clplaceholder3: 'Per favore inserisci i tuoi dati di pagamento',
  cltip: 'Nota speciale',
  cltip2:
    'Assicurati di aggiungere il numero della tua carta bancaria per il pagamento immediato. ',
  cltip3:
    "Promemoria: quando vendi valuta digitale, il metodo di pagamento scelto verrà visualizzato all'acquirente. ",
  clTxt6: 'Opzionale',
  upload: 'caricamento',
  uploadTip: '(Supporta il formato JPG/JPEG/PNG/BMP, inferiore a 1 MB)',
  tgbtn: 'Riscatta il codice',
  tgth1: 'Nome della carta',
  tgth2: 'Riscatta il codice',
  tgth3: 'Valuta della carta',
  tgth4: 'Importo nominale della carta',
  tgth5: 'totale',
  tgth6: 'Già riscattato',
  tgth7: 'Orario di raccolta',
  tgbtn2: 'Materiale promozionale',
  tgbtn3: 'Riscattare ora',
  tgplaceholder: 'Inserisci il codice di riscatto',
  exchangesuccess: 'Riscatto riuscito! ',
  inth1: 'Invita utenti',
  inth2: 'Orario di registrazione',
  inth3: 'Stato del nome reale',
  adTxt: 'il mio annuncio',
  adTxt2:
    "Quando l'importo minimo della transazione dell'annuncio più le spese di gestione è maggiore della quantità rimanente dell'annuncio, l'annuncio verrà automaticamente rimosso dagli scaffali.",
  adbtn: 'Condizioni chiare',
  adbtn2: 'Sugli scaffali',
  adbtn3: 'Rimosso dagli scaffali',
  adth1: "Numero dell'annuncio",
  adth2: 'tipo di pubblicità',
  adth3: "Limiti dell'ordine",
  adth4: "L'ammontare residuo",
  adtip: 'confermare per eliminare',
  num_text1: 'Per favore inserisci quello che vuoi',
  num_text2: 'quantità',
  wTxt: 'Merci spot',
  wTxt2: 'Ordine attuale',
  wTxt3: 'coppia commerciale',
  wTxt4: 'Direzione commerciale',
  wth1: 'tempo',
  wth2: 'direzione',
  wth3: 'prezzo',
  wth4: 'Affare fatto',
  wth5: 'Turnover',
  wth6: 'Tipo di delegato',
  wth7: 'prezzo di attivazione',
  wth8: 'prezzo finale',
  wth9: "Quantità dell'ordine",
  wth10: 'Profitti e perdite di liquidazione',
  wth11: 'Stato della delega',
  wth12: 'Apri/Chiudi una posizione',
  wth13: 'margine',
  wbtn: 'Annulla Ordine',
  wtip: 'Promemoria di cancellazione',
  wtiptxt: "Confermi l'annullamento dell'ordine?",
  zbplaceholder: "Inserisci il numero dell'ordine per avviare la ricerca",
  zbtab1: 'Piano di investimenti fissi',
  zbtab2: 'Record di investimenti fissi',
  zbth1: 'ciclo di investimenti',
  zbth2: 'interruttore',
  zbth3: "Importo dell'investimento cumulativo",
  zbth4: 'Quantità trattenuta',
  zbth5: 'costo medio',
  zbth6: 'profitti o perdite non realizzati',
  zbth7: 'Prossima data di investimento prevista',
  zbth8: 'Data di creazione del piano',
  etTxt: 'Centro annunci',
  etTxt2: 'Scansiona per condividere',
  etmenu1: 'Criptovaluta',
  etmenu2: 'Annuncio di aggiornamento del server Alcex',
  et2menu1: 'Introduzione a RX.Tron',
  et2menu2: 'Bitcoin è uno schema Ponzi?',
  hisTxt: 'commissione storica',
  orTab1: 'Non pagato',
  orTab2: 'Già pagato',
  orTab3: 'completato',
  orTab4: 'Annullato',
  orTab5: 'Attraente',
  orth1: "numero d'ordine",
  orth2: 'Valuta di transazione',
  orth3: 'Tipo di transazione',
  orth4: 'Soci in affari',
  orth5: 'Quantità',
  qcTab1: 'Risultati precedenti',
  qcTab2: 'Preventivi in ​​tempo reale',
  qcTxt: 'NO.',
  qcTxt2: 'Aspettarsi',
  qcTxt3: 'orario di apertura',
  qcTxt4: 'orario di chiusura',
  qcTxt5: 'prezzo di apertura',
  qcTxt6: 'prezzo di chiusura',
  qcTxt7: 'Prezzo totale di acquisto',
  qcTxt8: 'Importo totale di acquisto e vendita',
  qcTxt9: 'La mia posizione aperta',
  qcTxt10: 'Progresso della previsione',
  qcTxt11: 'prezzo di apertura',
  qcTxt12: 'Prezzo di chiusura',
  qcTxt13: 'Questo periodo di previsione',
  qcbtn1: 'rialzista',
  qcbtn2: 'Ribassista',
  qcth1: 'Numero di periodi contrattuali',
  qcth2: 'Importo di apertura',
  qcth3: 'direzione della previsione',
  qcth4: 'risultato della previsione',
  qcth5: 'Importo del bonus',
  qcth6: 'Tassa di apertura',
  qcth7: "pompa dell'acqua",
  mplaceholder: 'Inserisci il nome della valuta da cercare',
  mTab: 'Opzionale',
  mth: 'raccogliere',
  mth1: 'Ultimo prezzo',
  mth2: '24 ore su 24, salita e discesa',
  mTxt: 'Prezzo più alto 24 ore',
  mTxt2: 'Prezzo più basso 24 ore su 24',
  mTxt3: 'Numero di transazioni',
  mTxt4: 'tasso di finanziamento',
  mTxt5: 'aprire',
  mTxt6: 'alto',
  mTxt7: 'Basso',
  mTxt8: 'ricevere',
  echat1: 'Grafico a linea K',
  echat2: 'Mappa della profondità',
  mTab2: 'Posizione attuale',
  mTab3: 'Storia della Commissione',
  mth3: 'Compensazione garantita',
  mth4: 'Prezzo di apertura',
  mth5: 'ciclo',
  mth6: 'Prezzo di chiusura',
  mth7: 'profitto',
  mTxt9: 'modello commerciale',
  mTxt10: 'Orario di apertura',
  mTxt11: 'Redditività',
  mTxt12: 'bilancia',
  mTxt13: "Volume d'acquisto",
  mTxt14: 'mezzo',
  cadTxt: 'Crea un accordo pubblicitario',
  cadTxt2:
    'Se fai scambi frequentemente, puoi creare i tuoi annunci commerciali.',
  cadTxt2_1:
    'Se fai trading solo occasionalmente, ti consigliamo di cercare direttamente',
  cadTxt2_2: 'La creazione di un annuncio commerciale è gratuita.',
  cadLink2: 'pubblicità delle transazioni',
  cadTxt3:
    'Se desideri modificare direttamente un annuncio che hai creato, consulta',
  cadLink3: 'il mio annuncio',
  cadTxt4: 'prezzo di partecipazione al mercato',
  cadTxt5:
    'Il premio si riferisce alla percentuale di vendita superiore al prezzo di mercato corrente',
  cadTxt6: 'Formula di prezzo',
  cadTxt7:
    '[Suggerimento] Puoi andare al centro personale per associare/aggiungere metodi di pagamento',
  cadTxt8:
    "L'importo massimo della transazione non può superare l'importo totale di vendita 0CNYI",
  cadTxt9:
    "Dopo averlo abilitato, quando un utente avvia una transazione con te tramite questo annuncio, il sistema invierà automaticamente all'altra parte il testo di risposta automatica che hai selezionato.",
  cadTxt10:
    'Il premio si riferisce alla percentuale di acquisto superiore al prezzo di mercato corrente',
  cadLabel1: 'Voglio:',
  cadLabel2: 'Transazione valutaria:',
  cadLabel3: 'nazione:',
  cadLabel4: 'valuta:',
  cadLabel5: 'Attiva il prezzo fisso',
  cadLabel6: 'Premio:',
  cadLabel7: 'Prezzo di negoziazione',
  cadLabel8: 'Quantità venduta:',
  cadLabel9: 'metodo di pagamento:',
  cadLabel10: 'Importo minimo della transazione:',
  cadLabel11: 'Importo massimo della transazione:',
  cadLabel12: 'Attiva le risposte automatiche',
  cadLabel13: 'Password del fondo:',
  cadLabel14: 'Osservazioni:',
  cadLabel15: 'Prezzo fisso:',
  cadLabel16: 'risposta automatica:',
  cadLabel17: "Quantità d'acquisto:",
  radio: 'in vendita online',
  radio2: 'comprare on line',
  cadplaceholder: 'Imposta il tuo premio',
  cadplaceholder2: 'Inserisci la quantità che desideri vendere',
  cadplaceholder3: "Inserisci l'importo minimo della transazione desiderato",
  cadplaceholder4: "Inserisci l'importo massimo della transazione desiderato",
  cadplaceholder5:
    'Puoi inserire i tuoi requisiti speciali nelle informazioni del commento, ad esempio: requisiti per gli acquirenti, tempo online, ecc.',
  cadplaceholder6: 'Inserisci il prezzo della transazione',
  cadplaceholder7:
    "Dopo aver ricevuto l'ordine, rispondi automaticamente alle informazioni dell'acquirente, come metodo di pagamento, numero di conto di pagamento, ecc.",
  cadplaceholder8: 'Inserisci la quantità che desideri acquistare',
  caderr1: "Inserisci l'importo minimo della transazione",
  caderr2:
    "L'importo minimo della transazione deve essere maggiore o uguale a 100!",
  caderr3:
    "L'importo minimo della transazione deve essere inferiore all'importo massimo della transazione",
  caderr4: "Inserisci l'importo massimo della transazione!",
  caderr5: 'Inserisci un numero intero',
  caderr6:
    "L'importo massimo della transazione deve essere maggiore dell'importo minimo della transazione!",
  caderr7:
    "L'importo massimo della transazione non può superare l'importo totale delle vendite",
  caderr8: 'Seleziona un metodo di transazione',
  submit: 'invia',
  cadnew:
    "Dopo l'attivazione, il prezzo della tua valuta non oscillerà con il mercato e il prezzo rimarrà invariato.",
  dhTxt: 'Scambia con un clic e goditi i vantaggi',
  dhTxt2: 'limite di prezzo',
  dhTxt3: 'vendere',
  dhTxt4: 'Acquistare',
  dhTxt5: 'massimo',
  dhTxt6: "Inserire l'importo",
  dhplaceholder: 'Saldo disponibile del portafoglio spot',
  dhSuccess: 'Congratulazioni! ',
  crTxt: 'Commercio di valuta fiat',
  crTxt2: 'Acquisto e vendita convenienti, sicuri e veloci di valute digitali',
  crTxt3: 'Regolazione dinamica',
  crTxt4: 'Nessuna commissione di gestione',
  crTxt5: 'Transazione istantanea',
  crTxt6: 'Garanzia della piattaforma',
  crTxt7: 'Fluttuano in tempo reale in base ai prezzi di mercato',
  crTxt8:
    'Ciò che gli utenti vedono è ciò che ottengono e non sono previste commissioni per la piattaforma oltre al prezzo di acquisto e vendita.',
  crTxt9:
    "Presentazione di una piattaforma per servire i commercianti, abbinamento intelligente e ordini di transazione senza attendere l'abbinamento",
  crTxt10:
    'Commercianti certificati sulla piattaforma, servizio clienti sicuro e protetto 24 ore su 24 per proteggere le transazioni',
  crth1: 'Mercante',
  crth2: 'Numero di transazioni',
  crth3: 'metodo di pagamento',
  crth4: 'limite',
  crth5: 'prezzo unitario',
  crbtn: 'Acquistare',
  crbtn2: 'Diventa un commerciante',
  cr1Txt: 'Congratulazioni! ',
  cr1Txt2: 'preparare i materiali',
  cr1Txt3: 'Invia recensione',
  cr1Txt4: 'verificato',
  cr1Txt5: 'Ti vengono concesse le seguenti autorizzazioni',
  cr1Txt6: 'Stand esclusivo',
  cr1Txt7: 'Servizio uno a uno',
  cr1Txt8:
    'I commercianti godono di stand pubblicitari esclusivi per aumentare i tassi di successo delle transazioni',
  cr1Txt9: 'Commissioni più basse',
  cr1btn: 'Inserisci un annuncio',
  cr1btn2: 'Richiedere la resa',
  reTxt: 'Alcex Financial Management ● Soluzione di investimento unica',
  reTxt2: 'Guadagna denaro istantaneamente, facile e sicuro',
  reTab: 'Gestione finanziaria regolare',
  reTab2: 'Gestione finanziaria degli investimenti fissi',
  reTxt3: 'regolare',
  reTxt4: 'Personalizzazione flessibile, profitti potenziali più elevati',
  reTxt6: 'investimento fisso',
  reTxt7:
    'Inizia a utilizzare gli investimenti fissi e fai crescere il tuo patrimonio',
  reTxt8: 'Espandi tutti i 18 prodotti normali',
  reth1: 'tasso di rendimento annualizzato',
  reth2: 'Scadenza (giorni)',
  reth3: 'Limite di transazione',
  reth4: 'Prodotto',
  reth5: 'rendimento storico dell’investimento',
  rebtn: 'Crea piano',
  redTxt: 'Creare',
  redTxt2: 'Piano di investimenti fissi',
  redTxt3: "Inserisci l'importo dell'investimento",
  redTxt4: 'Il mio saldo disponibile è',
  redTxt5: 'Disponibile',
  redTxt6: 'periodo del ciclo',
  redTxt7: 'ogni giorno',
  redTxt8: 'settimanalmente',
  redTxt9: 'al mese',
  redTxt10: 'ora locale',
  redTxt11: 'Il tuo primo ciclo di investimento automatico sarà',
  redTxt12: 'inizio',
  confirm: 'Confermare',
  confirm2: 'Sicuro',
  cancel: 'Annulla',
  redongoing: 'in corso',
  redcompleted: 'completato',
  minNumErr: "Meno dell'importo minimo iniziale",
  minNumErr2: "L'importo iniziale non può essere 0",
  buyamounttip: "Inserisci l'importo dell'acquisto",
  pricetipwarning: "L'importo della transazione non può essere superiore a",
  buyamounttipwarning: 'La quantità di acquisto non può essere superiore a',
  sellamounttip: "Inserisci l'importo della vendita",
  sellamounttipwarning: 'La quantità di vendita non può essere superiore a',
  sellpricetip: 'Inserisci il prezzo di vendita',
  sellpricetipwarning: 'Il prezzo di vendita non può essere superiore a',
  sellmore: 'Il massimo che può essere venduto',
  sellunit: 'individuale',
  loginFirst: 'per favore fai prima il log in',
  betsuccess: 'Congratulazioni! ',
  selectAmount: "Seleziona l'importo della scommessa",
  balancenotenough: 'Saldo disponibile insufficiente!',
  balancenotenough2: 'Equilibrio insufficiente!',
  minlimitamountfailed:
    "L'importo del rimborso non può essere inferiore all'importo minimo del rimborso!",
  maxlimitamountfailed:
    'La quantità di rimborso non può essere superiore alla quantità massima di acquisto!',
  limittimesfailed:
    'Il numero di volte in cui hai partecipato supera il numero massimo di acquisti personali!',
  commitfailed: "L'invio per partecipare non è riuscito!",
  trplaceholder: 'Chiedere informazioni',
  trplaceholder2: 'ultimo prezzo',
  trplaceholder3: 'Acquista al miglior prezzo sul mercato',
  trplaceholder4: 'Vendi al miglior prezzo sul mercato',
  triggleplaceholder:
    "Il prezzo dell'ordine predefinito è il prezzo di mercato",
  triggerpriceshouldbigger:
    'Il prezzo di attivazione deve essere maggiore di 0',
  marginModeSuccessTip:
    "La modalità dell'account del contratto è stata modificata correttamente",
  noenoughbalance: 'Margine del conto insufficiente',
  pleaseinputopenvolume: 'La quantità di apertura deve essere maggiore di 0',
  pleaseinputcorrectopenvolume: 'Inserisci la quantità di apertura corretta',
  pleaseinputrightentrustprice: "Inserisci il prezzo dell'ordine corretto",
  noenoughposition: 'Posizioni liquidabili insufficienti',
  pleaseinputclosevolume: 'La quantità di chiusura deve essere maggiore di 0',
  pleaseinputcorrectclosevolume: 'Inserisci la quantità di chiusura corretta',
  option1: 'antenna',
  option2: 'perimetro',
  option3: 'linea mensile',
  trTxt: 'Volume delle transazioni',
  trTxt2: 'prezzo di mercato',
  trTxt3: 'Volume di vendita',
  trSuccess: 'Inserito con successo',
  udTxt: 'Modalità margine',
  udTxt2: 'Adeguare i multipli del contratto',
  udTxt3: 'leva',
  udTxt4: 'Sostenibile',
  udTxt4_1: 'Sostenibile',
  udTxt5: 'Importo trasferibile',
  udTxt6: 'Quantità di trasferimento',
  udTxt7: 'Trasferisci tutto',
  udTxt8: 'conto perpetuo',
  ubtn: 'Magazzino pieno',
  ubtn2: 'Posizione isolata',
  uplaceholder: 'Conto a contratto perpetuo',
  uradio1: 'condivisione del tempo',
  uradio2: '15 minuti',
  uradio3: '1 ora',
  uradio4: '4 ore',
  uradio5: '1 giorno',
  uth1: 'contratto multiplo',
  uth2: 'reddito',
  uth3: 'tasso di rendimento',
  uth4: 'Interesse aperto',
  uth5: 'Può essere misurato in modo uniforme',
  uth6: 'prezzo medio di apertura',
  uth7: 'Parità forte',
  uth8: 'Margine attuale',
  uth9: 'tasso di margine',
  zhang: 'aprire',
  or: 'O',
  ureg: 'Iscriviti ora',
  uTxt8: 'Commercio',
  uTab: 'Apri una posizione',
  uTab2: 'Posizione chiusa',
  uTxt9: 'Può aprirsi a lungo',
  uTxt10: 'Può essere aperto brevemente',
  uTxt11: "Prezzo dell'ordine",
  uplaceholder2: 'Transazione al miglior prezzo sul mercato',
  uTxt12: 'Acquista per aprire a lungo',
  uTxt13: 'vendere allo scoperto',
  closeup: 'comprare allo scoperto',
  closedown: 'Vendi a lungo',
  uTab3: 'Prendi profitto e ferma le perdite',
  uTxt14: 'Il mio conto contrattuale',
  ulabel1: 'Modalità conto',
  ulabel2: 'Entrate totali del conto',
  ulabel3: 'Margine di posizione',
  ulabel4: 'Tasso di utilizzo del fondo',
  ulabel5: 'molti',
  ulabel6: 'nullo',
  uTxt15: 'lungo',
  uTxt16: 'corto',
  total: 'totale',
  limited_price: 'ordine limite',
  market_price: 'ordine di mercato',
  spot_price: 'Prendi profitto e ferma le perdite',
  ent_status1: 'Su commissione',
  ent_status2: 'Revocato',
  ent_status3: 'La delega è fallita',
  ent_status4: 'Delega riuscita',
  ent_statusblast: 'Liquidazione',
  uTxt17: 'Le posizioni lunghe possono essere chiuse',
  uTxt18: 'La posizione corta può essere chiusa',
  prev: 'Precedente',
  next: 'Prossimo',
  prtxt: 'Il mio link di invito',
  copy: 'copia',
  prtxt2: 'Il mio amico di primo livello',
  prtxt3: 'I miei amici secondari',
  prtxt4: 'Commissioni attive (equivalenti a USDT)',
  prtxt5: 'Premi aggiuntivi',
  prtxt6: 'Livello di partenariato',
  prtit: 'Dettagli della regola',
  prdesc: `Il [Partner di promozione] di Alcex è un'attività di promozione con il rapporto di sconto sulle commissioni più alto e la durata di sconto più lunga (sconto massimo a vita) sull'intera rete, consentendo ai promotori che si impegnano in modo corrispondente di diventare un vero "partner" della piattaforma e godersi il dividendi della crescita insieme alla piattaforma di trading Alcex! `,
  prli1:
    '1. Al fine di promuovere il concetto di risorse digitali ed espandere la portata degli utenti, Alcex ha lanciato il programma [Promotion Partner], che è efficace da molto tempo.',
  prli2:
    "2. L'invito degli amici è diviso in due livelli: se A invita B, allora B è l'amico di primo livello di A. Se B invita C, allora C è l'amico di primo livello di B e anche l'amico di secondo livello di A.",
  prli3:
    "3. Quando ti registri come amico della promozione, devi utilizzare il collegamento fornito dall'invitante o inserire manualmente il codice di invito dell'invitante per diventare l'amico di primo livello dell'invitante.",
  prli4:
    "4. L'incentivo del rimborso può avere effetto solo dopo che l'invitato si registra con il codice di invito dell'invitante e completa l'autenticazione del nome reale.",
  prli5:
    "5. Il periodo di sconto è di N mesi dal momento in cui l'invitato completa l'autenticazione del nome reale. A seconda del livello, il rapporto di sconto è diverso.",
  prli6:
    "6. La risorsa dello sconto promozionale è la commissione di transazione della valuta dell'amico subordinato e la valuta dello sconto sono le tre principali valute di base del mercato, vale a dire: USDT, BTC ed ETH.",
  prli7:
    '7. Il rapporto di sconto della promozione e la durata dello sconto sono correlati positivamente al numero di amici di primo livello promossi. Più persone promuovi, maggiore è il rapporto di sconto di cui puoi usufruire. ',
  prli8:
    '8. I primi 10 o i primi 100 nella classifica della commissione di promozione riceveranno di volta in volta ulteriori premi ufficialmente emessi da Alcex.',
  prli9:
    '9. Se altre attività sono in conflitto con le attività di [Partner di Promozione], modifiche temporanee verranno decise previa consultazione. Ci auguriamo nella vostra comprensione da parte dei partner.',
  prli10:
    '10. I partner di livello L4 (Governatore), L5 (Prefetto) e L6 (Jiedushi) godono di dividendi rispettivamente del 5%, 10% e 15% dello sconto totale sulle commissioni annuali.',
  prli11:
    '11. Il diritto di interpretazione finale di questa attività appartiene a Alcex.',
  prth1: 'livello',
  prth2: 'Numero di amici di primo livello',
  prth3: 'Rapporto sconto amico di primo livello/durata dello sconto',
  prth4: 'Rapporto/durata del rimborso amico di secondo livello',
  prth5: 'Dividendi dei partner',
  prlev1: 'Li Zheng',
  prlev2: 'Magistrato di contea',
  prlev3: 'prefetto',
  prlev4: 'Governatore',
  prlev5: 'Prefetto',
  prlev6: 'Jiedushi',
  prtd1: 'mesi',
  prtd2: 'Sconto a vita',
  prtit2: 'Per esempio',
  prtxt7:
    "Se l'utente Xiao Yan invita 100 amici di primo livello e ciascuno dei suoi amici di primo livello invita 5 amici di secondo livello, Xiao Yan avrà 100 amici di primo livello e 500 amici di secondo livello. ",
  prtxt8:
    "Se l'utente Xiao Yan invita 1.000 amici di primo livello e ciascuno dei suoi amici di primo livello invita 5 amici di secondo livello, Xiao Yan avrà 1.000 amici di primo livello e 5.000 amici di secondo livello. ",
  prtit3: 'Cassetta degli attrezzi per la promozione',
  prtit4: 'Primi 20 sconti promozionali totali (elenco totale)',
  prtit5: 'Primi 20 promotori (elenco totale)',
  prtxt9: 'Scadenza statistiche:',
  prth6: 'classifica',
  prth7: 'membro',
  prth8: 'Numero della promozione',
  prth9: 'Sconto (equivalente a USDT)',
  prth10: 'Premi aggiuntivi',
  prtip1:
    'I dati sulle commissioni sopra indicati non vengono aggiornati in tempo reale e il sistema li conteggia e li aggiorna ogni 24 ore.',
  prtip2:
    "Il numero di invitati corrisponde al numero di amici di primo livello e questo elenco rappresenta la classifica dell'importo totale del rimborso",
  prtip3:
    'Il numero di invitati è il numero di amici di primo livello e questo elenco è la classifica del numero di invitati di primo livello.',
  recth1: 'Orario di arrivo',
  recth2: 'Protocollo di rete',
  recth3: 'Indirizzo di deposito',
  recth4: 'Quantità di ricarica',
  recbtn: 'Ricarica codice prelievo monete',
  recplaceholder: 'Inserisci il codice di prelievo',
  recstatus_0: 'fallire',
  recstatus_1: 'Non ricevuto',
  recstatus_2: 'Già arrivato',
  wittit: 'Prelievo di valuta digitale',
  wittext: 'Registro dei prelievi',
  wittext2: 'Codice di prelievo',
  wittext3: 'Elenco codici prelievo monete',
  witbtn: 'Preleva codice moneta Preleva moneta',
  witbtn2: 'Genera codice di prelievo valuta',
  witdia: 'verifica della sicurezza',
  witdia2: 'Codice di prelievo generato correttamente',
  witplaceholder: "Inserisci l'importo del prelievo",
  witerr: 'Seleziona la valuta',
  addresstip: "Si prega di inserire l'indirizzo",
  witstate: 'Da riscattare',
  witstate2: 'fallire',
  witstate3: 'Riscatto completato',
  witstatus_1: 'in corso di revisione',
  witstatus_2: 'Trasferimento',
  witstatus_3: 'fallire',
  witstatus_4: 'successo',
  type1: 'trasferimento',
  type2: 'Commercio di criptovaluta',
  type3: 'Acquisto di valuta fiat',
  type4: 'Vendere valuta fiat',
  type5: 'Premio Attività',
  type6: 'Premi promozionali',
  type7: 'dividendo',
  type8: 'votazione',
  type9: 'Ricarica manuale',
  type10: 'paio',
  type11: "Riscatto dell'evento",
  type12: 'Acquista C2C',
  type13: 'Vendita C2C',
  type14: 'buste rosse',
  type15: 'ricevere la busta rossa',
  type16: 'Preleva codice moneta Preleva moneta',
  type17: 'Preleva il codice moneta e deposita la moneta',
  type18: 'Commissione di gestione del contratto perpetuo',
  type19: 'Profitto del contratto perpetuo',
  type20: 'Perdita perpetua del contratto',
  type21: 'Il contratto di opzioni è fallito',
  type22: 'Commissione di gestione del contratto di opzioni',
  type23: 'Bonus contratto di opzioni',
  type24: 'Sconto contrattuale',
  type25: 'Premi di livello',
  type26: 'Entrate delle commissioni della piattaforma',
  type27: 'Il secondo contratto è fallito',
  type28: 'Secondo bonus contrattuale',
  type29: 'interesse finanziario',
  type30: 'Fondi di spesa',
  type31: 'Ottenere commissioni di finanziamento',
  type32: 'Acquisto investimento fisso',
  type33: 'Vendita investimento fisso',
  type34: 'acquistare regolarmente',
  type35: 'Riscatto regolare',
  type36: 'Trasferimento del contratto basato su monete',
  type37: 'Trasferimento del contratto basato su monete',
  type38: 'Trasferimento del contratto basato su U',
  type39: 'Trasferimento del contratto basato su U',
  type40: 'Trasferimento del secondo contratto',
  type41: 'Cessione del secondo contratto',
  type42: 'Trasferimento di trasferimento di monete',
  type43: 'Trasferimento di monete in uscita',
  lctit: 'conto finanziario',
  lcbtn: 'Trasferimento della gestione finanziaria',
  lcth1: 'Interessi accumulati',
  day: 'cielo',
  lctxt: "Valuta dell'attività",
  lctxt2: 'Valute accettate',
  lctxt3: 'Quantità iniziale',
  lctxt4: 'periodo di selezione',
  year: 'Anno',
  month: 'luna',
  ldtab1: 'Registro della posizione',
  ldtab2: 'Chiusura dei record',
  ldth1: 'Data di inizio guadagno',
  ldth2: 'data di riscatto',
  ldth3: 'stima dei ricavi',
  ldth4: 'reddito',
  attit: 'Modificare il piano di investimenti fissi',
  attxt: 'Quantità di investimento',
  attxt2: 'ciclo di investimenti',
  atth1: 'Data di investimento fissa',
  atth2: "Importo fisso dell'investimento",
  atth3: 'Tasso di transazione',
  success: 'successo',
  cxth1: 'nome',
  cxth2: 'tipo',
  cxth3: 'Quantità di abbonamento',
  cxth4: 'Valute partecipanti',
  cxth5: 'Unità di abbonamento',
  cxth6: 'Stato attuale',
  cxth7: 'Turnover',
  cxth8: 'tempo di creazione',
  cxnav1: 'Ho partecipato',
  cxnav2: 'La mia macchina mineraria',
  cxnav3: 'La mia posizione di blocco',
  cxtit2: "L'elenco delle mie macchine minerarie",
  cxtit3: 'La mia lista di blocco',
  cxkth1: 'Valuta di uscita',
  cxkth2: 'ciclo produttivo',
  cxkth3: 'Tempo di estrazione mineraria',
  cxkth4: 'Tempo estratto',
  cxkth5: 'capacità di base',
  cxkth6: 'Capacità effettiva',
  cxstate1: 'Non distribuito',
  cxstate2: 'Distribuito',
  cxstate3: 'Revocato',
  cxstate4: 'transazione in sospeso',
  cxstate5: 'Sopra',
  cxstate6: 'Da rilasciare',
  cxstate7: 'rilasciando',
  cxcth1: 'Valuta di rilascio',
  cxcth2: 'Blocco totale',
  cxcth3: 'periodo di rilascio totale',
  cxcth4: 'Ciclo rilasciato',
  cxcth5: 'rilascio della base',
  cxcth6: 'rilascio effettivo',
  more: 'Di più',
  abtit: 'Funzionalità della piattaforma',
  abtit2: 'chi siamo',
  abtit3: 'Contattaci',
  abtit4: 'Comunità Alcex',
  abdesc:
    'Alcex è la piattaforma leader a livello mondiale per il trading di asset digitali con tecnologia. È registrata nelle Isole Cayman e il suo team operativo principale ha sede a Hong Kong. ',
  abdesc2:
    'La piattaforma Alcex ha una struttura di transazione professionale a livello finanziario e un motore di transazione di corrispondenza della memoria ad alta concorrenza sviluppato internamente. La piattaforma adotta un sistema di portafoglio con ricarica completamente a freddo + multi-firma + sistema di attacco DDOS ad alta protezione e altre strutture di transazione per garantire la sicurezza del patrimonio dei clienti.',
  abdesc3:
    "Per consentire ai clienti di cogliere al meglio le opportunità di investimento, Alcex adotta una gestione flat all'interno del servizio clienti e stabilisce un meccanismo di risposta del servizio estremamente rapido.Il responsabile del servizio clienti asset è online 7 ore su 7, 24 ore su 24, tutto l'anno per fornire ai clienti servizi come quelli asset ricarica e prelievo, garantendo che i clienti possano rispondere entro 5 minuti.Completare il deposito e il prelievo del bene.",
  abdesc4:
    'Alcex seleziona rigorosamente progetti di alta qualità e fornisce servizi di custodia patrimoniale sicuri e stabili. ',
  abdesc5:
    "Sebbene le risorse digitali siano ormai solo una piccola cerchia, crediamo che questo tipo di risorsa, che tutti possono controllare completamente, diventerà sicuramente popolare in futuro. Lavoriamo insieme e non vediamo l'ora!",
  abdesc6: 'assistenza clienti',
  abdesc7: 'Supporto tecnico',
  abdesc8: "Applicazione per l'elenco delle monete",
  abdesc9: 'Suggerimenti',
  abdesc10: 'Cooperazione commerciale',
  abdesc11: 'WeChat',
  abdesc12: 'Weibo',
  abdesc13: 'Twitter',
  abdesc14: 'Uso della moneta',
  abdesc15: 'telegrafo',
  abdesc16:
    'Aggiungi "bmate601" come amico WeChat ed entra nella comunità del welfare WeChat',
  realtxt: 'nome vero e proprio',
  realtxt2: 'Numero di identificazione',
  realtxt3: "Foto frontale della carta d'identità",
  realtxt4: "Foto sul retro della carta d'identità",
  realtxt5: "Foto di una carta d'identità in mano e firma personale",
  realtxt6:
    '1. Sono supportati i formati JPG e PNG e la dimensione del file è inferiore a 8 MB; le informazioni sul certificato sono chiaramente visibili e non è consentita alcuna modifica o blocco. Il numero e il nome del certificato devono essere chiaramente visibili.',
  realtxt7:
    '2. La foto dovrà essere scattata senza cappello né trucco, i tratti del viso della persona in possesso del documento dovranno essere ben visibili e le braccia dovranno essere completamente scoperte.',
  realtxt8:
    '3. È necessario conservare contemporaneamente il certificato e la copia scritta a mano (nome + data della domanda). Tutte le mie azioni su questo sito sono condotte da me, sono consapevole dei relativi rischi e sono disposto a sopportare tutte le conseguenze legali di questo conto. ',
  realbtn: 'Fare clic per caricare',
  realstate: 'Non è il vero nome',
  realstate2: 'Già vero nome',
  uploadimg: "Carica una foto frontale della tua carta d'identità",
  uploadimg2: "Carica una foto del retro della tua carta d'identità",
  uploadimg3: "Per favore carica una foto della tua carta d'identità",
  uploaderr: "La dimensione dell'immagine caricata non può superare",
  save_success: 'Salvato con successo!',
  save_failure: 'Salvataggio fallito!',
  close: 'chiusura',
  hztxt: 'Conferma trasferimento',
  submittip1:
    "Per la sicurezza dei tuoi fondi, esegui prima l'[autenticazione del nome reale]!",
  submittip2:
    'Per la sicurezza dei tuoi fondi, esegui prima il [binding del telefono cellulare]!',
  submittip3:
    'Per la sicurezza dei tuoi fondi, imposta prima una [password fondo]!',
  submittip4: 'Associa almeno un metodo di pagamento',
  aderr1: 'Inserisci il numero corretto',
  aderr2: 'Il valore del premio è 0-20',
  aderr3: 'Il valore del premio è 0-20 e non può essere 0',
  aderr4: 'Inserisci il prezzo fisso corretto',
  aderr5:
    "Inserisci il numero corretto e l'importo massimo della transazione non supera 100 monete",
  orderTypeNo: 'NO',
  orderTypeYes: 'SÌ',
  win: 'successo',
  lose: 'fallire',
  tied: 'disegno',
  cancelsuccess: 'Cancellazione avvenuta con successo!',
  shelves:
    'Gli annunci possono essere cancellati solo dopo essere stati rimossi!',
  shelves2:
    "La modifica può essere eseguita solo dopo aver rimosso l'annuncio!",
  business: 'Richiedi prima la certificazione commerciante!',
  swappleaseselectwallet:
    'Seleziona prima il conto di trasferimento/trasferimento',
  swappleaseinputamount: "Inserisci l'importo del bonifico",
  clickchange: 'Fare clic per cambiare la direzione dello scorrimento',
  daterange: 'Seleziona un intervallo di date di ricerca',
  apierr: "Inserisci le informazioni sull'osservazione",
  apierr2: "Inserisci l'indirizzo IP",
  apierr3: "Inserisci l'indirizzo IP corretto",
  apierr4: 'inserisci il codice di verifica',
  clickget: 'Fare clic per ottenere',
  apisecond: 'Secondo',
  apitips:
    'La chiave verrà visualizzata solo quando verrà aggiunta, salvala in tempo',
  apiaccess: 'chiave',
  do_favorite: 'raccolto',
  cancel_favorite: 'Cancella i preferiti',
  fail_favorite: 'fallire',
  deptotal: 'somma totale',
  chart: 'grafico',
  book: 'portafoglio ordini',
  trade: 'ultima transazione',
  pt_title: 'Carta regalo in valuta digitale',
  pt_desc:
    'Dopo il riscatto, diventerai automaticamente un amico di livello inferiore e potrai inviare regali ad amici, parenti, clienti e partner. ',
  pt_more: 'Altri strumenti, restate sintonizzati',
  pt_tips:
    'Se hai una buona idea, invia una mail a Promotion@al-cex.com e sarai premiato se adottata!',
  pt_card_amount: 'Importo nominale della carta',
  pt_card_deadline: 'Periodo di validità',
  pt_card_noend: 'indefinitamente',
  pt_card_btn:
    'I partner promozionali ricevono 30 biglietti gratis (circa 2.000 yuan)',
  pt_card_btn_login: 'Accedi e ricevi 30 biglietti gratis',
  pt_card_rule: 'Dettagli della regola',
  pt_card_summary:
    'Le carte regalo in valuta digitale sono uno degli strumenti di promozione sviluppati per consentire ai partner di promozione di invitare meglio gli amici offline. Quando gli utenti target della promozione riscattano le carte regalo, diventeranno automaticamente amici offline del promotore.',
  pt_card_rule1: '1. Ogni utente ha un limite di 30 carte regalo gratuite. ',
  pt_card_rule2: '2. Ogni utente ha a disposizione 1 carta regalo gratuita. ',
  pt_card_rule3: `3. Prima che l'utente riscatti la carta regalo, se non è presente alcun "invitante" (ovvero non è stato inserito alcun codice di invito durante la registrazione dell'account), l'utente verrà automaticamente associato come amico di primo livello dell'emittente della carta. `,
  pt_card_rule4:
    "4. Al fine di prevenire transazioni fraudolente, l'importo del conto della carta regalo non viene riscattato in tempo reale e l'autenticazione del nome reale deve essere completata dopo la ricezione prima dell'operazione.",
  pt_card_rule5:
    "5. È necessario completare l'autenticazione del nome reale quando si ricevono buoni regalo gratuiti. Non è necessario completare l'autenticazione del nome reale quando si riscattano. Questo per facilitare i promotori a ottenere amici di livello inferiore più velocemente.",
  pt_card_rule6:
    '6. Il diritto di interpretazione finale della carta regalo gratuita appartiene a Alcex.',
  pt_card_day: 'cielo',
  pt_card_title_tips: 'Ricevi una sovvenzione promozionale di 2.000 yuan',
  pt_invite_desc:
    "Codice QR esclusivo, puoi diventare automaticamente un amico subordinato tramite la registrazione del codice QR, adatto per l'inoltro in vari gruppi o forum.",
  saveimage: 'salva immagine',
  imagetips:
    "Se non riesci a scaricare l'immagine normalmente, utilizza uno strumento screenshot per acquisire uno screenshot e salvarlo!",
  pt_card_receivew_success: 'Congratulazioni! ',
  usepromotion: 'Utilizza materiale promozionale',
  context_title: 'Codice di riscatto esclusivo del partner promozionale',
  context_title1: 'Scansiona per saperne di più',
  context_title2: 'il mio codice invito',
  context_title3: "Immagine dell'invito",
  pt_card_title: 'Carta di promozione del partner',
  copysuccess: 'Copia con successo!',
  copyerr: 'Copia fallita! ',
  noopening: 'Al momento non ci sono contratti di opzione pendenti',
  createSuccess: 'Creato con successo',
  tradewarning1: 'Inserisci fino a 2 cifre decimali',
  tradewarning2: "L'importo dell'ordine è",
  tradewarning3: 'Inserisci fino a 8 cifre decimali',
  tradewarning4: "La quantità dell'ordine è",
  tradeconfirmbuyin: "Conferma l'acquisto",
  tradeconfirmsellout: 'Conferma la vendita',
  tradeinfo: {
    emaildone: 'E-mail certificata',
    emailundo: 'E-mail non autenticata',
    teldone: 'Il numero di cellulare è stato verificato',
    telundo: 'Numero di cellulare non verificato',
    idcarddone: "La carta d'identità è stata verificata",
    idcardundo: "Carta d'identità non autenticata",
    exchangetimes: 'Numero di transazioni',
    price: 'prezzo',
    num: 'quantità',
    paymethod: 'metodo di pagamento',
    exchangelimitamount: 'Limite di transazione',
    location: 'posizione',
    location_text: 'Cina',
    exchangeperiod: 'Periodo della transazione',
    minute: 'minuto',
    amounttip: "Inserisci l'importo",
    numtip: 'Inserisci la quantità',
    remarktip: 'digli la tua richiesta',
    remarktitle: 'Osservazioni',
    exchangetitle: 'Istruzioni di trading',
    exchange_tip1:
      'Dopo aver avviato una richiesta di transazione, la valuta digitale viene bloccata nel deposito a garanzia e protetta dalla piattaforma. ',
    exchange_tip2:
      'Prima di fare trading, leggi i "Termini del servizio di rete della piattaforma" e i documenti di aiuto come le domande frequenti e le guide alle transazioni.',
    exchange_tip3: 'Attenzione ai truffatori! ',
    exchange_tip4:
      "Tieni presente che gli arrotondamenti e le fluttuazioni dei prezzi possono influenzare l'importo finale della valuta digitale scambiata. ",
    exchange_tip5:
      'I servizi di deposito a garanzia proteggono acquirenti e venditori di transazioni online. ',
    warning1: 'Inserisci fino a 2 cifre decimali',
    warning2: "L'importo dell'ordine è",
    warning3: 'Inserisci fino a 8 cifre decimali',
    warning4: "La quantità dell'ordine è",
    confirmbuyin: "Conferma l'acquisto",
    confirmsellout: 'Conferma la vendita',
    buyin: 'Acquistare',
    sellout: 'vendere',
    warning5: "Si prega di compilare l'ordine come richiesto",
  },
  convertResult:'Anteprima risultato della conversione',
  willGet:'Otterrai',
  pleaseCommit: 'Fare clic per confermare entro questo tempo. ',
  returnText:'ritorno',
  convert:'convertire'
};
