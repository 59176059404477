export default {
	cloud: 'Cloud Mining',
	cloudDesc: 'Mining farm management experts, one-click purchase to experience daily profit! After purchase, support redemption and refund, providing users with maximum rights protection.',
	clouding: 'Available Hashrate',
	cloudOrder: 'My Orders',
	cloudMoney: 'Earnings Records',
	noCloud: 'No Orders',
	// ---------------
	hasMoney: 'Dividend amount distributed',
	allMoney: 'Total project revenue',
	totalAmount24: 'Total 24-hour transaction volume',
	dayUser: 'Daily active trading users',
	monthUp: 'New VIP in the current month',
	todayUp: 'Today\'s new VIP',
	allVip: 'Total VIP count',
	noNotice: 'No system announcement',
	activeStart: 'Event Start',
	join: 'Participate',
	activeEnd: 'Event End',
	get: 'snapped up',
	totalMoney: 'Total project revenue',
	totalHas: 'Total project dividends',
	totalMonth: 'This month\'s dividends',
	personalTotal: 'Maximum personal income',
	personReceive: 'Per capita income',
	activeReceive: 'Event profit ranking\n',
	hasactiveReceive: 'Dividend profit ranking',
	now: 'Today',
	week: 'This week',
	month: 'This month',
	nodata: 'No data ',
	all: 'Accumulated profit ranking',
	exchangeGet: 'Trading Mining\n',
	getAmount: 'Transaction is mining, top project tokens are mined for free',
	percent7Amount: 'Random Free Order\n',
	percent7Amountget: 'Random discount for trading orders',
	random: '30% off rush purchase',
	randomGet: '30% off sale of mainstream tokens, earn 30% immediately',
	hash: 'Hash Scramble Rewards',
	hashGet: 'Millions of bonuses are sent every day, fair, open and traceable',
	noMoney: 'Fee reduction\n',
	noMoneyGet: 'Permanent reduction of transaction fees',
	ID: 'Ido Investment Limit',
	IDGet: 'Get The Top Project Ido Investment Limit',
	AFA: 'AFA Fund',
	AFAGet: 'Alpha-Cex members will receive a share of the income of the AFA fund',
	gudong: 'Shareholder dividend\n',
	gudongGet: 'Invite to register as an Alpha - Dao shareholder, dividend income smart contract distribution, fair and just',
	allDown: 'free trading at anytime and anywhere',
	scandownload: 'Scan the QR code to download the app',
	startRegister: 'Register to start trading now!！',
	nowRegister: 'Sign up now',
	// ------------------
	exchange: "Exchange",
	copyright: 'Copyright',
	earnDesc: 'Simple, secure, and earn high returns immediately',
	Register: 'Register',
	emailLogin: 'Email Login',
	Address: 'Address',
	Enter: 'Enter',
	IAgreeAndRead: 'Enter',
	PrivacyPolicy: 'Privacy Policy',
	And: 'And',
	noAccount: 'No account',
	BuyCryptocurrencyQuickly: "Buy Cryptocurrency Quickly",
	startJoin: "Start your journey to the world's leading blockchain asset trading platform immediately",
	markets: 'Markets',
	CloudMining: 'CloudMining',
	Activities: 'Activities',
	// -------------------- old----------------------------------
	testText1: 'This is a testing station! To purchase a commercial system, please contact Telegram:@Alcexhevin ',
	testText2: '@Alcexcom',
	testText3: '@bzengineer (Click@jump the only channel, beware of being cheated)',
	meun1: 'Home',
	meun2: 'Buy Crypto',
	meun3: 'Trade',
	meun3_1: 'Spot',
	meun3_2: 'Convert',
	meun4: 'Derivatives',
	meun4_1: 'USDⓈ-M Futures',
	meun4_2: 'COIN-M Futures',
	meun4_3: 'Options Contract',
	meun4_4: 'Second Futures',
	meun5: 'Finance',
	meun5_1: 'Alcex Lab',
	meun5_2: 'Promoting Partner',
	meun6: 'Earn',
	meun7: 'Announcement',
	meun8: 'Log In',
	meun9: 'Sign Up',
	meun10: 'Wallet',
	meun10_1: 'Fiat and Spot',
	meun10_2: 'USDⓈ-M Futures Account',
	meun10_3: 'COIN-M Futures Account',
	meun10_4: 'Second-M Futures',
	meun10_5: 'Earn Account',
	meun10_6: 'Transaction History',
	meun11: 'Orders',
	meun11_1: 'Spot Order',
	meun11_2: 'P2P Order',
	meun11_3: 'Earn Order',
	meun11_4: 'Convert History',
	meun11_5: 'Innovate Management',
	meun12: 'Downloads',
	meun13_1: 'Account Management',
	meun13_2: 'Payment',
	meun13_3: 'Promotion Center',
	meun13_4: 'API Management',
	meun13_5: 'Log Out',
	meun14_1: 'Language',
	meun14_2: 'Currency',
	meun14_3: 'Choose language',
	meun14_4: 'Choose currency',
	openTrade: 'Trade on the go. Anywhere, anytime.',
	scan: 'Scan to Download',
	footmeun1: 'Profile',
	footmeun1_1: 'About Us',
	footmeun1_2: 'Join Us',
	footmeun1_3: 'Announcement',
	footmeun1_4: 'White Paper',
	footmeun2: 'Help',
	footmeun2_1: 'Beginners Guide',
	footmeun2_2: 'FAQ',
	footmeun2_3: 'Exchange  Guide',
	footmeun2_4: 'Coins Information',
	footmeun3: 'Conditions',
	footmeun3_1: 'Disclaimer',
	footmeun3_2: 'Privacy Policy',
	footmeun3_3: 'Service Policy',
	footmeun3_4: 'legal Policy',
	footmeun4: 'Contact us',
	footmeun4_1: 'Service Mailbox',
	footmeun4_2: 'Cooperation',
	footmeun4_3: 'Market Application',
	footmeun4_4: 'Report',
	foottext: 'Global high-quality digital asset exchange',
	indexTxt1: 'Welcome',
	indexTxt2: 'We Are Dedicated To Creating A Blockchain Unicorn That Is Consensus-driven And Shared By Global Users',
	indexTxt3: 'HOT',
	// ---------------
	indexTxt99: 'Popular activities',
	indexTxt98: 'Seeking Good Trading Opportunities',
	indexTxt97: 'Project ranking',
	indexTxt96: 'Alpha - Eight major interests of CEX shareholders',
	indexTxt95: 'Partner or Business Partner',
	indexTxt94: 'free trading at anytime and anywhere',
	// -----------------
	indexTxt4: 'Beginners Guide',
	indexTxt5: 'Alcex Official Customer Service',
	indexLabel1: 'Currency Transaction',
	indexVal1: 'Use one digital asset to directly exchange for another',
	indexLabel2: 'Option Contract',
	indexVal2: 'Is a digital asset derivative',
	indexLabel3: 'Contract Transaction',
	indexVal3: 'T+0 two-way trading virtual goods as a barter contract',
	indexLabel4: 'Quick Exchange',
	indexVal4: 'Quickly exchange digital assets',
	indexth: 'Price Trend',
	indexbtn: 'Trade Now',
	indexh1: 'Honest',
	indexh2: 'Fair',
	indexh3: 'Enthusiasm',
	indexh4: 'Open',
	indexh5: 'Security',
	indexh6: '24H Service',
	indexh7: 'Global Exchange',
	indexh8: 'Our mission',
	indexp1: 'www.al-cex.com was founded by a group of early Bitcoin participants and geeks. The core members of the team are from well-known enterprises such as Google, Microsoft, Alibaba, Tencent, etc., with deep R&D strength and rich Internet product operation experience.',
	indexp2: 'www.al-cex.com Located in the blockchain basic service provider, BIZ is committed to providing global users with a high-quality encrypted asset trading platform, adhering to the basic principle of "not doing evil", adhering to honest, fair and enthusiastic service to customers, and welcoming all partners/projects conducive to the fundamental interests of users with an open attitude.',
	indexp3: 'All-round financial wind control system and anti-theft system, hot and cold wallet, multi-signature system to ensure the safety of funds',
	indexp4: '24H manual online audit can protect customers from missing out on the best investment opportunities.',
	indexp5: 'Global Business Service Network Coverage Helps You Invest in Global Encrypted Assets and Transact with Global Users',
	indexp6: 'Our mission is to increase economic freedom in the world，Everyone deserves access to financial services',
	indexp7: 'Download APP',
	scandownload: "Download App",
	// 订单
	ormenu1: 'P2P Order',
	ormenu2: 'My Order',
	ormenu3: 'Launchpad',
	please: 'Please',

	// 登录
	account: 'Alcex Account Login',
	phone: 'Phone',
	email: 'Email',
	sign: 'Log In',
	password: 'Password',
	password2: 'Password',
	password3: 'Forgot password',
	account2: 'Create a Alcex account',
	logErr: 'Please input email or phone number',
	logErr2: 'Please input password',
	logErr3: 'Password length not less than six',
	logSuccess: 'Success',
	// 注册
	account3: 'Create personal account',
	phone1: 'Tel Regist',
	email1: 'Email Regist',
	email2: 'Email Address',
	code: 'Sms verification code',
	code2: 'Send',
	code3: 'Verification code',
	password4: 'Confirm Password',
	invite: 'Invite ID (Optional)',
	agree: 'I have read and agree',
	agreement: 'User Agreement',
	register: 'Sign up',
	regErr: 'Enter verification code',
	confirmpwdtip: 'Enter confirm password',
	confirmpwderr: 'Two passwords not same!',
	//忘记密码
	wjtab: 'Reset tel password',
	wjtab2: 'Reset email password',
	wjlabel: 'New Password',
	wjerr: 'Please input new password',
	// 创新实验室
	labTxt: 'Alcex Lightning Lab',
	labTxt2: 'Open Laboratories for Win-win Cooperation among Investors, Projectors and Exchanges',
	labTab1: 'All',
	labTab2: 'Coming',
	labTab3: 'Ongoing',
	labTab4: 'Distributing',
	labTab5: 'Completed',
	tokendistribute: "Token Distributing",
	activitytype: "Activity Type",
	labStart: 'Start Date',
	labEnd: 'End Date',
	labStartPlaceholder: 'Please select start date',
	labDetail: 'Detail',
	labSpeen: "Progress",
	labNumber: "Total Supply",
	labType1: 'List(FOF)',
	labType2: 'List(Split)',
	labType3: 'PositionDivide',
	labType4: 'FreeBuy',
	labType5: 'MiningBuy',
	labType6: 'Locked',
	baseinfo: 'Activity Info',
	ruledetail: "About Rule",
	releaseType: "Release Type",
	releaseType0: "Equal release",
	releaseType1: "Isometric release",
	lock: "Lock ",
	releaseDetail: "Release Detail",
	lockday: " Days",
	lockweek: " Weeks",
	lockmonth: " Months",
	lockyear: " Years",
	releasePercent: "Release Percent",
	lockFee: "Lock Fee",
	releaseTimes: "Release Times",
	times: "times",
	publishprice: "Price",
	holdcoin: "Hold Coin Symbol",
	limittimes: "Personal Limit Times",
	limitamount: "Personal Limit Amount",
	unlimited: "Unlimited",
	leveloneCount: "Limit Level One Count",
	alreadyamount: "Already Sell Amount",
	leftamount: "Left Amount",
	myalreadyholdamount: "My participation position",
	alreadyholdamount: "Total position of activity participation",
	currentdivided: "My current position will get",
	holdtips: "[HolderDivide] requires lockout until the end of the activity.",
	inputholdamount: "Please input hold amount!",
	pleaseinputamount: "Please input amount!",
	pleaseinputholdamount: "Please input hold amount!",
	inputamount: "Input Amount",
	inputminingamount: "Input Buy Count",
	attend: "Attend",
	attention: "Attention",
	attentiontxt1: "1、Assets will be frozen after submitting for participation in [warehouse partitioning] and [free subscription] type activities until thawing is completed.",
	attentiontxt2: "2、Users may not withdraw their application for participation after participating in the activities of the type of 'Holder Divide' and 'Fress Buy'",
	attentiontxt3: "3、If the activity is cancelled due to the project party and other force majeure factors, the original route shall be returned to the frozen assets.",
	attentiontxt4: "* The right of final interpretation of this activity belongs to the official ownership of Alcex.",
	// 币本位合约账户
	transfer: 'Transfer',
	placeholder: 'Search Coin',
	bith1: 'Futures Trade',
	bith2: 'Account Equity',
	bith3: 'Unrealized PnL',
	bith4: 'Available Margin',
	bith5: 'Used Margin',
	bith6: 'Frozen Margin',
	bith7: 'Futures Account',
	// 现货账户
	btn1: 'Deposit',
	btn2: 'Withdraw',
	btn3: 'Transfer',
	btn4: 'Deposit',
	btn5: 'Withdrawal',
	totalAssets: 'Estimated Total Value of Assets',
	th1: 'Coin',
	th2: 'Available',
	th3: 'Frozen Balance',
	th4: 'Assets to be released',
	th5: 'Action',
	// 充值
	czTxt: "Digital currency recharge",
	czTxt2: "Coin",
	czTxt3: "network",
	czTxt4: "Available",
	czTxt5: "Address",
	czTxt6: "Amount",
	czTxt7: "Received quantity",
	czTxt8: "Swap Fee",
	czTxt9: "Tip",
	czTxt10: "Minimum recharge amount: ",
	czTxt10_1: "recharge less than the minimum amount will not be accounted.",
	czTxt11: "Do not recharge any non-currency assets to the above address, otherwise the assets will not be recovered.",
	czTxt12: "After you recharge to the above address, you need to confirm the entire network node, after 2 network confirmations arrive, after 6 network confirmation can be extracted.",
	czTxt13: "Your top-up address will not change frequently and you can repeat the recharge; if there is any change, we will try to notify you via website announcement or email.",
	czTxt14: "Please be sure to confirm the security of your computer and browser to prevent information from being tampered with or leaked.",
	czTxt15: "Deposit record",
	czTxt16: "n order to ensure the security of funds, when your account security policy changes, password changes, and you withdraw money with a new address, we will manually review the withdrawal. Please wait patiently for the staff to contact us by phone or email.",
	czTxt17: "The minimum withdrawal quantity is：",
	czth1: 'Charge Time',
	czth2: 'From',
	czth3: 'To',
	czth4: 'Amout',
	czth5: 'Rate',
	czth6: 'Exchange Amount',
	placeholder2: 'Please enter',
	placeholder3: 'Please select',
	czEmpty: 'No records found.',
	logintip: 'please log in first',
	cztip: 'You have not applied for a recharge address，Please click the button to get the recharge address',
	czget: 'Get Address',
	czplaceholder: 'Please select an agreement',
	// 秒合约账户
	mAccount: "Second contract account",
	// 钱包历史记录
	btn6: 'Search',
	wtTxt: 'Time',
	wtTxt2: 'Action Type',
	wtth1: 'Trade Date',
	wtth2: 'Type',
	wtth3: 'Coin',
	wtth4: 'Amount',
	wtth5: 'Fee payable',
	wtth6: 'Deduction charge',
	wtth7: 'Service charge',
	wtth8: 'Status',
	// 账户安全
	nav1: 'Security',
	nav2: 'My invite',
	nav3: 'My Cards',
	acTxt: 'Security level',
	acbtn1: 'Not verified',
	acbtn2: 'Enable',
	acbtn3: 'Change',
	acbtn4: 'Certified',
	acbtn5: 'Binded',
	acbtn6: 'Set',
	acbtn7: 'Bindind',
	acbtn8: 'Retry',
	acTxt2: 'Verified',
	acTxt3: 'Email',
	acTxt4: 'Phone',
	acTxt5: 'Login Password',
	acTxt6: 'Fund password',
	acTxt7: 'Google Auth',
	acTxt8: 'Set fund password',
	acTxt9: 'Change fund password',
	acTxt10: 'Retrieve fund password',
	acDesc2: 'To protect your account security, please complete real-name certification before you can trade operations!',
	acDesc3: 'Bind email',
	acDesc4: 'Bind telphone',
	acDesc5: 'Use when logging in to the platform',
	acDesc6: 'When account funds change, you need to verify the funds password first',
	acDesc7: 'Please bind GoogleAuth',
	acDesc8: 'Bound GoogleAuth',
	acDesc9: 'Remove GoogleAuth',
	acTit: 'Mobile phone binding',
	acTit2: 'Email binding',
	acTit3: 'Google binding',
	acTit4: 'Google remove',
	mailtip: 'Enter email',
	emailerr2: 'Email format error,enter again',
	agreementtip: 'Please read and agree to the user agreement',
	acverify: "Audit failed",
	again: "please try again",
	verifiedmsg: 'Please perform real name authentication first',
	// 我的-API
	apiTxt: 'Bind real name account',
	apiTxt2: 'Tips',
	apiTxt3: 'We have provided you with a powerful API, through which you can use market inquiry, automatic trading and other services. See how to use through the API documentation.',
	apiTxt4: 'Each user can create up to 5 sets of API Keys',
	apiTxt5: 'Please do not disclose your API Key to avoid asset loss. For security reasons, it is recommended to bind IP addresses to API keys. Each API key can bind up to 5 Ips. Fill in a single address directly, and separate multiple IP addresses with half commas, such as 192.168.1.1192.168.1.2192.168.1.3',
	apiTxt6: 'API Remarks',
	apiTxt7: 'Authorized IP (the key of the authorized IP address is valid for 90 days)',
	apibtn: 'Create',
	apith1: 'API Remarks',
	apith2: 'Create Time',
	apith3: 'Access Key',
	apith4: 'Bind IP address',
	apith5: 'Remaining validity (days)',
	// 更改登录密码
	chTxt: 'Change Login Password',
	chTxt2: 'Scan Enable',
	chTxt3: 'Please enter verification',
	chTxt4: 'Old Fund password',
	chTxt5: 'New Fund password',
	chTxt6: 'Confirm New Password',
	chTxt7: 'Forgot password',
	chTxt8: 'Change phone Verification',
	chTxt9: 'Old Login Password',
	chTxt10: 'New Login Password',
	chTxt11: 'Email verification code',
	chTxt12: 'Telphone verification code',
	telerr: 'Incorrect phone number',
	emailerr: 'Incorrect email',
	codeerr: 'Incorrect verification code',
	reset: 'Reset',
	save: 'Save',
	chtip: 'Please enter the original password',
	chtip2: 'Please enter no less than 6 new login passwords',
	chtip3: 'New login password is inconsistent',
	chtip4: 'Please enter the correct fund password',
	chtip5: 'Password cannot be less than 6 characters',
	chtip6: 'Please enter no less than 6 passwords',
	chtip7: 'Inconsistent password',
	chtip8: 'Please enter funds password',
	chtip9: 'Please enter your mobile number',
	chtip10: 'Please enter the login password',
	realnametip: 'Please enter your real name',
	idcardtip: 'Please enter the ID number',
	// 收款方式
	clTxt: 'Please set your payment method, please be sure to use my real name account',
	clTxt2: 'Add a payment method',
	edit: 'Edit',
	delete: 'Delete',
	name: 'Name',
	clTxt3: 'ID Number',
	clTxt4: 'IBAN Number',
	clTxt5: 'All Payment',
	clLink1: 'Account',
	clLink2: 'Edit',
	clLink3: 'Payment details(Optional)',
	clplaceholder1: 'Please enter your full name',
	clplaceholder2: 'Please enter your bank account password',
	clplaceholder3: 'Please enter your payment details',
	cltip: 'Tip',
	cltip2: 'Please make sure to add your bank card number for instant payment. Do not include details of other banks or payment methods. You must add payment/receipt information for the selected bank.',
	cltip3: 'Tips: When you sell digital currency, the payment method you choose will be shown to the buyer. Please confirm that the information is correct. Please use a collection account that is consistent with your own KYC identity authentication on the Coin Security platform.',
	clTxt6: 'Optional',
	upload: 'Upload',
	uploadTip: '(Support JPG/JPEG/PNG/BMP format, less than 1MB) ',
	// 我的推广卡
	tgbtn: 'Exchange With Code',
	tgth1: 'Name',
	tgth2: 'Exchange Code',
	tgth3: 'Coin Symbol',
	tgth4: 'Amount',
	tgth5: 'Total',
	tgth6: 'Exchanged',
	tgth7: 'Create Time',
	tgbtn2: 'Material',
	tgbtn3: 'Exchange',
	tgplaceholder: 'Please input the card code',
	exchangesuccess: "Congratulations！You can check the asset in finance center！",
	// 我的邀请
	inth1: 'Invite User',
	inth2: 'Registion Date',
	inth3: 'Real Name',
	// 我的广告
	adTxt: 'My ads',
	adTxt2: 'When the minimum amount of advertising purchases plus the fee is greater than the remaining number of advertisements, the ad is automatically taken off the shelf.',
	adbtn: 'Reset',
	adbtn2: 'Shelf',
	adbtn3: 'Dropoff',
	adth1: 'No',
	adth2: 'Type',
	adth3: 'Limit',
	adth4: 'Remain Amount',
	adtip: 'Are you confirm to delete',
	num_text1: 'Please enter what you want',
	num_text2: 'num',
	// 当前委托
	wTxt: 'Spot',
	wTxt2: 'Open Orders',
	wTxt3: 'Pair',
	wTxt4: 'Side',
	wth1: 'Time',
	wth2: 'Direction',
	wth3: 'Price',
	wth4: 'Have done a deal',
	wth5: 'Turnover',
	wth6: 'Type',
	wth7: 'Trigger Price',
	wth8: 'Traded Price',
	wth9: 'Volume',
	wth10: 'P/L',
	wth11: 'Status',
	wth12: 'Open/Close',
	wth13: 'Margin',
	wbtn: 'Undo',
	wtip: 'Tip',
	wtiptxt: 'Confirm Undo',
	// 赚币
	zbplaceholder: 'Enter the order number',
	zbtab1: 'Auto-Invest',
	zbtab2: 'Auto-Record',
	zbth1: 'Investment Cycle',
	zbth2: 'On/Off',
	zbth3: 'Cumulative investment amoun',
	zbth4: 'Amount held',
	zbth5: 'Average Cost',
	zbth6: 'Unrealized PnL',
	zbth7: 'Next scheduled vote date',
	zbth8: 'Planned creation date',
	// 当前委托-1
	etTxt: 'Announcement',
	etTxt2: 'Scan to share',
	etmenu1: 'Cryptocurrency',
	etmenu2: 'Alcex Server Update Announcement',
	// 当前委托-3
	et2menu1: 'RX.波场简介',
	et2menu2: '比特币是庞氏骗局吗?',
	// 历史委托
	hisTxt: 'Order History',
	// 我的订单
	orTab1: 'Unpaid',
	orTab2: 'Paided',
	orTab3: 'Finished',
	orTab4: 'Canceled',
	orTab5: 'Appealing',
	orth1: 'OrderNo',
	orth2: 'Coin',
	orth3: 'Type',
	orth4: 'TradeName',
	orth5: 'Money',
	// 期权合约
	qcTab1: 'History',
	qcTab2: 'Real-Time Quotes',
	qcTxt: 'No.',
	qcTxt2: '',
	qcTxt3: 'Open Time',
	qcTxt4: 'Close Time',
	qcTxt5: 'Open Price',
	qcTxt6: 'Close Price',
	qcTxt7: 'Buy Reward Pool',
	qcTxt8: 'Sell Reward Pool',
	qcTxt9: 'My Bet Amount',
	qcTxt10: 'Progress',
	qcTxt11: 'Open Price',
	qcTxt12: 'Close Price',
	qcTxt13: 'Time Range',
	qcbtn1: 'Price Up',
	qcbtn2: 'Price Down',
	qcth1: 'No.',
	qcth2: 'Bet Amount',
	qcth3: 'Direction',
	qcth4: 'Result',
	qcth5: 'Reward Amount',
	qcth6: 'Open Fee',
	qcth7: 'Reward Fee',
	// 秒合约
	mplaceholder: ' Enter the coin symbol...',
	mTab: 'Favor',
	mth: 'Favor',
	mth1: 'Last Price',
	mth2: '24h Change',
	mTxt: '24h High',
	mTxt2: '24h Low',
	mTxt3: 'Amount',
	mTxt4: 'Funding',
	mTxt5: 'Open',
	mTxt6: 'High',
	mTxt7: 'Low',
	mTxt8: 'Close',
	echat1: 'KLine',
	echat2: 'Depth',
	mTab2: 'Current position',
	mTab3: 'History Delegate',
	mth3: 'Order Type',
	mth4: 'Entry Price',
	mth5: 'Cycle',
	mth6: 'Close price',
	mth7: 'Earn',
	mTxt9: 'Transaction mode',
	mTxt10: 'Open time',
	mTxt11: 'Profitability',
	mTxt12: 'Balance',
	mTxt13: 'Buy Amount',
	mTxt14: 'Medium',
	// 创建广告
	cadTxt: 'Create a ad Deal',
	cadTxt2: 'If you trade frequently, you can create your own trade ads.',
	cadTxt2_1: 'If you only trade occasionally, we recommend that you search directly ',
	cadTxt2_2: 'Create a trading ad isFree of charge。',
	cadLink2: 'Trading Advertising',
	cadTxt3: 'If you want to edit the created ads directly, please check',
	cadLink3: 'My advertisement',
	cadTxt4: 'Market reference price',
	cadTxt5: 'Premium refers to the percentage higher than the current market price to sell',
	cadTxt6: 'Pricing formula',
	cadTxt7: '【Tip】You can go to the personal center to bind/add a payment method',
	cadTxt8: 'The maximum trading amount cannot exceed your total selling amount 0CNYI',
	cadTxt9: 'After enabling, the user sends you a transaction through this advertisement, and the system will automatically send the automatic reply phrase you selected to the other party.',
	cadTxt10: 'Premium refers to the percentage higher than the current market price to buy',
	cadLabel1: 'I want：',
	cadLabel2: 'Exchange Coin',
	cadLabel3: 'Country：',
	cadLabel4: 'Coin：',
	cadLabel5: 'Open fixed price',
	cadLabel6: 'Premium：',
	cadLabel7: 'Trade price',
	cadLabel8: 'Sell amount：',
	cadLabel9: 'Payment method：',
	cadLabel10: 'Min trade amount：',
	cadLabel11: 'Max trade amount：',
	cadLabel12: 'Enable AutoReply：',
	cadLabel13: 'Fund password：',
	cadLabel14: 'Remarks：',
	cadLabel15: 'Fixed price：',
	cadLabel16: 'AutoReply：',
	cadLabel17: 'Buy amount：',
	radio: 'sell online',
	radio2: 'buy online',
	cadplaceholder: 'Please set your premium',
	cadplaceholder2: 'Please enter what you want sell num',
	cadplaceholder3: 'Please enter the minimum transaction amount you want',
	cadplaceholder4: 'Please enter the maximum transaction amount you want',
	cadplaceholder5: "You can fill in your special requirements in the remarks information, such as: the buyer's requirements, online time and so on.",
	cadplaceholder6: 'Please enter your exchange price',
	cadplaceholder7: 'After receiving the order, the information is automatically returned to the buyer, for example: payment method, collection account number, etc.',
	cadplaceholder8: 'Please enter what you want buy num',
	caderr1: 'Please enter your minimum transaction amount!',
	caderr2: 'The minimum transaction amount must be greater than or equal to 100!',
	caderr3: 'The minimum transaction amount must be less than the maximum transaction amount',
	caderr4: 'Please enter your maximum transaction amount!',
	caderr5: 'Please enter an integer',
	caderr6: 'The maximum transaction amount must be greater than the minimum transaction amount!',
	caderr7: 'The maximum transaction amount cannot exceed your total sale amount',
	caderr8: 'Please select transaction method',
	submit: 'Submit',
	cadnew: 'When enabled, your currency price will not fluctuate with the market and the price will not change.',
	// 快速兑换
	dhTxt: 'One-click ● Profit everyday',
	dhTxt2: 'Limit Price',
	dhTxt3: 'Sell',
	dhTxt4: 'Buy',
	dhTxt5: 'Max',
	dhTxt6: 'Enter an amount',
	dhplaceholder: 'Available',
	dhSuccess: "Congratulations, Exchange successful!",
	// 法币交易
	crTxt: 'OTC Exchange',
	crTxt2: 'Convenient, safe and fast sale of digital currency',
	crTxt3: 'Dynamic Tuning',
	crTxt4: 'No Fee',
	crTxt5: 'Immediate Transaction',
	crTxt6: 'Platform Guarantee',
	crTxt7: 'Real time fluctuation according to market price',
	crTxt8: 'What you see is what you get. Besides the selling price, there is no service charge for the platform',
	crTxt9: 'The introduction of platform services for merchants, intelligent matching, order closing, no need to wait for matchmaking',
	crTxt10: 'Platform certification business, security is guaranteed, 24H customer service for the transaction escort',
	crth1: 'Merchant',
	crth2: 'Volume',
	crth3: 'Pay Method',
	crth4: 'Limit',
	crth5: 'Price',
	crbtn: 'Buy',
	crbtn2: 'Become Merchant',
	// 法币交易1
	cr1Txt: 'congratulations! Your merchant certification audit has passed',
	cr1Txt2: 'Preparation',
	cr1Txt3: 'Submit for review',
	cr1Txt4: 'Certified',
	cr1Txt5: 'You have the following permissions',
	cr1Txt6: 'Exclusive booth',
	cr1Txt7: 'One-to-one service',
	cr1Txt8: 'Businesses enjoy exclusive advertising booths to increase transaction success',
	cr1Txt9: 'Lower fees',
	cr1btn: 'Publish ads',
	cr1btn2: 'Apply for surrender deposit',
	// 定期理财
	reTxt: 'Alcex Earn ● One-stop investment solution',
	reTxt2: 'Simple & Secure、start earning.',
	reTab: 'LockedSavings',
	reTab2: 'AutoInvest',
	reTxt3: 'Regular',
	reTxt4: 'Flexible customization with higher potential income',
	reTxt6: 'Inves',
	reTxt7: 'Start growing your assets on autopilot',
	reTxt8: 'Expand all 18 regular products',
	reth1: 'PL Ratio',
	reth2: 'Duration(Days)',
	reth3: 'Exchange Limit',
	reth4: 'Product',
	reth5: 'Historical ROI',
	rebtn: 'Create a plan',
	redTxt: 'Create a',
	redTxt2: 'Auto-Invest',
	redTxt3: 'Enter the investment amount',
	redTxt4: 'Available Balance',
	redTxt5: 'Avbl',
	redTxt6: 'Recurring Cycle',
	redTxt7: 'Day',
	redTxt8: 'Week',
	redTxt9: 'Month',
	redTxt10: 'Local Time',
	redTxt11: 'Your first automatic investment cycle will begin on',
	redTxt12: 'start',
	confirm: 'Confirm',
	confirm2: 'Confirm',
	cancel: 'Cancel',
	redongoing: "Ongoing",
	redcompleted: "Completed",
	minNumErr: 'Less than the minimum starting amount',
	minNumErr2: 'Starting amount cannot be 0',
	buyamounttip: 'enter buy amount',
	pricetipwarning: 'buy price not more',
	buyamounttipwarning: 'buy amount not more',
	sellamounttip: 'enter sell amount',
	sellamounttipwarning: 'sell amount not more',
	sellpricetip: 'enter sell price',
	sellpricetipwarning: 'sell price not more',
	sellmore: 'sell at most',
	sellunit: '个',
	loginFirst: "Please login",
	betsuccess: "Congratulations！Bet successfull！",
	selectAmount: "Please select your amount",
	balancenotenough: "Balance is not enough！",
	balancenotenough2: "Balance is not enough！",
	minlimitamountfailed: "The quantity of exchange must not be less than the minimum minimum amount of exchange.！",
	maxlimitamountfailed: "The amount of exchange shall not be greater than the maximum purchase amount.！",
	limittimesfailed: "You have participated in more than the maximum number of individual purchases.！",
	commitfailed: "Commit Failed！",
	// 现货交易
	trplaceholder: 'Search',
	trplaceholder2: "Last Price",
	trplaceholder3: 'Buy at the best price in the market',
	trplaceholder4: 'Sell at the best price in the market',
	triggleplaceholder: "The default price is market",
	triggerpriceshouldbigger: "The trigger price should above 0",
	marginModeSuccessTip: "Modify success.",
	noenoughbalance: "Insufficient balance",
	pleaseinputopenvolume: "The volume should above 0",
	pleaseinputcorrectopenvolume: "Please input correct volume",
	pleaseinputrightentrustprice: "Plwase input correct price",
	noenoughposition: "Insufficient position",
	pleaseinputclosevolume: "The volume should above 0",
	pleaseinputcorrectclosevolume: "Please input correct volume",
	option1: '1D',
	option2: '1W',
	option3: '1M',
	trTxt: 'Total',
	trTxt2: 'Market Price',
	trTxt3: 'Sell Amount',
	trSuccess: 'submit success',
	// u本位
	udTxt: 'Margin Mode',
	udTxt2: 'Adjust Leverage',
	udTxt3: 'Margin',
	udTxt4: 'Swap',
	udTxt4_1: 'Perpetual',
	udTxt5: 'Avaliable',
	udTxt6: 'Transfer Amount',
	udTxt7: 'All',
	udTxt8: 'Swap',
	ubtn: 'Cross',
	ubtn2: 'Fixed',
	uplaceholder: 'Perpetual Swap Account',
	uradio1: 'Time',
	uradio2: 'M15',
	uradio3: 'H1',
	uradio4: 'H4',
	uradio5: 'D1',
	uth1: 'Leverage',
	uth2: 'PL',
	uth3: 'ROI',
	uth4: 'Position',
	uth5: 'Ava Position',
	uth6: 'Open Price',
	uth7: 'swap.pos_qp',
	uth8: 'Cur Margin',
	uth9: 'Margin Ratio',
	zhang: 'Cont',
	or: 'or',
	ureg: 'Register Now',
	uTxt8: 'Trade',
	uTab: 'Open',
	uTab2: 'Close',
	uTxt9: 'Max(L)',
	uTxt10: 'Max(S)',
	uTxt11: 'Entrust price',
	uplaceholder2: 'Deal at the best price in the market',
	uTxt12: 'Open Long',
	uTxt13: 'Open Short',
	closeup: "Close Short",
	closedown: "Close Long",
	uTab3: 'Trigger Price',
	uTxt14: 'My Futures Account',
	ulabel1: 'Pattren',
	ulabel2: 'Equity',
	ulabel3: 'Used Margin',
	ulabel4: 'Margin Ratio',
	ulabel5: 'L',
	ulabel6: 'S',
	uTxt15: 'Long',
	uTxt16: 'Short',
	total: 'Total',
	limited_price: 'Limited Price',
	market_price: 'Market Price',
	spot_price: 'Trigger Price',
	ent_status1: "Entrusting",
	ent_status2: "Canceled",
	ent_status3: "Entrust Failed",
	ent_status4: "Traded",
	ent_statusblast: "Blast",
	uTxt17: 'Max. L',
	uTxt18: 'Max. S',
	//
	prev: 'PREV',
	next: 'NEXT',
	// 推广合伙人
	prtxt: 'Promotion Link',
	copy: 'Copy',
	prtxt2: 'Level one friends',
	prtxt3: 'Level two friends',
	prtxt4: 'Commission income(USDT)',
	prtxt5: 'Additional rewards',
	prtxt6: 'Partnership level',
	prtit: 'Rule details',
	prdesc: '[Promotion Partner] is the highest proportion of online Commission return and the longest time of commission return (maximum lifetime commission). Promoters who make corresponding efforts can become real platform ‘partners’ and enjoy the dividend of growing together with Alcex trading platform. Detailed rules are as follows:',
	prli1: '1、In order to preach the concept of digital assets and expand the scale of users, Alcex launched the “Promotion Partner” program, which is effective for a long time.',
	prli2: "2、Inviting friends can be divided into two levels. If A invites B, then B is A's first-level friend. If B invites C, then C belongs to B's first-level friend and also to A's second-level friend.",
	prli3: "3、When promoting the registration of friends, the first-level friends of inviters can only be the first-level friends of inviters by links provided by inviters or by manually entering invitation codes of inviters.",
	prli4: "4、After the invitee registers with the inviter's invitation code and completes the real-name certification, the Commission incentive will take effect.",
	prli5: "5、The time of commission is N months from the beginning of the invitee's real-name certification. According to the different grades, the proportion of commission enjoyed is different.",
	prli6: "6、Promotion of commission assets is the transaction fee of lower-level Friend Currency, which is the three market base currencies, namely USDT, BTC and ETH.",
	prli7: "7、The proportion and duration of promotion commission are positively correlated with the number of first-level friends. The more the number of promotion, the higher the proportion of enjoyable commission. The specific promotion of commission rebate ratio is as follows：",
	prli8: "8、TOP10 or TOP100 will be awarded additional incentives by Alcex from time to time.",
	prli9: "9、If other activities are in conflict with the activities of the Promotion Partner, the Partners will be expected to understand whether to change temporarily after consultation.",
	prli10: "10、L4(Xunfu), L5(Taishou) and L6(Jiedushi) envoy-level partners are entitled to 5%, 10% and 15% bonuses of the total annual Commission returns, respectively.",
	prli11: "11、The right of final interpretation of this activity belongs to Alcex.",
	prth1: 'Level',
	prth2: 'Number of Levlel one',
	prth3: 'Level-One Return Ratio / Return Time	',
	prth4: 'Level-Two Return Ratio / Return Time',
	prth5: 'Partner Dividend',
	prlev1: 'Lizheng',
	prlev2: 'Xianling',
	prlev3: 'Zhifu',
	prlev4: 'Xunfu',
	prlev5: 'Taishou',
	prlev6: 'Jiedushi',
	prtd1: 'Months',
	prtd2: 'Lifetime',
	prtit2: 'For Example',
	prtxt7: "If the user Xiaoyan invited 100 first-class friends, TA's first-class friends invited five second-class friends, then Xiaoyan had 100 first-class friends and 500 second-class friends. If the average person trades 3000 yuan per day, Xiaoyan's monthly income is about (100 * 3000 * 0.001 * 30% + 500 * 3000 * 0.001 * 10%)* 30 = 7200 / month.",
	prtxt8: "If user Xiaoyan invited 1000 first-class friends, TA first-class friends invited 5 second-class friends, Xiaoyan had 1000 first-class friends and 5000 second-class friends. If the average person trades 3000 yuan per day, Xiaoyan's monthly income is about (1000*3000*0.001*50%+5000*3000*0.001*20%)*30=135000/month.",
	prtit3: 'Promotion Tools',
	prtit4: 'Promotion of total CommissionTOP20(All)',
	prtit5: 'Number of PromotionsTOP20(All)',
	prtxt9: 'Last stastic in: ',
	prth6: 'Rank',
	prth7: 'Member',
	prth8: 'Promotion',
	prth9: 'Commission(USDT)',
	prth10: 'Extra Reward',
	prtip1: 'The above data is not updated in real time. The system statistics and updates every 24H.',
	prtip2: 'The number of invitations is the number of first-class friends. This list ranks the total amount of commissions from large to small.',
	prtip3: 'The number of invitations is the number of first-class friends. The list is ranked by the number of first-class friends invited.',
	// 充币
	recth1: 'Arrival time',
	recth2: 'Network protocol',
	recth3: 'Deposit Address',
	recth4: 'Amount',
	recbtn: 'Recharge by code',
	recplaceholder: 'Please enter withdraw code',
	recstatus_0: 'fail',
	recstatus_1: 'Non arrival',
	recstatus_2: 'Received account',
	// 提币
	wittit: 'Digital currency withdraw',
	wittext: 'Withdrawals record',
	wittext2: 'Withdraw Code',
	wittext3: 'WithdrawCode List',
	witbtn: 'Withdraw by code',
	witbtn2: 'Generate WithdrawCode',
	witdia: 'Safety verification',
	witdia2: 'WithdrawCode generated!',
	witplaceholder: 'Enter the number of coins',
	witerr: 'Please select a currency',
	witstate: 'To be exchanged',
	witstate2: 'Fail',
	witstate3: 'Completed',
	witstatus_1: 'Reviewing',
	witstatus_2: 'Transfering',
	witstatus_3: 'Failure',
	witstatus_4: 'Success',
	// 操作类型
	type1: 'TRANSFER_ACCOUNTS',
	type2: 'Exchange',
	type3: 'OTC-Buy',
	type4: 'OTC-Sell',
	type5: 'Activity Award',
	type6: 'Promotion Award',
	type7: 'Dividend',
	type8: 'Vote',
	type9: 'Artificial recharge',
	type10: 'Match',
	type11: 'Activity Buy',
	type12: 'C2C BUY',
	type13: 'C2C SELL',
	type14: 'RED-OUT',
	type15: 'RED-IN',
	type16: 'WithdrawCode Withdraw',
	type17: 'WithdrawCode Recharge',
	type18: 'Swap Fee',
	type19: 'Swap Profit',
	type20: 'Swap Loss',
	type21: 'Option Fail',
	type22: 'Option Fee',
	type23: 'Option Reward',
	type24: 'Contract Reward',
	type25: 'Level Reward',
	type26: 'Platform Fee Reward',
	type27: 'Second contract failed',
	type28: 'Second Contract Reward',
	type29: 'Finance Interest',
	type30: 'Pay Charge Fee',
	type31: 'Get Funding Fee',
	type32: 'AutoInvestBuy',
	type33: 'Scheduled to sell',
	type34: 'Buy Regularly',
	type35: 'Regular redemption',
	type36: 'Currency Standard Contract Transfer In',
	type37: 'Currency Standard Contract Transfer Out',
	type38: 'U-standard contract transfer in',
	type39: 'U-standard contract transfer Out',
	type40: 'Seconds contract transfer in',
	type41: 'Seconds contract transfer Out',
	type42: 'Currency Transfer In',
	type43: 'Currency Transfer Out',
	// 理财账户
	lctit: 'Financial account',
	lcbtn: 'Financial transfer',
	lcth1: 'Accumulated interest',
	lctxt3: 'Starting Quantity',
	lctxt4: 'Select time',
	day: 'Day',
	lctxt: 'Activity Coin Symbol',
	lctxt2: 'activity.acceptcoin',
	year: 'years',
	month: 'month',
	// 理财订单
	ldtab1: "Position record",
	ldtab2: "Closed position record",
	ldth1: "Earning start date",
	ldth2: "Redemption Day",
	ldth3: "Earning Estimate",
	ldth4: "Earning",
	// 定投计划
	attit: "Modify the scheduled investment plan",
	attxt: "Investment amount",
	attxt2: "Investment Cycle",
	atth1: 'Fixed Investment Date',
	atth2: 'Amount of fixed investment',
	atth3: 'Transaction fee',
	success: 'Success',
	// 创新管理
	cxth1: 'Name',
	cxth2: 'Type',
	cxth3: 'Amount',
	cxth4: 'Unit(IN)',
	cxth5: 'Unit(OUT)',
	cxth6: 'Status',
	cxth7: 'Turnover',
	cxth8: 'Create Time',
	cxnav1: 'My Orders',
	cxnav2: 'My Minings',
	cxnav3: 'My Locked',
	cxtit2: 'My Mining List',
	cxtit3: 'My Locked List',
	cxkth1: 'Mining Unit',
	cxkth2: 'Mining Period',
	cxkth3: 'Mining Times',
	cxkth4: 'Mininged Times',
	cxkth5: 'Base Output',
	cxkth6: 'Current Output',
	cxstate1: 'No Deployed',
	cxstate2: 'Deployed',
	cxstate3: 'Canceled',
	cxstate4: 'To be transacted',
	cxstate5: 'Closed',
	cxstate6: 'To be released',
	cxstate7: 'Releasing',
	cxcth1: 'Release Unit',
	cxcth2: 'Total Locked',
	cxcth3: 'Release Times',
	cxcth4: 'Released Times',
	cxcth5: 'Base Output',
	cxcth6: 'Current Output',
	more: 'More',
	// 关于我们
	abtit: 'Features',
	abtit2: 'About us',
	abtit3: 'Contact us',
	abtit4: 'Alcex Community',
	abdesc: "We are building a safe, fair, efficient and transparent cryptocurrency trading platform, and we have the world's leading blockchain trading products. Meta Spark is consistently rated as one of the best places to buy and sell cryptocurrencies online, thanks to our great service, low fees, multiple trading products and strict security standards.",
	abdesc2: "Meta Spark platform has a professional blockchain software system, the world's leading matching transaction engine technology, and the platform adopts a transaction structure such as a full cold recharge wallet system + multi-signature + high-protection DDOS attack system to ensure the safety of customer assets.",
	abdesc3: 'Our vision is to increase the freedom of money globally. We believe that by spreading this freedom, we can significantly improve lives around the world.',
	abdesc4: '',
	abdesc5: '',
	abdesc6: 'Customer Service',
	abdesc7: 'Support',
	abdesc8: 'Board Apply',
	abdesc9: 'Suggestion',
	abdesc10: 'Cooperation',
	abdesc11: 'Wechat',
	abdesc12: 'Weibo',
	abdesc13: 'Twitter',
	abdesc14: 'Biyong',
	abdesc15: 'Telegram',
	abdesc16: "Add 'Alcex' as Wechat friends and then enter Wechat community",
	// 实名认证
	realtxt: 'Actual name',
	realtxt2: 'ID Card',
	realtxt3: 'Upload photo ID front',
	realtxt4: 'Upload ID card reverse photo',
	realtxt5: 'Upload handheld ID photos',
	realtxt6: '1. The file is less than 8M.The information on the document should be clearly visible, no modifications in the document or cover by the foreign objects is allowed, and the document number and name should be visible.',
	realtxt7: '2. The photograph should show you without a hat on. It is recommended that there is no make-up. The facial features of the hand-held document holders should be clear, and the arms should be completely exposed.',
	realtxt8: "3. Need to hold both hand-held documents and handwritten copy (name + application date), 'All my actions on this site are my own operations, and I understand the risks involved and willing to bear all the legal consequences of this account.'",
	realbtn: 'Upload',
	realstate: 'No real name',
	realstate2: 'Real name',
	uploadimg: "Please upload the photo ID card front",
	uploadimg2: "Please upload the photo ID card reverse",
	uploadimg3: "Please upload handheld ID photos",
	uploaderr: "The size of uploaded pictures cannot exceed 8MB!",
	save_success: 'Success!',
	save_failure: 'Failure!',
	// 划转
	close: 'Close',
	hztxt: 'Transfer Now',
	// 商家认证
	submittip1: 'Please proceed with a series of certifications such as real names',
	submittip2: 'Please conduct a series of certifications such as mobile phones',
	submittip3: 'Please proceed with a series of certifications such as capital passwords',
	submittip4: 'Please bind at least one payment method',
	aderr1: 'Please enter the correct number',
	aderr2: 'The overflow value is 0-20',
	aderr3: 'The overflow value is 0-20 and cannot be 0',
	aderr4: 'Please enter the correct fixed price',
	aderr5: 'Please enter the correct number, and the maximum number of transactions does not exceed 100',
	orderTypeNo: "No",
	orderTypeYes: "Yes",
	win: "win",
	lose: "lose",
	tied: "tied",
	cancelsuccess: "Cancel order Successful",
	shelves: 'The advertisement can be deleted only after it is removed from the shelf!',
	shelves2: 'Can be edited and modified after the ad is down',
	business: 'Please apply for merchant authentication first！',
	swappleaseselectwallet: "Please choose a transfer wallet fist.",
	swappleaseinputamount: "Please input transfer amount",
	clickchange: "Click to change direction",
	daterange: "Please select the search date range",
	// API
	apierr: 'please scanner remark',
	apierr2: 'please scanner IP Address',
	apierr3: 'please scanner Correct IP Address',
	apierr4: 'please scanner Correct IP Address',
	clickget: 'Click to get',
	apisecond: 's',
	apitips: "The key is only displayed when it is added, please save it in time.",
	apiaccess: "key",
	// 自定义
	do_favorite: 'Collected',
	cancel_favorite: 'Cancel Collected',
	fail_favorite: 'Fail',
	deptotal: 'Total',
	chart: 'Chart',
	book: 'Order Book',
	trade: 'Trades',
	pt_title: "Cryptocurrency Gift Card",
	pt_desc: "This is a surprise from the future, to send friends, relatives, customers, partners.",
	pt_more: "More tools is coming",
	pt_tips: "If you have a good idea, please send an email to promotion@al-cex.com, with a reward after adoption.！",
	pt_card_amount: "Card Amount",
	pt_card_deadline: "DeadLine",
	pt_card_noend: "Indefinite",
	pt_card_btn: "Get 30 cards for free(≈2000 CNY)",
	pt_card_btn_login: "Please login",
	pt_card_rule: "Rule Details",
	pt_card_summary: "Digital currency gift card is a promotional tool developed to enable promotional partners to better invite offline friends. When users exchange gift cards, they will automatically become offline friends of promotional partners.",
	pt_card_rule1: "1. Free gift cards are limited to 30 cards per user. If more gift cards need to be issued, users need to pay for customization, which can customize the amount of cards, LOGO, instructions, etc. For customized requirements, please send an email to",
	pt_card_rule2: "2. Free gift cards are limited to one copy per user. Free gift cards issued by the Alcex government are limited to one for each user. Even if different users receive free gift cards with different exchange codes, only one can be exchanged. Custom gift cards are not subject to this restriction.",
	pt_card_rule3: "3. Before the user exchanges the gift card, if there is no 'inviter' (i.e. the invitation code has not been entered at the time of registering the account), it will be automatically associated with the first-level friend of the card issuer. If A does not enter an invitation code when registering an account, then A does not belong to any one's first or second-level friends, but when A is converted into B's first-level friends through the gift card issued by B, A automatically becomes B's first-level friends.",
	pt_card_rule4: "4. In order to prevent brushing, the book value of gift cards is not paid in real time. It will be frozen for 180 days after collection and released into user account balance automatically after 180 days.",
	pt_card_rule5: "5. When receiving free gift cards, we need to complete the real-name certification. When converting, we can get them without completing the real-name certification. This is to facilitate promoters to get lower-level friends faster.",
	pt_card_rule6: "6. The right of final interpretation of free gift cards belongs to AlcexEX.COM.",
	pt_card_day: "Days",
	pt_card_title_tips: "Free promotion grant of 2000 CNY",
	pt_invite_desc: "Automatically become subordinate friends through two-dimensional code registration, suitable for forwarding in various groups or forums.",
	saveimage: "Save Image",
	imagetips: "If you can't download pictures properly, please download them using the screenshot tool!",
	pt_card_receivew_success: "Congratulations! Successful collection of partner promotion gift cards! Please go to the Personal Center - > Card and Voucher Center.",
	usepromotion: "Use Promotional Materials",
	context_title: "Exclusive Redemption Code for Promotion Partners",
	context_title1: "Scan to learn more",
	context_title2: "My Invitation Code",
	context_title3: "Invitation Image",
	pt_card_title: "Partner promotion card",
	copysuccess: 'Success！',
	copyerr: 'Failure！Please copy it manually',
	noopening: "No opening now!",
	createSuccess: "Success！",
	tradewarning1: 'Enter up to 2 decimal places',
	tradewarning2: 'Order amount is',
	tradewarning3: 'Enter up to 8 decimal places',
	tradewarning4: 'The order quantity is',
	tradeconfirmbuyin: 'Confirm buy',
	tradeconfirmsellout: 'Confirm sell',
	tradeinfo: {
		emaildone: 'Email certified',
		emailundo: 'Email unauthorized',
		teldone: 'Telno certified',
		telundo: 'Telno unauthorized',
		idcarddone: 'IDCard certified',
		idcardundo: 'IDCard unauthorized',
		exchangetimes: 'Exchange Times',
		price: 'Price',
		num: 'Amount',
		paymethod: 'PayMethod',
		exchangelimitamount: 'Exchange limit',
		location: 'Location',
		location_text: 'China',
		exchangeperiod: 'Exchange period',
		minute: 'min',
		amounttip: 'Please enter the amount',
		numtip: 'Please enter the num',
		remarktip: 'Tell him your request',
		remarktitle: 'Horizon Remarks',
		exchangetitle: 'Trading Information',
		exchange_tip1: 'After you initiate the transaction request, the digital currency is locked in the hosting and protected by the platform. If you are a seller, after you initiate a transaction request, you can top up and wait for the buyer to pay. Buyers pay within the payment deadline. After you receive the payment, you should release the digital currency that is under your custody.',
		exchange_tip2: 'Read before trading《Platform Network Terms of Service》 and FAQs, trading guides and other help documentation.',
		exchange_tip3: 'Beware of liar!Before the transaction, please check the rating received by the user and pay more attention to the newly created account.',
		exchange_tip4: 'Please note，Rounding and price fluctuations may affect the amount of digital currency that is eventually traded.The fixed amount you enter determines the final amount, and the digital currency amount will be calculated from the instant exchange rate at the same time that the request is issued.',
		exchange_tip5: 'Hosting services protect both buyers and sellers of online transactions.In the event of a dispute, we will evaluate all the information provided and release the hosted digital currency to its legal owner.',
		warning1: 'Enter up to 2 decimal places',
		warning2: 'Order amount is',
		warning3: 'Enter up to 8 decimal places',
		warning4: 'The order quantity is',
		confirmbuyin: 'Confirm buy',
		confirmsellout: 'Confirm sell',
		buyin: 'Buy in',
		sellout: 'Sell out',
		warning5: 'Please fill in the order as required',
	},
	convertResult:'Preview conversion result',
	willGet:'You will get',
	pleaseCommit: 'Please click to confirm within this time. ',
	returnText:'return',
	convert:'convert'
}
