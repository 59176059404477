export default {
    testText1: 'Dies ist eine Teststation! ',
    testText2: '@Alcexcom',
    testText3: '@bzengineer (der einzige Kanal, Vorsicht vor Betrug)',
    meun1: 'Titelseite',
    meun2: 'Kaufen Sie Münzen mit einem Klick',
    meun3: 'Handel',
    meun3_1: 'Spothandel',
    meun3_2: 'Schneller Austausch',
    meun4: 'Vertragshandel',
    meun4_1: 'U-Standardvertrag',
    meun4_2: 'Vertrag mit Münzmarge',
    meun4_3: 'Optionsvertrag',
    meun4_4: 'zweiter Vertrag',
    meun5: 'Finanzdienstleistungen',
    meun5_1: 'Innovationslabor',
    meun5_2: 'Promotion-Partner',
    meun6: 'Finanzverwaltung',
    meun7: 'Bekanntmachung',
    meun8: 'Anmeldung',
    meun9: 'registrieren',
    meun10: 'Geldbörse',
    meun10_1: 'Spotkonto',
    meun10_2: 'U-basiertes Vertragskonto',
    meun10_3: 'Münzmarginiertes Vertragskonto',
    meun10_4: 'Zweiter Vertrag',
    meun10_5: 'Bankkonto',
    meun10_6: 'Wallet-Geschichte',
    meun11: 'Befehl',
    meun11_1: 'Spot-Bestellung',
    meun11_2: 'C2C-Bestellung',
    meun11_3: 'Finanzverwaltungsaufträge',
    meun11_4: 'Flash-Swap-Verlauf',
    meun11_5: 'Innovationsmanagement',
    meun12: 'herunterladen',
    meun13_1: 'Kontoverwaltung',
    meun13_2: 'Bezahlverfahren',
    meun13_3: 'meine Beförderung',
    meun13_4: 'API-Verwaltung',
    meun13_5: 'Abmelden',
    meun14_1: 'Sprache',
    meun14_2: 'Tauschrate',
    meun14_3: 'Wählen Sie eine Sprache',
    meun14_4: 'Wechselkurs auswählen',
    openTrade: 'Beginnen Sie jederzeit und überall mit dem Handel.',
    scan: 'IOS & Android QR-Code zum Herunterladen scannen',
    footmeun1: 'um',
    footmeun1_1: 'über uns',
    footmeun1_2: 'begleiten Sie uns',
    footmeun1_3: 'Ankündigung der Plattform',
    footmeun1_4: 'weißes Papier',
    footmeun2: 'Hilfezentrum',
    footmeun2_1: 'Ratgeber für Anfänger',
    footmeun2_2: 'häufiges Problem',
    footmeun2_3: 'Handelsleitfaden',
    footmeun2_4: 'Währungsinformationen',
    footmeun3: 'Bedingungen Vereinbarung',
    footmeun3_1: 'Haftungsausschluss',
    footmeun3_2: 'Datenschutzrichtlinie',
    footmeun3_3: 'Nutzungsbedingungen',
    footmeun3_4: 'Tarifbeschreibung',
    footmeun4: 'kontaktiere uns',
    footmeun4_1: 'Kundendienst-E-Mail',
    footmeun4_2: 'Zusammenarbeit von Unternehmen',
    footmeun4_3: 'Antrag auf Münzauflistung',
    footmeun4_4: 'Beschwerden',
    foottext: 'Globaler Austausch hochwertiger digitaler Vermögenswerte',
    indexTxt1: 'Die 50 besten digitalen Währungsbörsen der Welt',
    indexTxt2:
        'Nationale Behörden garantieren die Sicherheit von Vermögenswerten',
    indexTxt3: 'Ratgeber für Anfänger',
    indexTxt4: 'Kaufen Sie schnell Münzen',
    indexTxt5: 'Offizieller Alcex-Hilfskanal für Anfänger',
    indexLabel1: 'Handel mit Kryptowährungen',
    indexVal1: 'Nutzen Sie ein digitales Asset zum direkten Austausch',
    indexLabel2: 'Optionshandel',
    indexVal2: 'Derivate digitaler Vermögenswerte',
    indexLabel3: 'Vertragshandel',
    indexVal3: 'Münzstandard, U-Standardvertrag',
    indexLabel4: 'Schneller Austausch',
    indexVal4:
        'Sofortiger Währungsumtausch, Transaktionen mit einem Klick abschließen',
    indexth: 'Preisentwicklung',
    indexbtn: 'Handel jetzt',
    indexh1: 'ehrlich',
    indexh2: 'gerecht',
    indexh3: 'Begeisterung',
    indexh4: 'offen',
    indexh5: 'Finanzielle Sicherheit',
    indexh6: 'Extrem schnelle Ein- und Auszahlungen',
    indexh7: 'Weltweiter Service',
    indexh8: 'Sorgfältig ausgewählte Projekte',
    indexp1:
        'Alcex wurde von einer Gruppe früher Bitcoin-Teilnehmer und -Freaks gegründet. Die Kernmitglieder des Teams kommen von bekannten Unternehmen wie Google, Microsoft, Alibaba und Tencent. Sie verfügen über umfassende Forschungs- und Entwicklungskapazitäten und umfangreiche Erfahrung im Betrieb von Internetprodukten.',
    indexp2:
        'Alcex positioniert sich als grundlegender Blockchain-Dienstleister und engagiert sich für die Bereitstellung qualitativ hochwertiger Krypto-Asset-Handelsplattformen für globale Benutzer. Es hält sich an das Grundprinzip „Tue nichts Böses“ und besteht darauf, Kunden ehrlich, fair und enthusiastisch zu bedienen. und begrüßt alle Interessierten mit einer offenen Haltung. Partner/Projekte, die den grundsätzlichen Interessen der Nutzer dienen.',
    indexp3:
        'Ein umfassendes Finanzrisikokontrollsystem und ein Diebstahlschutzsystem, Hot- und Cold-Wallets sowie ein Multisignatursystem gewährleisten die Sicherheit der Gelder',
    indexp4:
        'Ein- und Auszahlungen können in nur 3 Minuten abgeschlossen werden und die manuelle Online-Überprüfung rund um die Uhr schützt Kunden davor, die besten Investitionsmöglichkeiten zu verpassen.',
    indexp5:
        'Abdeckung eines globalen Business-Service-Netzwerks, das Ihnen hilft, in globale Krypto-Assets zu investieren und mit globalen Benutzern zu handeln',
    indexp6:
        'Wählen Sie strikt qualitativ hochwertige Verschlüsselungsprojekte aus und filtern Sie 80 % der Projekte mit extrem hohem Risiko für Sie',
    indexp7: 'Scannen Sie den QR-Code und laden Sie die App herunter',
    scandownload: 'Scannen Sie, um die APP herunterzuladen',
    ormenu1: 'C2C-Bestellung',
    ormenu2: 'Meine Bestellung',
    ormenu3: 'Verdienen Sie Münzen',
    please: 'bitte zuerst',
    account: 'Alcex-Kontoanmeldung',
    phone: 'Telefonnummer',
    email: 'Post',
    sign: 'Anmeldung',
    password: 'Passwort',
    password2: 'Passwort',
    password3: 'vergessen Sie das Passwort',
    account2: 'Erstellen Sie ein Alcex-Konto',
    logErr: 'Bitte geben Sie den Benutzernamen ein',
    logErr2: 'Bitte Passwort eingeben',
    logErr3: 'Die Länge des Passworts darf nicht weniger als 6 Zeichen betragen',
    logSuccess: 'Anmeldung erfolgreich',
    account3: 'Erstellen Sie ein persönliches Konto',
    phone1: 'Registrieren Sie Ihr Telefon',
    email1: 'E-Mail Registrierung',
    email2: 'E-Mail-Adresse',
    code: 'SMS-Bestätigungscode',
    code2: 'Senden Sie den Bestätigungscode',
    code3: 'Bestätigungscode',
    password4: 'Bestätige das Passwort',
    invite: 'Einladungscode (optional)',
    agree: 'Ich habe gelesen und stimme zu',
    agreement: 'Nutzungsbedingungen',
    register: 'registrieren',
    regErr: 'Bitte geben Sie den SMS-Bestätigungscode ein',
    confirmpwdtip: 'Bitte geben Sie das Bestätigungspasswort ein',
    confirmpwderr: 'Die beiden Passworteingaben sind inkonsistent!',
    wjtab: 'Handy-Passwort zurücksetzen',
    wjtab2: 'E-Mail-Passwort zurücksetzen',
    wjlabel: 'Neues Kennwort',
    wjerr: 'Bitte geben Sie ein neues Passwort ein',
    labTxt: 'Alcex-Labor ● Teilen Sie Innovationsergebnisse',
    labTxt2:
        'Ein offenes Win-Win-Labor für Investoren, Projektparteien, Börsen und andere Parteien',
    labTab1: 'alle',
    labTab2: 'Kurz vor dem Start',
    labTab3: 'im Gange',
    labTab4: 'Verteilen',
    labTab5: 'vollendet',
    tokendistribute: 'Token wird verteilt',
    activitytype: 'Abonnementtyp',
    labStart: 'Anfangszeit',
    labEnd: 'Endzeit',
    labStartPlaceholder: 'Bitte wählen Sie eine Startzeit aus',
    labDetail: 'Überprüfen Sie die Details',
    labSpeen: 'Zeitplan',
    labNumber: 'Gesamtaktivität',
    labType1: 'Zuerst online (Eilkauf)',
    labType2: 'Zum ersten Mal online (sogar geteilt)',
    labType3: 'Positionsteilung',
    labType4: 'Kostenloses Abonnement',
    labType5: 'Abonnement für Cloud-Mining-Maschinen',
    labType6: 'Sperrfreigabe',
    baseinfo: 'Grundinformation',
    ruledetail: 'Typbeschreibung',
    releaseType: 'Release-Typ',
    releaseType0: 'In gleichen Mengen freigeben',
    releaseType1: 'Freigabe im gleichen Verhältnis',
    lock: 'Periodenfreigabe',
    releaseDetail: 'Veröffentlichungsdetails',
    lockday: 'Tag',
    lockweek: 'Woche',
    lockmonth: 'Mond',
    lockyear: 'Jahr',
    releasePercent: 'Freigabeverhältnis',
    lockFee: 'Sperrschwelle',
    releaseTimes: 'Mehrere freigeben',
    times: 'mal',
    publishprice: 'Einschreibegebühr',
    holdcoin: 'Währung, die zum Halten von Positionen erforderlich ist',
    limittimes: 'Persönliches Einkaufslimit',
    limitamount: 'Persönliches Einkaufslimit',
    unlimited: 'Keine Begrenzung',
    leveloneCount: 'Anzahl der erforderlichen Freunde der ersten Ebene',
    alreadyamount: 'Gekaufte Menge',
    leftamount: 'Der verbleibende Betrag',
    myalreadyholdamount: 'Meine teilnehmenden Positionen',
    alreadyholdamount: 'Gesamtbestand der Veranstaltungsteilnehmer',
    currentdivided: 'Meine aktuelle Position lässt sich in ungefähr einteilen',
    holdtips:
        'Um an der Aktivität vom Typ [Positionsfreigabe] teilzunehmen, müssen Sie Ihre Position bis zum Ende der Aktivität sperren',
    inputholdamount: 'Geben Sie den Sperrbetrag ein',
    pleaseinputamount: 'Bitte geben Sie die Einlösemenge ein',
    pleaseinputholdamount: 'Bitte geben Sie den Sperrbetrag ein',
    inputamount: 'Geben Sie den Rückzahlungsbetrag ein',
    inputminingamount:
        'Geben Sie die Anzahl der zu kaufenden Mining-Maschinen ein',
    attend: 'Jetzt teilnehmen',
    attention: 'Beachten',
    attentiontxt1:
        '1. Nach der Anmeldung zur Teilnahme an den Aktivitäten vom Typ „Positionsfreigabe“, „Kostenloses Abonnement“, „Cloud-Mining-Maschinen-Abonnement“ und „Sperren“ werden die Vermögenswerte eingefroren und nach Beendigung der Aktivität wieder freigegeben oder entsperrt .',
    attentiontxt2:
        '2. Nach der Teilnahme an Aktivitäten vom Typ [Position Sharing], [Kostenloses Abonnement], [Cloud Mining Machine-Abonnement], [Lock-up] ist es Benutzern nicht gestattet, ihren Teilnahmeantrag zu stornieren.',
    attentiontxt3:
        '3. Wenn die Veranstaltung aufgrund der Projektpartei oder anderer Faktoren höherer Gewalt abgesagt wird, werden die eingefrorenen Vermögenswerte auf den ursprünglichen Weg zurückgeführt.',
    attentiontxt4:
        '* Das endgültige Interpretationsrecht dieser Veranstaltung liegt beim offiziellen Alcex (www.al-cex.com).',
    transfer: 'Geldtransfer',
    placeholder: 'Suchwährung',
    bith1: 'Vertragshandel',
    bith2: 'Kontorechte',
    bith3: 'nicht realisierter Gewinn oder Verlust',
    bith4: 'Verfügbare Spanne',
    bith5: 'Gebrauchter Rand',
    bith6: 'Rand einfrieren',
    bith7: 'Vertragskonto',
    btn1: 'nachfüllen',
    btn2: 'zurückziehen',
    btn3: 'überweisen',
    btn4: 'Zahlen Sie Münzen ein',
    btn5: 'Münzen abheben',
    totalAssets: 'Gesamtvermögen umgerechnet',
    th1: 'Währungsname',
    th2: 'Verfügbare Vermögenswerte',
    th3: 'Vermögenswerte einfrieren',
    th4: 'Freizugebende Vermögenswerte',
    th5: 'arbeiten',
    czTxt: 'Aufladen digitaler Währungen',
    czTxt2: 'Währung',
    czTxt3: 'Übertragungsnetz',
    czTxt4: 'Verfügbares Guthaben',
    czTxt5: 'Auszahlungsadresse',
    czTxt6: 'Anzahl der abgehobenen Münzen',
    czTxt7: 'Ankunftsmenge',
    czTxt8: 'Bearbeitungsgebühr',
    czTxt9: 'Freundliche Tipps',
    czTxt10: 'Mindesteinzahlungsbetrag:',
    czTxt10_1: 'Aufladungen unter dem Mindestbetrag werden nicht gutgeschrieben.',
    czTxt11:
        'Bitte hinterlegen Sie keine Vermögenswerte in Fremdwährung an der oben genannten Adresse, da die Vermögenswerte sonst nicht abgerufen werden können.',
    czTxt12:
        'Nach dem Aufladen an die oben genannte Adresse ist eine Bestätigung vom gesamten Blockchain-Netzwerkknoten erforderlich. Im Allgemeinen erfolgt die Gutschrift auf dem Konto nach drei Netzwerkbestätigungen vom Haupt-Blockchain-Netzwerk.',
    czTxt13:
        'Ihre Aufladeadresse ändert sich nicht häufig und kann wiederholt aufgeladen werden. Bei Änderungen werden wir unser Bestes tun, um Sie durch Website-Ankündigungen oder E-Mails zu benachrichtigen.',
    czTxt14:
        'Bitte stellen Sie sicher, dass Ihr Computer und Ihr Browser sicher sind, um zu verhindern, dass Informationen manipuliert werden oder verloren gehen.',
    czTxt15: 'Aufladeaufzeichnung',
    czTxt16:
        'Um die Sicherheit der Gelder zu gewährleisten, führen wir eine manuelle Überprüfung der Auszahlung durch, wenn Sie Ihre Kontosicherheitsrichtlinie ändern, Ihr Passwort ändern oder eine neue Adresse zum Abheben von Münzen verwenden. Bitte haben Sie etwas Geduld und warten Sie, bis sich das Personal mit Ihnen in Verbindung setzt Sie per Telefon oder E-Mail.',
    czTxt17: 'Der Mindestauszahlungsbetrag beträgt:',
    czth1: 'Auszahlungszeit',
    czth2: 'aus',
    czth3: 'Zu',
    czth4: 'Menge der sofortigen Einlösung',
    czth5: 'Umtauschverhältnis',
    czth6: 'Tauschmenge',
    placeholder2: 'Bitte eingeben',
    placeholder3: 'bitte auswählen',
    czEmpty: 'keine Aufzeichnungen',
    logintip: 'Bitte loggen Sie sich zuerst ein',
    cztip:
        'Sie haben keine Aufladeadresse beantragt. Klicken Sie bitte auf die Schaltfläche, um die Aufladeadresse zu erhalten.',
    czget: 'Aufladeadresse abrufen',
    czplaceholder: 'Bitte wählen Sie ein Protokoll aus',
    mAccount: 'zweites Vertragskonto',
    btn6: 'suchen',
    wtTxt: 'Start- und Endzeit',
    wtTxt2: 'Operationstyp',
    wtth1: 'Transaktionsstunde',
    wtth2: 'Typ',
    wtth3: 'Währung',
    wtth4: 'Menge',
    wtth5: 'Bearbeitungsgebühr zahlbar',
    wtth6: 'Bearbeitungsgebühren abziehen',
    wtth7: 'Tatsächliche Bearbeitungsgebühr',
    wtth8: 'Zustand',
    nav1: 'Konto Sicherheit',
    nav2: 'meine Einladung',
    nav3: 'Meine Werbekarte',
    acTxt: 'Sicherheitsstufe',
    acbtn1: 'nicht zertifiziert',
    acbtn2: 'Bindung',
    acbtn3: 'Überarbeiten',
    acbtn4: 'verifiziert',
    acbtn5: 'Gebunden',
    acbtn6: 'aufstellen',
    acbtn7: 'in Prüfung',
    acbtn8: 'Wiederholen',
    acTxt2: 'Verifiziert',
    acTxt3: 'Post',
    acTxt4: 'Handy',
    acTxt5: 'Passwort',
    acTxt6: 'Passwort für den Fonds',
    acTxt7: 'Google Authenticator',
    acTxt8: 'Legen Sie ein Fondspasswort fest',
    acTxt9: 'Fondspasswort ändern',
    acTxt10: 'Fondspasswort abrufen',
    acDesc2:
        'Um die Sicherheit Ihres Kontos zu gewährleisten, führen Sie bitte vor dem Handel eine Echtnamen-Authentifizierung durch!',
    acDesc3: 'E-Mail binden',
    acDesc4: 'Handy binden',
    acDesc5: 'Wird beim Anmelden bei der Plattform verwendet',
    acDesc6:
        'Wenn sich das Guthaben auf dem Konto ändert, muss das Guthabenpasswort überprüft werden',
    acDesc7: 'Bitte binden Sie den Google-Authentifikator ein',
    acDesc8: 'Google Authenticator ist gebunden',
    acDesc9: 'Lösen',
    acTit: 'Handybindung',
    acTit2: 'E-Mail-Bindung',
    acTit3: 'Google-Bindung',
    acTit4: 'Google entbindet',
    mailtip: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
    emailerr2: 'Das E-Mail-Format ist falsch. Bitte geben Sie es erneut ein.',
    agreementtip:
        'Bitte lesen Sie die Nutzungsvereinbarung und stimmen Sie ihr zu',
    acverify: 'Die Überprüfung ist fehlgeschlagen',
    again: 'Bitte versuche es erneut',
    verifiedmsg:
        'Bitte führen Sie zunächst die Echtnamen-Authentifizierung durch',
    apiTxt: 'Echtnamenkonto binden',
    apiTxt2: 'Hinweis',
    apiTxt3:
        'Wir stellen Ihnen eine leistungsstarke API zur Verfügung, über die Sie Dienste wie Marktabfragen und automatischen Handel nutzen können. ',
    apiTxt4: 'Jeder Benutzer kann bis zu 5 Sätze API-Schlüssel erstellen',
    apiTxt5:
        'Bitte geben Sie Ihren API-Schlüssel nicht weiter, um einen Vermögensverlust zu vermeiden. ',
    apiTxt6: 'API-Hinweise',
    apiTxt7:
        'Autorisierte IP (der Schlüssel für die autorisierte IP-Adresse ist 90 Tage lang gültig)',
    apibtn: 'erstellen',
    apith1: 'API-Hinweise',
    apith2: 'Erstellungszeitpunkt',
    apith3: 'Zugangsschlüssel',
    apith4: 'IP-Adresse binden',
    apith5: 'Verbleibende Gültigkeitsdauer (Tage)',
    chTxt: 'Login-Passwort ändern',
    chTxt2: 'Zum Binden den Code scannen',
    chTxt3: 'Bitte geben Sie den Bestätigungscode ein',
    chTxt4: 'Ursprüngliches Fondspasswort',
    chTxt5: 'Neues Fondspasswort',
    chTxt6: 'Bestätigen Sie das neue Passwort',
    chTxt7: 'vergessen Sie das Passwort',
    chTxt8: 'Mobiltelefonverifizierung ändern',
    chTxt9: 'Ursprüngliches Login-Passwort',
    chTxt10: 'Neues Login-Passwort',
    chTxt11: 'E-Mail-Bestätigungscode',
    chTxt12: 'Bestätigungscode für Mobiltelefone',
    telerr: 'Die Mobiltelefonnummer ist falsch',
    emailerr: 'E-Mail ist falsch',
    codeerr: 'Falscher verifikationscode',
    reset: 'zurücksetzen',
    save: 'speichern',
    chtip: 'Bitte geben Sie das Original-Passwort ein',
    chtip2:
        'Bitte geben Sie ein neues Login-Passwort mit mindestens 6 Ziffern ein',
    chtip3: 'Das neue Login-Passwort ist inkonsistent',
    chtip4: 'Bitte geben Sie das korrekte Fondspasswort ein',
    chtip5: 'Das Passwort darf nicht weniger als 6 Zeichen lang sein',
    chtip6: 'Bitte geben Sie ein Passwort mit mindestens 6 Ziffern ein',
    chtip7: 'Passwörter sind inkonsistent',
    chtip8: 'Bitte geben Sie das Fondspasswort ein',
    chtip9: 'Bitte geben Sie die Telefonnummer ein',
    chtip10: 'Bitte geben Sie Ihr Passwort ein',
    realnametip: 'Bitte geben Sie Ihren richtigen Namen ein',
    idcardtip: 'Bitte geben Sie Ihre ID-Nummer ein',
    clTxt:
        'Bitte legen Sie Ihre Zahlungsmethode fest und verwenden Sie unbedingt Ihr Echtnamenkonto',
    clTxt2: 'Zahlungsmethode hinzufügen',
    edit: 'bearbeiten',
    delete: 'löschen',
    name: 'Name',
    clTxt3: 'ID-Nummer',
    clTxt4: 'IBAN-Nummer',
    clTxt5: 'Alle Zahlungsarten',
    clLink1: 'Kontoname',
    clLink2: 'Zahlungsart bearbeiten',
    clLink3: 'Zahlungsdetails (optional)',
    clplaceholder1: 'bitte tragen Sie Ihren vollen Namen ein',
    clplaceholder2: 'Bitte geben Sie Ihr Bankkonto-Passwort ein',
    clplaceholder3: 'Bitte geben Sie Ihre Zahlungsinformationen ein',
    cltip: 'Spezielle Notiz',
    cltip2:
        'Bitte geben Sie für die sofortige Zahlung unbedingt Ihre Bankkartennummer an. ',
    cltip3:
        'Warme Erinnerung: Wenn Sie digitale Währungen verkaufen, wird dem Käufer die von Ihnen gewählte Zahlungsmethode angezeigt. ',
    clTxt6: 'Optional',
    upload: 'hochladen',
    uploadTip: '(Unterstützt das JPG/JPEG/PNG/BMP-Format, weniger als 1 MB)',
    tgbtn: 'Code einlösen',
    tgth1: 'Kartenname',
    tgth2: 'Code einlösen',
    tgth3: 'Kartenwährung',
    tgth4: 'Betrag der Kartenvorderseite',
    tgth5: 'gesamt',
    tgth6: 'Bereits eingelöst',
    tgth7: 'Sammelzeit',
    tgbtn2: 'Werbematerial',
    tgbtn3: 'Jetzt einlösen',
    tgplaceholder: 'Bitte geben Sie den Einlösecode ein',
    exchangesuccess: 'Einlösung erfolgreich! ',
    inth1: 'Benutzer einladen',
    inth2: 'Anmeldezeit',
    inth3: 'Echter Namensstatus',
    adTxt: 'meine Anzeige',
    adTxt2:
        'Wenn der Mindesttransaktionsbetrag der Anzeige zuzüglich der Bearbeitungsgebühr größer ist als die verbleibende Menge der Anzeige, wird die Anzeige automatisch aus den Regalen entfernt.',
    adbtn: 'Klare Konditionen',
    adbtn2: 'Auf den Regalen',
    adbtn3: 'Aus den Regalen entfernt',
    adth1: 'Anzeigennummer',
    adth2: 'Art der Werbung',
    adth3: 'Bestelllimits',
    adth4: 'Der verbleibende Betrag',
    adtip: 'Zum Löschen bestätigen',
    num_text1: 'Bitte geben Sie ein, was Sie möchten',
    num_text2: 'Menge',
    wTxt: 'Spotware',
    wTxt2: 'Aktueller Auftrag',
    wTxt3: 'Handelspaar',
    wTxt4: 'Handelsrichtung',
    wth1: 'Zeit',
    wth2: 'Richtung',
    wth3: 'Preis',
    wth4: 'Geschäft abgeschlossen',
    wth5: 'Umsatz',
    wth6: 'Delegatentyp',
    wth7: 'Auslösepreis',
    wth8: 'Endgültiger Preis',
    wth9: 'Bestellmenge',
    wth10: 'Abrechnungsgewinn und -verlust',
    wth11: 'Delegationsstatus',
    wth12: 'Eine Position öffnen/schließen',
    wth13: 'Marge',
    wbtn: 'Bestellung stornieren',
    wtip: 'Stornierungserinnerung',
    wtiptxt: 'Stornierung der Bestellung bestätigen?',
    zbplaceholder: 'Geben Sie die Bestellnummer ein, um die Suche zu starten',
    zbtab1: 'Fester Investitionsplan',
    zbtab2: 'Feste Investitionsbilanz',
    zbth1: 'Investitionszyklus',
    zbth2: 'schalten',
    zbth3: 'Kumulierter Investitionsbetrag',
    zbth4: 'Menge gehalten',
    zbth5: 'Durchschnittskosten',
    zbth6: 'nicht realisierter Gewinn oder Verlust',
    zbth7: 'Nächster geplanter Investitionstermin',
    zbth8: 'Erstellungsdatum des Plans',
    etTxt: 'Ankündigungszentrum',
    etTxt2: 'Zum Teilen scannen',
    etmenu1: 'Kryptowährung',
    etmenu2: 'Ankündigung des Alcex-Server-Updates',
    et2menu1: 'Einführung in RX.Tron',
    et2menu2: 'Ist Bitcoin ein Schneeballsystem?',
    hisTxt: 'Historische Kommission',
    orTab1: 'Unbezahlt',
    orTab2: 'Bereits bezahlt',
    orTab3: 'vollendet',
    orTab4: 'Abgesagt',
    orTab5: 'Reizvoll',
    orth1: 'Bestellnummer',
    orth2: 'Transaktionswährung',
    orth3: 'Art der Transaktion',
    orth4: 'Handelspartner',
    orth5: 'Menge',
    qcTab1: 'Vorherige Ergebnisse',
    qcTab2: 'Echtzeit-Kurse',
    qcTxt: 'NEIN.',
    qcTxt2: 'Erwarten',
    qcTxt3: 'Öffnungszeit',
    qcTxt4: 'Geschäftsschluss',
    qcTxt5: 'Eröffnungskurs',
    qcTxt6: 'Schlusskurs',
    qcTxt7: 'Gesamtkaufpreis',
    qcTxt8: 'Gesamtbetrag aus Kauf und Verkauf',
    qcTxt9: 'Meine offene Stelle',
    qcTxt10: 'Fortschritt prognostizieren',
    qcTxt11: 'Eröffnungskurs',
    qcTxt12: 'Schlusskurs',
    qcTxt13: 'Dieser Prognosezeitraum',
    qcbtn1: 'bullisch',
    qcbtn2: 'Bärisch',
    qcth1: 'Anzahl der Vertragslaufzeiten',
    qcth2: 'Eröffnungsbetrag',
    qcth3: 'Vorhersagerichtung',
    qcth4: 'Prognoseergebnis',
    qcth5: 'Bonusbetrag',
    qcth6: 'Eröffnungsgebühr',
    qcth7: 'Wasser pumpen',
    mplaceholder: 'Geben Sie den Währungsnamen ein, um zu suchen',
    mTab: 'Optional',
    mth: 'sammeln',
    mth1: 'Letzter Preis',
    mth2: '24h Aufstieg und Fall',
    mTxt: '24h Höchstpreis',
    mTxt2: '24h niedrigster Preis',
    mTxt3: 'Anzahl der Transaktionen',
    mTxt4: 'Fördersatz',
    mTxt5: 'offen',
    mTxt6: 'hoch',
    mTxt7: 'Niedrig',
    mTxt8: 'erhalten',
    echat1: 'K-Liniendiagramm',
    echat2: 'Tiefenkarte',
    mTab2: 'Aktuelle Position',
    mTab3: 'Geschichte der Kommission',
    mth3: 'Garantierte Entschädigung',
    mth4: 'Eröffnungskurs',
    mth5: 'Zyklus',
    mth6: 'Schlusskurs',
    mth7: 'profitieren',
    mTxt9: 'Handelsmodell',
    mTxt10: 'Öffnungszeit',
    mTxt11: 'Rentabilität',
    mTxt12: 'Gleichgewicht',
    mTxt13: 'Kaufvolumen',
    mTxt14: 'Mitte',
    cadTxt: 'Erstellen Sie einen Werbedeal',
    cadTxt2:
        'Wenn Sie häufig handeln, können Sie Ihre eigenen Handelsanzeigen erstellen.',
    cadTxt2_1:
        'Wenn Sie nur gelegentlich handeln, empfehlen wir Ihnen, direkt zu suchen',
    cadTxt2_2: 'Das Erstellen einer Handelsanzeige ist kostenlos.',
    cadLink2: 'Transaktionswerbung',
    cadTxt3:
        'Wenn Sie eine von Ihnen erstellte Anzeige direkt bearbeiten möchten, lesen Sie bitte',
    cadLink3: 'meine Anzeige',
    cadTxt4: 'Marktbeteiligungspreis',
    cadTxt5:
        'Die Prämie bezieht sich auf den Prozentsatz, der über dem aktuellen Marktpreis liegt und verkauft werden soll',
    cadTxt6: 'Preisformel',
    cadTxt7:
        '[Tipp] Sie können zum persönlichen Center gehen, um Zahlungsmethoden zu binden/hinzufügen',
    cadTxt8:
        'Der maximale Transaktionsbetrag darf Ihren Gesamtverkaufsbetrag 0CNYI nicht überschreiten',
    cadTxt9:
        'Wenn ein Nutzer nach der Aktivierung über diese Anzeige eine Transaktion mit Ihnen initiiert, sendet das System automatisch den von Ihnen ausgewählten Wortlaut der automatischen Antwort an die andere Partei.',
    cadTxt10:
        'Die Prämie bezieht sich auf den Prozentsatz über dem aktuellen Marktpreis für den Kauf',
    cadLabel1: 'Ich will:',
    cadLabel2: 'Währungstransaktion:',
    cadLabel3: 'Nation:',
    cadLabel4: 'Währung:',
    cadLabel5: 'Festpreis aktivieren',
    cadLabel6: 'Prämie:',
    cadLabel7: 'Handelspreis',
    cadLabel8: 'Verkaufte Menge:',
    cadLabel9: 'Bezahlverfahren:',
    cadLabel10: 'Mindesttransaktionsbetrag:',
    cadLabel11: 'Maximaler Transaktionsbetrag:',
    cadLabel12: 'Aktivieren Sie automatische Antworten',
    cadLabel13: 'Fondspasswort:',
    cadLabel14: 'Bemerkungen:',
    cadLabel15: 'Festpreis:',
    cadLabel16: 'automatische Antwort:',
    cadLabel17: 'Kaufmenge:',
    radio: 'zum Online-Verkauf',
    radio2: 'Online kaufen',
    cadplaceholder: 'Bitte legen Sie Ihre Prämie fest',
    cadplaceholder2: 'Bitte geben Sie die Menge ein, die Sie verkaufen möchten',
    cadplaceholder3:
        'Bitte geben Sie den gewünschten Mindesttransaktionsbetrag ein',
    cadplaceholder4:
        'Bitte geben Sie den gewünschten maximalen Transaktionsbetrag ein',
    cadplaceholder5:
        'Sie können Ihre speziellen Anforderungen in die Bemerkungsinformationen eingeben, z. B. Anforderungen an Käufer, Online-Zeit usw.',
    cadplaceholder6: 'Bitte geben Sie Ihren Transaktionspreis ein',
    cadplaceholder7:
        'Antworten Sie nach Erhalt der Bestellung automatisch auf die Informationen des Käufers, z. B. Zahlungsmethode, Zahlungskontonummer usw.',
    cadplaceholder8: 'Bitte geben Sie die Menge ein, die Sie kaufen möchten',
    caderr1: 'Bitte geben Sie Ihren Mindesttransaktionsbetrag ein',
    caderr2: 'Der Mindesttransaktionsbetrag muss größer oder gleich 100 sein!',
    caderr3:
        'Der Mindesttransaktionsbetrag muss geringer sein als der maximale Transaktionsbetrag',
    caderr4: 'Bitte geben Sie Ihren maximalen Transaktionsbetrag ein!',
    caderr5: 'Bitte geben Sie eine Ganzzahl ein',
    caderr6:
        'Der maximale Transaktionsbetrag muss größer sein als der minimale Transaktionsbetrag!',
    caderr7:
        'Der maximale Transaktionsbetrag darf den Gesamtbetrag Ihrer Verkäufe nicht überschreiten',
    caderr8: 'Bitte wählen Sie eine Transaktionsmethode aus',
    submit: 'einreichen',
    cadnew:
        'Nach der Aktivierung wird Ihr Währungspreis nicht mit dem Markt schwanken und der Preis bleibt unverändert.',
    dhTxt: 'Tauschen Sie mit einem Klick aus und genießen Sie die Vorteile',
    dhTxt2: 'Preislimit',
    dhTxt3: 'verkaufen',
    dhTxt4: 'Kaufen',
    dhTxt5: 'maximal',
    dhTxt6: 'Menge eingeben',
    dhplaceholder: 'Verfügbares Guthaben in der Spot-Wallet',
    dhSuccess: 'Glückwunsch! ',
    crTxt: 'Fiat-Währungshandel',
    crTxt2:
        'Bequemer, sicherer und schneller Kauf und Verkauf digitaler Währungen',
    crTxt3: 'Dynamische Anpassung',
    crTxt4: 'Keine Bearbeitungsgebühr',
    crTxt5: 'Sofortige Transaktion',
    crTxt6: 'Plattformgarantie',
    crTxt7: 'Schwanken Sie in Echtzeit basierend auf den Marktpreisen',
    crTxt8:
        'Was Benutzer sehen, ist, was sie bekommen, und es fallen keine Plattformgebühren außer dem Kauf- und Verkaufspreis an.',
    crTxt9:
        'Einführung einer Plattform, die Händlern intelligentes Matching und Transaktionsaufträge bietet, ohne auf das Matching warten zu müssen',
    crTxt10:
        'Plattformzertifizierte Händler, sicherer 24-Stunden-Kundendienst zum Schutz von Transaktionen',
    crth1: 'Händler',
    crth2: 'Anzahl der Transaktionen',
    crth3: 'Bezahlverfahren',
    crth4: 'Grenze',
    crth5: 'Einzelpreis',
    crbtn: 'Kaufen',
    crbtn2: 'Werden Sie Händler',
    cr1Txt: 'Glückwunsch! ',
    cr1Txt2: 'Materialien vorbereiten',
    cr1Txt3: 'Bewertung abschicken',
    cr1Txt4: 'verifiziert',
    cr1Txt5: 'Ihnen werden die folgenden Berechtigungen gewährt',
    cr1Txt6: 'Exklusiver Stand',
    cr1Txt7: 'Eins-zu-eins-Service',
    cr1Txt8:
        'Händler profitieren von exklusiven Werbeständen, um die Erfolgsquote bei Transaktionen zu erhöhen',
    cr1Txt9: 'Niedrigere Gebühren',
    cr1btn: 'Anzeige aufgeben',
    cr1btn2: 'Beantragen Sie die Übergabe',
    reTxt: 'Alcex Financial Management●Anlagelösung aus einer Hand',
    reTxt2: 'Verdienen Sie sofort, einfach und sicher Geld',
    reTab: 'Regelmäßige Finanzverwaltung',
    reTab2: 'Finanzmanagement für Anlageinvestitionen',
    reTxt3: 'regulär',
    reTxt4: 'Flexible Anpassung, höhere potenzielle Gewinne',
    reTxt6: 'Anlageinvestition',
    reTxt7: 'Beginnen Sie mit Anlageinvestitionen und steigern Sie Ihr Vermögen',
    reTxt8: 'Erweitern Sie alle 18 regulären Produkte',
    reth1: 'annualisierte Rendite',
    reth2: 'Frist (Tage)',
    reth3: 'Transaktionslimit',
    reth4: 'Produkt',
    reth5: 'historische Kapitalrendite',
    rebtn: 'Plan erstellen',
    redTxt: 'Erstellen',
    redTxt2: 'Fester Investitionsplan',
    redTxt3: 'Geben Sie den Investitionsbetrag ein',
    redTxt4: 'Mein verfügbares Guthaben beträgt',
    redTxt5: 'Verfügbar',
    redTxt6: 'Zyklusperiode',
    redTxt7: 'täglich',
    redTxt8: 'wöchentlich',
    redTxt9: 'pro Monat',
    redTxt10: 'Ortszeit',
    redTxt11: 'Ihr erster automatischer Investitionszyklus wird sein',
    redTxt12: 'Start',
    confirm: 'bestätigen',
    confirm2: 'Sicher',
    cancel: 'Stornieren',
    redongoing: 'im Gange',
    redcompleted: 'vollendet',
    minNumErr: 'Weniger als der Mindestanfangsbetrag',
    minNumErr2: 'Der Startbetrag darf nicht 0 sein',
    buyamounttip: 'Bitte geben Sie den Kaufbetrag ein',
    pricetipwarning: 'Der Transaktionsbetrag darf nicht höher sein als',
    buyamounttipwarning: 'Die Kaufmenge darf nicht höher sein als',
    sellamounttip: 'Bitte geben Sie den Verkaufsbetrag ein',
    sellamounttipwarning: 'Die Verkaufsmenge darf nicht höher sein als',
    sellpricetip: 'Bitte geben Sie den Verkaufspreis ein',
    sellpricetipwarning: 'Der Verkaufspreis darf nicht höher sein als',
    sellmore: 'Das meiste, was verkauft werden kann',
    sellunit: 'individuell',
    loginFirst: 'Bitte loggen Sie sich zuerst ein',
    betsuccess: 'Glückwunsch! ',
    selectAmount: 'Bitte wählen Sie Ihren Einsatzbetrag aus',
    balancenotenough: 'Unzureichendes Guthaben!',
    balancenotenough2: 'Mangelhaftes Gleichgewicht!',
    minlimitamountfailed:
        'Der Einlösungsbetrag darf den Mindesteinlösungsbetrag nicht unterschreiten!',
    maxlimitamountfailed:
        'Die Einlösungsmenge darf nicht größer sein als die maximale Kaufmenge!',
    limittimesfailed:
        'Die Anzahl Ihrer Teilnahmen übersteigt die maximale Anzahl persönlicher Einkäufe!',
    commitfailed: 'Anmeldung zur Teilnahme fehlgeschlagen!',
    trplaceholder: 'Anfragen',
    trplaceholder2: 'letzter Preis',
    trplaceholder3: 'Kaufen Sie zum besten Preis auf dem Markt',
    trplaceholder4: 'Verkaufen Sie zum besten Preis auf dem Markt',
    triggleplaceholder: 'Der Standardbestellpreis ist der Marktpreis',
    triggerpriceshouldbigger: 'Der Auslösepreis muss größer als 0 sein',
    marginModeSuccessTip: 'Vertragskontomodus erfolgreich geändert',
    noenoughbalance: 'Unzureichende Kontomarge',
    pleaseinputopenvolume: 'Die Öffnungsmenge muss größer als 0 sein',
    pleaseinputcorrectopenvolume:
        'Bitte geben Sie die korrekte Öffnungsmenge ein',
    pleaseinputrightentrustprice:
        'Bitte geben Sie den korrekten Bestellpreis ein',
    noenoughposition: 'Unzureichende liquidierbare Positionen',
    pleaseinputclosevolume: 'Die Abschlussmenge muss größer als 0 sein',
    pleaseinputcorrectclosevolume:
        'Bitte geben Sie die korrekte Abschlussmenge ein',
    option1: 'Antenne',
    option2: 'Umfang',
    option3: 'Monatszeile',
    trTxt: 'Transaktionsvolumen',
    trTxt2: 'Marktpreis',
    trTxt3: 'Verkaufsvolumen',
    trSuccess: 'erfolgreich eingereicht',
    udTxt: 'Margin-Modus',
    udTxt2: 'Vertragsmultiplikatoren anpassen',
    udTxt3: 'Hebel',
    udTxt4: 'Nachhaltig',
    udTxt4_1: 'Nachhaltig',
    udTxt5: 'Übertragbarer Betrag',
    udTxt6: 'Menge übertragen',
    udTxt7: 'Alles übertragen',
    udTxt8: 'Dauerkonto',
    ubtn: 'Volles Lager',
    ubtn2: 'Isolierte Lage',
    uplaceholder: 'Unbefristetes Vertragskonto',
    uradio1: 'Zeit teilen',
    uradio2: '15 Minuten',
    uradio3: '1 Stunde',
    uradio4: '4 Stunden',
    uradio5: '1 Tag',
    uth1: 'Vertragsmultiplikator',
    uth2: 'Einkommen',
    uth3: 'Rendite',
    uth4: 'Offenes Interesse',
    uth5: 'Kann gleichmäßig gemessen werden',
    uth6: 'durchschnittlicher Eröffnungspreis',
    uth7: 'Starke Parität',
    uth8: 'Aktuelle Marge',
    uth9: 'Margensatz',
    zhang: 'offen',
    or: 'oder',
    ureg: 'Jetzt registrieren',
    uTxt8: 'Handel',
    uTab: 'Eröffnen Sie eine Position',
    uTab2: 'Geschlossene Position',
    uTxt9: 'Kann lange geöffnet werden',
    uTxt10: 'Kann kurz geöffnet werden',
    uTxt11: 'Bestellpreis',
    uplaceholder2: 'Transaktion zum besten Preis auf dem Markt',
    uTxt12: 'Kaufen, um Long zu eröffnen',
    uTxt13: 'kurz verkaufen',
    closeup: 'kurz kaufen',
    closedown: 'Lange verkaufen',
    uTab3: 'Gewinn mitnehmen und Verlust stoppen',
    uTxt14: 'Mein Vertragskonto',
    ulabel1: 'Kontomodus',
    ulabel2: 'Gesamtkontoeinkommen',
    ulabel3: 'Positionsrand',
    ulabel4: 'Auslastungsgrad der Mittel',
    ulabel5: 'viele',
    ulabel6: 'Null',
    uTxt15: 'lang',
    uTxt16: 'kurz',
    total: 'gesamt',
    limited_price: 'Limit-Order',
    market_price: 'Marktordnung',
    spot_price: 'Gewinn mitnehmen und Verlust stoppen',
    ent_status1: 'Auf Provision',
    ent_status2: 'Widerrufen',
    ent_status3: 'Die Delegation ist fehlgeschlagen',
    ent_status4: 'Delegation erfolgreich',
    ent_statusblast: 'Liquidation',
    uTxt17: 'Long-Positionen können geschlossen werden',
    uTxt18: 'Short-Position kann geschlossen werden',
    prev: 'Vorherige',
    next: 'Nächste',
    prtxt: 'Mein Einladungslink',
    copy: 'Kopieren',
    prtxt2: 'Mein Freund der ersten Stufe',
    prtxt3: 'Meine sekundären Freunde',
    prtxt4: 'Provisionseinnahmen (entspricht USDT)',
    prtxt5: 'Zusätzliche Belohnungen',
    prtxt6: 'Partnerschaftsebene',
    prtit: 'Regeldetails',
    prdesc:
        'Der [Promotion-Partner] von Alcex ist eine Promotion-Aktivität mit der höchsten Provisions-Rabattquote und der längsten Rabattdauer (maximaler Lifetime-Rabatt) im gesamten Netzwerk, die es Promotern, die entsprechende Anstrengungen unternehmen, ermöglicht, ein echter „Partner“ der Plattform zu werden und die zu genießen Dividenden des gemeinsamen Wachstums mit der Alcex-Handelsplattform! ',
    prli1:
        '1. Um das Konzept digitaler Assets zu fördern und den Nutzerumfang zu erweitern, hat Alcex das [Promotion Partner]-Programm ins Leben gerufen, das seit langem wirksam ist.',
    prli2:
        '2. Das Einladen von Freunden ist in zwei Ebenen unterteilt. Wenn A B einlädt, ist B der Freund der ersten Ebene von A. Wenn B C einlädt, ist C der Freund der ersten Ebene von B und auch der Freund der zweiten Ebene von A.',
    prli3:
        '3. Wenn Sie sich als Werbefreund registrieren, müssen Sie den vom Einladenden bereitgestellten Link verwenden oder den Einladungscode des Einladenden manuell eingeben, um der Freund der ersten Ebene des Einladenden zu werden.',
    prli4:
        '4. Der Rabattanreiz kann erst wirksam werden, nachdem sich der Eingeladene mit dem Einladungscode des Einladenden registriert und die Authentifizierung mit echtem Namen abgeschlossen hat.',
    prli5:
        '5. Der Rabattzeitraum beträgt N Monate ab dem Zeitpunkt, an dem der Eingeladene die Authentifizierung mit echtem Namen abschließt. Je nach Stufe ist das Rabattverhältnis unterschiedlich.',
    prli6:
        '6. Der Aktionsrabattwert ist die Transaktionsgebühr der Währung des untergeordneten Freundes, und die Rabattwährung sind die drei wichtigsten Marktbasiswährungen, nämlich USDT, BTC und ETH.',
    prli7:
        '7. Das Rabattverhältnis und die Rabattdauer der Beförderung korrelieren positiv mit der Anzahl der beförderten Freunde der ersten Ebene. Je mehr Personen Sie befördern, desto höher ist das Rabattverhältnis, das Sie genießen können. ',
    prli8:
        '8. Die Top 10 oder Top 100 in der Rangliste der Werbeprovisionen erhalten von Zeit zu Zeit zusätzliche Belohnungen, die offiziell von Alcex vergeben werden.',
    prli9:
        '9. Sollten andere Aktivitäten im Widerspruch zu den Aktivitäten des [Werbepartners] stehen, werden vorübergehende Änderungen nach Rücksprache beschlossen. Wir hoffen auf Ihr Verständnis seitens der Partner.',
    prli10:
        '10. Partner der Stufen L4 (Gouverneur), L5 (Präfekt) und L6 (Jiedushi) erhalten Dividendenprämien in Höhe von 5 %, 10 % bzw. 15 % der gesamten jährlichen Provisionsrückerstattung.',
    prli11:
        '11. Das endgültige Interpretationsrecht dieser Aktivität liegt bei Alcex.',
    prth1: 'Ebene',
    prth2: 'Anzahl der Freunde der ersten Ebene',
    prth3: 'Rabattverhältnis/Rabattzeit für Freunde der ersten Ebene',
    prth4: 'Rabattverhältnis/Rabattzeit für Freunde der zweiten Ebene',
    prth5: 'Partnerdividenden',
    prlev1: 'Li Zheng',
    prlev2: 'Bezirksrichter',
    prlev3: 'Präfekt',
    prlev4: 'Gouverneur',
    prlev5: 'Präfekt',
    prlev6: 'Jiedushi',
    prtd1: 'Monate',
    prtd2: 'Lebenslanger Rabatt',
    prtit2: 'Zum Beispiel',
    prtxt7:
        'Wenn der Benutzer Xiao Yan 100 Freunde der ersten Ebene einlädt und jeder seiner Freunde der ersten Ebene 5 Freunde der zweiten Ebene einlädt, hat Xiao Yan 100 Freunde der ersten Ebene und 500 Freunde der zweiten Ebene. ',
    prtxt8:
        'Wenn der Benutzer Xiao Yan 1.000 Freunde der ersten Ebene einlädt und jeder seiner Freunde der ersten Ebene 5 Freunde der zweiten Ebene einlädt, hat Xiao Yan 1.000 Freunde der ersten Ebene und 5.000 Freunde der zweiten Ebene. ',
    prtit3: 'Werbe-Toolbox',
    prtit4: 'Top 20 der Aktionsrabatte insgesamt (Gesamtliste)',
    prtit5: 'Top 20 Promoter (Gesamtliste)',
    prtxt9: 'Statistikschluss:',
    prth6: 'Rangfolge',
    prth7: 'Mitglied',
    prth8: 'Aktionsnummer',
    prth9: 'Rabatt (entspricht USDT)',
    prth10: 'Zusätzliche Belohnungen',
    prtip1:
        'Die oben genannten Provisionsdaten werden nicht in Echtzeit aktualisiert und das System zählt und aktualisiert sie alle 24 Stunden.',
    prtip2:
        'Die Anzahl der Eingeladenen ist die Anzahl der Freunde der ersten Ebene, und diese Liste ist die Rangfolge des gesamten Rabattbetrags',
    prtip3:
        'Die Anzahl der Eingeladenen ist die Anzahl der Freunde der ersten Ebene, und diese Liste ist die Rangfolge der Anzahl der Eingeladenen der ersten Ebene.',
    recth1: 'Ankunftszeit',
    recth2: 'Netzwerkprotokoll',
    recth3: 'Einzahlungsadresse',
    recth4: 'Auflademenge',
    recbtn: 'Aufladen des Münzabhebungscodes',
    recplaceholder: 'Bitte geben Sie den Auszahlungscode ein',
    recstatus_0: 'scheitern',
    recstatus_1: 'Nicht erhalten',
    recstatus_2: 'Schon angekommen',
    wittit: 'Abhebung digitaler Währungen',
    wittext: 'Abhebungsprotokoll',
    wittext2: 'Auszahlungscode',
    wittext3: 'Liste der Münzabhebungscodes',
    witbtn: 'Münzcode abheben Münze abheben',
    witbtn2: 'Generieren Sie einen Währungsabhebungscode',
    witdia: 'Sicherheitsüberprüfung',
    witdia2: 'Auszahlungscode erfolgreich generiert',
    witplaceholder: 'Bitte geben Sie den Auszahlungsbetrag ein',
    witerr: 'Bitte wählen Sie die Währung aus',
    addresstip: 'Bitte geben Sie die Adresse ein',
    witstate: 'Einzulösen',
    witstate2: 'scheitern',
    witstate3: 'Einlösung abgeschlossen',
    witstatus_1: 'in Prüfung',
    witstatus_2: 'Übertragen',
    witstatus_3: 'scheitern',
    witstatus_4: 'Erfolg',
    type1: 'überweisen',
    type2: 'Handel mit Kryptowährungen',
    type3: 'Fiat-Währung kaufen',
    type4: 'Fiat-Währung verkaufen',
    type5: 'Aktivitätspreis',
    type6: 'Werbeprämien',
    type7: 'Dividende',
    type8: 'Abstimmung',
    type9: 'Manuelles Aufladen',
    type10: 'Paar',
    type11: 'Event-Einlösung',
    type12: 'C2C kaufen',
    type13: 'C2C-Verkauf',
    type14: 'rote Umschläge',
    type15: 'Erhalte einen roten Umschlag',
    type16: 'Münzcode abheben Münze abheben',
    type17: 'Münzcode abheben und Münze einzahlen',
    type18: 'Unbefristete Vertragsbearbeitungsgebühr',
    type19: 'Gewinn aus unbefristetem Vertrag',
    type20: 'Unbefristeter Vertragsverlust',
    type21: 'Der Optionsvertrag ist fehlgeschlagen',
    type22: 'Bearbeitungsgebühr für Optionskontrakte',
    type23: 'Optionsvertragsbonus',
    type24: 'Vertragsrückerstattung',
    type25: 'Level-Belohnungen',
    type26: 'Einnahmen aus Plattformgebühren',
    type27: 'Zweiter Vertrag scheiterte',
    type28: 'Zweiter Vertragsbonus',
    type29: 'finanzielles Interesse',
    type30: 'Gelder ausgeben',
    type31: 'Bezug von Fördergebühren',
    type32: 'Festinvestition kaufen',
    type33: 'Verkauf von Anlageinvestitionen',
    type34: 'regelmäßig kaufen',
    type35: 'Regelmäßige Einlösung',
    type36: 'Münzbasierte Vertragsübertragung',
    type37: 'Münzbasierter Vertragstransfer',
    type38: 'U-basierte Vertragsübertragung',
    type39: 'U-basierter Vertragstransfer',
    type40: 'Zweiter Vertragstransfer',
    type41: 'Zweiter Vertragsübergang',
    type42: 'Münztransfer',
    type43: 'Münztransfer aus',
    lctit: 'Bankkonto',
    lcbtn: 'Übertragung der Finanzverwaltung',
    lcth1: 'Kumulierte Zinsen',
    day: 'Himmel',
    lctxt: 'Aktivitätswährung',
    lctxt2: 'Akzeptierte Währungen',
    lctxt3: 'Startmenge',
    lctxt4: 'Auswahlzeitraum',
    year: 'Jahr',
    month: 'Mond',
    ldtab1: 'Positionsaufzeichnung',
    ldtab2: 'Abschlussunterlagen',
    ldth1: 'Beginnen Sie mit dem Verdienstdatum',
    ldth2: 'Einlösungsdatum',
    ldth3: 'Umsatzschätzung',
    ldth4: 'Einkommen',
    attit: 'Ändern Sie den Anlageinvestitionsplan',
    attxt: 'Investitionsmenge',
    attxt2: 'Investitionszyklus',
    atth1: 'Fester Anlagetermin',
    atth2: 'Fester Investitionsbetrag',
    atth3: 'Transaktionsrate',
    success: 'Erfolg',
    cxth1: 'Name',
    cxth2: 'Typ',
    cxth3: 'Abonnementmenge',
    cxth4: 'Teilnehmende Währungen',
    cxth5: 'Abonnementeinheit',
    cxth6: 'Aktueller Status',
    cxth7: 'Umsatz',
    cxth8: 'Erstellungszeitpunkt',
    cxnav1: 'ich habe teilgenommen',
    cxnav2: 'Meine Bergbaumaschine',
    cxnav3: 'Meine Sperrposition',
    cxtit2: 'Meine Mining-Maschinenliste',
    cxtit3: 'Meine Sperrliste',
    cxkth1: 'Ausgabewährung',
    cxkth2: 'Produktionszyklus',
    cxkth3: 'Bergbauzeit',
    cxkth4: 'Abgebaute Zeit',
    cxkth5: 'Grundkapazität',
    cxkth6: 'Tatsächliche Kapazität',
    cxstate1: 'Nicht bereitgestellt',
    cxstate2: 'Im Einsatz',
    cxstate3: 'Widerrufen',
    cxstate4: 'ausstehende Transaktion',
    cxstate5: 'über',
    cxstate6: 'Freigegeben werden',
    cxstate7: 'freigeben',
    cxcth1: 'Währung freigeben',
    cxcth2: 'Totale Blockade',
    cxcth3: 'Gesamtveröffentlichungszeitraum',
    cxcth4: 'Freigegebener Zyklus',
    cxcth5: 'Basisversion',
    cxcth6: 'tatsächliche Veröffentlichung',
    more: 'Mehr',
    abtit: 'Plattformfunktionen',
    abtit2: 'über uns',
    abtit3: 'kontaktiere uns',
    abtit4: 'Alcex-Gemeinschaft',
    abdesc:
        'Alcex ist die weltweit führende Technologieplattform für den Handel mit digitalen Vermögenswerten. Das Unternehmen ist auf den Kaimaninseln registriert und sein Kernbetriebsteam befindet sich in Hongkong. ',
    abdesc2:
        'Die Alcex-Plattform verfügt über eine professionelle Transaktionsstruktur auf Finanzebene und eine selbst entwickelte Transaktions-Engine mit hoher Parallelität und Speicheranpassung. Die Plattform verwendet ein vollständig kaltes Auflade-Wallet-System + Multisignatur + DDOS-Angriffssystem mit hohem Schutz und andere Transaktionsstrukturen, um sicherzustellen die Sicherheit der Kundenvermögenswerte.',
    abdesc3:
        'Damit Kunden Investitionsmöglichkeiten besser nutzen können, führt Alcex ein flaches Management innerhalb der Kundendienstabteilung ein und richtet einen extrem schnellen Service-Reaktionsmechanismus ein. Der Asset-Kundendienstmanager ist das ganze Jahr über 7 x 24 Stunden online, um Kunden Dienstleistungen wie Asset zu bieten Aufladen und Abheben, um sicherzustellen, dass Kunden innerhalb von 5 Minuten antworten können. Vollständige Ein- und Auszahlung von Vermögenswerten.',
    abdesc4:
        'Alcex wählt strikt qualitativ hochwertige Projekte aus und bietet sichere und stabile Vermögensverwahrungsdienste. ',
    abdesc5:
        'Auch wenn es derzeit nur einen kleinen Kreis digitaler Vermögenswerte gibt, glauben wir, dass diese Art von Vermögenswerten, die jeder vollständig kontrollieren kann, in Zukunft definitiv populär werden wird. Lassen Sie uns zusammenarbeiten und freuen Sie sich darauf!',
    abdesc6: 'Kundendienst',
    abdesc7: 'Technische Unterstützung',
    abdesc8: 'Antrag auf Münzauflistung',
    abdesc9: 'Vorschläge',
    abdesc10: 'Zusammenarbeit von Unternehmen',
    abdesc11: 'WeChat',
    abdesc12: 'Weibo',
    abdesc13: 'Twitter',
    abdesc14: 'Verwendung von Münzen',
    abdesc15: 'Telegraph',
    abdesc16:
        'Fügen Sie „bmate601“ als WeChat-Freund hinzu und treten Sie der WeChat-Wohlfahrtsgemeinschaft bei',
    realtxt: 'tatsächlicher Name',
    realtxt2: 'ID-Nummer',
    realtxt3: 'Foto auf der Vorderseite des Personalausweises',
    realtxt4: 'Foto auf der Rückseite des Personalausweises',
    realtxt5: 'Foto mit Personalausweis und persönlicher Unterschrift',
    realtxt6:
        '1. JPG- und PNG-Formate werden unterstützt und die Dateigröße beträgt weniger als 8 MB. Die Informationen auf dem Zertifikat sind deutlich sichtbar und es ist keine Änderung oder Blockierung zulässig. Die Zertifikatsnummer und der Name müssen deutlich sichtbar sein.',
    realtxt7:
        '2. Das Foto muss ohne Hut und ohne Make-up aufgenommen werden. Die Gesichtszüge der Person, die das Dokument hält, müssen deutlich sichtbar sein und die Arme müssen vollständig sichtbar sein.',
    realtxt8:
        '3. Sie müssen das Zertifikat und die handschriftliche Kopie (Name + Bewerbungsdatum) gleichzeitig in der Hand halten. Alle meine Handlungen auf dieser Website werden von mir selbst durchgeführt, ich bin mir der damit verbundenen Risiken bewusst und bin bereit, alle rechtlichen Konsequenzen zu tragen dieses Kontos. ',
    realbtn: 'Klicken Sie zum Hochladen',
    realstate: 'Kein richtiger Name',
    realstate2: 'Schon richtiger Name',
    uploadimg:
        'Bitte laden Sie ein Foto Ihres Personalausweises mit der Vorderseite hoch',
    uploadimg2:
        'Bitte laden Sie ein Foto der Rückseite Ihres Personalausweises hoch',
    uploadimg3: 'Bitte laden Sie ein Foto Ihres Personalausweises hoch',
    uploaderr:
        'Die Größe des hochgeladenen Bildes darf nicht überschritten werden',
    save_success: 'Erfolgreich gespeichert!',
    save_failure: 'Speichern fehlgeschlagen!',
    close: 'Schließung',
    hztxt: 'Bestätigen Sie die Übertragung',
    submittip1:
        'Um die Sicherheit Ihres Geldes zu gewährleisten, führen Sie bitte zuerst die [Authentifizierung mit echtem Namen] durch!',
    submittip2:
        'Um die Sicherheit Ihres Geldes zu gewährleisten, führen Sie bitte zuerst die [Mobiltelefonbindung] durch!',
    submittip3:
        'Zur Sicherheit Ihrer Gelder legen Sie bitte zuerst ein [Fondspasswort] fest!',
    submittip4: 'Bitte binden Sie mindestens eine Zahlungsart ein',
    aderr1: 'Bitte geben Sie die richtige Nummer ein',
    aderr2: 'Der Prämienwert beträgt 0-20',
    aderr3: 'Der Prämienwert liegt zwischen 0 und 20 und darf nicht 0 sein',
    aderr4: 'Bitte geben Sie den korrekten Festpreis ein',
    aderr5:
        'Bitte geben Sie die richtige Zahl ein und der maximale Transaktionsbetrag überschreitet nicht 100 Münzen',
    orderTypeNo: 'NEIN',
    orderTypeYes: 'Ja',
    win: 'Erfolg',
    lose: 'scheitern',
    tied: 'ziehen',
    cancelsuccess: 'Stornierung erfolgreich!',
    shelves:
        'Die Anzeigen können erst gelöscht werden, nachdem sie entfernt wurden!',
    shelves2:
        'Eine Bearbeitung ist erst möglich, nachdem die Anzeige entfernt wurde!',
    business: 'Bitte beantragen Sie zunächst die Händlerzertifizierung!',
    swappleaseselectwallet:
        'Bitte wählen Sie zunächst die Überweisung/das Überweisungskonto aus',
    swappleaseinputamount: 'Bitte geben Sie den Überweisungsbetrag ein',
    clickchange: 'Klicken Sie, um die Wischrichtung zu ändern',
    daterange: 'Bitte wählen Sie einen Suchzeitraum aus',
    apierr: 'Bitte geben Sie Bemerkungsinformationen ein',
    apierr2: 'Bitte geben Sie die IP-Adresse ein',
    apierr3: 'Bitte geben Sie die richtige IP-Adresse ein',
    apierr4: 'Bitte geben Sie den Bestätigungscode ein',
    clickget: 'Klicken Sie hier, um es zu erhalten',
    apisecond: 'Zweite',
    apitips:
        'Der Schlüssel wird erst angezeigt, wenn er hinzugefügt wird. Bitte speichern Sie ihn rechtzeitig',
    apiaccess: 'Schlüssel',
    do_favorite: 'gesammelt',
    cancel_favorite: 'Favoriten abbrechen',
    fail_favorite: 'scheitern',
    deptotal: 'Gesamtsumme',
    chart: 'Diagramm',
    book: 'Auftragsbuch',
    trade: 'Letzte Transaktion',
    pt_title: 'Geschenkkarte in digitaler Währung',
    pt_desc:
        'Nach der Einlösung werden Sie automatisch zu einem Freund mit niedrigerem Level und können Freunden, Verwandten, Kunden und Partnern Geschenke schicken. ',
    pt_more: 'Weitere Tools, bitte bleiben Sie dran',
    pt_tips:
        'Wenn Sie eine gute Idee haben, senden Sie bitte eine E-Mail an promotion@al-cex.com und Sie werden belohnt, wenn Sie angenommen werden!',
    pt_card_amount: 'Betrag der Kartenvorderseite',
    pt_card_deadline: 'Gültigkeitszeitraum',
    pt_card_noend: 'unbegrenzt',
    pt_card_btn: 'Werbepartner erhalten 30 Tickets kostenlos (ca. 2.000 Yuan)',
    pt_card_btn_login: 'Melden Sie sich an und erhalten Sie 30 Tickets kostenlos',
    pt_card_rule: 'Regeldetails',
    pt_card_summary:
        'Geschenkkarten in digitaler Währung sind eines der Werbetools, die entwickelt wurden, um Werbepartnern die Möglichkeit zu geben, Offline-Freunde besser einzuladen. Wenn Benutzer von Werbezielen Geschenkkarten einlösen, werden sie automatisch zu Offline-Freunden des Veranstalters.',
    pt_card_rule1:
        '1. Jeder Benutzer ist auf 30 kostenlose Geschenkkarten beschränkt. ',
    pt_card_rule2:
        '2. Jeder Benutzer ist auf 1 kostenlose Geschenkkarte beschränkt. ',
    pt_card_rule3:
        '3. Bevor der Benutzer die Geschenkkarte einlöst, wird der Benutzer automatisch als Freund der ersten Ebene des Kartenausstellers zugeordnet, wenn es keinen „Einladenden“ gibt (d. h. bei der Registrierung des Kontos wurde kein Einladungscode eingegeben). ',
    pt_card_rule4:
        '4. Um betrügerische Transaktionen zu verhindern, wird der Kontobetrag der Geschenkkarte nicht in Echtzeit eingelöst und die Authentifizierung mit echtem Namen muss nach Erhalt vor dem Betrieb abgeschlossen sein.',
    pt_card_rule5:
        '5. Sie müssen eine Authentifizierung mit echtem Namen durchführen, wenn Sie kostenlose Geschenkkarten erhalten. Sie müssen keine Authentifizierung mit echtem Namen durchführen, wenn Sie sie einlösen. Dies soll es Veranstaltern erleichtern, schneller Freunde auf niedrigerem Niveau zu gewinnen.',
    pt_card_rule6:
        '6. Das endgültige Auslegungsrecht der kostenlosen Geschenkkarte liegt bei Alcex.',
    pt_card_day: 'Himmel',
    pt_card_title_tips:
        'Erhalten Sie ein Förderstipendium in Höhe von 2.000 Yuan',
    pt_invite_desc:
        'Exklusiver QR-Code, Sie können durch die QR-Code-Registrierung automatisch ein untergeordneter Freund werden, der für die Weiterleitung in verschiedene Gruppen oder Foren geeignet ist.',
    saveimage: 'Bild speichern',
    imagetips:
        'Wenn Sie das Bild nicht normal herunterladen können, verwenden Sie bitte ein Screenshot-Tool, um einen Screenshot zu erstellen und ihn zu speichern!',
    pt_card_receivew_success: 'Glückwunsch! ',
    usepromotion: 'Verwenden Sie Werbematerialien',
    context_title: 'Exklusiver Einlösungscode für Aktionspartner',
    context_title1: 'Scannen Sie, um mehr zu erfahren',
    context_title2: 'mein Einladungscode',
    context_title3: 'Einladungsbild',
    pt_card_title: 'Partner-Werbekarte',
    copysuccess: 'Erfolgreich kopiert!',
    copyerr: 'Kopieren fehlgeschlagen! ',
    noopening: 'Derzeit sind keine Optionskontrakte ausstehend',
    createSuccess: 'Erfolgreich erstellt',
    tradewarning1: 'Geben Sie bis zu 2 Dezimalstellen ein',
    tradewarning2: 'Der Bestellwert beträgt',
    tradewarning3: 'Geben Sie bis zu 8 Dezimalstellen ein',
    tradewarning4: 'Die Bestellmenge beträgt',
    tradeconfirmbuyin: 'Bestätigen Sie den Kauf',
    tradeconfirmsellout: 'Bestätigen Sie den Verkauf',
    tradeinfo: {
        emaildone: 'E-Mail-zertifiziert',
        emailundo: 'E-Mail nicht authentifiziert',
        teldone: 'Die Mobiltelefonnummer wurde verifiziert',
        telundo: 'Mobiltelefonnummer nicht bestätigt',
        idcarddone: 'Der Personalausweis wurde verifiziert',
        idcardundo: 'Personalausweis nicht authentifiziert',
        exchangetimes: 'Anzahl der Transaktionen',
        price: 'Preis',
        num: 'Menge',
        paymethod: 'Bezahlverfahren',
        exchangelimitamount: 'Transaktionslimit',
        location: 'Standort',
        location_text: 'China',
        exchangeperiod: 'Transaktionszeitraum',
        minute: 'Minute',
        amounttip: 'Bitte geben Sie den Betrag ein',
        numtip: 'Bitte Menge eingeben',
        remarktip: 'Sagen Sie ihm Ihr Anliegen',
        remarktitle: 'Bemerkungen',
        exchangetitle: 'Handelsanweisungen',
        exchange_tip1:
            'Nachdem Sie eine Transaktionsanforderung initiiert haben, wird die digitale Währung treuhänderisch gesperrt und durch die Plattform geschützt. ',
        exchange_tip2:
            'Bitte lesen Sie vor dem Handel die „Plattform-Netzwerk-Servicebedingungen“ und Hilfedokumente wie FAQs und Transaktionsleitfäden.',
        exchange_tip3: 'Vorsicht vor Betrügern! ',
        exchange_tip4:
            'Bitte beachten Sie, dass Rundungen und Preisschwankungen Auswirkungen auf den endgültigen Betrag der gehandelten digitalen Währung haben können. ',
        exchange_tip5:
            'Treuhanddienste schützen Käufer und Verkäufer von Online-Transaktionen. ',
        warning1: 'Geben Sie bis zu 2 Dezimalstellen ein',
        warning2: 'Der Bestellwert beträgt',
        warning3: 'Geben Sie bis zu 8 Dezimalstellen ein',
        warning4: 'Die Bestellmenge beträgt',
        confirmbuyin: 'Bestätigen Sie den Kauf',
        confirmsellout: 'Bestätigen Sie den Verkauf',
        buyin: 'Kaufen',
        sellout: 'verkaufen',
        warning5: 'Bitte füllen Sie die Bestellung nach Bedarf aus',
    },
    convertResult:'Vorschau des Konvertierungsergebnisses',
    willGet:'Du wirst bekommen',
    pleaseCommit: 'Bitte klicken Sie, um innerhalb dieser Zeit zu bestätigen. ',
    returnText:'return',
    convert:'konvertieren'
};
