export default {
  testText1: '이곳은 시험장이에요! ',
  testText2: '@비잔컴',
  testText3: '@bzengineer (유일한 채널, 속임을 조심하세요)',
  meun1: '첫 장',
  meun2: '한 번의 클릭으로 코인 구매',
  meun3: '거래',
  meun3_1: '현물 거래',
  meun3_2: '빠른 교환',
  meun4: '계약 거래',
  meun4_1: 'U 표준 계약',
  meun4_2: '코인 마진 계약',
  meun4_3: '옵션 계약',
  meun4_4: '두 번째 계약',
  meun5: '금융 서비스',
  meun5_1: '혁신 연구실',
  meun5_2: '프로모션 파트너',
  meun6: '재무 관리',
  meun7: '발표',
  meun8: '로그인',
  meun9: '등록하다',
  meun10: '지갑',
  meun10_1: '현물 계좌',
  meun10_2: 'U 기반 계약 계정',
  meun10_3: '코인 마진 계약 계좌',
  meun10_4: '두 번째 기반 계약',
  meun10_5: '재무 회계',
  meun10_6: '지갑 내역',
  meun11: '주문하다',
  meun11_1: '현물 주문',
  meun11_2: 'C2C 주문',
  meun11_3: '재정관리명령',
  meun11_4: '플래시 스왑 기록',
  meun11_5: '혁신경영',
  meun12: '다운로드',
  meun13_1: '계정 관리',
  meun13_2: '결제수단',
  meun13_3: '내 프로모션',
  meun13_4: 'API 관리',
  meun13_5: '로그아웃',
  meun14_1: '언어',
  meun14_2: '환율',
  meun14_3: '언어를 선택하세요',
  meun14_4: '환율 선택',
  openTrade: '언제 어디서나 거래를 시작하세요.',
  scan: 'IOS 및 Android QR 코드를 스캔하여 다운로드하세요.',
  footmeun1: '~에 대한',
  footmeun1_1: '회사 소개',
  footmeun1_2: '우리와 함께',
  footmeun1_3: '플랫폼 발표',
  footmeun1_4: '백지',
  footmeun2: '지원 센터',
  footmeun2_1: '초보자 가이드',
  footmeun2_2: '일반적인 문제',
  footmeun2_3: '거래 가이드',
  footmeun2_4: '통화정보',
  footmeun3: '이용약관',
  footmeun3_1: '부인 성명',
  footmeun3_2: '개인 정보 정책',
  footmeun3_3: '사용자 계약',
  footmeun3_4: '관세 설명',
  footmeun4: '문의하기',
  footmeun4_1: '고객 서비스 이메일',
  footmeun4_2: '사업협력',
  footmeun4_3: '코인 상장 신청',
  footmeun4_4: '불만 사항',
  foottext: '글로벌 고품질 디지털 자산 거래소',
  indexTxt1: '세계 50대 디지털 화폐 거래소',
  indexTxt2: '국가 기관이 자산 안전을 보장합니다',
  indexTxt3: '초보자 가이드',
  indexTxt4: '코인을 빨리 구매하세요',
  indexTxt5: 'Alcex 공식 초보자 입장 보조 채널',
  indexLabel1: '암호화폐 거래',
  indexVal1: '디지털 자산을 사용하여 직접 교환',
  indexLabel2: '옵션 거래',
  indexVal2: '디지털자산 파생상품',
  indexLabel3: '계약 거래',
  indexVal3: '코인표준, U표준계약',
  indexLabel4: '빠른 교환',
  indexVal4: '즉각적인 환전, 한 번의 클릭으로 거래 완료',
  indexth: '가격 추세',
  indexbtn: '지금 거래하세요',
  indexh1: '솔직한',
  indexh2: '공정한',
  indexh3: '열광',
  indexh4: '열려 있는',
  indexh5: '금융 등급 보안',
  indexh6: '매우 빠른 입출금',
  indexh7: '글로벌 서비스',
  indexh8: '엄선된 프로젝트',
  indexp1:
    'Alcex은 초기 비트코인 ​​참가자 및 괴짜 그룹에 의해 설립되었으며 팀의 핵심 구성원은 Google, Microsoft, Alibaba 및 Tencent와 같은 유명 기업 출신으로 심층적인 R&D 역량과 인터넷 제품 운영에    대한 풍부한 경험을 보유하고 있습니다.',
  indexp2:
    'Alcex은 기본적인 블록체인 서비스 제공업체로 자리매김하여 전 세계 사용자에게 고품질 암호화폐 자산 거래 플랫폼을 제공하기 위해 최선을 다하고 있습니다. "악을 행하지 않는다"라는 기본 원칙을  준수하며 정직하고 공정하며 열정적으로 고객에게 서비스를 제공할 것을 주장합니다. 모든 이해관계자를 열린 태도로 환영합니다.사용자의 근본적인 이익에 도움이 되는 파트너/프로젝트.',
  indexp3:
    '종합적인 금융 리스크 관리 시스템과 도난 방지 시스템, 핫 월렛과 콜드 월렛, 다중 서명 시스템으로 자금의 안전을 보장합니다.',
  indexp4:
    '입출금은 3분 안에 완료할 수 있으며, 24시간 수동 온라인 검토를 통해 고객이 최고의 투자 기회를 놓치지 않도록 보호합니다.',
  indexp5:
    '글로벌 암호화폐 자산에 투자하고 글로벌 사용자와 거래할 수 있도록 지원하는 글로벌 비즈니스 서비스 네트워크 범위',
  indexp6:
    '고품질 암호화 프로젝트를 엄격하게 선택하고 위험도가 매우 높은 프로젝트의 80%를 필터링합니다.',
  indexp7: 'QR 코드를 스캔하고 앱을 다운로드하세요',
  scandownload: '스캔하여 앱을 다운로드하세요',
  ormenu1: 'C2C 주문',
  ormenu2: '내 주문',
  ormenu3: '돈을 얻다',
  please: '먼저 부탁드립니다',
  account: 'Alcex 계정 로그인',
  phone: '전화 번호',
  email: '우편',
  sign: '로그인',
  password: '비밀번호',
  password2: '비밀번호',
  password3: '비밀번호를 잊어버려',
  account2: 'Alcex 계정 만들기',
  logErr: '사용자 이름을 입력해주세요',
  logErr2: '비밀번호를 입력 해주세요',
  logErr3: '비밀번호 길이는 6자 미만일 수 없습니다.',
  logSuccess: '성공적 로그인',
  account3: '개인 계정 만들기',
  phone1: '휴대폰 등록',
  email1: '이메일 등록',
  email2: '이메일 주소',
  code: 'SMS 인증코드',
  code2: '인증 코드 보내기',
  code3: '확인 코드',
  password4: '비밀번호 확인',
  invite: '초대 코드(선택사항)',
  agree: '읽었으며 이에 동의합니다.',
  agreement: '사용자 계약',
  register: '등록하다',
  regErr: 'SMS 인증번호를 입력해주세요',
  confirmpwdtip: '확인 비밀번호를 입력해주세요',
  confirmpwderr: '두 개의 비밀번호 입력이 일치하지 않습니다!',
  wjtab: '휴대폰 비밀번호 재설정',
  wjtab2: '이메일 비밀번호 재설정',
  wjlabel: '새 비밀번호',
  wjerr: '새로운 비밀번호를 입력해주세요',
  labTxt: 'Alcex 연구소 ● 혁신 성과 공유',
  labTxt2:
    '투자자, 프로젝트 당사자, 거래소 및 기타 당사자를 위한 상생 개방형 실험실',
  labTab1: '모두',
  labTab2: '곧 시작됩니다',
  labTab3: '진행 중',
  labTab4: '배포',
  labTab5: '완전한',
  tokendistribute: '토큰이 배포되고 있습니다',
  activitytype: '구독 유형',
  labStart: '시작 시간',
  labEnd: '종료 시간',
  labStartPlaceholder: '시작 시간을 선택하세요.',
  labDetail: '세부 사항을 확인하세요',
  labSpeen: '일정',
  labNumber: '총 활동',
  labType1: '최초 온라인(급하게 구매)',
  labType2: '처음으로 온라인에 접속함(분할 포함)',
  labType3: '위치 공유',
  labType4: '무료 구독',
  labType5: '클라우드 마이닝 머신 구독',
  labType6: '잠금 해제',
  baseinfo: '기본 정보',
  ruledetail: '유형 설명',
  releaseType: '출시 유형',
  releaseType0: '동일한 금액으로 출시',
  releaseType1: '균등 비율 출시',
  lock: '기간 릴리스',
  releaseDetail: '출시 세부정보',
  lockday: '낮',
  lockweek: '주',
  lockmonth: '달',
  lockyear: '년도',
  releasePercent: '출시 비율',
  lockFee: '잠금 임계값',
  releaseTimes: '여러 개 출시',
  times: '타임스',
  publishprice: '구독 가격',
  holdcoin: '포지션 보유에 필요한 통화',
  limittimes: '개인 구매 한도',
  limitamount: '개인 구매 한도',
  unlimited: '제한 없음',
  leveloneCount: '필요한 1레벨 친구 수',
  alreadyamount: '구매 수량',
  leftamount: '남은 금액',
  myalreadyholdamount: '나의 참여 포지션',
  alreadyholdamount: '이벤트 참가자의 총 보유량',
  currentdivided: '나의 현재 위치는 대략 다음과 같이 나눌 수 있습니다.',
  holdtips:
    '[포지션 공유] 유형의 활동에 참여하려면 활동이 끝날 때까지 위치를 고정해야 합니다.',
  inputholdamount: '잠금 금액을 입력하세요.',
  pleaseinputamount: '상환수량을 입력해주세요',
  pleaseinputholdamount: '잠금 금액을 입력하세요.',
  inputamount: '상환금액을 입력하세요.',
  inputminingamount: '구매할 채굴기 수를 입력하세요.',
  attend: '지금 참여하세요',
  attention: '알아채다',
  attentiontxt1:
    '1. [위치 공유], [무료 구독], [클라우드 마이닝 머신 구독], [락업] 유형의 활동에 참여하기 위해 제출한 후 자산은 동결되며 활동이 끝난 후 동결 또는 잠금 해제가 해제됩니다. .',
  attentiontxt2:
    '2. [위치 공유], [무료 구독], [클라우드 마이닝 머신 구독], [락업] 유형의 활동에 참여한 후에는 참여 신청을 취소할 수 없습니다.',
  attentiontxt3:
    '3. 프로젝트 당사자 또는 기타 불가항력적인 요인으로 인해 행사가 취소될 경우 동결된 자산은 원래 경로로 반환됩니다.',
  attentiontxt4:
    '* 본 이벤트의 최종 해석권은 공식 Alcex(www.al-cex.com)에 있습니다.',
  transfer: '자금 이체',
  placeholder: '통화 검색',
  bith1: '계약 거래',
  bith2: '계정 권한',
  bith3: '미실현 손익',
  bith4: '사용 가능한 마진',
  bith5: '사용된 마진',
  bith6: '마진 동결',
  bith7: '계약계좌',
  btn1: '충전하다',
  btn2: '철회하다',
  btn3: '옮기다',
  btn4: '코인 입금',
  btn5: '코인 출금',
  totalAssets: '전환된 총 자산',
  th1: '통화명',
  th2: '사용 가능한 자산',
  th3: '자산 동결',
  th4: '공개될 자산',
  th5: '작동하다',
  czTxt: '디지털 화폐 충전',
  czTxt2: '통화',
  czTxt3: '환승 네트워크',
  czTxt4: '사용 가능한 잔액',
  czTxt5: '출금주소',
  czTxt6: '인출된 코인의 양',
  czTxt7: '도착 수량',
  czTxt8: '수수료',
  czTxt9: '친절한 팁',
  czTxt10: '최소 입금액:',
  czTxt10_1: '최소 금액 미만의 충전은 적립되지 않습니다.',
  czTxt11:
    '위 주소로 비화폐 자산을 입금하지 마세요. 그렇지 않으면 해당 자산이 회수되지 않습니다.',
  czTxt12:
    '위 주소로 충전한 후 전체 블록체인 네트워크 노드의 확인이 필요하며 일반적으로 메인 블록체인 네트워크에서 3번의 네트워크 확인 후에 계정에 적립됩니다.',
  czTxt13:
    '충전주소는 자주 변경되지 않으며, 반복적으로 충전이 가능하며, 변경사항이 있을 경우 홈페이지 공지사항이나 이메일을 통해 알려드릴 수 있도록 최선을 다하겠습니다.',
  czTxt14:
    '정보가 변조되거나 유출되는 것을 방지하기 위해 컴퓨터와 브라우저가 안전한지 확인하십시오.',
  czTxt15: '충전기록',
  czTxt16:
    '자금의 보안을 보장하기 위해 계정 보안 정책을 변경하거나 비밀번호를 수정하거나 새 주소를 사용하여 코인을 인출할 경우 인출에 대한 수동 검토가 진행됩니다. 인내심을 갖고 직원의 연락을 기다려  주십시오. 전화나 이메일로 연락주세요.',
  czTxt17: '최소 인출 금액은 다음과 같습니다.',
  czth1: '출금시간',
  czth2: '~에서',
  czth3: '에게',
  czth4: '즉시 상환 수량',
  czth5: '교환비율',
  czth6: '교환수량',
  placeholder2: '들어 오세요',
  placeholder3: '선택해주세요',
  czEmpty: '어떤 기록도 없다',
  logintip: '먼저 로그인을 해주세요',
  cztip:
    '충전주소를 신청하지 않으셨습니다. 버튼을 클릭하시면 충전주소를 받으실 수 있습니다.',
  czget: '충전 주소 받기',
  czplaceholder: '프로토콜을 선택하세요',
  mAccount: '두 번째 계약 계좌',
  btn6: '찾다',
  wtTxt: '시작 및 종료 시간',
  wtTxt2: '작업 유형',
  wtth1: '거래 시간',
  wtth2: '유형',
  wtth3: '통화',
  wtth4: '수량',
  wtth5: '처리 수수료 지불',
  wtth6: '취급 수수료 공제',
  wtth7: '실제 수수료',
  wtth8: '상태',
  nav1: '계정 보안',
  nav2: '내 초대장',
  nav3: '나의 홍보카드',
  acTxt: '보안 레벨',
  acbtn1: '인증되지 않음',
  acbtn2: '제본',
  acbtn3: '개정하다',
  acbtn4: '검증됨',
  acbtn5: '경계',
  acbtn6: '설정',
  acbtn7: '검토중',
  acbtn8: '다시 해 보다',
  acTxt2: '확인됨',
  acTxt3: '우편',
  acTxt4: '휴대폰',
  acTxt5: '로그인 비밀번호',
  acTxt6: '펀드 비밀번호',
  acTxt7: '구글 OTP',
  acTxt8: '펀드 비밀번호 설정',
  acTxt9: '펀드 비밀번호 변경',
  acTxt10: '펀드 비밀번호 찾기',
  acDesc2: '계정 보안을 위해 거래 전 실명인증을 완료해주세요!',
  acDesc3: '이메일 바인딩',
  acDesc4: '휴대폰 바인딩',
  acDesc5: '플랫폼에 로그인할 때 사용됩니다.',
  acDesc6: '계좌 자금이 변경되면 자금 비밀번호를 확인해야 합니다.',
  acDesc7: 'Google OTP를 바인딩해 주세요',
  acDesc8: 'Google OTP가 바인딩되었습니다.',
  acDesc9: '바인딩 해제',
  acTit: '휴대폰 바인딩',
  acTit2: '이메일 바인딩',
  acTit3: '구글 바인딩',
  acTit4: 'Google 바인딩 해제',
  mailtip: '이메일을 입력해주세요',
  emailerr2: '이메일 형식이 올바르지 않습니다. 다시 입력해 주세요.',
  agreementtip: '사용자 계약을 읽고 동의해 주세요.',
  acverify: '검토 실패',
  again: '다시 시도해 주세요',
  verifiedmsg: '먼저 실명인증을 해주세요',
  apiTxt: '실명계좌 바인딩',
  apiTxt2: '힌트',
  apiTxt3:
    '시장조회, 자동매매 등의 서비스를 이용할 수 있는 강력한 API를 제공합니다. ',
  apiTxt4: '각 사용자는 최대 5개의 API 키 세트를 생성할 수 있습니다.',
  apiTxt5: '자산 손실을 방지하려면 API 키를 공개하지 마세요. ',
  apiTxt6: 'API 노트',
  apiTxt7: '공인 IP(공인 IP 주소의 키는 90일 동안 유효합니다.)',
  apibtn: '만들다',
  apith1: 'API 노트',
  apith2: '생성 시간',
  apith3: '액세스 키',
  apith4: 'IP 주소 바인딩',
  apith5: '남은 유효기간(일)',
  chTxt: '로그인 비밀번호 변경',
  chTxt2: '바인딩할 코드 스캔',
  chTxt3: '인증번호를 입력해주세요',
  chTxt4: '기존 펀드 비밀번호',
  chTxt5: '새로운 펀드 비밀번호',
  chTxt6: '새 비밀번호를 확인하세요',
  chTxt7: '비밀번호를 잊어버려',
  chTxt8: '휴대폰 인증 변경',
  chTxt9: '원래 로그인 비밀번호',
  chTxt10: '새로운 로그인 비밀번호',
  chTxt11: '이메일 인증코드',
  chTxt12: '휴대폰 인증코드',
  telerr: '휴대폰 번호가 올바르지 않습니다.',
  emailerr: '이메일이 잘못되었습니다',
  codeerr: '잘못된 인증 코드',
  reset: '초기화',
  save: '구하다',
  chtip: '원래 비밀번호를 입력해주세요',
  chtip2: '새로운 로그인 비밀번호를 6자리 이상 입력해주세요',
  chtip3: '새 로그인 비밀번호가 일치하지 않습니다.',
  chtip4: '올바른 펀드 비밀번호를 입력해주세요',
  chtip5: '비밀번호는 6자 이상이어야 합니다.',
  chtip6: '비밀번호는 6자리 이상 입력해주세요',
  chtip7: '비밀번호가 일치하지 않습니다.',
  chtip8: '펀드 비밀번호를 입력해주세요',
  chtip9: '전화번호를 입력해주세요',
  chtip10: '비밀번호를 입력해주세요',
  realnametip: '실명을 입력해주세요',
  idcardtip: '귀하의 ID 번호를 입력해주세요',
  clTxt: '결제수단을 설정하시고 반드시 실명계좌를 이용해 주시기 바랍니다.',
  clTxt2: '결제 수단 추가',
  edit: '편집하다',
  delete: '삭제',
  name: '이름',
  clTxt3: 'ID 번호',
  clTxt4: 'IBAN 번호',
  clTxt5: '모든 결제 수단',
  clLink1: '계정 이름',
  clLink2: '결제 수단 수정',
  clLink3: '결제 세부정보(선택사항)',
  clplaceholder1: '당신의 성명을 입력 해주세요',
  clplaceholder2: '은행계좌 비밀번호를 입력해주세요',
  clplaceholder3: '결제 세부정보를 입력하세요.',
  cltip: '특별 참고 사항',
  cltip2: '즉시결제를 위해 반드시 은행카드번호를 추가해주세요. ',
  cltip3:
    '알림: 디지털 화폐를 판매할 때 선택한 결제 방법이 구매자에게 표시됩니다. ',
  clTxt6: '선택 과목',
  upload: '업로드',
  uploadTip: '(JPG/JPEG/PNG/BMP 형식 지원, 1MB 이하)',
  tgbtn: '코드를 사용',
  tgth1: '카드 이름',
  tgth2: '코드를 사용',
  tgth3: '카드화폐',
  tgth4: '카드 액면가',
  tgth5: '총',
  tgth6: '이미 사용함',
  tgth7: '수집 시간',
  tgbtn2: '홍보 자료',
  tgbtn3: '지금 사용하세요',
  tgplaceholder: '상환 코드를 입력하세요',
  exchangesuccess: '상환에 성공했습니다! ',
  inth1: '사용자 초대',
  inth2: '등록 시간',
  inth3: '실명현황',
  adTxt: '내 광고',
  adTxt2:
    '광고의 최소 거래 금액과 수수료를 더한 금액이 남은 광고 수량보다 클 경우 해당 광고는 자동으로 서가에서 제거됩니다.',
  adbtn: '클리어 조건',
  adbtn2: '선반 위에',
  adbtn3: '선반에서 제거됨',
  adth1: '광고번호',
  adth2: '광고 유형',
  adth3: '주문 한도',
  adth4: '남은 금액',
  adtip: '삭제 확인',
  num_text1: '원하시는 내용을 입력해주세요',
  num_text2: '수량',
  wTxt: '현물 상품',
  wTxt2: '현재 주문',
  wTxt3: '거래 쌍',
  wTxt4: '거래 방향',
  wth1: '시간',
  wth2: '방향',
  wth3: '가격',
  wth4: '거래 완료',
  wth5: '회전율',
  wth6: '대리인 유형',
  wth7: '방아쇠 가격',
  wth8: '최종 가격',
  wth9: '주문 수량',
  wth10: '결제손익',
  wth11: '위임현황',
  wth12: '포지션 열기/닫기',
  wth13: '여유',
  wbtn: '주문 취소',
  wtip: '취소 알림',
  wtiptxt: '주문 취소를 확인하시겠습니까?',
  zbplaceholder: '검색을 시작하려면 주문 번호를 입력하세요',
  zbtab1: '고정 투자 계획',
  zbtab2: '고정투자실적',
  zbth1: '투자주기',
  zbth2: '스위치',
  zbth3: '누적투자금액',
  zbth4: '보유수량',
  zbth5: '평균 비용',
  zbth6: '미실현 손익',
  zbth7: '다음 투자예정일',
  zbth8: '계획 작성 날짜',
  etTxt: '공지사항 센터',
  etTxt2: '스캔하여 공유',
  etmenu1: '암호화폐',
  etmenu2: 'Alcex 서버 업데이트 안내',
  et2menu1: 'RX.Tron 소개',
  et2menu2: '비트코인은 폰지 사기입니까?',
  hisTxt: '역사위원회',
  orTab1: '미지급',
  orTab2: '이미 지불',
  orTab3: '완전한',
  orTab4: '취소 된',
  orTab5: '정치',
  orth1: '주문 번호',
  orth2: '거래통화',
  orth3: '거래 유형',
  orth4: '거래 파트너',
  orth5: '양',
  qcTab1: '이전 결과',
  qcTab2: '실시간 견적',
  qcTxt: '아니요.',
  qcTxt2: '예상하다',
  qcTxt3: '개장 시간',
  qcTxt4: '폐점 시간',
  qcTxt5: '개장 가격',
  qcTxt6: '종가',
  qcTxt7: '총 구매 가격',
  qcTxt8: '매수, 매도 총액',
  qcTxt9: '내 공개 포지션',
  qcTxt10: '예측 진행 상황',
  qcTxt11: '개장 가격',
  qcTxt12: '종가',
  qcTxt13: '이번 예측기간',
  qcbtn1: '낙관적인',
  qcbtn2: '약세',
  qcth1: '계약기간',
  qcth2: '개시금액',
  qcth3: '예측 방향',
  qcth4: '예측 결과',
  qcth5: '보너스 금액',
  qcth6: '개설비',
  qcth7: '펌프 물',
  mplaceholder: '검색할 통화명을 입력하세요',
  mTab: '선택 과목',
  mth: '모으다',
  mth1: '최신 가격',
  mth2: '24시간 상승 및 하강',
  mTxt: '24시간 최고가',
  mTxt2: '24시간 최저가',
  mTxt3: '거래수',
  mTxt4: '펀딩 비율',
  mTxt5: '열려 있는',
  mTxt6: '높은',
  mTxt7: '낮은',
  mTxt8: '받다',
  echat1: 'K-라인 차트',
  echat2: '깊이 지도',
  mTab2: '현재 위치',
  mTab3: '커미션 내역',
  mth3: '보상 보장',
  mth4: '개장 가격',
  mth5: '주기',
  mth6: '종가',
  mth7: '이익',
  mTxt9: '거래 모델',
  mTxt10: '영업 시간',
  mTxt11: '수익성',
  mTxt12: '균형',
  mTxt13: '구매량',
  mTxt14: '가운데',
  cadTxt: '광고 거래 만들기',
  cadTxt2: '자주 거래하는 경우 나만의 거래 광고를 만들 수 있습니다.',
  cadTxt2_1: '가끔씩만 거래하신다면 직접 검색해 보시길 추천드립니다',
  cadTxt2_2: '무역 광고를 만드는 것은 무료입니다.',
  cadLink2: '거래 광고',
  cadTxt3: '자신이 만든 광고를 직접 수정하려면 다음을 참조하세요.',
  cadLink3: '내 광고',
  cadTxt4: '시장 참여 가격',
  cadTxt5: '프리미엄은 현재 시장 가격보다 높은 판매 비율을 나타냅니다.',
  cadTxt6: '가격 공식',
  cadTxt7: '[Tip] 개인센터에서 결제수단 결합/추가가 가능합니다.',
  cadTxt8: '최대 거래 금액은 총 판매 금액 0CNYI를 초과할 수 없습니다.',
  cadTxt9:
    '활성화한 후 사용자가 이 광고를 통해 귀하와 거래를 시작하면 시스템은 귀하가 선택한 자동 응답 문구를 상대방에게 자동으로 보냅니다.',
  cadTxt10: '프리미엄은 현재 시장 가격보다 높은 구매 비율을 나타냅니다.',
  cadLabel1: '나는 원해요:',
  cadLabel2: '통화 거래:',
  cadLabel3: '국가:',
  cadLabel4: '통화:',
  cadLabel5: '고정 가격 켜기',
  cadLabel6: '프리미엄:',
  cadLabel7: '거래가격',
  cadLabel8: '판매 수량:',
  cadLabel9: '지불 방법:',
  cadLabel10: '최소 거래 금액:',
  cadLabel11: '최대 거래 금액:',
  cadLabel12: '자동 회신 켜기',
  cadLabel13: '펀드 비밀번호:',
  cadLabel14: '비고:',
  cadLabel15: '고정 가격:',
  cadLabel16: '자동 응답:',
  cadLabel17: '구매 수량:',
  radio: '온라인 판매',
  radio2: '온라인으로 구매',
  cadplaceholder: '프리미엄을 설정해주세요',
  cadplaceholder2: '판매하고 싶은 수량을 입력해주세요',
  cadplaceholder3: '원하는 최소 거래 금액을 입력하세요.',
  cadplaceholder4: '원하는 최대 거래 금액을 입력하세요.',
  cadplaceholder5:
    '구매자 요구 사항, 온라인 시간 등과 같은 특별 요구 사항을 비고 정보에 입력할 수 있습니다.',
  cadplaceholder6: '거래가격을 입력해주세요',
  cadplaceholder7:
    '주문 접수 후 결제수단, 결제계좌번호 등 구매자 정보에 자동으로 회신해 드립니다.',
  cadplaceholder8: '구매하고 싶은 수량을 입력해주세요',
  caderr1: '최소 거래 금액을 입력하세요.',
  caderr2: '최소 거래 금액은 100 이상이어야 합니다!',
  caderr3: '최소 거래 금액은 최대 거래 금액보다 작아야 합니다.',
  caderr4: '최대 거래 금액을 입력하세요!',
  caderr5: '정수를 입력하세요.',
  caderr6: '최대 거래 금액은 최소 거래 금액보다 커야 합니다!',
  caderr7: '최대 거래 금액은 총 판매 금액을 초과할 수 없습니다.',
  caderr8: '거래방법을 선택해주세요',
  submit: '제출하다',
  cadnew:
    '활성화 후에는 통화 가격이 시장에 따라 변동되지 않으며 가격은 변경되지 않습니다.',
  dhTxt: '원클릭 교환으로 혜택을 누려보세요',
  dhTxt2: '가격 제한',
  dhTxt3: '팔다',
  dhTxt4: '구입하다',
  dhTxt5: '최고',
  dhTxt6: '금액을 입력하세요',
  dhplaceholder: '현물 지갑 사용 가능 잔액',
  dhSuccess: '축하해요! ',
  crTxt: '법정화폐 거래',
  crTxt2: '디지털 화폐의 편리하고 안전하며 빠른 구매 및 판매',
  crTxt3: '동적 조정',
  crTxt4: '수수료 없음',
  crTxt5: '즉시거래',
  crTxt6: '플랫폼 보증',
  crTxt7: '시장 가격에 따라 실시간 변동',
  crTxt8:
    '사용자가 보는 것은 얻는 것이며, 구매 및 판매 가격 외에는 플랫폼 수수료가 없습니다.',
  crTxt9:
    '매칭을 기다리지 않고 가맹점, 지능형 매칭, 거래 주문을 처리할 수 있는 플랫폼 도입',
  crTxt10:
    '플랫폼 인증 가맹점, 안전한 보안, 거래 보호를 위한 24시간 고객 서비스',
  crth1: '상인',
  crth2: '거래수',
  crth3: '결제수단',
  crth4: '한계',
  crth5: '단가',
  crbtn: '구입하다',
  crbtn2: '판매자가 되세요',
  cr1Txt: '축하해요! ',
  cr1Txt2: '재료를 준비하다',
  cr1Txt3: '리뷰 제출',
  cr1Txt4: '검증됨',
  cr1Txt5: '귀하에게는 다음 권한이 부여됩니다',
  cr1Txt6: '전용부스',
  cr1Txt7: '일대일 서비스',
  cr1Txt8: '판매자는 거래 성공률을 높이기 위해 독점 광고 부스를 즐깁니다.',
  cr1Txt9: '낮은 수수료',
  cr1btn: '광고 게재',
  cr1btn2: '항복 신청',
  reTxt: '비잔재무관리●원스톱 투자솔루션',
  reTxt2: '즉시, 쉽고 안전하게 수익을 창출하세요',
  reTab: '정기적인 재무관리',
  reTab2: '고정투자 재무관리',
  reTxt3: '정기적인',
  reTxt4: '유연한 맞춤화, 더 높은 잠재 이익',
  reTxt6: '고정 투자',
  reTxt7: '고정 투자를 시작하고 자산을 늘리세요',
  reTxt8: '일반상품 18개 모두 확대',
  reth1: '연간 수익률',
  reth2: '마감일(일)',
  reth3: '거래 한도',
  reth4: '제품',
  reth5: '역사적 투자 수익',
  rebtn: '계획 만들기',
  redTxt: '만들다',
  redTxt2: '고정 투자 계획',
  redTxt3: '투자금액을 입력하세요',
  redTxt4: '내 사용 가능한 잔액은',
  redTxt5: '사용 가능',
  redTxt6: '주기',
  redTxt7: '매일',
  redTxt8: '주간',
  redTxt9: '달마다',
  redTxt10: '현지 시각',
  redTxt11: '첫 번째 자동 투자 주기는 다음과 같습니다.',
  redTxt12: '시작',
  confirm: '확인하다',
  confirm2: '확신하는',
  cancel: '취소',
  redongoing: '진행 중',
  redcompleted: '완전한',
  minNumErr: '최소 시작 금액 미만',
  minNumErr2: '시작 금액은 0일 수 없습니다.',
  buyamounttip: '구매금액을 입력해주세요',
  pricetipwarning: '거래 금액은 다음보다 높을 수 없습니다.',
  buyamounttipwarning: '구매 수량은 다음보다 높을 수 없습니다.',
  sellamounttip: '판매금액을 입력해주세요.',
  sellamounttipwarning: '판매 수량은 다음보다 높을 수 없습니다.',
  sellpricetip: '판매가격을 입력해주세요',
  sellpricetipwarning: '판매 가격은 다음보다 높을 수 없습니다.',
  sellmore: '가장 많이 팔릴 수 있는 것',
  sellunit: '개인',
  loginFirst: '먼저 로그인을 해주세요',
  betsuccess: '축하해요! ',
  selectAmount: '베팅 금액을 선택하세요',
  balancenotenough: '사용 가능한 잔액이 부족합니다!',
  balancenotenough2: '잔액 불충분!',
  minlimitamountfailed: '상환 금액은 최소 상환 금액보다 작을 수 없습니다!',
  maxlimitamountfailed: '교환 수량은 최대 구매 수량보다 클 수 없습니다!',
  limittimesfailed: '참여 횟수가 개인 구매 최대 횟수를 초과했습니다!',
  commitfailed: '참가 신청이 실패했습니다!',
  trplaceholder: '문의',
  trplaceholder2: '최신 가격',
  trplaceholder3: '시장에서 가장 좋은 가격으로 구매하세요',
  trplaceholder4: '시장에서 가장 좋은 가격으로 판매',
  triggleplaceholder: '기본 주문 가격은 시장 가격입니다.',
  triggerpriceshouldbigger: '트리거 가격은 0보다 커야 합니다.',
  marginModeSuccessTip: '계약 계정 모드가 성공적으로 수정되었습니다.',
  noenoughbalance: '계좌마진이 부족함',
  pleaseinputopenvolume: '개시 수량은 0보다 커야 합니다.',
  pleaseinputcorrectopenvolume: '정확한 개시 수량을 입력하세요.',
  pleaseinputrightentrustprice: '올바른 주문 가격을 입력하세요.',
  noenoughposition: '유동성이 부족한 포지션',
  pleaseinputclosevolume: '마감 수량은 0보다 커야 합니다.',
  pleaseinputcorrectclosevolume: '올바른 마감 수량을 입력하세요.',
  option1: '안테나',
  option2: '둘레',
  option3: '월간 라인',
  trTxt: '거래량',
  trTxt2: '시장 가격',
  trTxt3: '판매량',
  trSuccess: '성공적으로 제출되었습니다',
  udTxt: '마진 모드',
  udTxt2: '계약 배수 조정',
  udTxt3: '지렛대',
  udTxt4: '지속 가능한',
  udTxt4_1: '지속 가능한',
  udTxt5: '이체가능금액',
  udTxt6: '이체 수량',
  udTxt7: '모두 전송',
  udTxt8: '영구 계정',
  ubtn: '전체 창고',
  ubtn2: '고립된 위치',
  uplaceholder: '무기한 계약 계좌',
  uradio1: '시간 공유',
  uradio2: '15 분',
  uradio3: '1 시간',
  uradio4: '4 시간',
  uradio5: '1 일',
  uth1: '다수 계약',
  uth2: '소득',
  uth3: '수익률',
  uth4: '미결제약정',
  uth5: '균일하게 측정 가능',
  uth6: '평균 개장가',
  uth7: '강력한 패리티',
  uth8: '현재 마진',
  uth9: '마진율',
  zhang: '열려 있는',
  or: '또는',
  ureg: '지금 등록하세요',
  uTxt8: '거래',
  uTab: '포지션을 개설하세요',
  uTab2: '포지션 마감',
  uTxt9: '길게 열 수 있음',
  uTxt10: '짧게 열 수 있음',
  uTxt11: '주문 가격',
  uplaceholder2: '시장에서 가장 좋은 가격으로 거래',
  uTxt12: '오래오래 열려면 구매하세요',
  uTxt13: '공매도하다',
  closeup: '짧게 사다',
  closedown: '오래 팔다',
  uTab3: '이익을 얻고 손실을 막으세요',
  uTxt14: '나의 계약 계좌',
  ulabel1: '계정 모드',
  ulabel2: '총계좌소득',
  ulabel3: '포지션 마진',
  ulabel4: '자금 활용률',
  ulabel5: '많은',
  ulabel6: '없는',
  uTxt15: '긴',
  uTxt16: '짧은',
  total: '총',
  limited_price: '제한 주문',
  market_price: '시장가 주문',
  spot_price: '이익을 얻고 손실을 막으세요',
  ent_status1: '커미션 중',
  ent_status2: '취소됨',
  ent_status3: '위임 실패',
  ent_status4: '위임 성공',
  ent_statusblast: '변제',
  uTxt17: '롱 포지션 청산 가능',
  uTxt18: '숏 포지션 청산 가능',
  prev: '이전의',
  next: '다음',
  prtxt: '내 초대 링크',
  copy: '복사',
  prtxt2: '나의 1레벨 친구',
  prtxt3: '나의 보조친구들',
  prtxt4: '수수료 수입(USDT와 동일)',
  prtxt5: '추가 보상',
  prtxt6: '파트너십 수준',
  prtit: '규칙 세부정보',
  prdesc:
    'Alcex의 [프로모션 파트너]는 전체 네트워크에서 가장 높은 수수료 리베이트 비율과 가장 긴 리베이트 기간(최대 평생 리베이트)을 제공하는 프로모션 활동으로, 이에 상응하는 노력을 기울인 프로모터 가 플랫폼의 진정한 "파트너"가 되어 즐길 수 있습니다. Alcex 트레이딩 플랫폼과 함께 성장하는 배당금! ',
  prli1:
    '1. 디지털 자산의 개념을 알리고 사용자 규모를 확대하기 위해 Alcex은 오랫동안 유효한 [프로모션 파트너] 프로그램을 출시했습니다.',
  prli2:
    '2. 친구 초대는 2단계로 나누어 A가 B를 초대하면 B는 A의 1급 친구가 되고, B가 C를 초대하면 C는 B의 1급 친구이자 A의 2급 친구가 됩니다.',
  prli3:
    '3. 프로모션 친구 등록 시, 초대자가 제공한 링크를 이용하거나 초대자의 초대코드를 직접 입력해야 초대자의 1급 친구가 됩니다.',
  prli4:
    '4. 리베이트 인센티브는 초대받은 사람이 초대자의 초대코드로 등록하고 실명인증을 완료한 후에만 적용됩니다.',
  prli5:
    '5. 리베이트 기간은 피초청자 실명인증 완료일로부터 N개월이며, 등급에 따라 리베이트 비율이 다릅니다.',
  prli6:
    '6. 프로모션 리베이트 자산은 하위 친구 통화의 거래 수수료이며 리베이트 통화는 3대 주요 시장 기본 통화, 즉 USDT, BTC, ETH입니다.',
  prli7:
    '7. 프로모션 리베이트 비율과 리베이트 기간은 승진한 1급 친구 수와 정비례하며, 홍보하는 사람이 많을수록 리베이트 비율도 높아집니다. ',
  prli8:
    '8. 프로모션 커미션 순위 상위 10위 또는 상위 100위는 Alcex에서 공식적으로 발행하는 추가 보상을 수시로 받게 됩니다.',
  prli9:
    '9. 타 활동이 [프로모션 파트너] 활동과 상충될 경우 협의 후 임시 변경이 결정되오니, 파트너 여러분의 양해를 바랍니다.',
  prli10:
    '10. L4(Governor), L5(Prefect), L6(Jiedushi) 레벨 파트너는 각각 총 연간 커미션 리베이트의 5%, 10%, 15%의 배당금 보상을 받습니다.',
  prli11: '11. 본 활동에 대한 최종 해석권은 Alcex에 귀속됩니다.',
  prth1: '수준',
  prth2: '1급 친구 수',
  prth3: '1차 친구 리베이트 비율/리베이트 시간',
  prth4: '2차 친구 리베이트 비율/리베이트 시간',
  prth5: '파트너 배당금',
  prlev1: '리 정',
  prlev2: '카운티 치안판사',
  prlev3: '지사',
  prlev4: '지사',
  prlev5: '지사',
  prlev6: '지에두시',
  prtd1: '개월',
  prtd2: '평생 리베이트',
  prtit2: '예를 들어',
  prtxt7:
    '사용자 Xiao Yan이 1급 친구 100명을 초대하고, 그의 1급 친구 각각이 5명의 2급 친구를 초대하면 Xiao Yan은 1급 친구 100명과 2급 친구 500명이 됩니다. ',
  prtxt8:
    '사용자 Xiao Yan이 1급 친구 1,000명을 초대하고, 그의 1급 친구 각각이 5명의 2급 친구를 초대하면 Xiao Yan은 1급 친구 1,000명과 2급 친구 5,000명을 갖게 됩니다. ',
  prtit3: '프로모션 도구 상자',
  prtit4: '총 프로모션 리베이트 상위 20개(전체 목록)',
  prtit5: '상위 20개 발기인(전체 목록)',
  prtxt9: '통계 마감일:',
  prth6: '순위',
  prth7: '회원',
  prth8: '프로모션 번호',
  prth9: '리베이트(USDT와 동일)',
  prth10: '추가 보상',
  prtip1:
    '위 커미션 데이터는 실시간으로 업데이트되지 않으며, 시스템에서 24시간마다 집계하여 업데이트합니다.',
  prtip2:
    '초대받은 사람 수는 1급 친구 수이며, 이 목록은 총 리베이트 금액 순위입니다.',
  prtip3:
    '초대받은 사람 수는 1급 친구 수이고, 이 목록은 1급 초대받은 사람 수의 순위입니다.',
  recth1: '도착 시간',
  recth2: '네트워크 프로토콜',
  recth3: '입금주소',
  recth4: '충전 수량',
  recbtn: '코인출금코드 충전',
  recplaceholder: '출금코드를 입력해주세요',
  recstatus_0: '실패하다',
  recstatus_1: '받지 못했다',
  recstatus_2: '이미 도착했어요',
  wittit: '디지털 화폐 인출',
  wittext: '출금기록',
  wittext2: '출금코드',
  wittext3: '코인 출금 ​​코드 목록',
  witbtn: '코인 출금 ​​코드 코인 출금',
  witbtn2: '화폐 인출 코드 생성',
  witdia: '안전성 검증',
  witdia2: '출금코드가 성공적으로 생성되었습니다',
  witplaceholder: '출금금액을 입력해주세요',
  witerr: '통화를 선택하세요',
  addresstip: '주소를 입력해주세요',
  witstate: '상환 예정',
  witstate2: '실패하다',
  witstate3: '상환 완료',
  witstatus_1: '검토중',
  witstatus_2: '전송 중',
  witstatus_3: '실패하다',
  witstatus_4: '성공',
  type1: '옮기다',
  type2: '암호화폐 거래',
  type3: '법정화폐 구매',
  type4: '법정화폐 판매',
  type5: '활동상',
  type6: '프로모션 보상',
  type7: '피제수',
  type8: '투표',
  type9: '수동충전',
  type10: '쌍',
  type11: '이벤트 상환',
  type12: 'C2C 구매',
  type13: 'C2C 판매',
  type14: '빨간 봉투',
  type15: '빨간 봉투를 받다',
  type16: '코인 출금 ​​코드 코인 출금',
  type17: '코인 코드 출금 및 코인 입금',
  type18: '무기한 계약 처리 수수료',
  type19: '무기한 계약 수익',
  type20: '무기한 계약 손실',
  type21: '옵션 계약 실패',
  type22: '옵션 계약 처리 수수료',
  type23: '옵션 계약 보너스',
  type24: '계약 리베이트',
  type25: '레벨 보상',
  type26: '플랫폼 수수료 수입',
  type27: '두 번째 계약 실패',
  type28: '두 번째 계약 보너스',
  type29: '금전적 이해관계',
  type30: '지출 자금',
  type31: '펀딩 수수료 받기',
  type32: '고정 투자 구매',
  type33: '고정투자 매도',
  type34: '일반 구매',
  type35: '정기상환',
  type36: '코인 기반 계약 양도',
  type37: '코인 기반 계약 이체',
  type38: 'U 기반 계약 이전',
  type39: 'U 기반 계약 이전',
  type40: '두 번째 계약 양도 양도',
  type41: '두 번째 계약 이전',
  type42: '코인 이체 이체',
  type43: '코인 이체',
  lctit: '재무 회계',
  lcbtn: '재무관리 이전',
  lcth1: '누적이자',
  day: '하늘',
  lctxt: '활동 통화',
  lctxt2: '허용되는 통화',
  lctxt3: '시작 수량',
  lctxt4: '선발기간',
  year: '년도',
  month: '달',
  ldtab1: '직위기록',
  ldtab2: '마감 기록',
  ldth1: '적립 시작일',
  ldth2: '상환 날짜',
  ldth3: '예상 수익',
  ldth4: '소득',
  attit: '고정 투자 계획 수정',
  attxt: '투자금액',
  attxt2: '투자주기',
  atth1: '고정 투자 날짜',
  atth2: '고정투자금액',
  atth3: '거래율',
  success: '성공',
  cxth1: '이름',
  cxth2: '유형',
  cxth3: '구독 수량',
  cxth4: '참여 통화',
  cxth5: '구독 단위',
  cxth6: '현재 상태',
  cxth7: '회전율',
  cxth8: '생성 시간',
  cxnav1: '참여했어요',
  cxnav2: '나의 채굴기',
  cxnav3: '내 잠금 위치',
  cxtit2: '내 채굴기 목록',
  cxtit3: '내 잠금 목록',
  cxkth1: '출력 통화',
  cxkth2: '생산주기',
  cxkth3: '채굴 시간',
  cxkth4: '채굴 시간',
  cxkth5: '기본 용량',
  cxkth6: '실제 용량',
  cxstate1: '배포되지 않음',
  cxstate2: '배포됨',
  cxstate3: '취소됨',
  cxstate4: '보류 중인 거래',
  cxstate5: '~ 위에',
  cxstate6: '발매 예정',
  cxstate7: '방출',
  cxcth1: '통화 출시',
  cxcth2: '총 락업',
  cxcth3: '총 출시 기간',
  cxcth4: '출시주기',
  cxcth5: '기본 릴리스',
  cxcth6: '실제 출시',
  more: '더',
  abtit: '플랫폼 기능',
  abtit2: '회사 소개',
  abtit3: '문의하기',
  abtit4: '비잔 커뮤니티',
  abdesc:
    'Alcex은 기술력을 갖춘 세계 최고의 디지털 자산 거래 플랫폼으로 케이맨 제도에 등록되어 있으며 핵심 운영팀은 홍콩에 위치하고 있습니다. ',
  abdesc2:
    'Alcex 플랫폼은 전문적인 금융 수준의 거래 구조와 자체 개발한 높은 동시성 메모리 매칭 거래 엔진을 갖추고 있으며, 플랫폼은 완전 콜드 재충전 지갑 시스템 + 다중 서명 + 높은 보호 DDOS 공격 시스템 및 기타 거래 구조를 채택하여 보장합니다. 고객 자산의 안전.',
  abdesc3:
    '고객이 더 나은 투자 기회를 포착할 수 있도록 Alcex은 고객 서비스 부서 내 평면 관리를 채택하고 매우 빠른 서비스 응답 메커니즘을 구축합니다.자산 고객 서비스 관리자는 연중무휴 24시간 온라인으로 고객에게 자산과 같은 서비스를 제공합니다. 충전 및 출금을 통해 고객이 5분 이내에 응답할 수 있도록 보장 자산 입출금을 완료합니다.',
  abdesc4:
    'Alcex은 고품질의 프로젝트를 엄격하게 선정하여 안전하고 안정적인 자산보관 서비스를 제공합니다. ',
  abdesc5:
    '지금은 디지털 자산이 작은 범위에 불과하지만, 누구나 완벽하게 통제할 수 있는 이러한 자산이 미래에는 반드시 대중화될 것이라고 믿습니다. 함께 노력하고 기대해 주세요!',
  abdesc6: '고객 서비스',
  abdesc7: '기술적 지원',
  abdesc8: '코인 상장 신청',
  abdesc9: '제안',
  abdesc10: '사업협력',
  abdesc11: '위챗',
  abdesc12: '웨이보',
  abdesc13: '트위터',
  abdesc14: '코인사용',
  abdesc15: '전신',
  abdesc16:
    '"bmate601"을 WeChat 친구로 추가하고 WeChat 복지 커뮤니티에 입장하세요',
  realtxt: '실제 이름',
  realtxt2: 'ID 번호',
  realtxt3: '신분증 앞면 사진',
  realtxt4: '신분증 뒷면 사진',
  realtxt5: '신분증과 본인 서명을 들고 있는 사진',
  realtxt6:
    '1. JPG, PNG 형식을 지원하며, 파일 크기는 8M 이하, 인증서에 기재된 정보가 명확히 보이도록 수정 및 차단이 불가하며, 인증서 번호와 이름이 선명하게 표시되어야 합니다.',
  realtxt7:
    '2. 사진은 모자나 화장을 하지 않은 상태로 촬영되어야 하며, 서류를 들고 있는 사람의 얼굴 이목구비와 팔이 완전히 노출되어야 합니다.',
  realtxt8:
    '3. 증명서와 자필사본(이름+신청일자)을 동시에 보유해야 하며, 본 사이트에서의 모든 행위는 본인에 의해 수행되며, 관련 위험을 인지하고 있으며, 모든 법적 결과를 감수할 의향이 있습니다. 이 계정의. ',
  realbtn: '업로드하려면 클릭하세요.',
  realstate: '실명이 아님',
  realstate2: '이미 실명',
  uploadimg: '신분증 앞면 사진을 업로드해주세요.',
  uploadimg2: '신분증 뒷면 사진을 업로드해주세요.',
  uploadimg3: '신분증 사진을 업로드해주세요.',
  uploaderr: '업로드된 이미지의 크기는 다음을 초과할 수 없습니다.',
  save_success: '성공적으로 저장 되었음!',
  save_failure: '저장에 실패했습니다!',
  close: '폐쇄',
  hztxt: '이체 확인',
  submittip1: '자금의 안전을 위해 먼저 [실명인증]을 진행해주세요!',
  submittip2: '자금의 안전을 위해 먼저 [휴대폰 바인딩]을 진행해주세요!',
  submittip3: '고객님의 자금 안전을 위해 먼저 [펀드 비밀번호]를 설정해주세요!',
  submittip4: '하나 이상의 결제 수단을 연결하세요.',
  aderr1: '정확한 숫자를 입력해주세요',
  aderr2: '프리미엄 값은 0-20입니다.',
  aderr3: '프리미엄 값은 0~20이며 0일 수 없습니다.',
  aderr4: '올바른 고정 가격을 입력하세요.',
  aderr5:
    '정확한 숫자를 입력해 주시고, 최대 거래 금액은 100코인을 초과하지 마세요.',
  orderTypeNo: '아니요',
  orderTypeYes: '예',
  win: '성공',
  lose: '실패하다',
  tied: '그리다',
  cancelsuccess: '취소가 완료되었습니다.',
  shelves: '광고는 제거된 후에만 삭제할 수 있습니다!',
  shelves2: '수정은 광고가 삭제된 후에만 가능합니다!',
  business: '먼저 가맹점 인증을 신청해주세요!',
  swappleaseselectwallet: '먼저 이체/이체 계좌를 선택해주세요',
  swappleaseinputamount: '이체금액을 입력해주세요',
  clickchange: '스와이프 방향을 전환하려면 클릭하세요.',
  daterange: '검색 기간을 선택하세요.',
  apierr: '비고정보를 입력해주세요',
  apierr2: 'IP 주소를 입력하세요',
  apierr3: '올바른 IP 주소를 입력하세요.',
  apierr4: '인증번호를 입력해주세요',
  clickget: '얻으려면 클릭하세요',
  apisecond: '두번째',
  apitips: '키는 추가된 경우에만 표시됩니다. 제때에 저장하세요.',
  apiaccess: '열쇠',
  do_favorite: '모은',
  cancel_favorite: '즐겨찾기 취소',
  fail_favorite: '실패하다',
  deptotal: '총계',
  chart: '차트',
  book: '주문서',
  trade: '최신 거래',
  pt_title: '디지털 화폐 기프트 카드',
  pt_desc:
    '상환 후 자동으로 하위 친구가 되며, 친구, 친척, 고객, 파트너에게 선물을 보낼 수 있습니다. ',
  pt_more: '더 많은 도구를 기대해 주세요.',
  pt_tips:
    '좋은 아이디어가 있으시면 Promotion@al-cex.com로 이메일을 보내주시면 채택되면 보상을 드립니다!',
  pt_card_amount: '카드 액면가',
  pt_card_deadline: '유효기간',
  pt_card_noend: '무기한',
  pt_card_btn: '프로모션 파트너는 티켓 30장을 무료로 받습니다(약 2,000위안).',
  pt_card_btn_login: '로그인하고 티켓 30장을 무료로 받으세요',
  pt_card_rule: '규칙 세부정보',
  pt_card_summary:
    '디지털화폐 상품권은 프로모션 파트너가 오프라인 친구를 더 잘 초대할 수 있도록 개발된 프로모션 도구 중 하나이며, 프로모션 대상 사용자가 기프트 카드를 사용하면 자동으로 프로모터의 오 프라인 친구가 됩니다.',
  pt_card_rule1: '1. 각 사용자당 무료 기프트 카드는 30개로 제한됩니다. ',
  pt_card_rule2: '2. 각 사용자당 무료 기프트 카드는 1개로 제한됩니다. ',
  pt_card_rule3:
    '3. 사용자가 기프트 카드를 사용하기 전, "초대인"이 없는 경우(즉, 계정 등록 시 초대 코드를 입력하지 않은 경우) 사용자는 자동으로 카드 발급사의 1차 친구로 연결됩니다. ',
  pt_card_rule4:
    '4. 부정거래 방지를 위해 상품권 계좌금액은 실시간으로 환급되지 않으며, 수령 후 반드시 실명인증을 거쳐야 이용이 가능합니다.',
  pt_card_rule5:
    '5. 사은품 증정 시 실명인증을 완료해야 하며, 교환 시에는 실명인증을 완료할 필요가 없습니다.',
  pt_card_rule6: '6. 사은품 증정품의 최종 해석권은 Alcex에 귀속됩니다.',
  pt_card_day: '하늘',
  pt_card_title_tips: '승진지원금 2000위안 받기',
  pt_invite_desc:
    '전용 QR코드로, QR코드 등록을 통해 자동으로 하위 친구가 될 수 있어 다양한 그룹이나 포럼에 전달하기에 적합합니다.',
  saveimage: '사진을 저장하다',
  imagetips:
    '이미지가 정상적으로 다운로드 되지 않을 경우, 스크린샷 도구를 이용하여 스크린샷을 찍어 저장해주세요!',
  pt_card_receivew_success: '축하해요! ',
  usepromotion: '홍보자료를 활용하세요',
  context_title: '프로모션 파트너 전용 교환 코드',
  context_title1: '자세히 알아보려면 스캔하세요',
  context_title2: '내 초대 코드',
  context_title3: '초대장 사진',
  pt_card_title: '파트너 프로모션 카드',
  copysuccess: '성공적으로 복사되었습니다!',
  copyerr: '복사하지 못했습니다. ',
  noopening: '현재 보류 중인 옵션 계약이 없습니다.',
  createSuccess: '성공적으로 생성되었습니다',
  tradewarning1: '소수점 2자리까지 입력하세요.',
  tradewarning2: '주문금액은',
  tradewarning3: '소수점 8자리까지 입력하세요.',
  tradewarning4: '주문수량은',
  tradeconfirmbuyin: '구매 확인',
  tradeconfirmsellout: '판매 확인',
  tradeinfo: {
    emaildone: '이메일 인증됨',
    emailundo: '이메일이 인증되지 않았습니다.',
    teldone: '휴대폰번호가 인증되었습니다',
    telundo: '휴대폰 번호가 확인되지 않았습니다.',
    idcarddone: '신분증이 확인되었습니다',
    idcardundo: '신분증이 인증되지 않음',
    exchangetimes: '거래수',
    price: '가격',
    num: '수량',
    paymethod: '결제수단',
    exchangelimitamount: '거래 한도',
    location: '위치',
    location_text: '중국',
    exchangeperiod: '거래기간',
    minute: '분',
    amounttip: '금액을 입력해주세요.',
    numtip: '수량을 입력해주세요',
    remarktip: '그에게 당신의 요청을 말해',
    remarktitle: '비고',
    exchangetitle: '거래 지침',
    exchange_tip1:
      '거래 요청을 시작한 후 디지털 통화는 에스크로에 잠겨 플랫폼에 의해 보호됩니다. ',
    exchange_tip2:
      '거래 전, "플랫폼 네트워크 서비스 약관"과 FAQ, 거래 가이드 등 도움말 문서를 읽어보시기 바랍니다.',
    exchange_tip3: '사기꾼을 조심하세요! ',
    exchange_tip4:
      '반올림 및 가격 변동은 거래되는 디지털 통화의 최종 금액에 영향을 미칠 수 있습니다. ',
    exchange_tip5:
      '에스크로 서비스는 온라인 거래의 구매자와 판매자를 보호합니다. ',
    warning1: '소수점 2자리까지 입력하세요.',
    warning2: '주문금액은',
    warning3: '소수점 8자리까지 입력하세요.',
    warning4: '주문수량은',
    confirmbuyin: '구매 확인',
    confirmsellout: '판매 확인',
    buyin: '구입하다',
    sellout: '팔다',
    warning5: '필요에 따라 주문을 작성해주세요.',
  },
  convertResult:'변환 결과 미리보기',
  willGet:'당신은 얻을 것입니다',
  pleaseCommit: '이 시간 내에 확인하려면 클릭하세요. ',
  returnText:'반환',
  convert:'변환'
};
