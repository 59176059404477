import request from '../request'

// 忘记密码验证码
export function resetMobileCode(data) {
	return request({
		url: '/uc/mobile/reset/code',
		method: 'POST',
		data: data
	})
}


// 重置邮箱验证码
export function resetEmailCode(data) {
	return request({
		url: '/uc/reset/email/code',
		method: 'POST',
		data: data
	})
}


// 注册支持的国家
export function getCountry(data) {
	return request({
		url: '/uc/support/country',
		method: 'POST',
		data: data
	})
}


// 邮箱注册
export function registerEmail(data) {
	return request({
		url: '/uc/register/email',
		method: 'POST',
		data: data
	})
}

// 注册验证码发送
export function mobileCode(data) {
	return request({
		url: '/uc/mobile/code',
		method: 'POST',
		data: data
	})
}


// 手机注册
export function regPhone(data) {
	return request({
		url: '/uc/register/phone',
		method: 'POST',
		data: data
	})
}


// 注册邮箱验证码
export function emailCode(data) {
	return request({
		url: '/uc/reg/email/code',
		method: 'POST',
		data: data
	})
}

// 验证滑块
export function checkYZMPic(data) {
	return request({
		url: '/uc/checkYZMPic',
		method: 'POST',
		data: data
	})
}

// auto-main-config-feign-controller
export function autoFindAll(data) {
	return request({
		url: '/uc/autoMainConfigFeign/findAll',
		method: 'POST',
		data: data
	})
}

// 获取滑块验证码图片
export function getYZMPic(data) {
	return request({
		url: '/uc/getYZMPic',
		method: 'POST',
		data: data
	})
}



// 检查用户名是否重复
export function checkUsername(data) {
	return request({
		url: '/uc/register/check/username',
		method: 'POST',
		data: data
	})
}

// 邮箱注册
export function regEmail(data) {
	return request({
		url: '/uc/register/email',
		method: 'POST',
		data: data
	})
}


// 登录
export function login(data) {
	return request({
		url: '/uc/login',
		method: 'POST',
		data: data
	})
}


// 账户设置
export function accountSet(data) {
	return request({
		url: '/uc/approve/account/setting',
		method: 'POST',
		data: data
	})
}


// 检查是否登录
export function checkLogin(data) {
	return request({
		url: '/uc/check/login',
		method: 'POST',
		data: data
	})
}


// 检查是否登录
export function loginOut(data) {
	return request({
		url: '/uc/loginout',
		method: 'POST',
		data: data
	})
}

// 获取用户信息
export function myInfo(data) {
	return request({
		url: '/uc/member/my-info',
		method: 'POST',
		data: data
	})
}

// 获取推广合伙人信息
export function myPromotion(data) {
	return request({
		url: '/uc/promotion/mypromotion',
		method: 'POST',
		data: data
	})
}


// 获取排名前top名返佣金额 & 前top名邀请人数
export function topRank(data) {
	return request({
		url: '/uc/promotion/toprank',
		method: 'POST',
		data: data
	})
}


// 用户钱包信息
export function wallet(data) {
	return request({
		url: '/uc/asset/wallet',
		method: 'POST',
		data: data
	})
}

// 根据币种查找钱包
export function assetWallet(data) {
	return request({
		url: '/uc/asset/wallet/' + data.symbol,
		method: 'POST',
		data: data
	})
}


//  充值记录列表
export function rechargeList(data) {
	return request({
		url: '/uc/recharge/list',
		method: 'POST',
		data: data
	})
}


//  查询所有币种
export function coinList(data) {
	return request({
		url: '/uc/coin/list',
		method: 'GET',
		data: data
	})
}


//  读取地址
export function addressRead(data) {
	return request({
		url: '/uc/address/read?coinprotocol=' + data.coinprotocol,
		method: 'GET',
		data: data
	})
}

//  创建地址
export function addressCreate(data) {
	return request({
		url: '/uc/address/create',
		method: 'POST',
		data: data
	})
}


//  查询币种的余额
export function coinBalance(data) {
	return request({
		url: '/uc/coin/balance?coinName=' + data.coinName,
		method: 'GET',
		data: data
	})
}

//  提币记录列表
export function withdrawList(data) {
	return request({
		url: '/uc/withdraw/list',
		method: 'POST',
		data: data
	})
}


//  查询所有记录
export function transactionAll(data) {
	return request({
		url: '/uc/asset/transaction/all',
		method: 'POST',
		data: data
	})
}


//  提现币种详细信息
export function coinInfo(data) {
	return request({
		url: '/uc/withdraw/support/coin/info',
		method: 'POST',
		data: data
	})
}



//  查询所有支付方式
export function paymentList(data) {
	return request({
		url: '/uc/payment/list',
		method: 'GET',
		data: data
	})
}


//  获取记录
export function getRecords(data) {
	return request({
		url: '/uc/payment/getRecords',
		method: 'POST',
		data: data
	})
}


//  查询支付方式配置
export function findPayment(data) {
	return request({
		url: '/uc/payment/findPaymentTypeConfigById?id=' + data.id,
		method: 'GET',
		data: data
	})
}

//  绑定支付方式
export function savePayment(data) {
	return request({
		url: '/uc/payment/saveOrUpdate',
		method: 'POST',
		data: data
	})
}


//  根据id删除记录
export function delPayment(data) {
	return request({
		url: '/uc/payment/delRecordById',
		method: 'POST',
		data: data
	})
}

//  订单列表
export function convertList(data) {
	return request({
		url: '/uc/convert/orderList',
		method: 'POST',
		data: data
	})
}

//  推广记录查询
export function promotionRecord(data) {
	return request({
		url: '/uc/promotion/record',
		method: 'POST',
		data: data
	})
}


//  我的推广卡
export function promotionCard(data) {
	return request({
		url: '/uc/promotion/promotioncard/mycard',
		method: 'POST',
		data: data
	})
}


//  实名认证
export function realName(data) {
	return request({
		url: '/uc/approve/real/name',
		method: 'POST',
		data: data
	})
}

//  绑定邮箱
export function bindEmail(data) {
	return request({
		url: '/uc/approve/bind/email',
		method: 'POST',
		data: data
	})
}

//  发送绑定邮箱验证码
export function bindEmailCode(data) {
	return request({
		url: '/uc/bind/email/code',
		method: 'POST',
		data: data
	})
}

//  绑定手机号
export function bindPhone(data) {
	return request({
		url: '/uc/approve/bind/phone',
		method: 'POST',
		data: data
	})
}

//  绑定手机号验证码
export function bindMobileCode(data) {
	return request({
		url: '/uc/mobile/bind/code',
		method: 'POST',
		data: data
	})
}


//  设置资金密码
export function transactionPass(data) {
	return request({
		url: '/uc/approve/transaction/password',
		method: 'POST',
		data: data
	})
}


//  修改资金密码
export function transactionUpdatePass(data) {
	return request({
		url: '/uc/approve/update/transaction/password',
		method: 'POST',
		data: data
	})
}


//  支持提现的地址
export function withdrawCoin(data) {
	return request({
		url: '/uc/withdraw/support/coin',
		method: 'POST',
		data: data
	})
}

//  提币码列表
export function withdrawRecord(data) {
	return request({
		url: '/uc/withdrawcode/record',
		method: 'POST',
		data: data
	})
}


//  提币码列表
export function withdrawcodeInfo(data) {
	return request({
		url: '/uc/withdrawcode/apply/info',
		method: 'POST',
		data: data
	})
}

//  提币码列表
export function withdrawcodeRecharge(data) {
	return request({
		url: '/uc/withdrawcode/apply/recharge',
		method: 'POST',
		data: data
	})
}

//  安全设置
export function securitySet(data) {
	return request({
		url: '/uc/approve/security/setting',
		method: 'POST',
		data: data
	})
}

// 商家认证
export function businessStatus(data) {
	return request({
		url: '/uc/approve/certified/business/status',
		method: 'POST',
		data: data
	})
}


// 兑换卡详情
export function exchangecard(data) {
	return request({
		url: '/uc/promotion/promotioncard/exchangecard',
		method: 'POST',
		data: data
	})
}

// 更改登录密码验证码
export function passCode(data) {
	return request({
		// url: '/uc/mobile/update/password/code',
		url: '/uc/email/update/password/code',
		method: 'POST',
		data: data
	})
}

// 更改登录密码
export function updatePass(data) {
	return request({
		url: '/uc/approve/update/password',
		method: 'POST',
		data: data
	})
}


// 重置交易密码验证码
export function transactionCode(data) {
	return request({
		// url: '/uc/mobile/transaction/code',
		url: '/uc/email/transaction/code',
		method: 'POST',
		data: data
	})
}


// 重置资金密码
export function resetTransactionPass(data) {
	return request({
		url: '/uc/approve/reset/transaction/password',
		method: 'POST',
		data: data
	})
}


// Google
export function sendGoogle(data) {
	return request({
		url: '/uc/google/sendgoogle',
		method: 'GET',
		data: data
	})
}


 // 绑定Google
export function bindGoogle(data) {
	return request({
		url: '/uc/google/googleAuth',
		method: 'POST',
		data: data
	})
}

 // jcgoogle
export function jcgoogle(data) {
	return request({
		url: '/uc/google/jcgoogle',
		method: 'POST',
		data: data
	})
}


// 申请提币（添加验证码校验）
export function withdrawcodeApply(data) {
	return request({
		url: '/uc/withdrawcode/apply/code',
		method: 'POST',
		data: data
	})
}

// 获取全部币种
export function convertCoins(data) {
	return request({
		url: '/uc/convert/getCoins',
		method: 'POST',
		data: data
	})
}


// 根据单位获取币种
export function convertByUnit(data) {
	return request({
		url: '/uc/convert/getCoinsByUnit',
		method: 'POST',
		data: data
	})
}


// 获取价格
export function convertPrice(data) {
	return request({
		url: '/uc/convert/getPrice',
		method: 'POST',
		data: data
	})
}


// 转换
export function convertExchange(data) {
	return request({
		url: '/uc/convert/do-exchange',
		method: 'POST',
		data: data
	})
}

// 忘记密码后重置密码
export function resetPassword(data) {
	return request({
		url: '/uc/reset/login/password',
		method: 'POST',
		data: data
	})
}


// openKey
export function openKey(data) {
	return request({
		url: '/uc/open/get_key',
		method: 'GET',
		data: data
	})
}


// 绑定API发送验证码
export function apiCode(data) {
	return request({
		// url: '/uc/mobile/api/code',
		url: '/uc/email/api/code',
		method: 'POST',
		data: data
	})
}

// 绑定APIapiSave
export function apiSave(data) {
	return request({
		url: '/uc/open/api/save',
		method: 'POST',
		data: data
	})
}

// 绑定apiUpdate
export function apiUpdate(data) {
	return request({
		url: '/uc/open/api/update',
		method: 'POST',
		data: data
	})
}


// 绑定apiDel
export function apiDel(data) {
	return request({
		url: '/uc/open/api/del/'+data.id,
		method: 'GET',
		data: data
	})
}


// 广告
export function ucAdvertise(data) {
	return request({
		url: '/uc/ancillary/system/advertise',
		method: 'POST',
		data: data
	})
}


// 提现验证码
export function mobileWithdrawCode(data) {
	return request({
		url: '/uc/mobile/withdraw/code',
		method: 'POST',
		data: data
	})
}

// 提现验证码
export function emailWithdrawCode(data) {
	return request({
		url: '/uc/email/withdraw/code',
		method: 'POST',
		data: data
	})
}


// 提现保存
export function withdrawCreate(data) {
	return request({
		url: '/uc/withdraw/create',
		method: 'POST',
		data: data
	})
}


// 提现保存
export function promotionGetFreecard(data) {
	return request({
		url: '/uc/promotion/promotioncard/getfreecard',
		method: 'POST',
		data: data
	})
}



export function currencyFindAll(data) {
	return request({
		url: '/uc/currency/findAll',
		method: 'GET',
		data: data
	})
}