export default {
	cloud: '云算力',
	cloudDesc: '矿场管理专家，一键购买体验每天赚取利润！购买后支持赎回退款，给用户最大权益保障',
	clouding: '在售算力',
	cloudOrder: '我的订单',
	cloudMoney: '收益记录',
	noCloud: '暂无订单',
	// ---------------
	hasMoney: '已分红金额',
	allMoney: '项目总营收',
	totalAmount24: '成交总额',
	dayUser: '日活交易用户',
	monthUp: '当月新增',
	todayUp: '今日新增',
	allVip: '总VIP数',
	noNotice: '没有系统公告',
	activeStart: '活动开始',
	join: '参与',
	activeEnd: '活动结束',
	get: '抢购到',
	totalMoney: '项目总收益',
	totalHas: '项目总分红',
	totalMonth: '本月分红',
	personalTotal: '个人最高收益',
	personReceive: '人均收益',
	activeReceive: '活动收益排名',
	hasactiveReceive: '分红收益排名',
	now: '今日',
	week: '本周',
	month: '本月',
	nodata: '暂无数据',
	all: '累计收益排名',
	exchangeGet: '交易挖矿权益',
	getAmount: 'Alpha-Cex会员可享受指定交易对免手续费交易，0成本获得交易奖励',
	percent7Amount: '七折抢购权益',
	percent7Amountget: 'Alpha-Cex会员可享受7折抢购热门代币权益，即时享受收益42.8%<',
	random: '随机免单权益',
	randomGet: 'Alpha-Cex会员可享受对交易订单随机免费权益',
	hash: '哈希夺宝权益',
	hashGet: 'Alpha-Cex会员可享受基于智能合约的免费哈希夺宝权益，每日诞生一个百万富翁，公平透明无暗箱操作。',
	noMoney: '免手续费权益',
	noMoneyGet: '在会员身份期间，在交易挖矿中享受所有交易对交易，手续费全免权益',
	ID: 'ID额度权益',
	IDGet: 'Alpha-Cex会员将获得顶级项目的IDO份额',
	AFA: 'AFA基金权益',
	AFAGet: 'Alpha-Cex会员将获得AFA基金的收益分成',
	gudong: '股东分红权益',
	gudongGet: 'Alpha-Cex会员成功邀请新会员将获得分红代币，每月按照持股比例共同分享平台分红合约池中90%收益',
	allDown: '我们将全方位为您服务，下载Android或iOS移动端，随时随地管理财务状况',
	scandownload: '扫描二维码下载APP',
	startRegister: '注册开始交易吧！',
	nowRegister: '立即注册',
	// ------------------
	exchange: "交易所",
	copyright: '版权所',
	earnDesc: '简单，安全，立即赚取高收益',
	Register: '注册',
	emailLogin: '邮箱登录',
	Address: '地址',
	Enter: '输入',
	IAgreeAndRead: '我已同意并阅读',
	PrivacyPolicy: '隐私政策',
	And: '和',
	Noaccount: '没有账号',
	BuyCryptocurrencyQuickly: '快速购买加密货币',
	startJoin: '加入Alpha - CEX，百万奖金天天领取，做交易所真正的主人。',
	markets: '行情中心',
	CloudMining: '云算力',
	Activities: '热门活动',
	testText1: '这是一个测试站！购买商用系统请联系 技术Telegram:@Alcexhevin',
	testText2: '@Alcexcom ',
	testText3: '@bzengineer（唯一渠道，谨防被骗）',
	meun1: '首页',
	meun2: '一键买币',
	meun3: '交易',
	meun3_1: '现货交易',
	meun3_2: '快速兑换',
	meun4: '合约交易',
	meun4_1: 'U本位合约',
	meun4_2: '币本位合约',
	meun4_3: '期权合约',
	meun4_4: '秒合约',
	meun5: '金融业务',
	meun5_1: '创新实验室',
	meun5_2: '推广合伙人',
	meun6: '理财',
	meun7: '公告',
	meun8: '登录',
	meun9: '注册',
	meun10: '钱包',
	meun10_1: '现货账户',
	meun10_2: 'U本位合约账户',
	meun10_3: '币本位合约账户',
	meun10_4: '秒本位合约',
	meun10_5: '理财账户',
	meun10_6: '钱包历史记录',
	meun11: '订单',
	meun11_1: '现货订单',
	meun11_2: 'C2C订单',
	meun11_3: '理财订单',
	meun11_4: '闪兑历史',
	meun11_5: '创新管理',
	meun12: '下载',
	meun13_1: '账户管理',
	meun13_2: '收款方式',
	meun13_3: '我的推广',
	meun13_4: 'API管理',
	meun13_5: '退出登录',
	meun14_1: '语言',
	meun14_2: '汇率',
	meun14_3: '选择语言',
	meun14_4: '选择汇率',
	openTrade: '随时随地，开启交易。',
	scan: 'IOS & Andraid 扫码下载',
	footmeun1: '关于',
	footmeun1_1: '关于我们',
	footmeun1_2: '加入我们',
	footmeun1_3: '平台公告',
	footmeun1_4: '白皮书',
	footmeun2: '帮助中心',
	footmeun2_1: '新手指南',
	footmeun2_2: '常见问题',
	footmeun2_3: '交易指南',
	footmeun2_4: '币种资料',
	footmeun3: '条款协议',
	footmeun3_1: '免责条款',
	footmeun3_2: '隐私条款',
	footmeun3_3: '用户协议',
	footmeun3_4: '资费说明',
	footmeun4: '联系我们',
	footmeun4_1: '客服邮箱',
	footmeun4_2: '商务合作',
	footmeun4_3: '上币申请',
	footmeun4_4: '投诉举报',
	foottext: '全球优质数字资产交易所',
	indexTxt1: '欢迎来到',
	indexTxt2: '我们致力打造一个全球用户共识、共享的区块链独角兽',
	indexTxt3: '新手入门',
	// ---------------
	indexTxt99: '热门活动',
	indexTxt98: '寻找交易良机',
	indexTxt97: '项目排名',
	indexTxt96: 'Alpha - CEX 股东八大权益',
	indexTxt95: '合作伙伴',
	indexTxt94: '随时随地，指尖交易',
	// -----------------
	indexTxt4: '极速买币',
	indexTxt5: 'Alcex官方新手入门辅助通道',
	indexLabel1: '币币交易',
	indexVal1: '用一种数字资产去直接换取',
	indexLabel2: '期权交易',
	indexVal2: '数字资产衍生品',
	indexLabel3: '合约交易',
	indexVal3: '币本位，U本位合约',
	indexLabel4: '快速兑换',
	indexVal4: '币币闪兑，一键完成交易',
	indexth: '价格趋势',
	indexbtn: '立即交易',
	indexh1: '诚实',
	indexh2: '公平',
	indexh3: '热情',
	indexh4: '开放',
	indexh5: '金融级安全',
	indexh6: '极速充提',
	indexh7: '全球服务',
	indexh8: '严选项目',
	indexp1: 'Alcex 由一群比特币早期参与者与极客创立，团队核心成员来自于谷歌、微软、阿里巴巴、腾讯等知名企业，具有深厚的研发实力与丰富的互联网产品运营经验。',
	indexp2: 'Alcex 定位于区块链基础服务商，致力于为全球用户提供优质加密资产交易平台，秉承着“不作恶”的基本原则，坚持诚实、公正、热情的服务于客户，以开放的态度迎接一切有利于用户根本利益的伙伴/项目。',
	indexp3: '全方位金融风控系统和防盗系统，冷热钱包、多签系统保证资金安全',
	indexp4: '充值提现最快3分钟完成，24H人工在线审核，保护客户不错过最佳投资机会',
	indexp5: '全球业务服务网络覆盖，助您投资全球加密资产，与全球用户交易',
	indexp6: '严格选择优质加密项目，为您过滤80%极高风险项目',
	indexp7: '扫描二维码，下载app',
	scandownload: "扫一扫下载APP",
	// 订单
	ormenu1: 'C2C订单',
	ormenu2: '我的订单',
	ormenu3: '赚币',
	please: '请先',



	// 登录
	account: 'Alcex账户登录',
	phone: '手机号码',
	email: '邮箱',
	sign: '登录',
	password: '密码',
	password2: '密码',
	password3: '忘记密码',
	account2: '创建一个Alcex账户',
	logErr: '请输入账号',
	logErr2: '请输入密码',
	logErr3: '密码长度不能少于6位',
	logSuccess: '登录成功',
	// 注册
	account3: '创建个人账户',
	phone1: '手机注册',
	email1: '邮箱注册',
	email2: '邮箱地址',
	code: '短信验证码',
	code2: '发送验证码',
	code3: '验证码',
	password4: '确认密码',
	invite: '邀请码(选填)',
	agree: '我已阅读并同意',
	agreement: '用户协议',
	register: '注册',
	regErr: '请输入验证码',
	confirmpwdtip: '请输入确认密码',
	confirmpwderr: '两次密码输入不一致!',
	//忘记密码
	wjtab: '重置手机密码',
	wjtab2: '重置邮箱密码',
	wjlabel: '新密码',
	wjerr: '请输入新密码',
	// 创新实验室
	labTxt: 'Alcex实验室 ● 共享创新成果',
	labTxt2: '投资者、项目方、交易所等多方共赢的开放实验室',
	labTab1: '全部',
	labTab2: '即将开始',
	labTab3: '进行中',
	labTab4: '派发中',
	labTab5: '已完成',
	tokendistribute: "Token派发中",
	activitytype: "认购类型",
	labStart: '开始时间',
	labEnd: '结束时间',
	labStartPlaceholder: '请选择开始时间',
	labDetail: '查看详情',
	labSpeen: "进度",
	labNumber: "活动总量",
	labType1: '首次上线(抢购)',
	labType2: '首次上线(平分)',
	labType3: '持仓瓜分',
	labType4: '自由认购',
	labType5: '云矿机认购',
	labType6: '锁仓释放',
	baseinfo: '基本信息',
	ruledetail: "类型说明",
	releaseType: "释放类型",
	releaseType0: "等额释放",
	releaseType1: "等比释放",
	lock: " 期释放",
	releaseDetail: "释放详情",
	lockday: " 日",
	lockweek: " 周",
	lockmonth: " 月",
	lockyear: " 年",
	releasePercent: "释放比例",
	lockFee: "锁仓门槛",
	releaseTimes: "释放倍数",
	times: "倍",
	publishprice: "认购价格",
	holdcoin: "持仓要求币种",
	limittimes: "个人限购次数",
	limitamount: "个人限购数量",
	unlimited: "不限",
	leveloneCount: "需要一级好友数",
	alreadyamount: "已购数量",
	leftamount: "剩余数量",
	myalreadyholdamount: "我的参与持仓",
	alreadyholdamount: "活动参与总持仓",
	currentdivided: "当前我的持仓可瓜分约",
	holdtips: "参与【持仓瓜分】类型活动需锁仓至活动结束",
	inputholdamount: "输入锁仓数量",
	pleaseinputamount: "请输入兑换数量",
	pleaseinputholdamount: "请输入锁仓数量",
	inputamount: "输入兑换数量",
	inputminingamount: "输入购买矿机数",
	attend: "立即参与",
	attention: "注意",
	attentiontxt1: "1、提交参与【持仓瓜分】、【自由认购】、【云矿机认购】、【锁仓】类型活动后将冻结资产，活动结束后解冻或解锁释放",
	attentiontxt2: "2、参与【持仓瓜分】、【自由认购】、【云矿机认购】、【锁仓】类型活动后，用户不得撤销参与申请",
	attentiontxt3: "3、如因项目方及其他不可抗力因素取消活动，将原路退回冻结资产",
	attentiontxt4: "* 本活动最终解释权归Alcex(www.al-cex.com)官方所有",
	// 币本位合约账户
	transfer: '资金划转',
	placeholder: '搜索币种',
	bith1: '合约交易',
	bith2: '账户权益',
	bith3: '未实现盈亏',
	bith4: '可用保证金',
	bith5: '已用保证金',
	bith6: '冻结保证金',
	bith7: '合约账户',
	// 现货账户
	btn1: '充值',
	btn2: '提现',
	btn3: '划转',
	btn4: '充币',
	btn5: '提币',
	totalAssets: '总资产折合',
	th1: '币种名称',
	th2: '可用资产',
	th3: '冻结资产',
	th4: '待释放资产',
	th5: '操作',
	// 充值
	czTxt: "数字货币充值",
	czTxt2: "币种",
	czTxt3: "转账网络",
	czTxt4: "可用余额",
	czTxt5: "提币地址",
	czTxt6: "提币数量",
	czTxt7: "到账数量",
	czTxt8: "手续费",
	czTxt9: "温馨提示",
	czTxt10: "最小充值金额：",
	czTxt10_1: "小于最小金额的充值将不会上账。",
	czTxt11: "请勿向上述地址充值任何非币种资产，否则资产将不可找回。",
	czTxt12: "您充值至上述地址后，需要整个区块链网络节点的确认，一般区块链主网3次网络确认后到账。",
	czTxt13: "您的充值地址不会经常改变，可以重复充值；如有更改，我们会尽量通过网站公告或邮件通知您。",
	czTxt14: "请务必确认电脑及浏览器安全，防止信息被篡改或泄露。",
	czTxt15: "充值记录",
	czTxt16: "为保障资金安全，当您账户安全策略变更、密码修改、使用新地址提币，我们会对提币进行人工审核，请耐心等待工作人员电话或邮件联系。",
	czTxt17: "最小提币数量为：",
	czth1: '提现时间',
	czth2: '从',
	czth3: '至',
	czth4: '闪兑数量',
	czth5: '兑换比例',
	czth6: '兑换数量',
	placeholder2: '请输入',
	placeholder3: '请选择',
	czEmpty: '无记录',
	logintip: '请先登录',
	cztip: '您未申请过充值地址，请点击按钮获取充值地址',
	czget: '获取充值地址',
	czplaceholder: '请选择协议',
	// 秒合约账户
	mAccount: "秒合约账户",
	// 钱包历史记录
	btn6: '搜索',
	wtTxt: '起止时间',
	wtTxt2: '操作类型',
	wtth1: '交易时间',
	wtth2: '类型',
	wtth3: '币种',
	wtth4: '数量',
	wtth5: '应付手续费',
	wtth6: '抵扣手续费',
	wtth7: '实付手续费',
	wtth8: '状态',
	// 账户安全
	nav1: '账户安全',
	nav2: '我的邀请',
	nav3: '我的推广卡',
	acTxt: '安全等级',
	acbtn1: '未认证',
	acbtn2: '绑定',
	acbtn3: '修改',
	acbtn4: '已认证',
	acbtn5: '已绑定',
	acbtn6: '设置',
	acbtn7: '审核中',
	acbtn8: '重试',
	acTxt2: '实名认证',
	acTxt3: '邮箱',
	acTxt4: '手机',
	acTxt5: '登录密码',
	acTxt6: '资金密码',
	acTxt7: 'Google验证器',
	acTxt8: '设置资金密码',
	acTxt9: '更改资金密码',
	acTxt10: '找回资金密码',
	acDesc2: '为保障您的账户安全，请完成实名认证后方可交易操作！',
	acDesc3: '绑定邮箱',
	acDesc4: '绑定手机',
	acDesc5: '登录平台时使用',
	acDesc6: '账户资金变动时，需要验证资金密码',
	acDesc7: '请绑定Google验证器',
	acDesc8: '已绑定Google验证器',
	acDesc9: '解除绑定',
	acTit: '手机绑定',
	acTit2: '邮箱绑定',
	acTit3: 'Google绑定',
	acTit4: 'Google解除绑定',
	mailtip: '请输入邮箱',
	emailerr2: '邮箱格式不正确,请重新输入',
	agreementtip: '请阅读并同意用户协议',
	acverify: "审核未通过",
	again: "请重试",
	verifiedmsg: '请先进行实名认证',

	// 我的-API
	apiTxt: '绑定实名账号',
	apiTxt2: '提示',
	apiTxt3: '我们为您提供了强大的API,您可以通过API使用行情查询、自动交易等服务。通过 API文档 查看如何使用。',
	apiTxt4: '每个用户最多创建5组API Key',
	apiTxt5: '请不要泄露您的API Key，以免造成资产损失。 出于安全考虑，建议为API Key绑定IP，每个API Key最多绑定5个Ip。单个地址直接填写，多个IP地址用半角逗号分隔，如：192.168.1.1,192.168.1.2,192.168.1.3',
	apiTxt6: 'API备注',
	apiTxt7: '授权IP(授权IP地址的密钥有效期为90天)',
	apibtn: '创建',
	apith1: 'API备注',
	apith2: '创建时间',
	apith3: '访问密钥',
	apith4: '绑定IP地址',
	apith5: '剩余有效期（天）',
	// 更改登录密码
	chTxt: '更改登录密码',
	chTxt2: '扫码绑定',
	chTxt3: '请输入验证码',
	chTxt4: '原资金密码',
	chTxt5: '新资金密码',
	chTxt6: '确认新密码',
	chTxt7: '忘记密码',
	chTxt8: '更改手机验证',
	chTxt9: '原登录密码',
	chTxt10: '新登录密码',
	chTxt11: '邮箱验证码',
	chTxt12: '手机验证码',
	telerr: '手机号不正确',
	emailerr: '邮箱不正确',
	codeerr: '验证码不正确',
	reset: '重置',
	save: '保存',
	chtip: '请输入原密码',
	chtip2: '请输入不小于6位新登录密码',
	chtip3: '新登录密码不一致',
	chtip4: '请输入正确的资金密码',
	chtip5: '密码不得少于6个字符',
	chtip6: '请输入不小于6位密码',
	chtip7: '密码不一致',
	chtip8: '请输入资金密码',
	chtip9: '请输入手机号',
	chtip10: '请输入登录密码',
	realnametip: '请输入真实姓名',
	idcardtip: '请输入身份证号码',

	// 收款方式
	clTxt: '请设置您的收款方式，请务必使用本人的实名账号',
	clTxt2: '添加收款方式',
	edit: '编辑',
	delete: '删除',
	name: '姓名',
	clTxt3: 'ID号码',
	clTxt4: 'IBAN号码',
	clTxt5: '全部收款方式',
	clLink1: '账户名称',
	clLink2: '编辑收款方式',
	clLink3: '付款详情(选填)',
	clplaceholder1: '请输入您的完整姓名',
	clplaceholder2: '请输入您的银行账户密码',
	clplaceholder3: '请输入您的付款详情',
	cltip: '特别提示',
	cltip2: '请确保添加您的银行卡号以进行即时付款。请勿包含其他银行或付款方式的详细信息。您必须添加所选银行的付款/收款信息。',
	cltip3: '温馨提示：当您出售数字货币时，您选择的收款方式将向买方展示。请确认信息填写准确无误。请使用与您本人币安平台KYC身份认证一致的收款账户。',
	clTxt6: '选填',
	upload: '上传',
	uploadTip: '(支持JPG/JPEG/PNG/BMP格式, 小于1MB)',
	// 我的推广卡
	tgbtn: '兑换码兑换',
	tgth1: '卡名',
	tgth2: '兑换码',
	tgth3: '卡面币种',
	tgth4: '卡面金额',
	tgth5: '总数',
	tgth6: '已兑',
	tgth7: '领取时间',
	tgbtn2: '推广素材',
	tgbtn3: '立即兑换',
	tgplaceholder: '请输入兑换码',
	exchangesuccess: "兑换成功！请到资产管理查看余额！",
	// 我的邀请
	inth1: '邀请用户',
	inth2: '注册时间',
	inth3: '实名状态',
	// 我的广告
	adTxt: '我的广告',
	adTxt2: '当广告最小交易额所购买数量加上手续费大于广告剩余数量，该广告自动下架',
	adbtn: '清空条件',
	adbtn2: '上架',
	adbtn3: '下架',
	adth1: '广告编号',
	adth2: '广告类型',
	adth3: '订单限制',
	adth4: '剩余数量',
	adtip: '确定删除吗',
	num_text1: '请输入您要',
	num_text2: '的数量',
	// 当前委托
	wTxt: '现货',
	wTxt2: '当前委托',
	wTxt3: '交易对',
	wTxt4: '交易方向',
	wth1: '时间',
	wth2: '方向',
	wth3: '价格',
	wth4: '已成交',
	wth5: '成交金额',
	wth6: '委托类型',
	wth7: '触发价',
	wth8: '成交价',
	wth9: '委托数量',
	wth10: '结算盈亏',
	wth11: '委托状态',
	wth12: '开仓/平仓',
	wth13: '保证金',
	wbtn: '撤单',
	wtip: '撤单提示',
	wtiptxt: '是否确认撤单',
	// 赚币
	zbplaceholder: '输入订单编号开始搜索',
	zbtab1: '定投计划',
	zbtab2: '定投记录',
	zbth1: '投资周期',
	zbth2: '开/关',
	zbth3: '累计投资金额',
	zbth4: '持有数量',
	zbth5: '平均成本',
	zbth6: '未实现盈亏',
	zbth7: '下次定投日期',
	zbth8: '计划创建日期',
	// 当前委托-1
	etTxt: '公告中心',
	etTxt2: '扫一扫分享',
	etmenu1: '加密数字货币',
	etmenu2: 'Alcex服务器更新公告',
	// 当前委托-3
	et2menu1: 'RX.波场简介',
	et2menu2: '比特币是庞氏骗局吗?',
	// 历史委托
	hisTxt: '历史委托',
	// 我的订单
	orTab1: '未付款',
	orTab2: '已付款',
	orTab3: '已完成',
	orTab4: '已取消',
	orTab5: '申诉中',
	orth1: '订单号',
	orth2: '交易币种',
	orth3: '交易类型',
	orth4: '交易对象',
	orth5: '金额',
	// 期权合约
	qcTab1: '往期结果',
	qcTab2: '实时行情',
	qcTxt: '第',
	qcTxt2: '期',
	qcTxt3: '开盘时间',
	qcTxt4: '收盘时间',
	qcTxt5: '开盘价格',
	qcTxt6: '收盘价格',
	qcTxt7: '买涨总额',
	qcTxt8: '买跌总额',
	qcTxt9: '我的开仓',
	qcTxt10: '预测进度',
	qcTxt11: '开盘价',
	qcTxt12: '收盘价',
	qcTxt13: '本期预测周期',
	qcbtn1: '看涨',
	qcbtn2: '看跌',
	qcth1: '合约期数',
	qcth2: '开仓金额',
	qcth3: '预测方向',
	qcth4: '预测结果',
	qcth5: '奖金数量',
	qcth6: '开仓手续费',
	qcth7: '抽水',
	// 秒合约
	mplaceholder: '输入币种名称搜索',
	mTab: '自选',
	mth: '收藏',
	mth1: '最新价',
	mth2: '24h涨跌',
	mTxt: '24h最高价',
	mTxt2: '24h最低价',
	mTxt3: '交易数量',
	mTxt4: '资金费率',
	mTxt5: '开',
	mTxt6: '高',
	mTxt7: '低',
	mTxt8: '收',
	echat1: 'K线图',
	echat2: '深度图',
	mTab2: '当前持仓',
	mTab3: '委托历史',
	mth3: '包赔',
	mth4: '开仓价格',
	mth5: '周期',
	mth6: '平仓价格',
	mth7: '盈利',
	mTxt9: '交易模式',
	mTxt10: '开仓时间',
	mTxt11: '盈利率',
	mTxt12: '余额',
	mTxt13: '买入量',
	mTxt14: '中',
	// 创建广告
	cadTxt: '创建一个广告交易',
	cadTxt2: '如果您经常进行交易，您可以创建自己的交易广告。',
	cadTxt2_1: '如果您只是偶尔交易，我们建议您直接搜索',
	cadTxt2_2: '创建一则交易广告是免费的。',
	cadLink2: '交易广告',
	cadTxt3: '若您想直接编辑已创建的广告，请查看',
	cadLink3: '我的广告',
	cadTxt4: '市场参与价格',
	cadTxt5: '溢价是指高于当前市场价格多少百分比进行卖出',
	cadTxt6: '计价公式',
	cadTxt7: '【提示】可前往个人中心绑定/增加支付方式',
	cadTxt8: '最大交易额不能超过您的卖出总金额0CNYI',
	cadTxt9: '启用后，用户通过此广告向您发起交易，系统自动将您选择的自动回复用语发送给对方。',
	cadTxt10: '溢价是指高于当前市场价格多少百分比进行买入',
	cadLabel1: '我想要：',
	cadLabel2: '币种交易：',
	cadLabel3: '国家：',
	cadLabel4: '货币：',
	cadLabel5: '开启固定价格',
	cadLabel6: '溢价：',
	cadLabel7: '交易价格',
	cadLabel8: '卖出数量：',
	cadLabel9: '付款方式：',
	cadLabel10: '最小交易额：',
	cadLabel11: '最大交易额：',
	cadLabel12: '开启自动回复',
	cadLabel13: '资金密码：',
	cadLabel14: '备注信息：',
	cadLabel15: '固定价格：',
	cadLabel16: '自动回复：',
	cadLabel17: '买入数量：',
	radio: '在线出售',
	radio2: '在线购买',
	cadplaceholder: '请设置您的溢价',
	cadplaceholder2: '请输入您要卖出数量',
	cadplaceholder3: '请输入您要最小交易额',
	cadplaceholder4: '请输入您要最大交易额',
	cadplaceholder5: '可以内备注信息里填写您的特殊要求，例如：对买方的要求，在线时间等',
	cadplaceholder6: '请输入您的交易价格',
	cadplaceholder7: '在接收订单后，自动回复给买家的信息，例如：收款方式、收款账号等。',
	cadplaceholder8: '请输入您要买入数量',
	caderr1: '请输入您的最小交易额',
	caderr2: '最小交易额必须大于等于100！',
	caderr3: '最小交易额必须小于最大交易额',
	caderr4: '请输入您的最大交易额！',
	caderr5: '请输入整数',
	caderr6: '最大交易额必须大于最小交易额！',
	caderr7: '最大交易额不能超过您的卖出总金额',
	caderr8: '请选择交易方式',
	submit: '提交',
	cadnew: '启用后，您的币价不会随市场波动，价格不变。',
	// 快速兑换
	dhTxt: '一键兑换·坐享收益',
	dhTxt2: '限价',
	dhTxt3: '卖出',
	dhTxt4: '买入',
	dhTxt5: '最大',
	dhTxt6: '输入金额',
	dhplaceholder: '现货钱包可用余额',
	dhSuccess: '恭喜你！兑换成功！',
	// 法币交易
	crTxt: '法币交易',
	crTxt2: '便捷、安全、快速买卖数字货币',
	crTxt3: '动态调整',
	crTxt4: '免手续费',
	crTxt5: '即时成交',
	crTxt6: '平台担保',
	crTxt7: '根据市场价格实时波动',
	crTxt8: '用户所见即所得，买卖价格外无需任何平台手续费',
	crTxt9: '引入平台服务商家，智能匹配，成交订单，无须等待撮合',
	crTxt10: '平台认证商家，安全有保障，24小时客服为交易保驾护航',
	crth1: '商家',
	crth2: '交易笔数',
	crth3: '付款方式',
	crth4: '限额',
	crth5: '单价',
	crbtn: '购买',
	crbtn2: '成为商家',
	// 法币交易1
	cr1Txt: '恭喜！您的商家认证审核已通过',
	cr1Txt2: '准备资料',
	cr1Txt3: '提交审核',
	cr1Txt4: '已认证',
	cr1Txt5: '您获得以下权限',
	cr1Txt6: '专属展位',
	cr1Txt7: '一对一服务',
	cr1Txt8: '商家享有专属广告展位，增加交易成功率',
	cr1Txt9: '更低手续费',
	cr1btn: '发布广告',
	cr1btn2: '申请退保',
	// 定期理财
	reTxt: 'Alcex理财●一站式投资解决方案',
	reTxt2: '立即赚取收益、简单&安全',
	reTab: '定期理财',
	reTab2: '定投理财',
	reTxt3: '定期',
	reTxt4: '灵活定制，潜在收益更高',
	reTxt6: '定投',
	reTxt7: '开始使用定投并增加您的资产',
	reTxt8: '展开所有18个定期产品',
	reth1: '年化收益率',
	reth2: '期限(天)',
	reth3: '交易限额',
	reth4: '产品',
	reth5: '历史投资回报率',
	rebtn: '创建计划',
	redTxt: '创建一个',
	redTxt2: '定投计划',
	redTxt3: '输入投资数量',
	redTxt4: '我的可用余额为',
	redTxt5: '可用',
	redTxt6: '循环周期',
	redTxt7: '每天',
	redTxt8: '每周',
	redTxt9: '每月',
	redTxt10: '本地时间',
	redTxt11: '您首次自动投资周期将于',
	redTxt12: '开始',
	confirm: '确认',
	confirm2: '确定',
	cancel: '取消',
	redongoing: "进行中",
	redcompleted: "已完成",
	minNumErr: '小于最低起投数量',
	minNumErr2: '起投数量不能为0',
	buyamounttip: '请输入买入量',
	pricetipwarning: '交易额不能高于',
	buyamounttipwarning: '买入数量不能高于',
	sellamounttip: '请输入卖出量',
	sellamounttipwarning: '卖出数量不能高于',
	sellpricetip: '请输入卖出价格',
	sellpricetipwarning: '卖出价格不能高于',
	sellmore: '最多能卖',
	sellunit: '个',
	loginFirst: "请先登录",
	betsuccess: "恭喜你！投注成功！",
	selectAmount: "请选择投注数量",
	balancenotenough: "可用余额不足!",
	balancenotenough2: "余额不足！",
	minlimitamountfailed: "兑换数量不能小于最低起兑量！",
	maxlimitamountfailed: "兑换数量不能大于最高限购量！",
	limittimesfailed: "您参与的次数超过个人最高限购次数！",
	commitfailed: "提交参与失败！",
	// 现货交易
	trplaceholder: '查询',
	trplaceholder2: "最新价格",
	trplaceholder3: '以市场上最优价格买入',
	trplaceholder4: '以市场上最优价格卖出',
	triggleplaceholder: "默认委托价为市价",
	triggerpriceshouldbigger: "触发价必须大于0",
	marginModeSuccessTip: "合约账户模式修改成功",
	noenoughbalance: "账户保证金不足",
	pleaseinputopenvolume: "开仓数量必须大于0",
	pleaseinputcorrectopenvolume: "请输入正确的开仓数量",
	pleaseinputrightentrustprice: "请输入正确的委托价格",
	noenoughposition: "可平仓位不足",
	pleaseinputclosevolume: "平仓数量必须大于0",
	pleaseinputcorrectclosevolume: "请输入正确的平仓数量",
	option1: '天线',
	option2: '周线',
	option3: '月线',
	trTxt: '交易额',
	trTxt2: '市价',
	trTxt3: '卖出量',
	trSuccess: '提交成功',
	// u本位
	udTxt: '保证金模式',
	udTxt2: '调整合约倍数',
	udTxt3: '杠杆',
	udTxt4: '永续',
	udTxt4_1: '永续',
	udTxt5: '可转额度',
	udTxt6: '划转数量',
	udTxt7: '全部划转',
	udTxt8: '永续账户',
	ubtn: '全仓',
	ubtn2: '逐仓',
	uplaceholder: '永续合约账户',
	uradio1: '分时',
	uradio2: '15分钟',
	uradio3: '1小时',
	uradio4: '4小时',
	uradio5: '1天',
	uth1: '合约倍数',
	uth2: '收益',
	uth3: '收益率',
	uth4: '持仓量',
	uth5: '可平量',
	uth6: '开仓均价',
	uth7: '强平价',
	uth8: '当前保证金',
	uth9: '保证金率',
	zhang: '张',
	or: '或',
	ureg: '立即注册',
	uTxt8: '进行交易',
	uTab: '开仓',
	uTab2: '平仓',
	uTxt9: '可开多',
	uTxt10: '可开空',
	uTxt11: '委托价',
	uplaceholder2: '以市场最优价成交',
	uTxt12: '买入开多',
	uTxt13: '卖出开空',
	closeup: "买入平空",
	closedown: "卖出平多",
	uTab3: '止盈止损',
	uTxt14: '我的合约账户',
	ulabel1: '账户模式',
	ulabel2: '账户总收益',
	ulabel3: '仓位保证金',
	ulabel4: '资金利用率',
	ulabel5: '多',
	ulabel6: '空',
	uTxt15: '多头',
	uTxt16: '空头',
	total: '合计',
	limited_price: '限价委托',
	market_price: '市价委托',
	spot_price: '止盈止损',
	ent_status1: "委托中",
	ent_status2: "已撤销",
	ent_status3: "委托失败",
	ent_status4: "委托成功",
	ent_statusblast: "爆仓",
	uTxt17: '多仓可平',
	uTxt18: '空仓可平',
	//
	prev: '上一篇',
	next: '下一篇',
	// 推广合伙人
	prtxt: '我的邀请链接',
	copy: '复制',
	prtxt2: '我的一级好友',
	prtxt3: '我的二级好友',
	prtxt4: '佣金收入(折合USDT)',
	prtxt5: '额外奖励',
	prtxt6: '合伙级别',
	prtit: '规则详情',
	prdesc: 'Alcex的【推广合伙人】是全网返佣比例最高、返佣时长最长(最高终身返佣)的推广活动，让付出相应努力的推广者能够成为真正意义上的平台“合伙人”，享受与Alcex交易平台共同成长的红利！具体细则如下：',
	prli1: '一、为布道数字资产理念与扩大用户规模，Alcex推出【推广合伙人】计划，该活动长期有效。',
	prli2: '二、邀请好友分为两级，如A邀请B，则B是A的一级好友，若B又邀请了C，则C属于B的一级好友，同时也属于A的二级好友。',
	prli3: '三、推广好友注册时，需通过邀请者提供的链接或手动输入邀请者邀请码方可成为邀请者的一级好友。',
	prli4: '四、被邀请者通过邀请者邀请码注册，并且完成实名认证后，返佣激励方可生效。',
	prli5: '五、返佣时间为从被邀请者完成实名认证开始的N个月，根据等级不同，享受的返佣比例不同。',
	prli6: '六、推广返佣资产为下级好友币币交易手续费，返佣币为三大市场基础币，即：USDT、BTC、ETH。',
	prli7: '七、推广返佣比例、返佣时长与推广一级好友人数呈正相关，推广人数越多，可享返佣比例越高。具体推广返佣比例如下：',
	prli8: '八、推广返佣排行榜TOP10或TOP100不定期会获得由Alcex官方发放的额外奖励。',
	prli9: '九、如其他活动与【推广合伙人】活动冲突，则经协商后决定是否临时变更，望诸合伙人谅解。',
	prli10: '十、L4(巡抚)、L5(太守)、L6(节度使)级合伙人分别享受每年返佣总额的5%、10%、15%的分红奖励。',
	prli11: '十一、本活动最终解释权归Alcex所有。',
	prth1: '级别',
	prth2: '一级好友数',
	prth3: '一级好友返佣比例 / 返佣时间',
	prth4: '二级好友返佣比例 / 返佣时间',
	prth5: '合伙人分红',
	prlev1: '里正',
	prlev2: '县令',
	prlev3: '知府',
	prlev4: '巡抚',
	prlev5: '太守',
	prlev6: '节度使',
	prtd1: '个月',
	prtd2: '终身返佣',
	prtit2: '举例说明',
	prtxt7: '若用户小严邀请了100名一级好友，TA的一级好友分别邀请了5名二级好友，此时小严就拥有100名一级好友，500名二级好友。如果平均每人每天交易3000元，那么小严月收入约：(100*3000*0.001*30% + 500*3000*0.001*10%) * 30 = 7200/月。',
	prtxt8: '若用户小严邀请了1000名一级好友，TA的一级好友分别邀请了5名二级好友，此时小严就拥有1000名一级好友，5000名二级好友。如果平均每人每天交易3000元，那么小严月收入约：(1000*3000*0.001*50% + 5000*3000*0.001*20%) * 30 = 135000/月。',
	prtit3: '推广工具箱',
	prtit4: '推广返佣总额TOP20(总榜)',
	prtit5: '推广人数TOP20(总榜)',
	prtxt9: '统计截止时间:',
	prth6: '排名',
	prth7: '会员',
	prth8: '推广人数',
	prth9: '返佣(折合USDT)',
	prth10: '额外奖励',
	prtip1: '以上佣金数据非实时更新，系统每24H统计并更新一次。',
	prtip2: '邀请人数为一级好友数，此榜为返佣总金额排名',
	prtip3: '邀请人数为一级好友数，此榜为一级邀请人数排名',
	// 充币
	recth1: '到账时间',
	recth2: '网络协议',
	recth3: '充币地址',
	recth4: '充值数量',
	recbtn: '提币码充值',
	recplaceholder: '请输入提币码',
	recstatus_0: '失败',
	recstatus_1: '未到账',
	recstatus_2: '已到账',
	// 提币
	wittit: '数字货币提现',
	wittext: '提现记录',
	wittext2: '提币码',
	wittext3: '提币码列表',
	witbtn: '提币码提币',
	witbtn2: '生成提币码',
	witdia: '安全验证',
	witdia2: '提现码生成成功',
	witplaceholder: '请输入提币数量',
	witerr: '请选择币种',
	addresstip: '请填入地址',
	witstate: '待兑换',
	witstate2: '失败',
	witstate3: '兑换完成',
	witstatus_1: '审核中',
	witstatus_2: '转账中',
	witstatus_3: '失败',
	witstatus_4: '成功',
	// 操作类型
	type1: '转账',
	type2: '币币交易',
	type3: '法币买入',
	type4: '法币卖出',
	type5: '活动奖励',
	type6: '推广奖励',
	type7: '分红',
	type8: '投票',
	type9: '人工充值',
	type10: '配对',
	type11: '活动兑换',
	type12: 'C2C买入',
	type13: 'C2C卖出',
	type14: '发红包',
	type15: '领红包',
	type16: '提币码提币',
	type17: '提币码充币',
	type18: '永续合约手续费',
	type19: '永续合约盈利',
	type20: '永续合约亏损',
	type21: '期权合约失败',
	type22: '期权合约手续费',
	type23: '期权合约奖金',
	type24: '合约返佣',
	type25: '平级奖励',
	type26: '平台手续费收入',
	type27: '秒合约失败',
	type28: '秒合约奖金',
	type29: '理财利息',
	type30: '支出资金费用',
	type31: '获得资金费用',
	type32: '定投买入',
	type33: '定投卖出',
	type34: '购买定期',
	type35: '定期赎回',
	type36: '币本位合约划转转入',
	type37: '币本位合约划转转出',
	type38: 'U本位合约划转转入',
	type39: 'U本位合约划转转出',
	type40: '秒合约划转转入',
	type41: '秒合约划转转出',
	type42: '币币划转转入',
	type43: '币币划转转出',
	// 理财账户
	lctit: '理财账户',
	lcbtn: '理财转入',
	lcth1: '累计利息',
	day: '天',
	lctxt: '活动币种',
	lctxt2: '接受币种',
	lctxt3: '起投数量',
	lctxt4: '选择时间',
	year: '年',
	month: '月',
	// 理财订单
	ldtab1: "持仓记录",
	ldtab2: "平仓记录",
	ldth1: "开始收益日",
	ldth2: "赎回日",
	ldth3: "收益估算值",
	ldth4: "收益",
	// 定投计划
	attit: "修改定投计划",
	attxt: "投资数量",
	attxt2: "投资周期",
	atth1: '定投日期',
	atth2: '定投数量',
	atth3: '交易费率',
	success: '成功',
	// 创新管理
	cxth1: '名称',
	cxth2: '类型',
	cxth3: '认购数量',
	cxth4: '参与币种',
	cxth5: '认购单位',
	cxth6: '当前状态',
	cxth7: '成交额',
	cxth8: '创建时间',
	cxnav1: '我参与的',
	cxnav2: '我的矿机',
	cxnav3: '我的锁仓',
	cxtit2: '我的矿机列表',
	cxtit3: '我的锁仓列表',
	cxkth1: '产出币种',
	cxkth2: '产出周期',
	cxkth3: '挖矿时间',
	cxkth4: '已挖时间',
	cxkth5: '基础产能',
	cxkth6: '实际产能',
	cxstate1: '未部署',
	cxstate2: '已部署',
	cxstate3: '已撤销',
	cxstate4: '待成交',
	cxstate5: '已结束',
	cxstate6: '待释放',
	cxstate7: '释放中',
	cxcth1: '释放币种',
	cxcth2: '总锁仓',
	cxcth3: '总释放周期',
	cxcth4: '已释放周期',
	cxcth5: '基础释放',
	cxcth6: '实际释放',
	more: '更多',
	// 关于我们
	abtit: '平台特色',
	abtit2: '关于我们',
	abtit3: '联系我们',
	abtit4: 'Alcex社区',
	abdesc: 'Alcex是技术全球领先的数字资产交易平台，注册于开曼群岛，核心运营团队位于香港。Alcex核心成员来自于顶级互联网和金融公司，大部分成员是深度比特币和区块链信仰者，我们深信区块链将改变传统垄断性金融体系，会建立一个更加民主、自治的社会结构。',
	abdesc2: 'Alcex平台拥有专业金融级别的交易架构，拥有自主研发的高并发内存撮合交易引擎，平台采用全冷充值钱包系统+多重签名+高防护DDOS攻击系统等交易架构，保证客户资产安全。',
	abdesc3: 'Alcex为了让客户更好的把握投资机会，客服部门内部采用扁平化管理，建立了极速服务响应机制，资产客服经理常年7*24H在线，为客户提供资产充值提现等服务，保障客户在5分钟内完成资产充提。',
	abdesc4: 'Alcex严格筛选优质项目并提供安全稳定的资产托管服务。Alcex秉承着“诚实、公正、热情、开放”的理念，竭力为用户打造安全、可靠、高效和友好的极致交易所。',
	abdesc5: '尽管数字资产现在只在一个小小的圈子里，但是我们相信，这种每个人能够完全掌握的资产在未来一定能够流行起来，让我们一起努力并期待着！',
	abdesc6: '客户服务',
	abdesc7: '技术支持',
	abdesc8: '上币申请',
	abdesc9: '投诉建议',
	abdesc10: '商务合作',
	abdesc11: '微信',
	abdesc12: '微博',
	abdesc13: '推特',
	abdesc14: '币用',
	abdesc15: '电报',
	abdesc16: '添加 “bmate601” 为微信好友，进入微信福利社群',
	// 实名认证
	realtxt: '真实姓名',
	realtxt2: '身份证号',
	realtxt3: '身份证正面照',
	realtxt4: '身份证反面照',
	realtxt5: '手持身份证与个人签名照',
	realtxt6: '1、支持JPG、PNG格式，文件小于8M；证件上的信息，清晰可见，不允许任何修改和遮挡，必须能看清证件号和姓名。',
	realtxt7: '2、照片需免冠，建议未化妆，手持证件人的五官清晰可鉴，完整露出手臂。',
	realtxt8: "3、需要同时手持证件和手写文案（姓名+申请日期）'我在本站的所有行为均为本人操作，并已知悉相关风险，愿意承担本账户的一切法律后果。'",
	realbtn: '点击上传',
	realstate: '未实名',
	realstate2: '已实名',
	uploadimg: "请上传身份证正面照",
	uploadimg2: "请上传身份证反面照",
	uploadimg3: "请上传手持身份证照",
	uploaderr: "上传图片大小不能超过",
	save_success: '保存成功!',
	save_failure: '保存失败!',
	// 划转
	close: '关闭',
	hztxt: '确认划转',
	// 商家认证
	submittip1: '为了您的资金安全，请先进行【实名认证】！',
	submittip2: '为了您的资金安全，请先进行【手机绑定】！',
	submittip3: '为了您的资金安全，请先设置【资金密码】！',
	submittip4: '请至少绑定一种支付方式',
	aderr1: '请输入正确数字',
	aderr2: '溢价值为0-20',
	aderr3: '溢价值为0-20，且不能为0',
	aderr4: '请输入正确固定价格',
	aderr5: '请输入正确数字，并且最大交易数量不超过100币',
	orderTypeNo: "否",
	orderTypeYes: "是",
	win: "成功",
	lose: "失败",
	tied: "平局",
	cancelsuccess: "撤单成功！",
	shelves: '下架广告后才可以删除！',
	shelves2: '下架广告后才可以编辑修改！',
	business: '请先申请商家认证！',
	swappleaseselectwallet: "请先选择转入/转出账户",
	swappleaseinputamount: "请输入划转数量",
	clickchange: "点击切换划转方向",
	daterange: "请选择搜索日期范围",
	// API
	apierr: '请输入备注信息',
	apierr2: '请输入IP地址',
	apierr3: '请输入正确IP地址',
	apierr4: '请输入验证码',
	clickget: '点击获取',
	apisecond: '秒',
	apitips: "密钥只在新增时展示，请及时保存",
	apiaccess: "密钥",
	// 自定义
	do_favorite: '已收藏',
	cancel_favorite: '取消收藏',
	fail_favorite: '失败',
	deptotal: '累计',
	chart: '图表',
	book: '订单簿',
	trade: '最新成交',
	pt_title: "数字货币礼品卡",
	pt_desc: "兑换后自动成为下级好友，送朋友、送亲人、送客户、送伙伴。助力推广合伙人更好推广。",
	pt_more: "更多工具，敬请期待",
	pt_tips: "如果您有好的创意，请发送邮件至promotion@al-cex.com，采纳后有奖励哦！",
	pt_card_amount: "卡面金额",
	pt_card_deadline: "有效期间",
	pt_card_noend: "无限期",
	pt_card_btn: "推广合伙人免费领30张(约2000元)",
	pt_card_btn_login: "登录后免费领取30张",
	pt_card_rule: "规则详情",
	pt_card_summary: "数字货币礼品卡是为了让推广合伙人能够更好地邀请下线好友而开发的推广工具之一，推广对象用户兑换礼品卡时，会自动成为推广者的下线好友。",
	pt_card_rule1: "一、免费礼品卡每个用户限领30张。如需发放更多礼品卡，则需要用户付费定制，可定制卡面金额、LOGO、说明文等。定制需求请发送邮件至",
	pt_card_rule2: "二、免费礼品卡每个用户限兑1份。由Alcex官方发放的免费礼品卡，每个用户限兑一份，即使通过不同用户领取到不同兑换码的免费礼品卡，也只能兑换1份。自定义制作礼品卡不受此限制。",
	pt_card_rule3: "三、用户兑换礼品卡前，如不存在“邀请者”（即注册账号时未输入过邀请码），则自动关联为发卡者的一级好友。如A注册账号时未输入邀请码，此时A不属于任何人的一级或者二级好友，但当A通过B发放的礼品卡兑换后，那么A自动成为B的一级好友。",
	pt_card_rule4: "四、为防止刷客，礼品卡账面金额并非实时兑付，领取后需完成实名认证才可操作。",
	pt_card_rule5: "五、领取免费礼品卡时需要完成实名认证，兑换时无需完成实名认证即可领取，此举是为了方便推广者更快获取下级好友。",
	pt_card_rule6: "六、免费礼品卡最终解释权归Alcex所有。",
	pt_card_day: "天",
	pt_card_title_tips: "领2000元推广助力金",
	pt_invite_desc: "专属二维码，通过二维码注册自动成为下级好友，适合在各种群或论坛转发。",
	saveimage: "保存图片",
	imagetips: "如无法正常下载图片，请使用截图工具截图保存！",
	pt_card_receivew_success: "恭喜！成功领取合伙人推广礼品卡！请到【个人中心】->【卡券中心】查看！",
	usepromotion: "使用推广素材",
	context_title: "推广合伙人专属兑换码",
	context_title1: "扫一扫了解更多",
	context_title2: "我的邀请码",
	context_title3: "邀请图片",
	pt_card_title: "合伙人推广卡",
	copysuccess: '复制成功！',
	copyerr: '复制失败！请手动复制',
	noopening: "暂无待期权合约",
	createSuccess: "创建成功",
	tradewarning1: '最多输入2位小数',
	tradewarning2: '下单金额为',
	tradewarning3: '最多输入8位小数',
	tradewarning4: '下单数量为',
	tradeconfirmbuyin: '确认买入',
	tradeconfirmsellout: '确认卖出',
	tradeinfo: {
		emaildone: '邮件已认证',
		emailundo: '邮件未认证',
		teldone: '手机号码已认证',
		telundo: '手机号码未认证',
		idcarddone: '身份证已认证',
		idcardundo: '身份证未认证',
		exchangetimes: '交易次数',
		price: '价格',
		num: '数量',
		paymethod: '付款方式',
		exchangelimitamount: '交易限额',
		location: '所在地',
		location_text: '中国',
		exchangeperiod: '交易期限',
		minute: '分钟',
		amounttip: '请输入金额',
		numtip: '请输入数量',
		remarktip: '告诉他您的要求',
		remarktitle: '备注信息',
		exchangetitle: '交易须知',
		exchange_tip1: '在您发起交易请求后，数字货币被锁定在托管中，受到平台保护。 如果您是卖家，发起交易请求后，您可通过充值并等待买家付款。买家在付款时限内进行付款。您在收到付款后应放行处于托管中的数字货币。',
		exchange_tip2: '交易前请阅读《平台网络服务条款》以及常见问题、交易指南等帮助文档。',
		exchange_tip3: '当心骗子！交易前请检查该用户收到的评价，并对新近创建的账户多加留意。',
		exchange_tip4: '请注意，四舍五入和价格的波动可能会影响最终成交的数字货币数额。您输入的固定数额决定最后数额，数字货币金额将在请求发布的同一时间由即时的汇率算出。',
		exchange_tip5: '托管服务保护网上交易的买卖双方。在发生争议的情况下，我们将评估所提供的所有信息，并将托管的数字货币放行给其合法所有者。',
		warning1: '最多输入2位小数',
		warning2: '下单金额为',
		warning3: '最多输入8位小数',
		warning4: '下单数量为',
		confirmbuyin: '确认买入',
		confirmsellout: '确认卖出',
		buyin: '买入',
		sellout: '卖出',
		warning5: '请按要求填写订单',
	},
	convertResult:'预览兑换结果',
	willGet:'你将得到',
	pleaseCommit:'请在该时间内点击确认。',
	returnText:'返回',
	convert:'兑换'
}
