export default {
  testText1: '¡Esta es una estación de pruebas! ',
  testText2: '@Alcexcom',
  testText3: '@bzengineer (el único canal, cuidado con que te engañen)',
  meun1: 'página delantera',
  meun2: 'Compra monedas con un clic',
  meun3: 'comercio',
  meun3_1: 'Comercio al contado',
  meun3_2: 'Intercambio rápido',
  meun4: 'comercio por contrato',
  meun4_1: 'contrato estándar u',
  meun4_2: 'Contrato con margen de moneda',
  meun4_3: 'contrato de opciones',
  meun4_4: 'segundo contrato',
  meun5: 'Servicios financieros',
  meun5_1: 'Laboratorio de Innovación',
  meun5_2: 'Socio de promoción',
  meun6: 'gestión financiera',
  meun7: 'anuncio',
  meun8: 'Acceso',
  meun9: 'registro',
  meun10: 'billetera',
  meun10_1: 'cuenta al contado',
  meun10_2: 'Cuenta de contrato basada en U',
  meun10_3: 'Cuenta de contrato con margen de moneda',
  meun10_4: 'contrato de segunda base',
  meun10_5: 'cuenta financiera',
  meun10_6: 'Historial de billetera',
  meun11: 'Orden',
  meun11_1: 'orden al contado',
  meun11_2: 'orden C2C',
  meun11_3: 'Órdenes de gestión financiera',
  meun11_4: 'Historial de intercambio flash',
  meun11_5: 'Gestión de la innovación',
  meun12: 'descargar',
  meun13_1: 'Administración de cuentas',
  meun13_2: 'método de pago',
  meun13_3: 'mi ascenso',
  meun13_4: 'Gestión de API',
  meun13_5: 'desconectar',
  meun14_1: 'idioma',
  meun14_2: 'tipo de cambio',
  meun14_3: 'Elige un idioma',
  meun14_4: 'Seleccionar tipo de cambio',
  openTrade: 'Comience a operar en cualquier momento y en cualquier lugar.',
  scan: 'IOS y Android Escanea el código QR para descargar',
  footmeun1: 'acerca de',
  footmeun1_1: 'sobre nosotros',
  footmeun1_2: 'Únete a nosotros',
  footmeun1_3: 'Anuncio de plataforma',
  footmeun1_4: 'papel blanco',
  footmeun2: 'Centro de ayuda',
  footmeun2_1: 'Guía para principiantes',
  footmeun2_2: 'problema comun',
  footmeun2_3: 'Guía comercial',
  footmeun2_4: 'Información de moneda',
  footmeun3: 'acuerdo de términos',
  footmeun3_1: 'Descargo de responsabilidad',
  footmeun3_2: 'política de privacidad',
  footmeun3_3: 'Acuerdo del Usuario',
  footmeun3_4: 'Descripción arancelaria',
  footmeun4: 'Contáctenos',
  footmeun4_1: 'Correo electrónico de atención al cliente',
  footmeun4_2: 'Cooperación empresarial',
  footmeun4_3: 'Solicitud de listado de monedas',
  footmeun4_4: 'Quejas',
  foottext: 'Intercambio global de activos digitales de alta calidad',
  indexTxt1: 'Los 50 principales intercambios de divisas digitales del mundo',
  indexTxt2: 'Agencias nacionales garantizan la seguridad de los activos',
  indexTxt3: 'Guía para principiantes',
  indexTxt4: 'Compra monedas rápidamente',
  indexTxt5: 'Canal auxiliar oficial de entrada de novatos de Alcex',
  indexLabel1: 'Comercio de criptomonedas',
  indexVal1: 'Utilice un activo digital para intercambiar directamente',
  indexLabel2: 'comercio de opciones',
  indexVal2: 'Derivados de activos digitales',
  indexLabel3: 'comercio por contrato',
  indexVal3: 'Estándar de moneda, contrato estándar U',
  indexLabel4: 'Intercambio rápido',
  indexVal4:
    'Cambio de moneda instantáneo, transacciones completas con un clic',
  indexth: 'tendencia de precios',
  indexbtn: 'Negocia ahora',
  indexh1: 'honesto',
  indexh2: 'justo',
  indexh3: 'entusiasmo',
  indexh4: 'abierto',
  indexh5: 'Seguridad de grado financiero',
  indexh6: 'Depósitos y retiros extremadamente rápidos',
  indexh7: 'servicio global',
  indexh8: 'Proyectos cuidadosamente seleccionados',
  indexp1:
    'Alcex fue fundada por un grupo de geeks y participantes iniciales de Bitcoin. Los miembros principales del equipo provienen de empresas reconocidas como Google, Microsoft, Alibaba y Tencent, con profundas capacidades de investigación y desarrollo y una rica experiencia en la operación de productos de Internet.',
  indexp2:
    'Alcex se posiciona como un proveedor de servicios básicos de blockchain y se compromete a proporcionar plataformas de comercio de criptoactivos de alta calidad para usuarios globales. Se adhiere al principio básico de "no hacer el mal", insiste en servir a los clientes de manera honesta, justa y entusiasta. y da la bienvenida con una actitud abierta a todas las partes interesadas, socios/proyectos que beneficien los intereses fundamentales de los usuarios.',
  indexp3:
    'El sistema integral de control de riesgos financieros y el sistema antirrobo, las billeteras frías y calientes y el sistema de firmas múltiples garantizan la seguridad de los fondos.',
  indexp4:
    'Los depósitos y retiros se pueden completar en tan solo 3 minutos, y la revisión manual en línea las 24 horas protege a los clientes de perder las mejores oportunidades de inversión.',
  indexp5:
    'Cobertura de red de servicios empresariales globales para ayudarle a invertir en criptoactivos globales y comerciar con usuarios globales',
  indexp6:
    'Seleccione estrictamente proyectos de cifrado de alta calidad y filtre el 80% de los proyectos de riesgo extremadamente alto por usted',
  indexp7: 'Escanea el código QR y descarga la aplicación',
  scandownload: 'Escanea para descargar la APP',
  ormenu1: 'orden C2C',
  ormenu2: 'Mi pedido',
  ormenu3: 'Ganar monedas',
  please: 'por favor primero',
  account: 'Iniciar sesión en la cuenta Alcex',
  phone: 'número de teléfono',
  email: 'Correo',
  sign: 'Acceso',
  password: 'contraseña',
  password2: 'contraseña',
  password3: 'olvida la contraseña',
  account2: 'Crear una cuenta Alcex',
  logErr: 'Por favor ingrese el nombre de usuario',
  logErr2: 'Por favor, ingrese contraseña',
  logErr3: 'La longitud de la contraseña no puede ser inferior a 6 caracteres.',
  logSuccess: 'Inicio de sesión correcto',
  account3: 'Crear cuenta personal',
  phone1: 'Registra tu teléfono',
  email1: 'registro de email',
  email2: 'dirección de correo electrónico',
  code: 'código de verificación por SMS',
  code2: 'Enviar el código de verificación',
  code3: 'Código de verificación',
  password4: 'confirmar Contraseña',
  invite: 'Código de invitación (opcional)',
  agree: 'He leído y estoy de acuerdo',
  agreement: 'Acuerdo del Usuario',
  register: 'registro',
  regErr: 'Por favor ingresa el código de verificación por SMS',
  confirmpwdtip: 'Por favor ingrese la contraseña de confirmación',
  confirmpwderr: '¡Las dos entradas de contraseña son inconsistentes!',
  wjtab: 'Restablecer contraseña del teléfono móvil',
  wjtab2: 'Restablecer contraseña de correo electrónico',
  wjlabel: 'Nueva contraseña',
  wjerr: 'Por favor ingrese una nueva contraseña',
  labTxt: 'Laboratorio Alcex ● Compartir resultados de innovación',
  labTxt2:
    'Un laboratorio abierto en el que todos ganan para inversores, partes del proyecto, bolsas y otras partes',
  labTab1: 'todo',
  labTab2: 'A punto de comenzar',
  labTab3: 'en curso',
  labTab4: 'Distribuido',
  labTab5: 'terminado',
  tokendistribute: 'El token se está distribuyendo.',
  activitytype: 'Tipo de suscripción',
  labStart: 'Tiempo de empezar',
  labEnd: 'Hora de finalización',
  labStartPlaceholder: 'Por favor seleccione una hora de inicio',
  labDetail: 'comprobar los detalles',
  labSpeen: 'cronograma',
  labNumber: 'Actividad total',
  labType1: 'Primero en línea (compra urgente)',
  labType2: 'Primera vez en línea (incluso dividida)',
  labType3: 'Compartir posición',
  labType4: 'Suscripción gratuita',
  labType5: 'Suscripción a máquina de minería en la nube',
  labType6: 'liberación de bloqueo',
  baseinfo: 'Información básica',
  ruledetail: 'Descripción del tipo',
  releaseType: 'Tipo de lanzamiento',
  releaseType0: 'Liberar en cantidades iguales',
  releaseType1: 'Liberación de igual proporción',
  lock: 'liberación del período',
  releaseDetail: 'Detalles de lanzamiento',
  lockday: 'día',
  lockweek: 'semana',
  lockmonth: 'luna',
  lockyear: 'Año',
  releasePercent: 'Relación de liberación',
  lockFee: 'Umbral de bloqueo',
  releaseTimes: 'Liberar múltiples',
  times: 'veces',
  publishprice: 'Precio de suscripción',
  holdcoin: 'Moneda necesaria para mantener posiciones',
  limittimes: 'Límite de compra personal',
  limitamount: 'Límite de compra personal',
  unlimited: 'Sin límite',
  leveloneCount: 'Número de amigos de primer nivel requeridos',
  alreadyamount: 'Cantidad comprada',
  leftamount: 'La cantidad restante',
  myalreadyholdamount: 'Mis posiciones participantes',
  alreadyholdamount:
    'Participaciones totales de los participantes en el evento',
  currentdivided: 'Mi puesto actual se puede dividir en aproximadamente',
  holdtips:
    'Para participar en la actividad de tipo [Compartir posición], debes bloquear tu posición hasta el final de la actividad.',
  inputholdamount: 'Ingrese el monto del bloqueo',
  pleaseinputamount: 'Por favor ingrese la cantidad de canje',
  pleaseinputholdamount: 'Por favor ingresa el monto del bloqueo',
  inputamount: 'Ingrese el monto del canje',
  inputminingamount: 'Ingrese la cantidad de máquinas mineras a comprar',
  attend: 'Participa ahora',
  attention: 'Aviso',
  attentiontxt1:
    '1. Después de enviarse para participar en las actividades de tipo [Compartir posición], [Suscripción gratuita], [Suscripción a máquina de minería en la nube] y [Bloqueo], los activos se congelarán y se descongelarán o desbloquearán una vez finalizada la actividad. .',
  attentiontxt2:
    '2. Después de participar en actividades de tipo [Compartir posición], [Suscripción gratuita], [Suscripción a máquina de minería en la nube], [Bloqueo], los usuarios no pueden cancelar su solicitud de participación.',
  attentiontxt3:
    '3. Si el evento se cancela debido a la fiesta del proyecto u otros factores de fuerza mayor, los activos congelados serán devueltos a la ruta original.',
  attentiontxt4:
    '*El derecho de interpretación final de esta actividad pertenece al Alcex oficial (www.al-cex.com)',
  transfer: 'Transferencia de fondos',
  placeholder: 'Buscar moneda',
  bith1: 'comercio por contrato',
  bith2: 'Derechos de cuenta',
  bith3: 'ganancia o pérdida no realizada',
  bith4: 'Margen Disponible',
  bith5: 'Margen usado',
  bith6: 'Congelar margen',
  bith7: 'cuenta de contrato',
  btn1: 'completar',
  btn2: 'retirar',
  btn3: 'transferir',
  btn4: 'depositar monedas',
  btn5: 'retirar monedas',
  totalAssets: 'Activos totales convertidos',
  th1: 'Nombre de la moneda',
  th2: 'Activos disponibles',
  th3: 'Congelar activos',
  th4: 'Activos a liberar',
  th5: 'funcionar',
  czTxt: 'Recarga de moneda digital',
  czTxt2: 'Divisa',
  czTxt3: 'red de transferencia',
  czTxt4: 'Saldo disponible',
  czTxt5: 'dirección de retiro',
  czTxt6: 'Cantidad de monedas retiradas',
  czTxt7: 'Cantidad de llegada',
  czTxt8: 'tasa de tramitación',
  czTxt9: 'Consejos amables',
  czTxt10: 'Monto mínimo de depósito:',
  czTxt10_1: 'No se acreditarán recargas inferiores al importe mínimo.',
  czTxt11:
    'No deposite ningún activo que no sea moneda en la dirección anterior; de lo contrario, los activos no se recuperarán.',
  czTxt12:
    'Después de recargar a la dirección anterior, se requiere la confirmación de todo el nodo de la red blockchain. Generalmente, la cuenta se acreditará después de 3 confirmaciones de red de la red blockchain principal.',
  czTxt13:
    'Su dirección de recarga no cambiará con frecuencia y puede recargarse repetidamente; si hay algún cambio, haremos todo lo posible para notificarle a través de anuncios en el sitio web o correos electrónicos.',
  czTxt14:
    'Asegúrese de que su computadora y su navegador sean seguros para evitar que la información sea manipulada o filtrada.',
  czTxt15: 'Registro de recarga',
  czTxt16:
    'Para garantizar la seguridad de los fondos, cuando cambie la política de seguridad de su cuenta, modifique su contraseña o utilice una nueva dirección para retirar monedas, realizaremos una revisión manual del retiro. Tenga paciencia y espere a que el personal se comunique. usted por teléfono o correo electrónico.',
  czTxt17: 'El monto mínimo de retiro es:',
  czth1: 'tiempo de retiro',
  czth2: 'de',
  czth3: 'a',
  czth4: 'Cantidad de canje instantáneo',
  czth5: 'Relación de cambio',
  czth6: 'Cantidad de cambio',
  placeholder2: 'por favor escribe',
  placeholder3: 'por favor elige',
  czEmpty: 'sin registro',
  logintip: 'por favor ingresa primero',
  cztip:
    'No has solicitado una dirección de recarga. Haz clic en el botón para obtener la dirección de recarga.',
  czget: 'Obtener dirección de recarga',
  czplaceholder: 'Por favor seleccione un protocolo',
  mAccount: 'segunda cuenta de contrato',
  btn6: 'buscar',
  wtTxt: 'Hora de inicio y finalización',
  wtTxt2: 'Tipo de operación',
  wtth1: 'hora de transacción',
  wtth2: 'tipo',
  wtth3: 'Divisa',
  wtth4: 'cantidad',
  wtth5: 'Tarifa de manejo a pagar',
  wtth6: 'Deducir los gastos de manipulación',
  wtth7: 'Tarifa de manejo real',
  wtth8: 'estado',
  nav1: 'seguridad de la cuenta',
  nav2: 'mi invitación',
  nav3: 'mi tarjeta promocional',
  acTxt: 'Nivel de seguridad',
  acbtn1: 'no certificado',
  acbtn2: 'vinculante',
  acbtn3: 'Revisar',
  acbtn4: 'verificado',
  acbtn5: 'Atado',
  acbtn6: 'configuración',
  acbtn7: 'bajo revisión',
  acbtn8: 'Rever',
  acTxt2: 'Verificado',
  acTxt3: 'Correo',
  acTxt4: 'Teléfono móvil',
  acTxt5: 'contraseña de inicio de sesión',
  acTxt6: 'Contraseña del fondo',
  acTxt7: 'Autenticador de Google',
  acTxt8: 'Establecer contraseña de fondo',
  acTxt9: 'Cambiar contraseña de fondo',
  acTxt10: 'Recuperar contraseña de fondo',
  acDesc2:
    'Para garantizar la seguridad de su cuenta, complete la autenticación de nombre real antes de operar.',
  acDesc3: 'Vincular correo electrónico',
  acDesc4: 'vincular teléfono móvil',
  acDesc5: 'Se utiliza al iniciar sesión en la plataforma.',
  acDesc6:
    'Cuando los fondos de la cuenta cambian, es necesario verificar la contraseña del fondo.',
  acDesc7: 'Vincule el autenticador de Google',
  acDesc8: 'Google Authenticator está vinculado',
  acDesc9: 'Desatar',
  acTit: 'Enlace de teléfono móvil',
  acTit2: 'Enlace de correo electrónico',
  acTit3: 'Enlace de Google',
  acTit4: 'desvincular google',
  mailtip: 'por favor ingrese su correo electrónico',
  emailerr2:
    'El formato del correo electrónico es incorrecto, vuelva a ingresarlo.',
  agreementtip: 'Por favor lea y acepte el acuerdo de usuario.',
  acverify: 'Revisión fallida',
  again: 'Inténtalo de nuevo',
  verifiedmsg: 'Realice primero la autenticación de nombre real',
  apiTxt: 'Vincular cuenta de nombre real',
  apiTxt2: 'pista',
  apiTxt3:
    'Le proporcionamos una potente API a través de la cual puede utilizar servicios como investigación de mercado y comercio automático. ',
  apiTxt4: 'Cada usuario puede crear hasta 5 conjuntos de claves API',
  apiTxt5: 'No revele su clave API para evitar la pérdida de activos. ',
  apiTxt6: 'Notas API',
  apiTxt7:
    'IP autorizada (la clave de la dirección IP autorizada es válida por 90 días)',
  apibtn: 'crear',
  apith1: 'Notas API',
  apith2: 'tiempo de creación',
  apith3: 'llave de acceso',
  apith4: 'Vincular dirección IP',
  apith5: 'Período de validez restante (días)',
  chTxt: 'Cambiar contraseña de inicio de sesión',
  chTxt2: 'Escanear código para vincular',
  chTxt3: 'por favor ingrese el código de verificación',
  chTxt4: 'Contraseña original del fondo',
  chTxt5: 'Nueva contraseña de fondo',
  chTxt6: 'Confirma la nueva contraseña',
  chTxt7: 'olvida la contraseña',
  chTxt8: 'Cambiar la verificación del teléfono móvil',
  chTxt9: 'Contraseña de inicio de sesión original',
  chTxt10: 'Nueva contraseña de inicio de sesión',
  chTxt11: 'Código de verificación de correo electrónico',
  chTxt12: 'Código de verificación del teléfono móvil',
  telerr: 'El número de teléfono móvil es incorrecto',
  emailerr: 'El correo electrónico es incorrecto.',
  codeerr: 'código de verificación incorrecto',
  reset: 'reiniciar',
  save: 'ahorrar',
  chtip: 'Por favor ingrese la contraseña original',
  chtip2:
    'Ingrese una nueva contraseña de inicio de sesión de no menos de 6 dígitos',
  chtip3: 'La nueva contraseña de inicio de sesión es inconsistente',
  chtip4: 'Por favor ingrese la contraseña del fondo correcta',
  chtip5: 'La contraseña debe tener al menos 6 caracteres',
  chtip6: 'Por favor ingrese una contraseña de no menos de 6 dígitos',
  chtip7: 'Las contraseñas son inconsistentes',
  chtip8: 'Por favor ingrese la contraseña del fondo',
  chtip9: 'Por favor ingrese el número de teléfono',
  chtip10: 'Por favor, introduzca su contraseña',
  realnametip: 'por favor ingresa tu nombre real',
  idcardtip: 'Por favor ingrese su número de identificación',
  clTxt:
    'Configure su método de pago y asegúrese de utilizar su cuenta de nombre real',
  clTxt2: 'Añadir método de pago',
  edit: 'editar',
  delete: 'borrar',
  name: 'Nombre',
  clTxt3: 'número de identificación',
  clTxt4: 'Número IBAN',
  clTxt5: 'Todos los métodos de pago',
  clLink1: 'nombre de la cuenta',
  clLink2: 'Editar método de pago',
  clLink3: 'Detalles de pago (opcional)',
  clplaceholder1: 'Por favor ingresa tu nombre completo',
  clplaceholder2: 'Por favor ingrese la contraseña de su cuenta bancaria',
  clplaceholder3: 'Por favor introduzca sus datos de pago',
  cltip: 'Nota especial',
  cltip2:
    'Asegúrese de agregar el número de su tarjeta bancaria para realizar el pago instantáneo. ',
  cltip3:
    'Recordatorio: cuando venda moneda digital, el método de pago que elija se mostrará al comprador. ',
  clTxt6: 'Opcional',
  upload: 'subir',
  uploadTip: '(Admite formato JPG/JPEG/PNG/BMP, menos de 1 MB)',
  tgbtn: 'Canjear código',
  tgth1: 'Nombre de tarjeta',
  tgth2: 'Canjear código',
  tgth3: 'Moneda de la tarjeta',
  tgth4: 'Importe nominal de la tarjeta',
  tgth5: 'total',
  tgth6: 'Ya canjeado',
  tgth7: 'Hora de recogida',
  tgbtn2: 'Materiales promocionales',
  tgbtn3: 'Canjear ahora',
  tgplaceholder: 'Por favor ingresa el código de canje',
  exchangesuccess: '¡Redención exitosa! ',
  inth1: 'Invitar usuarios',
  inth2: 'Hora de registro',
  inth3: 'Estado del nombre real',
  adTxt: 'mi anuncio',
  adTxt2:
    'Cuando el monto mínimo de transacción del anuncio más la tarifa de procesamiento sea mayor que la cantidad restante del anuncio, el anuncio se eliminará automáticamente de los estantes.',
  adbtn: 'Condiciones claras',
  adbtn2: 'En los estantes',
  adbtn3: 'Eliminado de los estantes',
  adth1: 'Número de anuncio',
  adth2: 'tipo de anuncio',
  adth3: 'Límites de pedidos',
  adth4: 'La cantidad restante',
  adtip: 'confirmar para eliminar',
  num_text1: 'Por favor ingresa lo que quieras',
  num_text2: 'cantidad',
  wTxt: 'Productos al contado',
  wTxt2: 'Orden actual',
  wTxt3: 'par comercial',
  wTxt4: 'Dirección comercial',
  wth1: 'tiempo',
  wth2: 'dirección',
  wth3: 'precio',
  wth4: 'Trato hecho',
  wth5: 'Rotación',
  wth6: 'Tipo de delegado',
  wth7: 'precio de activación',
  wth8: 'precio final',
  wth9: 'Ordene la cantidad',
  wth10: 'Ganancias y pérdidas de la liquidación',
  wth11: 'Estado de la delegación',
  wth12: 'Abrir/Cerrar una posición',
  wth13: 'margen',
  wbtn: 'Cancelar orden',
  wtip: 'Recordatorio de cancelación',
  wtiptxt: '¿Confirmar la cancelación del pedido?',
  zbplaceholder: 'Ingrese el número de pedido para iniciar la búsqueda',
  zbtab1: 'Plan de inversión fija',
  zbtab2: 'Récord de inversión fija',
  zbth1: 'ciclo de inversión',
  zbth2: 'cambiar',
  zbth3: 'Monto de inversión acumulada',
  zbth4: 'Cantidad retenida',
  zbth5: 'costo promedio',
  zbth6: 'ganancia o pérdida no realizada',
  zbth7: 'Próxima fecha de inversión prevista',
  zbth8: 'Fecha de creación del plan',
  etTxt: 'Centro de anuncios',
  etTxt2: 'Escanear para compartir',
  etmenu1: 'Criptomoneda',
  etmenu2: 'Anuncio de actualización del servidor Alcex',
  et2menu1: 'Introducción a RX.Tron',
  et2menu2: '¿Es Bitcoin un esquema Ponzi?',
  hisTxt: 'comisión histórica',
  orTab1: 'No pagado',
  orTab2: 'Ya pagado',
  orTab3: 'terminado',
  orTab4: 'Cancelado',
  orTab5: 'Atractivo',
  orth1: 'número de orden',
  orth2: 'Moneda de transacción',
  orth3: 'tipo de transacción',
  orth4: 'Socios comerciales',
  orth5: 'Cantidad',
  qcTab1: 'Resultados anteriores',
  qcTab2: 'Cotizaciones en tiempo real',
  qcTxt: 'No.',
  qcTxt2: 'Esperar',
  qcTxt3: 'hora de apertura',
  qcTxt4: 'hora de cierre',
  qcTxt5: 'precio de apertura',
  qcTxt6: 'precio de cierre',
  qcTxt7: 'Precio total de compra',
  qcTxt8: 'Importe total de compra y venta',
  qcTxt9: 'Mi posición abierta',
  qcTxt10: 'Progreso del pronóstico',
  qcTxt11: 'precio de apertura',
  qcTxt12: 'Precio de cierre',
  qcTxt13: 'Este período de pronóstico',
  qcbtn1: 'alcista',
  qcbtn2: 'Osuno',
  qcth1: 'Número de periodos de contrato',
  qcth2: 'Monto de apertura',
  qcth3: 'dirección de predicción',
  qcth4: 'resultado del pronóstico',
  qcth5: 'Monto del bono',
  qcth6: 'Tarifa de apertura',
  qcth7: 'bomba de agua',
  mplaceholder: 'Ingrese el nombre de la moneda para buscar',
  mTab: 'Opcional',
  mth: 'recolectar',
  mth1: 'Último precio',
  mth2: '24h subida y bajada',
  mTxt: 'precio más alto 24h',
  mTxt2: '24h precio más bajo',
  mTxt3: 'Número de transacciones',
  mTxt4: 'tasa de financiación',
  mTxt5: 'abierto',
  mTxt6: 'alto',
  mTxt7: 'Bajo',
  mTxt8: 'recibir',
  echat1: 'gráfico de líneas k',
  echat2: 'mapa de profundidad',
  mTab2: 'Posición actual',
  mTab3: 'Historia de la comisión',
  mth3: 'Compensación garantizada',
  mth4: 'Precio de apertura',
  mth5: 'ciclo',
  mth6: 'Precio de cierre',
  mth7: 'ganancia',
  mTxt9: 'modelo comercial',
  mTxt10: 'Hora de apertura',
  mTxt11: 'Rentabilidad',
  mTxt12: 'balance',
  mTxt13: 'Volumen de compra',
  mTxt14: 'medio',
  cadTxt: 'Crear una oferta publicitaria',
  cadTxt2:
    'Si opera con frecuencia, puede crear sus propios anuncios comerciales.',
  cadTxt2_1:
    'Si solo opera ocasionalmente, le recomendamos buscar directamente',
  cadTxt2_2: 'Crear un anuncio comercial es gratis.',
  cadLink2: 'publicidad de transacciones',
  cadTxt3: 'Si desea editar directamente un anuncio que ha creado, consulte',
  cadLink3: 'mi anuncio',
  cadTxt4: 'precio de participación en el mercado',
  cadTxt5:
    'La prima se refiere al porcentaje por encima del precio actual de mercado para vender.',
  cadTxt6: 'Fórmula de precios',
  cadTxt7:
    '[Consejo] Puede ir al centro personal para vincular/agregar métodos de pago',
  cadTxt8:
    'El monto máximo de transacción no puede exceder el monto total de venta 0CNYI',
  cadTxt9:
    'Después de habilitarlo, cuando un usuario inicia una transacción con usted a través de este anuncio, el sistema enviará automáticamente el texto de respuesta automática que usted seleccionó a la otra parte.',
  cadTxt10:
    'La prima se refiere al porcentaje por encima del precio actual de mercado para comprar.',
  cadLabel1: 'Deseo:',
  cadLabel2: 'Transacción de moneda:',
  cadLabel3: 'nación:',
  cadLabel4: 'divisa:',
  cadLabel5: 'Activar precio fijo',
  cadLabel6: 'De primera calidad:',
  cadLabel7: 'Precio de negociación',
  cadLabel8: 'Cantidad vendida:',
  cadLabel9: 'método de pago:',
  cadLabel10: 'Monto mínimo de transacción:',
  cadLabel11: 'Monto máximo de transacción:',
  cadLabel12: 'Activar respuestas automáticas',
  cadLabel13: 'Contraseña del fondo:',
  cadLabel14: 'Observaciones:',
  cadLabel15: 'Precio fijo:',
  cadLabel16: 'respuesta automática:',
  cadLabel17: 'Cantidad de compra:',
  radio: 'a la venta en línea',
  radio2: 'comprar en linea',
  cadplaceholder: 'Por favor establezca su prima',
  cadplaceholder2: 'Por favor ingresa la cantidad que deseas vender',
  cadplaceholder3: 'Por favor ingrese el monto mínimo de transacción que desea',
  cadplaceholder4: 'Por favor ingrese el monto máximo de transacción que desea',
  cadplaceholder5:
    'Puede completar sus requisitos especiales en la información de comentarios, como: requisitos para compradores, tiempo en línea, etc.',
  cadplaceholder6: 'Por favor ingrese el precio de su transacción',
  cadplaceholder7:
    'Después de recibir el pedido, responda automáticamente a la información del comprador, como método de pago, número de cuenta de pago, etc.',
  cadplaceholder8: 'Por favor ingresa la cantidad que deseas comprar',
  caderr1: 'Por favor ingrese el monto mínimo de su transacción',
  caderr2: '¡El monto mínimo de transacción debe ser mayor o igual a 100!',
  caderr3:
    'El monto mínimo de transacción debe ser menor que el monto máximo de transacción',
  caderr4: '¡Ingrese el monto máximo de transacción!',
  caderr5: 'Por favor ingresa un número entero',
  caderr6:
    '¡El monto máximo de transacción debe ser mayor que el monto mínimo de transacción!',
  caderr7:
    'El monto máximo de transacción no puede exceder el monto total de sus ventas.',
  caderr8: 'Por favor seleccione un método de transacción',
  submit: 'entregar',
  cadnew:
    'Después de la activación, el precio de su moneda no fluctuará con el mercado y el precio permanecerá sin cambios.',
  dhTxt: 'Intercambia con un clic y disfruta de los beneficios',
  dhTxt2: 'límite de precio',
  dhTxt3: 'vender',
  dhTxt4: 'comprar',
  dhTxt5: 'máximo',
  dhTxt6: 'Ingrese la cantidad',
  dhplaceholder: 'Saldo disponible de la billetera al contado',
  dhSuccess: '¡Felicidades! ',
  crTxt: 'Comercio de divisas fiduciarias',
  crTxt2: 'Compra y venta cómoda, segura y rápida de monedas digitales',
  crTxt3: 'Ajuste dinámico',
  crTxt4: 'Sin gastos de gestión',
  crTxt5: 'Transacción instantánea',
  crTxt6: 'Garantía de plataforma',
  crTxt7: 'Fluctuar en tiempo real según los precios del mercado.',
  crTxt8:
    'Lo que los usuarios ven es lo que obtienen y no hay tarifas de plataforma más que el precio de compra y venta.',
  crTxt9:
    'Presentamos una plataforma para ofrecer a los comerciantes, cotejo inteligente y órdenes de transacciones sin esperar a que se cotejen.',
  crTxt10:
    'Comerciantes certificados en plataforma, seguros y protegidos, servicio al cliente las 24 horas para proteger las transacciones',
  crth1: 'Comerciante',
  crth2: 'Número de transacciones',
  crth3: 'método de pago',
  crth4: 'límite',
  crth5: 'precio unitario',
  crbtn: 'Comprar',
  crbtn2: 'Conviértete en comerciante',
  cr1Txt: '¡Felicidades! ',
  cr1Txt2: 'preparar materiales',
  cr1Txt3: 'Enviar opinión',
  cr1Txt4: 'verificado',
  cr1Txt5: 'Se le conceden los siguientes permisos',
  cr1Txt6: 'Stand exclusivo',
  cr1Txt7: 'Servicio uno a uno',
  cr1Txt8:
    'Los comerciantes disfrutan de stands publicitarios exclusivos para aumentar las tasas de éxito de las transacciones',
  cr1Txt9: 'Tarifas más bajas',
  cr1btn: 'Colocar un anuncio',
  cr1btn2: 'Solicitar rendición',
  reTxt: 'Alcex Financial Management●Solución de inversión integral',
  reTxt2: 'Gana dinero al instante, fácil y seguro',
  reTab: 'Gestión financiera periódica',
  reTab2: 'Gestión financiera de inversiones fijas',
  reTxt3: 'regular',
  reTxt4: 'Personalización flexible, mayores beneficios potenciales',
  reTxt6: 'inversión fija',
  reTxt7: 'Comience a utilizar la inversión fija y haga crecer sus activos',
  reTxt8: 'Ampliar los 18 productos habituales',
  reth1: 'tasa de rendimiento anualizada',
  reth2: 'Plazo (días)',
  reth3: 'Límite de transacciones',
  reth4: 'producto',
  reth5: 'retorno histórico de la inversión',
  rebtn: 'Crear plano',
  redTxt: 'Crear',
  redTxt2: 'Plan de inversión fija',
  redTxt3: 'Ingrese el monto de la inversión',
  redTxt4: 'Mi saldo disponible es',
  redTxt5: 'Disponible',
  redTxt6: 'período del ciclo',
  redTxt7: 'cada día',
  redTxt8: 'semanalmente',
  redTxt9: 'por mes',
  redTxt10: 'hora local',
  redTxt11: 'Su primer ciclo de inversión automático será',
  redTxt12: 'comenzar',
  confirm: 'confirmar',
  confirm2: 'Seguro',
  cancel: 'Cancelar',
  redongoing: 'en curso',
  redcompleted: 'terminado',
  minNumErr: 'Menos del monto inicial mínimo',
  minNumErr2: 'El importe inicial no puede ser 0',
  buyamounttip: 'Por favor ingrese el monto de la compra',
  pricetipwarning: 'El monto de la transacción no puede ser mayor que',
  buyamounttipwarning: 'La cantidad de compra no puede ser superior a',
  sellamounttip: 'Por favor ingrese el monto de venta',
  sellamounttipwarning: 'La cantidad de venta no puede ser superior a',
  sellpricetip: 'Por favor ingrese el precio de venta',
  sellpricetipwarning: 'El precio de venta no puede ser superior a',
  sellmore: 'Lo máximo que se puede vender.',
  sellunit: 'individual',
  loginFirst: 'por favor ingresa primero',
  betsuccess: '¡Felicidades! ',
  selectAmount: 'Por favor seleccione el monto de su apuesta',
  balancenotenough: '¡Saldo disponible insuficiente!',
  balancenotenough2: '¡Saldo insuficiente!',
  minlimitamountfailed:
    '¡El monto de canje no puede ser menor que el monto mínimo de canje!',
  maxlimitamountfailed:
    '¡La cantidad de canje no puede ser mayor que la cantidad máxima de compra!',
  limittimesfailed:
    '¡El número de veces que has participado supera el número máximo de compras personales!',
  commitfailed: '¡La presentación para participar falló!',
  trplaceholder: 'Preguntar',
  trplaceholder2: 'Último precio',
  trplaceholder3: 'Compra al mejor precio del mercado',
  trplaceholder4: 'Vender al mejor precio del mercado',
  triggleplaceholder:
    'El precio de orden predeterminado es el precio de mercado.',
  triggerpriceshouldbigger: 'El precio de activación debe ser mayor que 0.',
  marginModeSuccessTip: 'Modo de cuenta de contrato modificado exitosamente',
  noenoughbalance: 'Margen de cuenta insuficiente',
  pleaseinputopenvolume: 'La cantidad de apertura debe ser mayor que 0.',
  pleaseinputcorrectopenvolume:
    'Por favor ingrese la cantidad de apertura correcta',
  pleaseinputrightentrustprice:
    'Por favor ingrese el precio correcto del pedido',
  noenoughposition: 'Posiciones liquidables insuficientes',
  pleaseinputclosevolume: 'La cantidad de cierre debe ser mayor que 0.',
  pleaseinputcorrectclosevolume:
    'Por favor ingrese la cantidad de cierre correcta',
  option1: 'antena',
  option2: 'perímetro',
  option3: 'linea mensual',
  trTxt: 'Volumen de transacciones',
  trTxt2: 'precio de mercado',
  trTxt3: 'Volumen de ventas',
  trSuccess: 'Enviado satisfactoriamente',
  udTxt: 'Modo de margen',
  udTxt2: 'Ajustar múltiplos de contrato',
  udTxt3: 'palanca',
  udTxt4: 'Sostenible',
  udTxt4_1: 'Sostenible',
  udTxt5: 'Monto transferible',
  udTxt6: 'Cantidad de transferencia',
  udTxt7: 'Transferir todo',
  udTxt8: 'cuenta perpetua',
  ubtn: 'Almacén completo',
  ubtn2: 'Posición aislada',
  uplaceholder: 'Cuenta de contrato perpetuo',
  uradio1: 'tiempo compartido',
  uradio2: '15 minutos',
  uradio3: '1 hora',
  uradio4: '4 horas',
  uradio5: '1 día',
  uth1: 'contrato múltiple',
  uth2: 'ingreso',
  uth3: 'tasa de retorno',
  uth4: 'Interes abierto',
  uth5: 'Se puede medir uniformemente',
  uth6: 'precio medio de apertura',
  uth7: 'Fuerte paridad',
  uth8: 'Margen actual',
  uth9: 'tasa de margen',
  zhang: 'abierto',
  or: 'o',
  ureg: 'Regístrate ahora',
  uTxt8: 'Comercio',
  uTab: 'abrir una posición',
  uTab2: 'Posición cercana',
  uTxt9: 'Puede abrirse por mucho tiempo',
  uTxt10: 'Se puede abrir corto',
  uTxt11: 'Precio del pedido',
  uplaceholder2: 'Transacción al mejor precio del mercado.',
  uTxt12: 'Comprar para abrir en largo',
  uTxt13: 'vender corto',
  closeup: 'comprar corto',
  closedown: 'vender largo',
  uTab3: 'Obtenga ganancias y detenga las pérdidas',
  uTxt14: 'Mi cuenta de contrato',
  ulabel1: 'Modo cuenta',
  ulabel2: 'Ingresos totales de la cuenta',
  ulabel3: 'Margen de posición',
  ulabel4: 'Tasa de utilización de fondos',
  ulabel5: 'muchos',
  ulabel6: 'nulo',
  uTxt15: 'largo',
  uTxt16: 'corto',
  total: 'total',
  limited_price: 'orden límite',
  market_price: 'orden de mercado',
  spot_price: 'Obtenga ganancias y detenga las pérdidas',
  ent_status1: 'En comisión',
  ent_status2: 'Revocado',
  ent_status3: 'La delegación falló',
  ent_status4: 'Delegación exitosa',
  ent_statusblast: 'Liquidación',
  uTxt17: 'Se pueden cerrar posiciones largas',
  uTxt18: 'La posición corta se puede cerrar',
  prev: 'Anterior',
  next: 'Próximo',
  prtxt: 'mi enlace de invitación',
  copy: 'Copiar',
  prtxt2: 'mi amigo de primer nivel',
  prtxt3: 'mis amigos secundarios',
  prtxt4: 'Ingresos por comisiones (equivalentes a USDT)',
  prtxt5: 'Recompensas adicionales',
  prtxt6: 'Nivel de asociación',
  prtit: 'Detalles de la regla',
  prdesc:
    'El [Socio de promoción] de Alcex es una actividad de promoción con el índice de reembolso de comisión más alto y la duración de reembolso más larga (reembolso máximo de por vida) en toda la red, lo que permite a los promotores que realizan los esfuerzos correspondientes convertirse en un verdadero "socio" de la plataforma y disfrutar de ¡Dividendos de crecer junto con la plataforma comercial Alcex! ',
  prli1:
    '1. Para promover el concepto de activos digitales y ampliar la escala de usuarios, Alcex lanzó el programa [Promotion Partner], que es efectivo desde hace mucho tiempo.',
  prli2:
    '2. La invitación a amigos se divide en dos niveles: si A invita a B, entonces B es el amigo de primer nivel de A. Si B invita a C, entonces C es el amigo de primer nivel de B y también el amigo de segundo nivel de A.',
  prli3:
    '3. Al registrarse como amigo de la promoción, debe utilizar el enlace proporcionado por el invitante o ingresar manualmente el código de invitación del invitante para convertirse en el amigo de primer nivel del invitante.',
  prli4:
    '4. El incentivo de reembolso solo puede entrar en vigor después de que el invitado se registre con el código de invitación del invitante y complete la autenticación de nombre real.',
  prli5:
    '5. El período de reembolso es de N meses desde el momento en que el invitado completa la autenticación de nombre real. Dependiendo del nivel, la proporción de reembolso es diferente.',
  prli6:
    '6. El activo de reembolso de promoción es la tarifa de transacción de la moneda del amigo subordinado, y la moneda de reembolso son las tres principales monedas base del mercado, a saber: USDT, BTC y ETH.',
  prli7:
    '7. La proporción de reembolso de promoción y la duración del reembolso están correlacionadas positivamente con la cantidad de amigos de primer nivel promocionados. Cuantas más personas promueva, mayor será la proporción de reembolso que podrá disfrutar. ',
  prli8:
    '8. Los 10 primeros o 100 primeros en la lista de clasificación de la comisión de promoción recibirán recompensas adicionales emitidas oficialmente por Alcex de vez en cuando.',
  prli9:
    '9. Si otras actividades entran en conflicto con las actividades de [Socio de promoción], se decidirán cambios temporales después de consultar. Esperamos su comprensión por parte de los socios.',
  prli10:
    '10. Los socios de nivel L4 (Gobernador), L5 (Prefecto) y L6 (Jiedushi) disfrutan de recompensas de dividendos del 5%, 10% y 15% del reembolso total de la comisión anual, respectivamente.',
  prli11:
    '11. El derecho de interpretación final de esta actividad pertenece a Alcex.',
  prth1: 'nivel',
  prth2: 'Número de amigos de primer nivel',
  prth3:
    'Proporción de reembolso de amigos de primer nivel/tiempo de reembolso',
  prth4:
    'Proporción de reembolso de amigos de segundo nivel/tiempo de reembolso',
  prth5: 'Dividendos de socios',
  prlev1: 'Li Zheng',
  prlev2: 'magistrado del condado',
  prlev3: 'prefecto',
  prlev4: 'gobernador',
  prlev5: 'Prefecto',
  prlev6: 'Jiedushi',
  prtd1: 'meses',
  prtd2: 'Reembolso de por vida',
  prtit2: 'Por ejemplo',
  prtxt7:
    'Si el usuario Xiao Yan invita a 100 amigos de primer nivel y cada uno de sus amigos de primer nivel invita a 5 amigos de segundo nivel, Xiao Yan tendrá 100 amigos de primer nivel y 500 amigos de segundo nivel. ',
  prtxt8:
    'Si el usuario Xiao Yan invita a 1000 amigos de primer nivel y cada uno de sus amigos de primer nivel invita a 5 amigos de segundo nivel, Xiao Yan tendrá 1000 amigos de primer nivel y 5000 amigos de segundo nivel. ',
  prtit3: 'Caja de herramientas de promoción',
  prtit4: 'Los 20 principales reembolsos de promoción total (lista total)',
  prtit5: 'Top 20 promotores (lista total)',
  prtxt9: 'Fecha límite de estadísticas:',
  prth6: 'Clasificación',
  prth7: 'miembro',
  prth8: 'Número de promoción',
  prth9: 'Reembolso (equivalente a USDT)',
  prth10: 'Recompensas adicionales',
  prtip1:
    'Los datos de comisiones anteriores no se actualizan en tiempo real y el sistema los cuenta y actualiza cada 24 horas.',
  prtip2:
    'La cantidad de invitados es la cantidad de amigos de primer nivel y esta lista es la clasificación del monto total del reembolso.',
  prtip3:
    'La cantidad de invitados es la cantidad de amigos de primer nivel, y esta lista es la clasificación de la cantidad de invitados de primer nivel.',
  recth1: 'Hora de llegada',
  recth2: 'protocolo de red',
  recth3: 'Dirección de depósito',
  recth4: 'Cantidad de recarga',
  recbtn: 'Recarga de código de retiro de monedas',
  recplaceholder: 'Por favor ingrese el código de retiro',
  recstatus_0: 'fallar',
  recstatus_1: 'No recibido',
  recstatus_2: 'Ya llegó',
  wittit: 'Retiro de moneda digital',
  wittext: 'Registro de retiros',
  wittext2: 'código de retiro',
  wittext3: 'Lista de códigos de retiro de monedas',
  witbtn: 'Retirar Código de Moneda Retirar Moneda',
  witbtn2: 'Generar código de retiro de moneda',
  witdia: 'verificación de seguridad',
  witdia2: 'Código de retiro generado exitosamente',
  witplaceholder: 'Por favor ingrese el monto del retiro',
  witerr: 'Por favor seleccione moneda',
  addresstip: 'Por favor complete la dirección',
  witstate: 'Para ser redimido',
  witstate2: 'fallar',
  witstate3: 'Redención completada',
  witstatus_1: 'bajo revisión',
  witstatus_2: 'Transferencia',
  witstatus_3: 'fallar',
  witstatus_4: 'éxito',
  type1: 'transferir',
  type2: 'Comercio de criptomonedas',
  type3: 'Compra de moneda fiduciaria',
  type4: 'Vender moneda fiduciaria',
  type5: 'Premio a la actividad',
  type6: 'Recompensas promocionales',
  type7: 'dividendo',
  type8: 'votar',
  type9: 'recarga manual',
  type10: 'par',
  type11: 'Redención de eventos',
  type12: 'compra C2C',
  type13: 'venta C2C',
  type14: 'sobres rojos',
  type15: 'recibir sobre rojo',
  type16: 'Retirar Código de Moneda Retirar Moneda',
  type17: 'Retirar código de moneda y depositar moneda',
  type18: 'Tarifa de gestión de contrato perpetuo',
  type19: 'Beneficio del contrato perpetuo',
  type20: 'Pérdida de contrato perpetuo',
  type21: 'Contrato de opciones fallido',
  type22: 'Tarifa de manejo del contrato de opciones',
  type23: 'Bonificación del contrato de opciones',
  type24: 'Reembolso del contrato',
  type25: 'Recompensas de nivel',
  type26: 'Ingresos por tarifas de plataforma',
  type27: 'Segundo contrato fallido',
  type28: 'Bonificación del segundo contrato',
  type29: 'interés financiero',
  type30: 'Fondos de gasto',
  type31: 'Obtención de tarifas de financiación',
  type32: 'Compra de inversión fija',
  type33: 'Vendo inversion fija',
  type34: 'comprar regular',
  type35: 'Redención regular',
  type36: 'Transferencia de contrato basada en monedas',
  type37: 'Transferencia de contrato basada en monedas',
  type38: 'Transferencia de contrato basada en U',
  type39: 'Transferencia de contrato basada en U',
  type40: 'Transferencia de segundo contrato de transferencia',
  type41: 'Transferencia del segundo contrato',
  type42: 'Transferencia de monedas',
  type43: 'Transferencia de monedas',
  lctit: 'cuenta financiera',
  lcbtn: 'Transferencia de gestión financiera',
  lcth1: 'Intereses acumulados',
  day: 'cielo',
  lctxt: 'Moneda de actividad',
  lctxt2: 'Monedas aceptadas',
  lctxt3: 'Cantidad inicial',
  lctxt4: 'periodo de selección',
  year: 'Año',
  month: 'luna',
  ldtab1: 'Registro de posición',
  ldtab2: 'Registros de cierre',
  ldth1: 'Fecha de inicio de ganancias',
  ldth2: 'fecha de redención',
  ldth3: 'estimación de ingresos',
  ldth4: 'ingreso',
  attit: 'Modificar plan de inversión fija',
  attxt: 'Cantidad de inversión',
  attxt2: 'ciclo de inversión',
  atth1: 'Fecha de inversión fija',
  atth2: 'Monto de inversión fija',
  atth3: 'Tasa de transacción',
  success: 'éxito',
  cxth1: 'nombre',
  cxth2: 'tipo',
  cxth3: 'Cantidad de suscripción',
  cxth4: 'Monedas participantes',
  cxth5: 'Unidad de suscripción',
  cxth6: 'Estado actual',
  cxth7: 'Rotación',
  cxth8: 'tiempo de creación',
  cxnav1: 'participé',
  cxnav2: 'mi máquina minera',
  cxnav3: 'Mi posición de bloqueo',
  cxtit2: 'Mi lista de máquinas mineras',
  cxtit3: 'Mi lista de bloqueo',
  cxkth1: 'Moneda de salida',
  cxkth2: 'ciclo productivo',
  cxkth3: 'tiempo de minería',
  cxkth4: 'tiempo minado',
  cxkth5: 'capacidad básica',
  cxkth6: 'Capacidad real',
  cxstate1: 'No implementado',
  cxstate2: 'Desplegada',
  cxstate3: 'Revocado',
  cxstate4: 'transacción pendiente',
  cxstate5: 'encima',
  cxstate6: 'Para ser lanzado',
  cxstate7: 'liberando',
  cxcth1: 'Liberar moneda',
  cxcth2: 'bloqueo total',
  cxcth3: 'período de lanzamiento total',
  cxcth4: 'ciclo liberado',
  cxcth5: 'liberación básica',
  cxcth6: 'lanzamiento real',
  more: 'Más',
  abtit: 'Características de la plataforma',
  abtit2: 'sobre nosotros',
  abtit3: 'Contáctenos',
  abtit4: 'Comunidad BIZZÁN',
  abdesc:
    'Alcex es la plataforma de comercio de activos digitales con tecnología líder en el mundo. Está registrada en las Islas Caimán y su equipo operativo principal está ubicado en Hong Kong. ',
  abdesc2:
    'La plataforma Alcex tiene una estructura de transacciones de nivel financiero profesional y un motor de transacciones de coincidencia de memoria de alta concurrencia de desarrollo propio. La plataforma adopta un sistema de billetera de recarga completamente en frío + firma múltiple + sistema de ataque DDOS de alta protección y otras estructuras de transacciones para garantizar la seguridad de los activos de los clientes.',
  abdesc3:
    'Para permitir a los clientes aprovechar mejor las oportunidades de inversión, Alcex adopta una gestión plana dentro del departamento de servicio al cliente y establece un mecanismo de respuesta de servicio extremadamente rápido. El administrador de servicio al cliente de activos está en línea las 7 horas del día, las 24 horas del día, durante todo el año para brindar a los clientes servicios como recarga y retiro, asegurando que los clientes puedan responder en 5 minutos Complete el depósito y retiro de activos.',
  abdesc4:
    'Alcex selecciona estrictamente proyectos de alta calidad y brinda servicios de custodia de activos seguros y estables. ',
  abdesc5:
    'Aunque los activos digitales ahora son solo un pequeño círculo, creemos que este tipo de activo que todos pueden controlar por completo definitivamente se volverá popular en el futuro. ¡Trabajemos juntos y lo esperamos con ansias!',
  abdesc6: 'Servicio al Cliente',
  abdesc7: 'Apoyo técnico',
  abdesc8: 'Solicitud de listado de monedas',
  abdesc9: 'Sugerencias',
  abdesc10: 'Cooperación empresarial',
  abdesc11: 'WeChat',
  abdesc12: 'Weibo',
  abdesc13: 'Gorjeo',
  abdesc14: 'uso de monedas',
  abdesc15: 'telégrafo',
  abdesc16:
    'Agregue "bmate601" como amigo de WeChat e ingrese a la comunidad de bienestar de WeChat',
  realtxt: 'nombre real',
  realtxt2: 'número de identificación',
  realtxt3: 'Foto frontal del documento de identidad',
  realtxt4: 'Foto del reverso del documento de identidad',
  realtxt5: 'Foto de posesión de documento de identidad y firma personal.',
  realtxt6:
    '1. Se admiten los formatos JPG y PNG, el tamaño del archivo es inferior a 8 M, la información del certificado es claramente visible y no se permite ninguna modificación ni bloqueo, el número y el nombre del certificado deben ser claramente visibles.',
  realtxt7:
    '2. La fotografía deberá realizarse sin sombrero ni maquillaje, debiendo ser claramente visibles los rasgos faciales de la persona que porta el documento y los brazos totalmente expuestos.',
  realtxt8:
    '3. Debe tener el certificado y la copia manuscrita (nombre + fecha de solicitud) al mismo tiempo. Todas mis acciones en este sitio las realizo yo mismo, soy consciente de los riesgos relevantes y estoy dispuesto a asumir todas las consecuencias legales. de esta cuenta. ',
  realbtn: 'Haga clic para cargar',
  realstate: 'No es nombre real',
  realstate2: 'Ya nombre real',
  uploadimg: 'Sube una foto frontal de tu documento de identidad.',
  uploadimg2: 'Sube una foto del reverso de tu documento de identidad.',
  uploadimg3: 'Por favor sube una foto de tu documento de identidad.',
  uploaderr: 'El tamaño de la imagen cargada no puede exceder',
  save_success: '¡Guardado exitosamente!',
  save_failure: '¡Error al guardar!',
  close: 'cierre',
  hztxt: 'Confirmar transferencia',
  submittip1:
    'Para la seguridad de sus fondos, realice primero la [autenticación de nombre real].',
  submittip2:
    'Para la seguridad de sus fondos, realice primero la [vinculación del teléfono móvil].',
  submittip3:
    'Para la seguridad de sus fondos, ¡establezca primero una [contraseña de fondo]!',
  submittip4: 'Vincula al menos un método de pago',
  aderr1: 'Por favor ingrese el número correcto',
  aderr2: 'El valor de la prima es 0-20',
  aderr3: 'El valor de la prima es 0-20 y no puede ser 0.',
  aderr4: 'Por favor ingrese el precio fijo correcto',
  aderr5:
    'Ingrese el número correcto y el monto máximo de transacción no exceda las 100 monedas',
  orderTypeNo: 'No',
  orderTypeYes: 'Sí',
  win: 'éxito',
  lose: 'fallar',
  tied: 'dibujar',
  cancelsuccess: '¡Cancelación exitosa!',
  shelves: '¡Los anuncios solo se pueden eliminar después de eliminarlos!',
  shelves2:
    '¡La edición solo se puede realizar después de eliminar el anuncio!',
  business: '¡Solicite primero la certificación de comerciante!',
  swappleaseselectwallet:
    'Seleccione primero la cuenta de transferencia/transferencia',
  swappleaseinputamount: 'Por favor ingrese el monto de la transferencia',
  clickchange: 'Haga clic para cambiar la dirección del deslizamiento',
  daterange: 'Por favor seleccione un rango de fechas de búsqueda',
  apierr: 'Por favor ingrese la información del comentario',
  apierr2: 'Por favor ingrese la dirección IP',
  apierr3: 'Por favor ingrese la dirección IP correcta',
  apierr4: 'por favor ingrese el código de verificación',
  clickget: 'Haga clic para obtener',
  apisecond: 'Segundo',
  apitips: 'La clave solo se mostrará cuando se agregue, guárdela a tiempo',
  apiaccess: 'llave',
  do_favorite: 'recogido',
  cancel_favorite: 'Cancelar favoritos',
  fail_favorite: 'fallar',
  deptotal: 'gran total',
  chart: 'cuadro',
  book: 'libro de pedidos',
  trade: 'última transacción',
  pt_title: 'Tarjeta de regalo en moneda digital',
  pt_desc:
    'Después del canje, automáticamente se convertirá en un amigo de nivel inferior y podrá enviar amigos, familiares, clientes y socios como obsequios. ',
  pt_more: 'Más herramientas, estad atentos',
  pt_tips:
    'Si tiene una buena idea, envíe un correo electrónico a promoción@al-cex.com y será recompensado si la adopta.',
  pt_card_amount: 'Importe nominal de la tarjeta',
  pt_card_deadline: 'Periodo de validez',
  pt_card_noend: 'indefinidamente',
  pt_card_btn:
    'Los socios promocionales reciben 30 entradas gratis (unos 2.000 yuanes)',
  pt_card_btn_login: 'Inicia sesión y consigue 30 entradas gratis',
  pt_card_rule: 'Detalles de la regla',
  pt_card_summary:
    'Las tarjetas de regalo en moneda digital son una de las herramientas de promoción desarrolladas para permitir a los socios de promoción invitar mejor a amigos fuera de línea. Cuando los usuarios objetivo de la promoción canjean tarjetas de regalo, automáticamente se convertirán en amigos fuera de línea del promotor.',
  pt_card_rule1:
    '1. Cada usuario está limitado a 30 tarjetas de regalo gratuitas. ',
  pt_card_rule2:
    '2. Cada usuario está limitado a 1 tarjeta de regalo gratuita. ',
  pt_card_rule3:
    '3. Antes de que el usuario canjee la tarjeta de regalo, si no hay un "invitador" (es decir, no se ingresó ningún código de invitación al registrar la cuenta), el usuario será asociado automáticamente como amigo de primer nivel del emisor de la tarjeta. ',
  pt_card_rule4:
    '4. Para evitar transacciones fraudulentas, el monto de la cuenta de la tarjeta de regalo no se canjea en tiempo real y la autenticación del nombre real debe completarse después de la recepción antes de la operación.',
  pt_card_rule5:
    '5. Debe completar la autenticación de nombre real al recibir tarjetas de regalo gratuitas. No es necesario completar la autenticación de nombre real al canjear. Esto es para facilitar que los promotores obtengan amigos de nivel inferior más rápido.',
  pt_card_rule6:
    '6. El derecho de interpretación final de la tarjeta regalo gratuita pertenece a Alcex.',
  pt_card_day: 'cielo',
  pt_card_title_tips: 'Reciba una subvención de promoción de 2.000 yuanes',
  pt_invite_desc:
    'Código QR exclusivo, puede convertirse automáticamente en un amigo subordinado mediante el registro del código QR, adecuado para reenviar en varios grupos o foros.',
  saveimage: 'guardar foto',
  imagetips:
    'Si no puede descargar la imagen normalmente, utilice una herramienta de captura de pantalla para tomar una captura de pantalla y guardarla.',
  pt_card_receivew_success: '¡Felicidades! ',
  usepromotion: 'Utilice materiales promocionales.',
  context_title: 'Código de canje exclusivo para socios de promoción',
  context_title1: 'Escanee para obtener más información',
  context_title2: 'mi código de invitación',
  context_title3: 'Foto de invitación',
  pt_card_title: 'Tarjeta de promoción de socios',
  copysuccess: '¡Copia exitosamente!',
  copyerr: '¡Copia fallida! ',
  noopening: 'Actualmente no hay contratos de opciones pendientes',
  createSuccess: 'Creado con éxito',
  tradewarning1: 'Introduzca hasta 2 decimales',
  tradewarning2: 'El monto del pedido es',
  tradewarning3: 'Introduzca hasta 8 decimales',
  tradewarning4: 'La cantidad del pedido es',
  tradeconfirmbuyin: 'Confirmar compra',
  tradeconfirmsellout: 'Confirmar venta',
  tradeinfo: {
    emaildone: 'Certificado por correo electrónico',
    emailundo: 'Correo electrónico no autenticado',
    teldone: 'El número de teléfono móvil ha sido verificado.',
    telundo: 'Número de teléfono móvil no verificado',
    idcarddone: 'La tarjeta de identificación ha sido verificada.',
    idcardundo: 'DNI no autenticado',
    exchangetimes: 'Número de transacciones',
    price: 'precio',
    num: 'cantidad',
    paymethod: 'método de pago',
    exchangelimitamount: 'Límite de transacciones',
    location: 'ubicación',
    location_text: 'Porcelana',
    exchangeperiod: 'Período de transacción',
    minute: 'minuto',
    amounttip: 'Por favor ingrese la cantidad',
    numtip: 'Por favor ingrese la cantidad',
    remarktip: 'dile tu petición',
    remarktitle: 'Observaciones',
    exchangetitle: 'Instrucciones comerciales',
    exchange_tip1:
      'Después de iniciar una solicitud de transacción, la moneda digital queda bloqueada en depósito de garantía y protegida por la plataforma. ',
    exchange_tip2:
      'Antes de operar, lea los "Términos del servicio de Platform Network" y los documentos de ayuda, como las preguntas frecuentes y las guías de transacciones.',
    exchange_tip3: '¡Cuidado con los estafadores! ',
    exchange_tip4:
      'Tenga en cuenta que el redondeo y las fluctuaciones de precios pueden afectar la cantidad final de moneda digital negociada. ',
    exchange_tip5:
      'Los servicios de depósito en garantía protegen a los compradores y vendedores de transacciones en línea. ',
    warning1: 'Introduzca hasta 2 decimales',
    warning2: 'El monto del pedido es',
    warning3: 'Introduzca hasta 8 decimales',
    warning4: 'La cantidad del pedido es',
    confirmbuyin: 'Confirmar compra',
    confirmsellout: 'Confirmar venta',
    buyin: 'comprar',
    sellout: 'vender',
    warning5: 'Por favor complete el pedido según sea necesario',
  },
  convertResult:'Предварительный просмотр результата преобразования',
  willGet:'Вы получите',
  pleaseCommit: 'Пожалуйста, нажмите для подтверждения в течение этого времени. ',
  returnText:'return',
  convert:'конвертировать'
};
